export const calculateTotalCost = (
  shipmentAmount,
  deliveryFee,
  insuranceAmount,
  lastMileFee
) => {
  const newInsuranceAmount =
    typeof insuranceAmount === 'string' ? 0 : insuranceAmount;
  const newDeliveryFee = typeof deliveryFee === 'string' ? 0 : deliveryFee;
  const newLastMileFee = lastMileFee ?? 0;

  return shipmentAmount + newDeliveryFee + newInsuranceAmount + newLastMileFee;
};

export const extendedInsuranceLimits = Object.freeze({
  min: 300_000
});

const insurancePlans = [
  {
    name: 'Basic Insurance',
    summary: 'Covers damages up to ₦10,000',
    cost: 'FREE',
    value: 'None',
    amount: 0.0
  },
  {
    name: 'Premium Insurance',
    summary: 'Covers damages up to ₦300,000',
    cost: '₦12,000',
    value: 'Premium',
    amount: 12000.0
  },
  {
    name: 'Extended Coverage',
    summary: 'Covers damages up to ₦1,000,000',
    value: 'Extended',
    price: value =>
      !!value && value >= extendedInsuranceLimits.min
        ? Number((Number(value) * 0.05).toFixed(2))
        : 0
  }
];

export default insurancePlans;

export default function extractFilename(filename = '') {
  if (filename && typeof filename === 'string') {
    const names = filename.split('/');
    if (names.length <= 4) {
      return decodeURIComponent(names[names.length - 1]);
    } else {
      const newNames = names.filter((_, index) => index >= 3);
      return decodeURIComponent(newNames.join(''));
    }
  } else {
    throw new TypeError('File name is not a string');
  }
}

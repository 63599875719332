import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { useHistory, Link, useLocation } from 'react-router-dom';
import SubmitButton from '../../../components/submit-button';
import { useLoginMutation } from '../../../operations/mutations';
import { useShowAuthPage } from '../../../utilities/show-auth-pages';
import ContentLayout from '../../../components/Layouts/content-layout';
import Input from '../../../components/new-dashboard/custom-input';
import { TopShipBlueLogo } from '../../../constants/asset-contants';
import Checkbox from '@mui/material/Checkbox';
import ContactSupport from '../../../components/contact-support';
import { shipmentOptions } from '../../../constants/book-shipment-contants';
import { useRecoilValue } from 'recoil';
import { saveOrderSummaryPathState } from '../../../recoil/atoms';
import { useGetBusinessInformation } from '../../../operations/queries';

const Login = () => {
  const history = useHistory();
  const OrderSummaryPathState = useRecoilValue(saveOrderSummaryPathState);
  const [login, { loading, error, data }] = useLoginMutation();
  const [renderPage] = useShowAuthPage();
  const [checked, setChecked] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location?.search);
  const businessId = urlParams.get('lsp');

  const [getBusinessInfo, businessInfoResult] = useGetBusinessInformation();

  const businessInfo = businessInfoResult?.data;

  const lspInfoFalse = Boolean(!businessInfo && !businessInfoResult?.loading);
  const constants = {
    shipmentDetail: 'saveShipmentDetailsState',
    shipmentOption: 'shipmentOptionSelectionState'
  };
  const handleChecked = event => {
    setChecked(event.target.checked);
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('What is your email address?'),
    password: yup
      .string()
      .min(8, 'Please enter a password that is longer than 8 characters')
      .required('Please enter your password')
  });

  const {
    errors,
    touched,
    values,
    setFieldValue,
    handleSubmit,
    handleChange
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema,
    onSubmit: async values => {
      login(values);
    }
  });
  const shipmentDetail = JSON.parse(
    localStorage.getItem(constants.shipmentDetail)
  )?.saveShipmentDetailsState;
  const shipmentOption = JSON.parse(
    localStorage.getItem(constants.shipmentOption)
  )?.shipmentOptionSelectionState;

  useEffect(() => {
    if (shipmentDetail) {
      const detail =
        shipmentOption?.id ===
        shipmentOptions.find(option => option.value === 'Import').id
          ? shipmentDetail?.receiverDetail
          : shipmentDetail?.senderDetail;
      setFieldValue('email', detail?.email);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && !error) {
      if (shipmentDetail) {
        history.push(OrderSummaryPathState?.path, {
          search: !!businessId && location?.search
        });
      } else {
        history.push('/');
      }
    }
    // eslint-disable-next-line
  }, [data, error]);

  useLayoutEffect(() => {
    if (!!businessId) {
      getBusinessInfo(businessId);
    }
    // eslint-disable-next-line
  }, [businessId]);

  return renderPage(
    <div>
      <Helmet>
        <meta name='description' content='Topship Africa Login' />
        <title>Topship Africa Login</title>
        <link rel='canonical' href='https://topship.africa/login' />
      </Helmet>
      {lspInfoFalse && <ContactSupport />}

      <ContentLayout
        title='Welcome back'
        businessUser={businessInfo?.getBusinessInformationByUser}
        result={businessInfoResult}
        imageSource={TopShipBlueLogo}
        imageAltText='Topship Blue logo'
      >
        <div className='form-container'>
          <form onSubmit={handleSubmit}>
            <div className='form-item'>
              <label className='form-item-label' htmlFor='email'>
                Email address
              </label>
              <Input
                fullWidth
                id='email'
                name='email'
                placeholder='email@domain.com'
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </div>
            <div className='form-item'>
              <label className='form-item-label' htmlFor='password'>
                Password
              </label>
              <Input
                fullWidth
                id='password'
                name='password'
                placeholder='Enter a password'
                value={values.password}
                onChange={handleChange}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />
            </div>

            <p className='remember-me'>
              <span>
                <Checkbox
                  id='remember-me'
                  label='Remember me'
                  checked={checked}
                  onChange={handleChecked}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                />{' '}
                Remember me
              </span>
              <span>
                <Link
                  className='link-to'
                  to={{
                    pathname: '/forgot-password',
                    search: !!businessId && location.search
                  }}
                >
                  Forgot password?
                </Link>
              </span>
            </p>

            <SubmitButton
              text='Login'
              disabled={loading}
              loading={loading}
              onClick={() =>
                ReactGA.event({
                  category: 'Authentication',
                  action: 'User pressed the log in button'
                })
              }
            />
          </form>
          <p className='new-here'>
            New here? &nbsp;
            <span>
              <Link
                className='link-to'
                to={{
                  pathname: '/signup',
                  state: OrderSummaryPathState,
                  search: !!businessId && location?.search
                }}
              >
                Create an account
              </Link>
            </span>
          </p>
        </div>
      </ContentLayout>
    </div>
  );
};

export default Login;

import { IconButton } from '@mui/material';
import { DropdownIcon, InfoBlueIcon, PolygonIcon } from './asset-contants';
import { budgetRatePerCountries } from './book-shipment-contants';
import { Close } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';

export const BudgetPriceRatePrompt = ({
  howItWorks,
  setHowItWorks,
  isMultiple = false
}) => {
  const [options, setOptions] = useState({
    country: '',
    toggleDropdown: false
  });
  const budgetRatePerCountry = useMemo(() => {
    const budgetData = budgetRatePerCountries.find(rate =>
      rate.country.includes(options.country)
    );
    return budgetData ? budgetData.rates : { nonFlats: '', flats: [] }; // Return a default value if no data is found
  }, [options.country]);

  useEffect(() => {
    setOptions(options => ({
      ...options,
      country: budgetRatePerCountries[0].country
    }));
  }, []);
  return (
    <div onClick={() => setHowItWorks(!howItWorks)} className={`how-it-works `}>
      <img
        style={{
          height: '20px',
          paddingRight: '6px'
        }}
        src={InfoBlueIcon}
        alt=''
      />
      <p className='mb-0'>See how budget works</p>
      {howItWorks && (
        <div
          className={`absolute-position ${
            isMultiple ? 'top-style' : 'bottom-style'
          }`}
        >
          {isMultiple && (
            <img
              style={{
                paddingRight: '6%',
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                transform: 'rotate(180deg)'
              }}
              src={PolygonIcon}
              alt=''
            />
          )}
          <div className='how-guide'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                cursor: 'pointer'
              }}
            >
              <IconButton onClick={() => setHowItWorks(true)}>
                <Close />
              </IconButton>
            </div>

            <h3
              style={{
                color: '#545859',
                fontSize: '18px',
                lineHeight: '22px',
                fontWeight: 600,
                textAlign: 'left'
              }}
            >
              How budget works
            </h3>
            <p
              className=''
              style={{
                color: '#545859',
                fontSize: '14px',
                lineHeight: '22px',
                fontWeight: 400,
                textAlign: 'left'
              }}
            >
              Send More, Spend Less!
            </p>
            <div
              style={{
                position: 'relative'
              }}
            >
              <p
                style={{
                  color: '#545859',
                  fontSize: '14px',
                  lineHeight: '22px',
                  fontWeight: 500,
                  textAlign: 'left'
                }}
              >
                Select country:
              </p>
              <div
                style={{
                  position: 'relative'
                }}
              >
                <div
                  onClick={e => {
                    e.stopPropagation();
                    setOptions(options => ({
                      ...options,
                      toggleDropdown: !options.toggleDropdown
                    }));
                  }}
                  className='budget-country-lists'
                >
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}
                  >
                    {options.country}{' '}
                  </span>
                  <img
                    className='faq-item-accordion-button'
                    src={DropdownIcon}
                    style={{
                      width: '20px',
                      transform: options.toggleDropdown
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                      transition: 'transform 0.3s ease-in-out',
                      cursor: 'pointer'
                    }}
                    alt='accordion toggle button'
                  />
                </div>
                {options.toggleDropdown && (
                  <div
                    style={{
                      marginTop: '20px'
                    }}
                    className='country-popup'
                  >
                    {budgetRatePerCountries.map((c, i) => {
                      return (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            cursor: 'pointer'
                          }}
                          onClick={e => {
                            e.stopPropagation();
                            setOptions(prev => ({
                              ...prev,
                              country: c.country,
                              toggleDropdown: false
                            }));
                          }}
                          key={i}
                        >
                          <span>{c.country} </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div>
              {options.country.includes('Rest of Africa') ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    border: '1px solid rgb(226, 228, 232)',
                    padding: '30px 10px',
                    borderRadius: '6px'
                  }}
                >
                  <p
                    style={{
                      fontSize: '30px'
                    }}
                  >
                    🌍
                  </p>
                  <p
                    style={{
                      fontSize: '16px',
                      fontWeight: 500
                    }}
                  >
                    Coming soon!
                  </p>
                </div>
              ) : (
                <div className='rates-wrap'>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <p className='shipping-text'>Non-flat rates</p>

                    <div
                      className='mb-2'
                      style={{
                        color: '#22428F',
                        fontSize: '14px',
                        fontWeight: 500
                      }}
                    >
                      💰 {budgetRatePerCountry?.nonFlats}
                    </div>
                  </div>
                  <span
                    style={{
                      borderRight: '1px solid #E2E4E8'
                    }}
                  ></span>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <p className='shipping-text'>Flat rates</p>

                    {budgetRatePerCountry.flats.map(rate => {
                      return (
                        <div
                          className='mb-2'
                          style={{
                            color: '#22428F',
                            fontSize: '14px',
                            fontWeight: 500
                          }}
                        >
                          💰 {rate}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <i
              style={{
                fontSize: '12px',
                display: 'block',
                textAlign: 'left',
                paddingTop: '15px',
                fontWeight: 500
              }}
            >
              Includes customs clearance
            </i>
          </div>
          {!isMultiple && (
            <img
              style={{
                paddingLeft: '6%',
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              src={PolygonIcon}
              alt=''
            />
          )}
        </div>
      )}
    </div>
  );
};

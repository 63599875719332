import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';
import ReactGA from 'react-ga';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment
} from '@mui/material';
import {
  AddCircle,
  ChevronRight,
  RemoveCircle,
  SwapVert
} from '@mui/icons-material';
import Input from '../../../../components/new-dashboard/custom-input';
import CustomAutoComplete from '../../../../components/new-dashboard/custom-autocomplete';
import SubmitButton from '../../../../components/submit-button';
import {
  useGetCountriesLazyQuery,
  useGetSavedAddresses,
  useGetStatesQuery,
  useGetUserLazyQuery
} from '../../../../operations/queries';
import ChooseAddressPrompt from '../../../../components/modals/choose-address-prompt';
import debounce from 'lodash.debounce';
import {
  useGetCitiesMutation,
  useSaveAddressMutation,
  useUpdateAddressMutation
} from '../../../../operations/mutations';
import BusyOverlay from '../../../../components/busy-overlay';
import NewShippingStepsLayout from '../../../../components/Layouts/new-shipping-steps-layout';
import { useRecoilState } from 'recoil';
import {
  addressTotalState,
  multiplePhoneNumbersState,
  saveShipmentDetailsState
} from '../../../../recoil/atoms';
import {
  defaultDetail,
  shipmentDestinationTypes,
  shipmentOptions
} from '../../../../constants/book-shipment-contants';
import {
  multipleShipmentLinks,
  singleShipmentLinks
} from '../../../../constants/shipment-links';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { checkIsCompleted } from '../../helper-functions';
import usePlaces from '../../../../utilities/use-places';
import { extractLocation } from '../../../../utilities/extract-location';
import { useAuthToken } from '../../../../token';
import omit from 'lodash.omit';
import { useSendAlert } from '../../../../utilities/send-alert';
const init = {
  id: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  country: '',
  countryCode: '',
  email: '',
  name: '',
  phoneNumber: '',
  postalCode: '',
  state: '',
  shouldSave: false
};
const SenderInfo = () => {
  const history = useHistory();
  const sendAlert = useSendAlert();
  const addressLine1Ref = useRef();
  const addressLine2Ref = useRef();
  const addressLine3Ref = useRef();
  const [addressTotal] = useRecoilState(addressTotalState);
  const [{ sender: phoneNumbers }, setPhoneNumbers] = useRecoilState(
    multiplePhoneNumbersState
  );

  const [shipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  const [place, setPlace] = useState({
    description: shipmentDetail?.senderDetail?.addressLine1 || ''
  });
  const shipmentDestination = shipmentDestinationTypes.find(
    destination => shipmentDetail.destinationType === destination.value
  );
  const selectedOption = shipmentOptions.find(
    option => shipmentDetail.shipmentRoute === option.value
  );
  const [, , , getToken] = useAuthToken();
  const isLoggedIn = Boolean(getToken());
  const [isAutomated, setIsAutomated] = useState(false);
  const [initialValues, setInitialValues] = useState(init);
  const [saveAddress] = useSaveAddressMutation();
  const [updateAddress] = useUpdateAddressMutation();
  const [openChooseAddressPrompt, setOpenChooseAddressPrompt] = useState(false);
  const [promptLoading, setPromptLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [getUser, user] = useGetUserLazyQuery(setInitialValues, 'sender');
  const [getCountries, countries] = useGetCountriesLazyQuery(countries => {
    setInitialValues(init => ({
      ...init,
      ...shipmentDetail?.senderDetail,
      country:
        shipmentDetail?.senderDetail?.countryCode &&
        !shipmentDetail?.senderDetail?.country
          ? countries?.find(
              country =>
                country?.code?.toLowerCase() ===
                shipmentDetail?.senderDetail?.countryCode?.toLowerCase()
            )?.name
          : shipmentDetail?.senderDetail?.country
    }));
  }, 'no-cache');
  const [getStates, states] = useGetStatesQuery();
  const [getCities, citiesResult] = useGetCitiesMutation();
  const isImport = selectedOption?.value === shipmentOptions[1].value;
  const shipmentLinks =
    shipmentDestination?.value === shipmentDestinationTypes[1].value
      ? multipleShipmentLinks
      : singleShipmentLinks;

  const currentIndex = shipmentLinks.findIndex(
    link => history.location.pathname === link.link
  );

  const { getPlaces, placesService, placesResult } = usePlaces();
  const [getAddresses, getAddressesResult] = useGetSavedAddresses();

  const loading =
    citiesResult.loading ||
    states.loading ||
    countries.loading ||
    user.loading ||
    promptLoading ||
    placesResult.loading ||
    getAddressesResult.loading;

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .test(
        'number of words',
        'Name should not be less than 2 words',
        value => value?.split(' ')?.length >= 2
      )
      .required('Please enter a name'),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('please enter an email address'),
    phoneNumber: yup.string().required('Please enter a phone number'),
    addressLine1: yup
      .string()
      .max(
        40,
        'Please your address must not exceed 40 characters, use the next input.'
      )
      .test(
        'contains the + character',
        'Please enter a valid address',
        value => !value?.includes('+')
      )
      .required('Please enter an address'),
    addressLine2: yup
      .string()
      .max(
        40,
        'Please your address must not exceed 40 characters, use the next input.'
      )
      .test(
        'contains the + character',
        'Please enter a valid address',
        value => !value?.includes('+')
      )
      .nullable(),
    addressLine3: yup
      .string()
      .max(40, 'Please your address must not exceed 40 characters')
      .test(
        'contains the + character',
        'Please enter a valid address',
        value => !value?.includes('+')
      )
      .nullable(),
    country: yup.string().max(35).required('Please select a country'),
    state: yup.string().max(35).required('Please select a state'),
    city: yup.string().max(35).required('Please select a city'),
    countryCode: yup.string().required('Please select a country'),
    postalCode: yup.string().when(['countryCode'], {
      is: countryCode =>
        isImport || countryCode?.toLowerCase() !== defaultDetail.countryCode,
      then: yup.string().required('Please provide a postal code'),
      otherwise: yup.string().nullable().notRequired()
    })
  });

  const {
    values,
    errors,
    handleChange,
    validateForm,
    handleSubmit,
    setFieldValue,
    setFieldError
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      const senderDetail = omit({ ...values, isCompleted: true }, [
        'shouldSave'
      ]);
      const shipmentData = {
        ...shipmentDetail,
        senderDetail
      };

      shipmentData.senderDetail.countryCode = senderDetail?.countryCode?.toUpperCase();

      if (values.shouldSave) {
        const cleanSenderDetail = omit(senderDetail, [
          'isCompleted',
          'updatedDate',
          'createdDate'
        ]);

        if (values.id) {
          updateAddress(omit(cleanSenderDetail, ['id']), values.id);
        } else {
          saveAddress(omit(cleanSenderDetail, ['id']));
        }
      }
      setSaveShipmentDetail(shipmentData);
      history.push(shipmentLinks[currentIndex + 1].link);
    }
  });

  const countryCode = values.countryCode;
  const choseNigeria = countryCode?.toLowerCase() === defaultDetail.countryCode;
  const choseNigeriaOnImportMessage =
    'Please do not select a nigerian address when importing';
  const addresses =
    isEdit || (shipmentDetail?.senderDetail?.name && isLoaded)
      ? [isEdit ? values : shipmentDetail?.senderDetail]
      : getAddressesResult.data?.getAddresses?.list || [];

  const importCountries = isImport
    ? countries.data?.getCountries?.filter(
        country => country?.name !== 'Nigeria'
      )
    : countries.data?.getCountries;

  const handleLocationSelect = place => {
    const { name, value, countryCode } = place;
    countryCode && setFieldValue('countryCode', countryCode);
    handleChange({ target: { name, value } });
  };

  const handleValidations = async values => {
    const errors = await validateForm(values);
    return !Boolean(Object.values(errors).length);
  };

  const handleValidationOnSubmit = event => {
    event.preventDefault();

    if (!choseNigeria && !values.postalCode) {
      return setFieldError('postalCode', 'Please provide a postal code');
    }

    if (isImport && choseNigeria) {
      return setFieldError('country', 'Please choose another country');
    }

    handleValidations(values).then(isValid => {
      if (isValid) {
        handleSubmit(event);
      }
    });
  };

  const handleEditAddress = address => {
    const choseNigeria =
      address?.countryCode?.toLowerCase() === defaultDetail.countryCode;
    if (isImport && choseNigeria) {
      return sendAlert(choseNigeriaOnImportMessage, 'error');
    }
    setIsEdit(true);
    setPlace(place => ({ ...place, description: address?.addressLine1 }));
    setInitialValues({ ...address, shouldSave: true });
    getStates(address.countryCode);
    getCities({
      countryCode: address.countryCode,
      cityName: address.city
    });
  };

  const onSelectAddress = async (address, promptLoading, shouldSave) => {
    setPromptLoading(promptLoading);
    setInitialValues({ ...address, shouldSave });
    return await handleValidations(address);
  };

  const handleSelectAddress = address => {
    const choseNigeria =
      address?.countryCode?.toLowerCase() === defaultDetail.countryCode;
    if (isImport && choseNigeria) {
      return sendAlert(choseNigeriaOnImportMessage, 'error');
    }
    onSelectAddress(address, true, false).then(isValid => {
      setPromptLoading(false);
      if (
        address?.countryCode?.toLowerCase() !== defaultDetail.countryCode &&
        !address?.postalCode
      ) {
        setFieldError('postalCode', 'Please provide a postal code');
      } else if (isValid) {
        handleSubmit();
      }
    });
  };

  const handleSelectPlace = place => {
    placesService?.getDetails(
      { placeId: place?.place_id },
      (placeInfo, placeStatus) => {
        ReactGA.event({
          category: 'Shipment Booking',
          action: `User used autocomplete and got this status in sender booking - ${placeStatus}`
        });
        const { countryCode, city, country, state, address } = extractLocation(
          placeInfo
        );
        const choseNigeria =
          countryCode?.toLowerCase() === defaultDetail.countryCode;

        if (isImport && choseNigeria) {
          return sendAlert(choseNigeriaOnImportMessage, 'error');
        }
        getStates(countryCode);
        getCities({ countryCode, cityName: city });
        const cleanAddress = address
          ?.split(', ')
          ?.slice(0, address?.split(', ').length - 2)
          ?.join(', ');
        const addressLine1 = cleanAddress.substring(0, 40);
        const addressLine2 = cleanAddress.substring(40, 80);
        const addressLine3 = cleanAddress.substring(80, 120);
        const newAddress = {
          ...values,
          ...(addressLine2 && { addressLine2 }),
          ...(addressLine3 && { addressLine3 }),
          ...(choseNigeria && { postalCode: '100001' }),
          city:
            citiesResult.data.length &&
            citiesResult.data.find(
              initCity => initCity?.city?.toLowerCase() === city?.toLowerCase()
            )
              ? city
              : '',
          addressLine1,
          country,
          state,
          countryCode
        };
        setInitialValues(newAddress);
        setIsAutomated(false);
        setPlace({ description: newAddress.addressLine1 });
      }
    );
  };

  const addPhoneNumber = () => {
    setPhoneNumbers(numbers => ({
      ...numbers,
      sender: [...numbers.sender, { index: phoneNumbers.length, number: '' }]
    }));
  };

  const removePhoneNumber = index => () => {
    setPhoneNumbers(numbers => ({
      ...numbers,
      sender: numbers.sender.filter(phone => phone.index !== index)
    }));
  };

  const getCountryCode = input => {
    const term = input?.terms[input?.terms?.length - 1];
    const value = term?.value === 'USA' ? 'United states' : term.value;
    return countries.data?.getCountries
      ? countries.data?.getCountries
          .find(country => country?.name.toLowerCase() === value?.toLowerCase())
          ?.code?.toLowerCase() || defaultDetail.countryCode
      : defaultDetail.countryCode;
  };

  useEffect(() => {
    getCountries();
    if (!isImport && !shipmentDetail.senderDetail?.name) {
      getUser();
    }
    if (shipmentDetail?.senderDetail?.city) {
      getStates(shipmentDetail?.senderDetail?.countryCode);
      getCities({
        countryCode: shipmentDetail.senderDetail?.countryCode,
        cityName: shipmentDetail.senderDetail?.city
      });
    }
    if (shipmentDetail?.senderDetail?.addressLine1) {
      getPlaces(shipmentDetail?.senderDetail?.addressLine1);
    }
    getAddresses({
      take: 10,
      page: 1
    });
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      if (countryCode && !isAutomated) {
        getStates(countryCode);
        getCities({
          countryCode,
          cityName: shipmentDetail.senderDetail?.city || 'a'
        });
      }
    },
    // eslint-disable-next-line
    !isEdit ? [countryCode] : [null]
  );

  useEffect(() => {
    if (history.location.state?.reValidate) {
      const isCompleted = checkIsCompleted(currentIndex, shipmentDetail);
      if (!isCompleted) {
        handleValidations(values);
      }
    }
    history.replace({
      state: {}
    });
    // eslint-disable-next-line
  }, [history.location.state?.reValidate]);

  return (
    <NewShippingStepsLayout>
      <Helmet>
        <meta
          name='description'
          content='Topship Africa Shipment Request Sender Information'
        />
        <title>Topship Africa Shipment Request Sender Information</title>
        <link
          rel='canonical'
          href='https://topship.africa/shipment-request-sender-information'
        />
      </Helmet>
      <BusyOverlay loading={loading} />
      <ChooseAddressPrompt
        sender={values.id}
        openPrompt={openChooseAddressPrompt}
        setOpenPrompt={setOpenChooseAddressPrompt}
        onEditAddress={handleEditAddress}
        onSelectAddress={handleSelectAddress}
      />
      <form onSubmit={handleValidationOnSubmit} className='sender-info'>
        <label className='sender-info__label'>
          Please provide the Sender’s information
        </label>
        {addressTotal > 0 && (
          <>
            <Button
              variant='outlined'
              className='sender-info__select'
              onClick={() => setOpenChooseAddressPrompt(true)}
            >
              <span>Choose from a saved address</span>
              <ChevronRight />
            </Button>
            <div className='sender-info__divider'>
              <span></span>
              <span>OR</span>
              <span></span>
            </div>
          </>
        )}

        <Grid container columnSpacing='10px' rowSpacing='16px'>
          <CustomAutoComplete
            options={addresses}
            getOptionLabel={option => option?.name}
            freeSolo
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option) => (
              <Box
                key={option.id}
                component='li'
                sx={{
                  textTransform: 'capitalize',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start !important',
                  justifyContent: 'flex-start !important'
                }}
                {...props}
              >
                <p className='d-block'>
                  <b>{option?.name}</b>
                </p>
                <span className='d-block'>{option.addressLine1}</span>
              </Box>
            )}
            value={
              shipmentDetail?.senderDetail?.name && isLoaded
                ? addresses[0]
                : addresses.find(address => address.id === values.id)
            }
            noOptionsText={
              !values.country
                ? 'Please enter your name'
                : getAddressesResult.loading
                ? 'Addresses loading...'
                : 'Saved address not found'
            }
            onChange={(event, value) => {
              const choseNigeria =
                value?.countryCode?.toLowerCase() === defaultDetail.countryCode;

              if (isImport && choseNigeria) {
                return sendAlert(choseNigeriaOnImportMessage, 'error');
              }
              if (value?.id) {
                getStates(value?.countryCode);
                getCities({
                  countryCode: value?.countryCode,
                  cityName: value?.city
                });
                setPlace({ description: value?.addressLine1 });
                setInitialValues(value);
              }
            }}
            textfieldProps={{
              lg: 12,
              md: 12,
              name: 'name',
              customLabel: 'Full Name',
              containerClassName: 'new-addresses__modal__item__input',
              error: Boolean(errors.name),
              helperText: errors.name,
              value: values.name,
              onChange: debounce(event => {
                if (addressTotal) {
                  getAddresses({
                    take: 10,
                    page: 1,
                    keyword: event?.target?.value
                  });
                }
                setFieldValue('name', event?.target?.value);
                if (isLoaded) {
                  setIsLoaded(false);
                  setIsEdit(false);
                }
              }, 1000)
            }}
          />
          <Input
            error={Boolean(errors.email)}
            helperText={errors.email}
            value={values.email}
            onChange={handleChange}
            customLabel='Email address'
            name='email'
            id='email'
            type='email'
            containerClassName='new-addresses__modal__item__input'
            placeholder='garrick@topship.com'
            fullWidth
            lg={6}
            md={12}
          />
          <Input
            id='phoneNumber'
            name='phoneNumber'
            error={Boolean(errors.phoneNumber)}
            helperText={errors.phoneNumber}
            onChange={value =>
              handleChange({ target: { name: 'phoneNumber', value } })
            }
            defaultCountry={isImport ? 'us' : defaultDetail.countryCode}
            value={values.phoneNumber}
            type='tel'
            placeholder='+234 801 234 5678'
            containerClassName='new-addresses__modal__item__input'
            customLabel='Phone Number'
            lg={6}
            md={12}
          />
        </Grid>
        <div className='mt-3 item-description__action switcher'>
          <Button variant='text' onClick={() => setIsAutomated(bool => !bool)}>
            <span>
              {isAutomated
                ? 'Switch to manually fill address details'
                : 'Switch to automatically fill address details'}
            </span>
            <SwapVert />
          </Button>
        </div>
        <Grid container columnSpacing='10px' rowSpacing='16px'>
          {isAutomated ? (
            <CustomAutoComplete
              options={placesResult.data}
              getOptionLabel={option => (option ? option?.description : '')}
              isOptionEqualToValue={(option, value) =>
                option?.place_id === value?.place_id
              }
              renderOption={(props, option, data) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                  key={props.id}
                >
                  {data && (
                    <img
                      loading='lazy'
                      width='30'
                      height='20'
                      src={`https://flagcdn.com/w20/${getCountryCode(
                        option
                      )}.png`}
                      srcSet={`https://flagcdn.com/w40/${getCountryCode(
                        option
                      )}.png 2x`}
                      alt=''
                    />
                  )}
                  {option?.description}
                </Box>
              )}
              filterOptions={option => option}
              value={place}
              noOptionsText={
                !place?.description
                  ? 'Please enter your address'
                  : placesResult.loading
                  ? 'Addresses loading...'
                  : 'Address not found'
              }
              onChange={(event, value, reason) => {
                if (reason === 'clear') {
                  setPlace({ description: '' });
                } else {
                  handleSelectPlace(value);
                }
              }}
              textfieldProps={{
                lg: 12,
                md: 12,
                name: 'addressLine1',
                customLabel: 'Address',
                containerClassName: 'new-addresses__modal__item__input',
                value: place?.description,
                error: Boolean(!place.description && errors.addressLine1),
                helperText: errors.addressLine1,
                onChange: event => {
                  setPlace(place => ({
                    ...place,
                    description: event.target.value
                  }));
                  getPlaces(event.target.value);
                }
              }}
            />
          ) : (
            <>
              <Input
                error={Boolean(errors.addressLine1)}
                helperText={errors.addressLine1}
                value={values.addressLine1}
                onChange={event => {
                  const {
                    target: { name, value }
                  } = event;
                  if (value.length < 41) {
                    handleChange(event);
                  } else {
                    handleChange({
                      target: {
                        name,
                        value: value.substring(0, 40)
                      }
                    });
                    setFieldValue(
                      'addressLine2',
                      value.substring(40, value.length > 80 ? 80 : value.length)
                    );
                    if (value.length > 80) {
                      setFieldValue(
                        'addressLine3',
                        value.substring(
                          80,
                          value.length > 120 ? 120 : value.length
                        )
                      );
                    }
                    addressLine2Ref.current?.focus();
                  }
                }}
                customLabel='Address'
                name='addressLine1'
                id='addressLine1'
                type='text'
                containerClassName='new-addresses__modal__item__input'
                placeholder='1, Idumota lane, Iweka Road'
                fullWidth
                inputProps={{
                  ref: addressLine1Ref
                }}
              />
              <Input
                error={Boolean(errors.addressLine2)}
                helperText={errors.addressLine2}
                value={values.addressLine2}
                onChange={event => {
                  const {
                    target: { name, value }
                  } = event;
                  if (value.length < 41) {
                    handleChange(event);
                    if (!value) {
                      addressLine1Ref.current?.focus();
                    }
                  } else {
                    handleChange({
                      target: {
                        name,
                        value: value.substring(0, 40)
                      }
                    });
                    setFieldValue(
                      'addressLine3',
                      value.substring(40, value.length)
                    );
                    addressLine3Ref.current?.focus();
                  }
                }}
                customLabel='Nearest Landmark'
                name='addressLine2'
                id='addressLine2'
                type='text'
                containerClassName='new-addresses__modal__item__input'
                placeholder='Oando Petrol Station, Iweka Road'
                inputProps={{
                  ref: addressLine2Ref
                }}
                fullWidth
              />
              <Input
                error={Boolean(errors.addressLine3)}
                helperText={errors.addressLine3}
                value={values.addressLine3}
                onChange={event => {
                  if (event.target.value.length < 41) {
                    handleChange(event);
                    if (!event.target.value) {
                      addressLine2Ref.current?.focus();
                    }
                  } else {
                    handleChange({
                      target: {
                        name: event.target.name,
                        value: event.target.value.substring(0, 40)
                      }
                    });
                  }
                }}
                customLabel='Address Line 3'
                name='addressLine3'
                id='addressLine3'
                type='text'
                containerClassName='new-addresses__modal__item__input'
                placeholder='Iweka Road, Agege, Lagos.'
                fullWidth
                inputProps={{
                  ref: addressLine3Ref
                }}
              />
              <CustomAutoComplete
                options={countries.data ? importCountries : []}
                getOptionLabel={option => option?.name}
                isOptionEqualToValue={(option, value) =>
                  option?.name === value?.name
                }
                renderOption={(props, option, country) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                    key={props.id}
                  >
                    {country && (
                      <img
                        loading='lazy'
                        width='30'
                        height='20'
                        src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
                        alt=''
                      />
                    )}
                    {option.name}
                  </Box>
                )}
                value={
                  countries.data
                    ? countries?.data?.getCountries?.find(
                        country =>
                          country.name?.toLowerCase() ===
                            values.country?.toLowerCase() ||
                          country?.code?.toLowerCase() ===
                            values.countryCode?.toLowerCase()
                      )
                    : { name: '' }
                }
                noOptionsText={'Country not found'}
                onChange={(event, value, reason) => {
                  if (reason === 'clear') {
                    handleLocationSelect({
                      name: 'country',
                      value: '',
                      countryCode: ''
                    });
                  } else {
                    handleLocationSelect({
                      name: 'country',
                      value: value?.name,
                      countryCode: value?.code
                    });
                    getCities({ countryCode: value?.code, cityName: 'a' });
                  }
                }}
                textfieldProps={{
                  lg: 4,
                  md: 12,
                  name: 'country',
                  customLabel: 'Country',
                  containerClassName: 'new-addresses__modal__item__input',
                  error: Boolean(errors.country),
                  helperText: errors.country,
                  value: values.country,
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img
                          loading='lazy'
                          width='20'
                          height='14'
                          src={`https://flagcdn.com/w20/${
                            values.countryCode
                              ? values.countryCode?.toLowerCase()
                              : isImport
                              ? 'us'
                              : defaultDetail.countryCode
                          }.png`}
                          srcSet={`https://flagcdn.com/w40/${
                            values.countryCode
                              ? values.countryCode?.toLowerCase()
                              : isImport
                              ? 'us'
                              : defaultDetail.countryCode
                          }.png 2x`}
                          alt=''
                        />
                      </InputAdornment>
                    )
                  }
                }}
              />
              <CustomAutoComplete
                options={states.data ? states.data?.getStates : []}
                getOptionLabel={option => option?.name}
                isOptionEqualToValue={(option, value) =>
                  option?.name === value?.name
                }
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                    key={props.id}
                  >
                    {option.name}
                  </Box>
                )}
                value={
                  states?.data?.getStates?.length
                    ? states?.data?.getStates?.find(
                        state =>
                          state.name?.toLowerCase() ===
                          values.state?.toLowerCase()
                      )
                    : { name: '' }
                }
                noOptionsText={
                  !values.country
                    ? 'Please Select a country'
                    : 'State not found'
                }
                onChange={(event, value) =>
                  handleLocationSelect({ name: 'state', value: value?.name })
                }
                textfieldProps={{
                  lg: 4,
                  md: 12,
                  name: 'state',
                  customLabel: 'State',
                  containerClassName: 'new-addresses__modal__item__input',
                  error: Boolean(errors.state),
                  helperText: errors.state,
                  value: values.state
                }}
              />
              <CustomAutoComplete
                options={citiesResult.data.length ? citiesResult.data : []}
                getOptionLabel={option =>
                  option?.city
                    ?.split(' ')
                    ?.map(
                      word =>
                        `${word.charAt().toUpperCase()}${word
                          .substring(1, word.length)
                          ?.toLowerCase()}`
                    )
                    ?.join(' ')
                }
                isOptionEqualToValue={(option, value) =>
                  option?.postalCode
                    ? option?.postalCode === values.postalCode &&
                      option?.city === value?.city
                    : option?.city === value?.city
                }
                renderOption={(props, option) => (
                  <Box
                    key={props.id}
                    component='li'
                    sx={{
                      textTransform: 'capitalize'
                    }}
                    {...props}
                  >
                    {`${option?.city?.toLowerCase()}${
                      option?.postalCode ? ` (${option?.postalCode})` : ''
                    }`}
                  </Box>
                )}
                value={citiesResult.data?.find(
                  city =>
                    city.city?.toLowerCase() === values.city?.toLowerCase()
                )}
                noOptionsText={
                  !values.country
                    ? 'Please select a country'
                    : citiesResult.loading
                    ? 'Cities loading...'
                    : 'City not found'
                }
                onChange={(event, value, reason) => {
                  if (reason === 'clear') {
                    handleLocationSelect({
                      name: 'city',
                      value: ''
                    });
                  } else {
                    handleLocationSelect({
                      name: 'city',
                      value: value?.city
                    });
                  }
                }}
                textfieldProps={{
                  lg: 4,
                  md: 12,
                  name: 'city',
                  customLabel: 'City',
                  containerClassName: 'new-addresses__modal__item__input',
                  error: Boolean(errors.city),
                  helperText: errors.city,
                  value: values.city,
                  onChange: debounce(event => {
                    getCities({
                      countryCode: values.countryCode,
                      cityName: event?.target?.value
                    });
                  }, 500)
                }}
              />
            </>
          )}
          <Input
            error={Boolean(errors.postalCode)}
            helperText={errors.postalCode}
            value={values.postalCode}
            onChange={handleChange}
            customLabel='Postal Code'
            name='postalCode'
            id='postalCode'
            type='text'
            containerClassName='new-addresses__modal__item__input'
            placeholder='100001'
            fullWidth
            lg={12}
            md={12}
          />
        </Grid>
        {isLoggedIn && (
          <FormControlLabel
            className='sender-info__checkbox'
            control={
              <Checkbox
                checked={values.shouldSave}
                onChange={() => setFieldValue('shouldSave', !values.shouldSave)}
              />
            }
            label='Save this address'
          />
        )}
        <div className='my-5 item-description__action'>
          <Button variant='text' onClick={addPhoneNumber}>
            <span>Add an extra Phone Number</span>
            <AddCircle />
          </Button>
        </div>
        {phoneNumbers.length > 0 && (
          <div className='order-summary__shipment mb-3'>
            <div className='order-summary__shipment__wrap'>
              <Grid container columnSpacing='10px' rowSpacing='16px'>
                {phoneNumbers.map(phone => (
                  <div
                    key={phone.index}
                    className='w-100 my-3 d-flex align-items-center'
                  >
                    <Input
                      id='phoneNumber'
                      name='phoneNumber'
                      onChange={number => {
                        const update = { ...phone, number };
                        setPhoneNumbers(numbers => ({
                          ...numbers,
                          sender: phoneNumbers.map(phone =>
                            phone.index === update.index ? update : phone
                          )
                        }));
                      }}
                      defaultCountry={
                        isImport ? 'us' : defaultDetail.countryCode
                      }
                      value={
                        phoneNumbers.find(
                          numbers => numbers.index === phone.index
                        ).number
                      }
                      type='tel'
                      placeholder='+234 801 234 5678'
                      containerClassName='new-addresses__modal__item__input'
                      customLabel='Phone Number'
                      lg={12}
                      md={12}
                    />
                    <div className='ml-3 item-description__main__action'>
                      <Button
                        variant='text'
                        onClick={removePhoneNumber(phone.index)}
                      >
                        <RemoveCircle />
                      </Button>
                    </div>
                  </div>
                ))}
              </Grid>
            </div>
          </div>
        )}
        <div className='delivery-options__footer'>
          <div
            onClick={() =>
              history.push(shipmentLinks[currentIndex].initialLink)
            }
            className='delivery-options__footer__action'
          >
            Go Back
          </div>
          <SubmitButton text='Continue' />
        </div>
      </form>
    </NewShippingStepsLayout>
  );
};
export default SenderInfo;

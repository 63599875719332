import React, { useEffect, useState } from 'react';
import tickCircle from '../../../../../../assets/image/tick-circle.png';
import SubmitButton from '../../../../../../components/submit-button';
import BusyOverlay from '../../../../../../components/busy-overlay';
import FileUpload from '../../../../../../components/file-upload';
import axios from 'axios';
import { useSendAlert } from '../../../../../../utilities/send-alert';
import { useUpdateBusinessMutation } from '../../../../../../operations/mutations';
import { useRecoilState } from 'recoil';
import { userState } from '../../../../../../recoil/atoms';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';

const DirectorCertification = ({ nextStep, previousStep }) => {
  const sendAlert = useSendAlert();
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useRecoilState(userState);
  const [fileLoading, setFileLoading] = useState(false);

  const goBack = () => {
    previousStep();
  };

  const {
    values: { directors },
    handleSubmit,
    setFieldValue,
    setValues
  } = useFormik({
    initialValues: {
      directors: [{ compliance: '', fullName: '' }]
    },
    enableReinitialize: true,
    onSubmit: values => {
      updateBusiness({
        businessId: user.businessInformation.id,
        update: { directors: values.directors }
      });
    }
  });

  const [updateBusiness, updateBusinessResult] = useUpdateBusinessMutation(
    () => {
      setUser({
        ...user,
        businessInformation: { ...user.businessInformation, directors }
      });
      setSuccess(true);
    }
  );

  const handleUploadFile = (file, setIsUploaded, index) => {
    if (file) {
      setFileLoading(true);

      const url = `${process.env.REACT_APP_REST_API}/upload`;
      const formData = new FormData();

      formData.append('file', file);
      formData.append('fileName', file.name);
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      axios
        .post(url, formData, config)
        .then(response => {
          setFileLoading(false);
          setFieldValue(
            `directors.${index}.compliance`,
            response.data.Location
          );
          setIsUploaded(true);
        })
        .catch(err => {
          sendAlert(err?.message, 'error');
          setFileLoading(false);
        });
    }
  };

  const loading = fileLoading || updateBusinessResult.loading;

  useEffect(() => {
    if (user.businessInformation.directors) {
      setValues({ directors: user.businessInformation.directors });
    }
    // eslint-disable-next-line
  }, [user.businessInformation.directors]);

  return (
    <div className='verification__body'>
      {success && (
        <div className='verification__image'>
          <img src={tickCircle} className='img-fluid' alt='' />
        </div>
      )}
      <div className={`verification__content${!success ? ' mt-3' : ''}`}>
        <div className='verification__header'>
          <h1>
            {success
              ? 'Directors IDs saved!'
              : 'Upload valid IDs of company directors.'}
          </h1>
        </div>
        <div className='verification__description'>
          <p>
            {success
              ? 'Your company’s directors IDs  has been successfully saved.'
              : 'Please provide a valid means of identification for the directors.'}
          </p>
        </div>
      </div>
      {!success && (
        <Grid container spacing={2}>
          {directors.map((director, index) => (
            <Grid
              className='form-container new-addresses__modal__item__input'
              item
              sx={{ display: 'flex', flexDirection: 'column' }}
              key={index}
              lg={12}
              md={12}
            >
              <label
                className='form-item-label'
                style={{ alignSelf: 'flex-start' }}
              >
                {director.fullName}
              </label>
              <FileUpload
                onUploadFile={(file, setIsUploaded) =>
                  handleUploadFile(file, setIsUploaded, index)
                }
                onCancel={() =>
                  setFieldValue(`directors.${index}.compliance`, '')
                }
                uploadedFile={director.compliance}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <div className='verification__action'>
        <div onClick={goBack} className='item-description__footer__action'>
          Go Back
        </div>
        <SubmitButton
          text={success ? 'Continue' : 'Submit'}
          onClick={success ? nextStep : handleSubmit}
          disabled={directors.some(director => !director.compliance)}
        />
      </div>
      <BusyOverlay loading={loading} />
    </div>
  );
};

export default DirectorCertification;

import { shipmentOptions } from '../constants/book-shipment-contants';

export const isItemCollectionModeMatch = (shipmentMethods, shipmentDetail) => {
  return shipmentMethods[1]?.value === shipmentDetail?.itemCollectionMode;
};

export const getItemCategories = shipmentDetail => {
  return shipmentDetail?.items?.map(item => item.category);
};

export const getItemValues = shipmentDetail => {
  const totalValue = (shipmentDetail?.items || []).reduce(
    (accumulator, item) => accumulator + (item.value || 0),
    0
  );
  return totalValue;
};

export const isWithinLagos = shipmentDetail => {
  return shipmentDetail?.senderDetail?.state?.toLowerCase() === 'lagos';
};

export const getExpressOption = rates => {
  return rates.find(rate => rate?.pricingTier === 'Express');
};

export const isImportBooking = shipmentDetail => {
  return (
    shipmentDetail?.shipmentRoute === shipmentOptions[1].value &&
    shipmentDetail?.receiverDetail?.country?.toLowerCase() === 'nigeria' &&
    shipmentDetail?.receiverDetail?.state?.toLowerCase() !== 'lagos'
  );
};

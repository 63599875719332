export const formatErrorMessage = error => {
  let returnMessage;
  const { graphQLErrors, networkError } = error;
  if (graphQLErrors) {
    const messages = graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      return message;
    });

    returnMessage = messages.join('\n\n');
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    returnMessage = networkError.toString().split(':')[2];
  }

  if (returnMessage) {
    returnMessage = `${returnMessage} \n\n Please contact support for help`;
  }

  if (
    returnMessage?.includes('Specified sender / reciever Address not available')
  ) {
    returnMessage =
      'Either sender/receiver city is currently not shipped to, you could choose another city of close proximity or contact support';
  }

  if (returnMessage?.includes('expected maxLength')) {
    returnMessage = 'Please reduce the length item description and try again';
  }

  if (returnMessage?.includes('Postcode not found')) {
    returnMessage =
      'Please check the postal code on the receiver address and try again';
  }

  return returnMessage;
};

/* eslint-disable eqeqeq */
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Add, Edit, RemoveCircle } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Skeleton,
  Alert
} from '@mui/material';
import Helmet from 'react-helmet';
import { useRecoilState } from 'recoil';
import { useFormik } from 'formik';
import * as yup from 'yup';

import NewShippingStepsLayout from '../../../../components/Layouts/new-shipping-steps-layout';
import SubmitButton from '../../../../components/submit-button';
import {
  useGetCouponQuery,
  useGetShipmentRateQuery
} from '../../../../operations/queries';
import {
  saveOrderSummaryPathState,
  saveShipmentDetailsState,
  shipmentRequestState,
  shippingTermsAndConditionsState,
  termsOverlayState,
  multipleSaveShipmentLoadingState,
  multiplePhoneNumbersState,
  userState,
  saveShipmentRateState,
  multipleShipmentCounponListState,
  multipleShipmentResultsState
} from '../../../../recoil/atoms';
import {
  useSaveShipmentMutation,
  useSaveMultipleShipmentsMutation
} from '../../../../operations/mutations';
// import ShipmentBonus from './shipment-bonus-step';
import { toCurrency } from '../../../../utilities/to-currency';
import {
  multipleShipmentLinks,
  singleShipmentLinks
} from '../../../../constants/shipment-links';
import BusyOverlay from '../../../../components/busy-overlay';
import PaymentMethodPrompt from '../../../../components/modals/payment-method-prompt';
import BookingSuccessfulPrompt from '../../../../components/modals/booking-successful-prompt';
import {
  shipmentDestinationTypes,
  shipmentMethods,
  shipmentOptions
} from '../../../../constants/book-shipment-contants';
import AdditionalNotesPrompt from '../../../../components/modals/additional-notes-prompt';
import Input from '../../../../components/new-dashboard/custom-input';
import { useAuthToken } from '../../../../token';
import { verifyToken } from '../../../../authorization';
import {
  cleanShipmentDetail as handleCleanShipmentDetail,
  omitSingle
} from '../../helper-functions';
import { calculateTotalCost } from '../../../../utilities/calculate-total-cost';
import { applyCouponToPrice } from '../../../../utilities/apply-coupon-to-price';
import BookShipmentModal from '../../../../components/modals/book-shipment-modal';
import omit from 'lodash.omit';
import getPlan from '../../../../utilities/getPlans';
import { itemCategories } from '../../../../constants/item-categories';
import { toFixed } from '../../../../utilities/to-fixed';
import SelectCurrencyPrompt from '../../../../components/modals/select-currency-prompt';
import { currencies } from '../../../../constants/currencies';
import OrderSummaryBox from '../../../../components/box-summary';
import {
  DeliveryAddressComponent,
  ItemInfoComponent,
  PaymentBreakdownComponent,
  PickupAddressComponent,
  ReceiverStepsComponent
} from '../../../../components/Commons/summary-component';
import insurancePlans from '../../../../constants/insurance-plans';
import { shippingRates } from '../../../../constants/shipping-rates';
import { useSendAlert } from '../../../../utilities/send-alert';
import {
  isImportBooking,
  isItemCollectionModeMatch
} from '../../../../utilities/check-item-collection-mode';

const useQuery = () => new URLSearchParams(useLocation().search);

const OrderSummary = () => {
  const [authToken, , , getToken] = useAuthToken();
  const isValidToken = verifyToken(authToken);
  const [phoneNumbers] = useRecoilState(multiplePhoneNumbersState);
  const history = useHistory();

  const query = useQuery();

  const shipmentID =
    history.location.pathname === singleShipmentLinks[5].link &&
    query.get('shipmentID');

  const [user] = useRecoilState(userState);
  const [shipmentRates] = useRecoilState(saveShipmentRateState);
  const [openCurrencyPrompt, setOpenCurrencyPrompt] = useState(false);

  const [, setOrderSummaryPathState] = useRecoilState(
    saveOrderSummaryPathState
  );
  const [shipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  const { multipleReceivers } = shipmentDetail;

  const selectedRate = shipmentRates.find(
    rate => shipmentDetail?.pricingTier === rate?.pricingTier
  );

  const shipmentDestination = shipmentDestinationTypes.find(
    destination => shipmentDetail.destinationType === destination.value
  );
  const selectedMethod = shipmentMethods.find(
    method => shipmentDetail?.itemCollectionMode === method.value
  );

  const isWithinLagos =
    shipmentDetail?.senderDetail?.state?.toLowerCase() == 'lagos';

  const [multipleSaveShipmentLoading] = useRecoilState(
    multipleSaveShipmentLoadingState
  );

  const isBudgetOutsideLagos = useMemo(() => {
    if (!shipmentDetail) {
      return false;
    }

    // LastMileBudget
    return (
      shipmentDetail?.pricingTier === shippingRates[3].value &&
      shipmentDetail?.shipmentRoute === shipmentOptions[0].value &&
      !isWithinLagos
    );
  }, [shipmentDetail, isWithinLagos]);

  const pickupOptionMatch = isItemCollectionModeMatch(
    shipmentMethods,
    shipmentDetail
  );

  const initialReceiverSteps = Array.from(
    { length: multipleReceivers?.length },
    (_, index) => ({
      index,
      isActive: index === 0
    })
  );
  const isMultipleBooking =
    shipmentDestination?.value === shipmentDestinationTypes[1].value;
  const [receiverSteps, setReceiverSteps] = useState(
    isMultipleBooking ? initialReceiverSteps : []
  );
  const currentReceiver = useMemo(() => {
    if (isMultipleBooking) {
      return receiverSteps?.find(step => step?.isActive);
    } else return null;
  }, [receiverSteps, isMultipleBooking]);
  const activeReceiverIndex = useMemo(() => {
    if (isMultipleBooking) {
      return receiverSteps.findIndex(step => step === currentReceiver);
    } else return -1;
  }, [currentReceiver, receiverSteps, isMultipleBooking]);
  const activeReceiver = isMultipleBooking
    ? multipleReceivers[activeReceiverIndex]
    : null;

  const [, setRequestPage] = useRecoilState(shipmentRequestState);
  const [, setShowTermsOverlay] = useRecoilState(termsOverlayState);
  const [checked, setChecked] = useRecoilState(shippingTermsAndConditionsState);
  const isLoggedIn = Boolean(getToken());

  const [expandData] = useState(false);
  const [isDeletedIndex, setIsDeletedIndex] = useState('');
  const [bonusAmount] = useState(0);
  const [openPaymentMethodPrompt, setOpenPaymentMethodPrompt] = useState(false);
  const [openAdditionalNotesPrompt, setOpenAdditionalNotesPrompt] = useState(
    false
  );
  const [, setMultipleShipmentResults] = useRecoilState(
    multipleShipmentResultsState
  );
  const [
    openBookingSuccessfulPrompt,
    setOpenBookingSuccessfulPrompt
  ] = useState(false);
  const [paymentID, setPaymentID] = useState('');
  const [openBookShipmentModal, setOpenBookShipmentModal] = useState(false);
  const [couponData, setCouponData] = useState(null);
  // const [applyBonus, setApplyBonus] = useState(false);
  const [, getRatesResult] = useGetShipmentRateQuery({
    transactionType: 'shipment'
  });

  const [boxes, setBoxes] = useState([]);

  const [coupons] = useRecoilState(multipleShipmentCounponListState);
  const sendAlert = useSendAlert();

  const [getCouponValue, couponResult] = useGetCouponQuery(data => {
    sendAlert('Coupon code applied', 'success');
    setCouponData(data?.getCoupon);
  });
  const businessId = localStorage.getItem('lspId');

  const shipmentLinks = isMultipleBooking
    ? multipleShipmentLinks
    : singleShipmentLinks;

  const currentIndex = shipmentLinks.findIndex(
    link => link.link === history.location.pathname
  );

  const calculateVAT = shippingFeeInKobo => {
    return Math.ceil((7.5 / 100) * shippingFeeInKobo);
  };

  const shippingFeeInKobo = applyCouponToPrice(
    calculateTotalCost(
      shipmentDetail.shipmentCharge +
        (shipmentDetail?.budgetDeliveryCharge || 0),
      pickupOptionMatch && shipmentDetail.pickupCharge
        ? shipmentDetail.pickupCharge
        : 0,
      shipmentDetail.insuranceCharge,
      shipmentDetail?.lastMileCharge
    ),
    couponData ? couponData?.value : 0,
    couponData ? couponData?.type : 'Value'
  );

  const VAT = calculateVAT(shippingFeeInKobo);

  const totalCost = shippingFeeInKobo + VAT;

  const deleteItem = (box, index) => {
    const items = shipmentDetail?.items?.filter(item => item.boxNumber === box);

    if (items.length <= 1) {
      return;
    }

    setSaveShipmentDetail(shipmentDetail => ({
      ...shipmentDetail,
      items: shipmentDetail.items?.filter((_, itemIndex) => itemIndex !== index)
    }));

    setIsDeletedIndex(index.toString());
  };

  const goBack = () => {
    history.push(shipmentLinks[currentIndex].initialLink);
  };

  const validationSchema = yup.object().shape({
    code: yup.string().required('Please enter the coupon code')
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    resetForm
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: ''
    },
    validationSchema,
    onSubmit: async values => {
      getCouponValue(values.code);
    }
  });

  const onCompleted = data => {
    if (
      data?.saveShipment?.length > 1 &&
      // Sea Export
      data?.saveShipment?.[0]?.pricingTier !== shippingRates[12].value
    ) {
      const results = [
        ...data?.saveShipment?.map((data, index) => ({ index, data: [data] }))
      ];

      const isLastMile = data?.saveShipment?.[0]?.shipmentRoute === 'Import';

      setMultipleShipmentResults(isLastMile ? results : results.reverse());

      const newMultipleReceivers = [
        ...data?.saveShipment?.map((shipment, index) => ({
          receiverDetail: shipment.receiverDetail,
          items: shipmentDetail?.items,
          couponCode: index === 1 ? '' : values.code,
          insuranceCharge: index === 1 ? 0 : shipmentDetail?.insuranceCharge,
          insuranceType:
            index === 1
              ? insurancePlans[0].value
              : shipmentDetail?.insuranceType,
          pricingTier:
            index === 1 ? shippingRates[0].value : shipmentDetail?.pricingTier,
          shipmentCharge:
            index === 1
              ? shipment?.totalCharge - shipment?.valueAddedTaxCharge
              : shipmentDetail?.shipmentCharge,
          isCompleted: true
        }))
      ];

      setSaveShipmentDetail({
        ...shipmentDetail,
        multipleReceivers: isLastMile
          ? newMultipleReceivers
          : newMultipleReceivers.reverse()
      });

      history.push(
        multipleShipmentLinks[multipleShipmentLinks.length - 1].link
      );
    } else {
      setOpenCurrencyPrompt(true);
    }
  };

  const [
    saveShipmentDetails,
    { loading, data: saveShipmentData }
  ] = useSaveShipmentMutation(onCompleted);

  const saveMultipleShipments = useSaveMultipleShipmentsMutation();

  const pushRoute = index => {
    history.push(shipmentLinks[index].link);
  };

  const removeCoupon = () => {
    resetForm();
    setCouponData(null);
  };

  const calculateCosts = (receiver, index) => {
    const totalCostInKobo =
      receiver?.shipmentCharge +
      receiver?.insuranceCharge +
      (receiver?.budgetDeliveryCharge || 0) +
      (shipmentDetail?.itemCollectionMode === shipmentMethods[1].value &&
      index === 0
        ? shipmentDetail?.pickupCharge
        : 0);

    const shippingCostInKoboPerReceiver = applyCouponToPrice(
      totalCostInKobo,
      coupons[index]?.data ? coupons[index]?.data?.value : 0,
      coupons[index]?.data ? coupons[index]?.data?.type : 'Value'
    );

    const addedVat = calculateVAT(shippingCostInKoboPerReceiver);

    const totalCostPerReceiver = shippingCostInKoboPerReceiver + addedVat;

    return { addedVat, totalCostPerReceiver, shippingCostInKoboPerReceiver };
  };

  const isLocalShipment = isMultipleBooking
    ? shipmentDetail?.senderDetail?.countryCode === 'NG' &&
      multipleReceivers
        ?.map(receiver =>
          receiver?.receiverDetail?.countryCode === 'NG' ? true : false
        )
        .every(bool => bool)
    : shipmentDetail?.senderDetail?.countryCode === 'NG' &&
      shipmentDetail?.receiverDetail?.countryCode === 'NG';

  const seo = {
    title: shipmentID
      ? 'Topship Africa Shipment Payment'
      : 'Topship Africa Shipment Request Confirm and Delivery Details',
    link: shipmentID
      ? 'https://topship.africa/shipment-payment'
      : 'https://topship.africa/shipment-request-confirm-delivery-details'
  };

  const saveShipment = () => {
    if (isLoggedIn || (authToken && isValidToken.success)) {
      setShowTermsOverlay(false);

      const cleanShipmentDetail = handleCleanShipmentDetail(shipmentDetail);

      if (cleanShipmentDetail.itemCollectionMode === shipmentMethods[0].value) {
        delete cleanShipmentDetail.pickupPartner;
        delete cleanShipmentDetail.deliveryLocation;
        delete cleanShipmentDetail.pickupId;
      }

      if (isMultipleBooking) {
        const cleanMultipleReceivers = multipleReceivers?.map(receiver => {
          const cleanReceiver = {
            ...receiver,
            receiverDetail: omit(receiver?.receiverDetail, [
              'shouldSave',
              'id',
              'isDefault',
              'updatedDate',
              'isCompleted',
              'codeArea'
            ]),
            items: receiver.items.map(item =>
              omit(item, [
                'isCompleted',
                'width',
                'height',
                'length',
                'isVolumetric'
              ])
            )
          };
          return omit(cleanReceiver, ['isCompleted']);
        });

        const cleanShipments = cleanMultipleReceivers.map(
          (receiver, receiverIndex) => {
            const { addedVat } = calculateCosts(receiver, receiverIndex);
            const newShipment = {
              ...cleanShipmentDetail,
              ...receiver,
              pickupCharge:
                pickupOptionMatch && receiverIndex === 0
                  ? shipmentDetail.pickupCharge
                  : 0,
              deliveryLocation: shipmentDetail?.deliveryLocation,
              logisticsBusinessId: businessId,
              valueAddedTaxCharge: addedVat
            };

            delete newShipment.multipleReceivers;

            return newShipment;
          }
        );
        saveMultipleShipments(cleanShipments, bonusAmount);
      } else {
        delete cleanShipmentDetail.multipleReceivers;

        cleanShipmentDetail.receiverDetail = omitSingle(
          'isDefault',
          omitSingle('updatedDate', cleanShipmentDetail.receiverDetail)
        );

        if (phoneNumbers?.sender?.length || phoneNumbers?.receiver?.length) {
          cleanShipmentDetail.additionalInformation = `${
            cleanShipmentDetail.additionalInformation
          }${
            cleanShipmentDetail.additionalInformation
              ? `;
          
          `
              : ''
          }${
            phoneNumbers.sender.length
              ? `Sender Detail Extra Phone Numbers: 

          ${phoneNumbers.sender.map(number => `Number: ${number.number}`)
            .join(`,
          
            `)}`
              : ''
          }${
            phoneNumbers.receiver.length
              ? `Receiver Detail Extra Phone Numbers: 
          ${phoneNumbers.receiver.map(number => `Number: ${number.number}`)
            .join(`,
          
            `)}`
              : ''
          }`;
        }

        if (
          shipmentDetail?.initialReceiverAddress?.addressLine1 &&
          shipmentDetail?.receiverDetail?.addressLine1 !==
            shipmentDetail?.initialReceiverAddress?.addressLine1
        ) {
          cleanShipmentDetail.additionalInformation += `


======================
Initial Receiver Address
-------------------------
Address: ${shipmentDetail?.initialReceiverAddress?.addressLine1 || '-'}

Nearest Landmark: ${shipmentDetail?.initialReceiverAddress?.addressLine2 || '-'}

Address Line 3: ${shipmentDetail?.initialReceiverAddress?.addressLine3 || '-'}

City: ${shipmentDetail?.initialReceiverAddress?.city || '-'}

State: ${shipmentDetail?.initialReceiverAddress?.state || '-'}

Country: ${shipmentDetail?.initialReceiverAddress?.country || '-'}

Postal Code: ${shipmentDetail?.initialReceiverAddress?.postalCode || '-'}
======================
                  `;
        }

        saveShipmentDetails(
          [
            {
              ...cleanShipmentDetail,
              ...(bonusAmount > 0 && { discount: bonusAmount }),
              logisticsBusinessId: businessId,
              valueAddedTaxCharge: VAT
              // ...((isWithinLagos || isWithinAbuja) && { pickupCharge: 0 })
            }
          ],
          values.code || null
        );
      }

      setChecked(false);
    } else {
      const path = history.location.pathname;
      setOrderSummaryPathState({ path });
      history.push('/login');
    }
  };

  useEffect(() => {
    if (isDeletedIndex) {
      const { senderDetail, receiverDetail } = shipmentDetail;
      const newItems = shipmentDetail.items.filter(
        (item, index) => index !== parseFloat(isDeletedIndex)
      );
      let totalWeightValue = 0;
      newItems.forEach(item => {
        if (item.isVolumetric) {
          const volumetricWeight = Number(
            ((item?.width * item?.height * item?.length) / 5000).toFixed(2)
          );
          totalWeightValue += volumetricWeight;
        } else {
          totalWeightValue += item.weight;
        }
      });
      // const totalWeight = newItems.reduce((a, b) => a + b.weight, 0);
      const requestRatesData = {
        senderDetails: {
          cityName: senderDetail.city,
          countryCode: senderDetail.countryCode
        },
        receiverDetails: {
          cityName: receiverDetail.city,
          countryCode: receiverDetail.countryCode
        },
        totalWeight: totalWeightValue
      };

      history.push(shipmentLinks[currentIndex].initialLink, requestRatesData);
    }

    if (shipmentID) {
      setPaymentID(shipmentID);

      setOpenPaymentMethodPrompt(true);
    }

    if (multipleSaveShipmentLoading.completed) {
      if (isMultipleBooking) {
        history.push(shipmentLinks[currentIndex + 1].link);
      }
    }

    // eslint-disable-next-line
  }, [isDeletedIndex, shipmentID, multipleSaveShipmentLoading.completed]);

  const {
    addedVat,
    totalCostPerReceiver,
    shippingCostInKoboPerReceiver
  } = calculateCosts(activeReceiver, activeReceiverIndex);

  useEffect(() => {
    if (isMultipleBooking) {
      if (multipleReceivers?.[activeReceiverIndex]) {
        const newBoxes = [];

        multipleReceivers?.[activeReceiverIndex]?.items?.forEach(item => {
          if (!newBoxes.includes(item?.boxNumber)) {
            newBoxes.push(item?.boxNumber);
          }
        });

        setBoxes(newBoxes.sort());
      }
    } else {
      if (shipmentDetail?.items?.length) {
        shipmentDetail?.items?.forEach(item => {
          if (!boxes.includes(item?.boxNumber)) {
            boxes.push(item?.boxNumber);
          }
        });

        setBoxes(boxes.sort());
      }
    }
  }, []);

  useEffect(() => {
    if (isMultipleBooking && multipleReceivers?.[activeReceiverIndex]) {
      const newBoxes = [];

      multipleReceivers?.[activeReceiverIndex]?.items?.forEach(item => {
        if (!newBoxes.includes(item?.boxNumber)) {
          newBoxes.push(item?.boxNumber);
        }
      });

      setBoxes(newBoxes.sort());
    }
  }, [activeReceiverIndex]);

  // if (applyBonus) {
  //   return (
  //     <ShipmentBonus
  //       setApplyBonus={setApplyBonus}
  //       setBonusDiscountValue={setBonusDiscountValue}
  //     />
  //   );
  // }

  return (
    <>
      <BookShipmentModal
        openModal={openBookShipmentModal}
        setOpenModal={setOpenBookShipmentModal}
        onCustomNext={() => setOpenBookShipmentModal(false)}
      />

      <NewShippingStepsLayout>
        <Helmet>
          <meta name='description' content={seo.title} />
          <title>{seo.title}</title>
          <link rel='canonical' href={seo.link} />
        </Helmet>

        <BusyOverlay
          loading={
            getRatesResult.loading ||
            loading ||
            multipleSaveShipmentLoading.loading
          }
        />

        <AdditionalNotesPrompt
          openPrompt={openAdditionalNotesPrompt}
          setOpenPrompt={setOpenAdditionalNotesPrompt}
        />

        <PaymentMethodPrompt
          openPrompt={openPaymentMethodPrompt}
          setOpenPrompt={setOpenPaymentMethodPrompt}
          paymentID={paymentID}
          transactionType='Shipment'
          onPromptSuccess={() => {
            setOpenPaymentMethodPrompt(false);
            setOpenBookingSuccessfulPrompt(true);
          }}
        />

        <SelectCurrencyPrompt
          openPrompt={openCurrencyPrompt}
          setOpenPrompt={setOpenCurrencyPrompt}
          onOpenPaymentPrompt={({ currency, rate }) => {
            setOpenCurrencyPrompt(false);

            if (currency === currencies[0].currency) {
              history.push(
                `${singleShipmentLinks[currentIndex + 1].link}?shipmentID=${
                  saveShipmentData?.saveShipment[0].id
                }`
              );
            } else {
              history.push(
                `/shipment-request-foreign-exchange-payment?shipmentID=${saveShipmentData?.saveShipment[0].id}&currency=${currency}`
              );
            }
          }}
        />

        <BookingSuccessfulPrompt
          openPrompt={openBookingSuccessfulPrompt}
          setOpenPrompt={setOpenBookingSuccessfulPrompt}
        />

        <section className='order-summary'>
          <div className='order-summary__wrap'>
            <h2 className='order-summary__header'>
              Review your Shipping information
            </h2>
            {isMultipleBooking && (
              <ReceiverStepsComponent
                receivers={multipleReceivers}
                activeReceiverIndex={activeReceiverIndex}
                receiverSteps={receiverSteps}
                setReceiverSteps={setReceiverSteps}
              />
            )}

            <div className='order-summary__shipment'>
              <div className='order-summary__shipment__wrap'>
                <div className='order-summary__alerts'>
                  <Alert
                    severity={''}
                    sx={{
                      fontSize: '1.5rem',
                      fontFamily: 'General Sans',
                      fontWeight: 500,
                      backgroundColor: '#E1F9FF',
                      color: '#416EB2',
                      border: 'none',
                      '& .MuiAlert-icon': {
                        display: 'none'
                      }
                    }}
                  >
                    Ship it right the first time!{' '}
                    <b>Avoid being charged modification fees</b> by confirming
                    all details provided are accurate.
                  </Alert>
                </div>
                <div className='order-summary__shipment__addresses'>
                  {/* pickup section */}
                  <div
                    className={`order-summary__shipment__address multiple ${
                      shipmentDestination?.value ===
                      shipmentDestinationTypes[1].value
                        ? ' multiple'
                        : ''
                    }`}
                  >
                    <PickupAddressComponent
                      shipmentDetail={shipmentDetail?.senderDetail}
                      pushRoute={pushRoute}
                      hasCta
                    />
                  </div>

                  {isMultipleBooking ? (
                    <>
                      <div>
                        <div className=''>
                          <>
                            <div className='order-summary__shipment__address deliver-to'>
                              <div className='order-summary__shipment__address__wrap'>
                                <div className='remove-margin'>
                                  <DeliveryAddressComponent
                                    receiver={activeReceiver}
                                  />
                                </div>

                                {(activeReceiverIndex !== 1 || expandData) && (
                                  <div className='order-summary__shipment__address__action mt-2'>
                                    <Button
                                      onClick={() => pushRoute(1)}
                                      variant='text'
                                    >
                                      <span>edit</span>
                                      <Edit />
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='mt-3' />
                            <div className='add-margin'>
                              {activeReceiver?.pricingTier ==
                                'LastMileBudget' && !isWithinLagos ? (
                                <ItemInfoComponent
                                  label={'DELIVERY TO LAGOS METHOD'}
                                  value={
                                    activeReceiver?.budgetDeliveryCharge === 0
                                      ? getPlan(
                                          'shipping',
                                          activeReceiver?.pricingTier
                                        )?.budgetOptionFree
                                      : getPlan(
                                          'shipping',
                                          activeReceiver?.pricingTier
                                        )?.budgetOptionWithExpress
                                  }
                                  cost={toCurrency(
                                    toFixed(
                                      activeReceiver.budgetDeliveryCharge /
                                        100 || 0
                                    )
                                  )}
                                  pushRoute={() => pushRoute(2)}
                                />
                              ) : null}
                            </div>
                            <ItemInfoComponent
                              label={'SHIPPING METHOD'}
                              value={
                                getPlan('shipping', activeReceiver?.pricingTier)
                                  ?.name
                              }
                              cost={toCurrency(
                                toFixed(activeReceiver?.shipmentCharge / 100)
                              )}
                              pushRoute={() => pushRoute(2)}
                            />
                            <ItemInfoComponent
                              label={'INSURANCE COVER'}
                              value={
                                getPlan(
                                  'insurance',
                                  activeReceiver?.insuranceType
                                )?.name || '-'
                              }
                              cost={
                                activeReceiver?.insuranceCharge
                                  ? toCurrency(
                                      activeReceiver?.insuranceCharge / 100
                                    )
                                  : 'FREE'
                              }
                              pushRoute={() => pushRoute(2)}
                            />
                            {activeReceiverIndex === 0 && (
                              <ItemInfoComponent
                                label={'PICKUP METHOD'}
                                value={
                                  selectedMethod?.title ||
                                  shipmentDetail?.itemCollectionMode ||
                                  '-'
                                }
                                cost={
                                  toCurrency(
                                    shipmentDetail?.pickupCharge / 100
                                  ) || 'FREE'
                                }
                                pushRoute={() => {
                                  setRequestPage(1);
                                  setOpenBookShipmentModal(true);
                                }}
                              />
                            )}
                            <div className='order-summary__items'>
                              {boxes?.map(box => {
                                const boxItems = activeReceiver?.items.filter(
                                  item => item?.boxNumber === box
                                );
                                return (
                                  <OrderSummaryBox
                                    box={box}
                                    shipmentDetail={multipleReceivers}
                                    itemCategories={itemCategories}
                                    boxItems={boxItems}
                                    deleteItem={deleteItem}
                                    pushRoute={pushRoute}
                                  />
                                );
                              })}
                            </div>
                          </>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className='order-summary__shipment__address deliver-to'>
                      <div className='order-summary__shipment__address__wrap'>
                        <DeliveryAddressComponent receiver={shipmentDetail} />
                        <div className='order-summary__shipment__address__action'>
                          <Button onClick={() => pushRoute(1)} variant='text'>
                            <span>edit</span>
                            <Edit />
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {!isMultipleBooking && (
                  <>
                    {isBudgetOutsideLagos ? (
                      <ItemInfoComponent
                        label={'DELIVERY TO LAGOS METHOD'}
                        value={
                          shipmentDetail?.budgetDeliveryCharge === 0
                            ? getPlan('shipping', shipmentDetail?.pricingTier)
                                ?.budgetOptionFree
                            : getPlan('shipping', shipmentDetail?.pricingTier)
                                ?.budgetOptionWithExpress
                        }
                        cost={toCurrency(
                          toFixed(
                            shipmentDetail.budgetDeliveryCharge / 100 || 0
                          )
                        )}
                        pushRoute={() => pushRoute(3)}
                      />
                    ) : null}
                    <ItemInfoComponent
                      label={'SHIPPING METHOD'}
                      value={
                        getPlan('shipping', shipmentDetail?.pricingTier)?.name
                      }
                      cost={toCurrency(
                        toFixed(shipmentDetail?.shipmentCharge / 100)
                      )}
                      pushRoute={() => pushRoute(3)}
                    />
                    {isImportBooking(shipmentDetail) ? (
                      <ItemInfoComponent
                        label={'DELIVERY METHOD'}
                        value={
                          getPlan(
                            'lastMile',
                            shipmentDetail?.lastMilePricingTier
                          )?.name
                        }
                        cost={toCurrency(
                          toFixed(shipmentDetail?.lastMileCharge / 100)
                        )}
                        pushRoute={() => pushRoute(3)}
                      />
                    ) : null}
                    <ItemInfoComponent
                      label={'INSURANCE COVER'}
                      value={
                        getPlan('insurance', shipmentDetail?.insuranceType)
                          ?.name || '-'
                      }
                      cost={
                        shipmentDetail.insuranceCharge
                          ? toCurrency(shipmentDetail.insuranceCharge / 100)
                          : 'FREE'
                      }
                      pushRoute={() => pushRoute(3)}
                    />
                    {!isMultipleBooking && pickupOptionMatch && (
                      <ItemInfoComponent
                        label={'PICKUP METHOD'}
                        value={
                          selectedMethod?.title ||
                          shipmentDetail.itemCollectionMode ||
                          '-'
                        }
                        cost={
                          toCurrency(shipmentDetail?.pickupCharge / 100) ||
                          'FREE'
                        }
                        pushRoute={() => {
                          setRequestPage(1);
                          setOpenBookShipmentModal(true);
                        }}
                      />
                    )}
                  </>
                )}
                {!isMultipleBooking &&
                  shipmentDetail?.shipmentRoute === 'Export' && (
                    <>
                      <ItemInfoComponent
                        label={'PICKUP METHOD'}
                        value={
                          selectedMethod?.title ||
                          shipmentDetail.itemCollectionMode ||
                          '-'
                        }
                        cost={toCurrency(selectedMethod?.amount) || 'FREE'}
                        pushRoute={() => {
                          setRequestPage(1);
                          setOpenBookShipmentModal(true);
                        }}
                      />

                      {shipmentDetail?.pricingTier === 'SeaExport' && (
                        <ItemInfoComponent
                          label={'ADDITIONAL OPTIONS'}
                          value={`${
                            shipmentDetail?.itemPickupMode?.includes('Doorstep')
                              ? shipmentDetail?.itemPickupMode
                              : `Pick-up from ${shipmentDetail?.itemPickupMode}`
                          }`}
                          cost={
                            shipmentDetail?.itemPickupMode?.includes('Doorstep')
                              ? 'Price quote will be sent after confirmation'
                              : 'FREE'
                          }
                          pushRoute={() => {
                            pushRoute(3);
                          }}
                        />
                      )}
                    </>
                  )}

                {!isMultipleBooking && (
                  <div className='order-summary__items'>
                    {boxes?.map(box => {
                      const boxItems = shipmentDetail?.items
                        ?.filter(item => item?.boxNumber === box)
                        ?.map(item => ({
                          ...item,
                          itemIndex: shipmentDetail?.items?.findIndex(
                            value =>
                              JSON.stringify(value) === JSON.stringify(item)
                          )
                        }));
                      return (
                        <OrderSummaryBox
                          box={box}
                          shipmentDetail={shipmentDetail}
                          itemCategories={itemCategories}
                          boxItems={boxItems}
                          deleteItem={deleteItem}
                          pushRoute={pushRoute}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            {shipmentDetail.additionalInformation ? (
              <div className='order-summary__additional-notes'>
                <div className='order-summary__additional-notes__wrap'>
                  <div className='order-summary__additional-notes__content'>
                    <h4>ADDITIONAL NOTES</h4>
                    <span style={{ lineHeight: '24px' }}>
                      {shipmentDetail.additionalInformation}
                    </span>
                  </div>
                  <div className='order-summary__additional-notes__action order-summary__shipment__address__action'>
                    <Button
                      onClick={() => setOpenAdditionalNotesPrompt(true)}
                      variant='text'
                    >
                      <span>edit</span>
                      <Edit />
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className='item-description__action'>
                <Button
                  variant='text'
                  onClick={() => setOpenAdditionalNotesPrompt(true)}
                >
                  <Add />
                  <span className='button-span'>Additional Notes</span>
                </Button>
              </div>
            )}

            {isMultipleBooking ? (
              <PaymentBreakdownComponent
                shippingFeeInKobo={shippingCostInKoboPerReceiver}
                VAT={addedVat}
                totalCost={totalCostPerReceiver}
              />
            ) : (
              <>
                {Boolean(
                  user.percentageCommissionOnUser &&
                    selectedRate &&
                    selectedRate?.actualCost
                ) ? (
                  <div className='order-summary__coupon'>
                    <div className='order-summary__coupon__wrap'>
                      <div className='mt-1 mb-1 d-flex justify-content-between'>
                        <h4 className=''>Discount applied</h4>
                        <div className='item-description__main__action'></div>
                      </div>
                      <div className={`delivery-options__item active`}>
                        <div className='delivery-options__item__wrap discount'>
                          <div className='delivery-options__item__content'>
                            <div className='delivery-options__item__price'>
                              <span className='no-discount'>
                                {toCurrency(selectedRate?.actualCost / 100)}
                              </span>
                            </div>
                          </div>
                          <div className='delivery-options__item__price discount'>
                            <span>{toCurrency(selectedRate?.cost / 100)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className='order-summary__coupon'>
                  <div className='order-summary__coupon__wrap'>
                    {couponResult.loading ? (
                      <>
                        <h4 className='mt-1 mb-1'>Applying...</h4>
                        <div className={`delivery-options__item`}>
                          <div className='delivery-options__item__wrap discount'>
                            <div className='delivery-options__item__content w-100'>
                              <div className='delivery-options__item__address w-100'>
                                <Skeleton className='w-100' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : couponData ? (
                      <>
                        <div className='mt-1 mb-2 d-flex justify-content-between align-items-center'>
                          <h4 className=''>Coupon code applied</h4>
                          <div className='item-description__main__action'>
                            <Button variant='text' onClick={removeCoupon}>
                              <span>Erase coupon</span>
                              <RemoveCircle />
                            </Button>
                          </div>
                        </div>
                        <div className={`delivery-options__item active`}>
                          <div className='delivery-options__item__wrap discount'>
                            <div className='delivery-options__item__content'>
                              <div className='delivery-options__item__price mt-0'>
                                <span className='no-discount'>
                                  {toCurrency(
                                    calculateTotalCost(
                                      toFixed(
                                        (shipmentDetail.shipmentCharge +
                                          (shipmentDetail?.budgetDeliveryCharge
                                            ? shipmentDetail?.budgetDeliveryCharge
                                            : 0)) /
                                          100
                                      ),
                                      pickupOptionMatch
                                        ? shipmentDetail?.pickupCharge / 100
                                        : 0,
                                      shipmentDetail.insuranceCharge / 100,
                                      isImportBooking(shipmentDetail)
                                        ? shipmentDetail.lastMileCharge / 100
                                        : 0
                                    )
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className='delivery-options__item__price discount'>
                              <span>{toCurrency(shippingFeeInKobo / 100)}</span>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Input
                        variant='standard'
                        customLabel='Coupon Code'
                        type='text'
                        id='code'
                        name='code'
                        onChange={handleChange}
                        className='inputGroup2__inputs__input'
                        placeholder='Got a code?'
                        value={values.code}
                        error={touched.code && Boolean(errors.code)}
                        helperText={touched.code && errors.code}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <div className='order-summary__shipment__address__action'>
                                <Button onClick={handleSubmit} variant='text'>
                                  <span>Apply</span>
                                </Button>
                              </div>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  </div>
                </div>
                <PaymentBreakdownComponent
                  shippingFeeInKobo={shippingFeeInKobo}
                  VAT={VAT}
                  totalCost={totalCost}
                />
              </>
            )}

            <div className='order-summary__terms'>
              <div className='order-summary__terms__wrap'>
                {isLocalShipment ? null : (
                  <div className='order-summary__terms__checkbox'>
                    <FormControlLabel
                      className='sender-info__checkbox order-summary'
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                        />
                      }
                      label={
                        <span className='quote-summary__ter'>
                          By clicking Confirm, I acknowledge that I have read,
                          understood and agreed to the Topship’s{' '}
                          <Link to='/terms' target='_blank'>
                            {' '}
                            Privacy Policy
                          </Link>{' '}
                          and{' '}
                          <Link to='/terms' target='_blank'>
                            Terms of Service
                          </Link>
                        </span>
                      }
                    />
                  </div>
                )}
              </div>
            </div>

            <div className='order-summary__footer'>
              <div onClick={goBack} className='order-summary__footer__action'>
                Go Back
              </div>

              <SubmitButton
                disabled={loading || (!isLocalShipment && !checked)}
                onClick={saveShipment}
                text={isMultipleBooking ? 'Confirm' : 'Confirm & Pay'}
              />
            </div>
          </div>
        </section>
      </NewShippingStepsLayout>
    </>
  );
};

export default OrderSummary;

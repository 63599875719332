import React, { useEffect, useRef, useState } from 'react';
import { TimerOutlined } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { conveneDate } from '../helper-functions';
import { useGetTransactionsLazyQuery } from '../../../operations/queries';
import EmptyList from '../../../components/empty-list';
import emptyAddressIcon from '../../../assets/svg/wallet-empty.svg';
import viewAllArrow from '../../../assets/svg/view-all-arrow.svg';

import { toCurrency } from '../../../utilities/to-currency';
import usePagination from '../../../utilities/usePagination';
import Pagination from '../../../components/Commons/pagination';
import PaymentMethodPrompt from '../../../components/modals/payment-method-prompt';
import { Link, useHistory } from 'react-router-dom';
import { transactionTypesEnum } from '../../../utilities/enums/transaction-types.enum';
import SelectCurrencyPrompt from '../../../components/modals/select-currency-prompt';
import { currencies } from '../../../constants/currencies';

const PendingTransactions = ({ take, shownOnDashboard }) => {
  const [openPaymentMethodPrompt, setOpenPaymentMethodPrompt] = useState(false);
  const [paymentID, setPaymentID] = useState('');
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [openCurrencyPrompt, setOpenCurrencyPrompt] = useState(false);
  const history = useHistory();

  const [transactions, setTransactions] = useState({
    totalCount: 0,
    currentPage: 1,
    totalPages: 0,
    list: []
  });

  const [getTransactions, { loading }] = useGetTransactionsLazyQuery(data => {
    if (data?.getTransactions) {
      setTransactions(data?.getTransactions);
    }
  });

  const {
    start,
    end,
    disableNext,
    disablePrevious,
    currentPage,
    totalCount,
    onNextPage,
    onPreviousPage,
    setStart,
    setEnd,
    setCurrentPage
  } = usePagination({
    currentPage: transactions?.currentPage,
    totalCount: transactions?.totalCount,
    take
  });

  const handlePayNow = itemId => {
    const transaction = transactions.list?.find(
      item => item?.transactionId === itemId
    );

    if (!transaction) {
      return;
    }

    setPaymentID(transaction?.id);

    setPaymentAmount(transaction?.amount);

    setOpenCurrencyPrompt(true);
  };

  const tableRef = useRef();

  useEffect(() => {
    getTransactions({
      take,
      page: currentPage,
      transactionStatus: 'Pending',
      transactionType: 'AdditionalCharge'
    });
    // eslint-disable-next-line
  }, [currentPage]);

  const renderRowKeyPairs = transaction => {
    const keyPairs = [];
    for (const key in transaction) {
      if (Object.hasOwnProperty.call(transaction, key)) {
        const element = transaction[key];
        const initKey = key.replace('transaction', '');
        if (initKey.match(/amount|description/)) {
          if (initKey === 'amount') {
            keyPairs.push({ key: initKey, value: toCurrency(element / 100) });
          } else {
            keyPairs.push({
              key: initKey,
              value: element.replace('Additional Charge |', '')
            });
          }
        }
      }
    }
    return keyPairs;
  };

  const paginationSettings = {
    start,
    end,
    take,
    disableNext,
    disablePrevious,
    totalCount,
    loading,
    onNextPage,
    onPreviousPage,
    setStart,
    setEnd,
    setCurrentPage
  };

  return (
    <>
      <div className='new-wallet__transactions__container'>
        {!transactions.list.length ? (
          <>
            <div className='recent-shipments-header'>
              <h2
                style={{
                  paddingLeft: shownOnDashboard ? '' : '30px'
                }}
              >
                Pending Charges
              </h2>
            </div>

            <EmptyList
              style={{
                width: '100%'
              }}
              emptyIcon={emptyAddressIcon}
              heading={`You don’t have any pending charge`}
              subHeading='Manage your lists of outstanding charges and easily make payments when needed.'
            />
          </>
        ) : (
          <>
            <div className='recent-shipments-header'>
              <h2
                style={{
                  paddingLeft: shownOnDashboard ? '' : '30px'
                }}
              >
                Pending Charges
              </h2>
              {shownOnDashboard && transactions?.list?.length && (
                <Link to='/pending-charges'>
                  <p>
                    <span>View All</span>{' '}
                    <img src={viewAllArrow} alt='view all' />
                  </p>
                </Link>
              )}
            </div>

            <div className='new-wallet__transactions__list'>
              <table ref={tableRef} className='table table-borderless'>
                <thead style={{ fontWeight: 'bold' }}>
                  <tr>
                    <th>amount</th>
                    <th
                      style={{
                        width: '35vw'
                      }}
                    >
                      description
                    </th>
                    <th>action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading
                    ? [...Array(take)]?.map((_, i) => (
                        <tr
                          key={i}
                          className={`new-wallet__transactions__list__row${
                            i % 2 === 0 ? ' odd' : ''
                          }`}
                        >
                          <td className='new-wallet__transactions__list__amount'>
                            <Skeleton />
                          </td>
                          <td className='new-wallet__transactions__list__type'>
                            <Skeleton />
                          </td>
                          <td
                            className={`new-wallet__transactions__list__status`}
                          >
                            <Skeleton />
                          </td>
                        </tr>
                      ))
                    : transactions?.list?.map((transaction, i) => (
                        <tr
                          key={i}
                          style={{
                            alignItems: 'center',
                            padding: '16px 30px'
                          }}
                          className={`new-wallet__transactions__list__row${
                            i % 2 === 0 ? ' odd' : ''
                          }`}
                        >
                          <td
                            style={{
                              color: '#0f1414'
                            }}
                            className='new-wallet__transactions__list__pending'
                          >
                            {toCurrency(transaction.amount / 100)}
                          </td>

                          <td
                            style={{
                              width: '35vw'
                            }}
                            className='new-wallet__transactions__list__pending'
                          >
                            {transaction.description.replace(
                              'Additional Charge |',
                              ''
                            )}
                          </td>
                          <td className='new-wallet__transactions__list__date'>
                            <button
                              disabled={
                                transaction.transactionStatus === 'Success'
                              }
                              onClick={() => {
                                handlePayNow(transaction.transactionId);
                              }}
                              className={`charge-btn pending-charges${
                                transaction.transactionStatus === 'Success'
                                  ? ' success-btn'
                                  : ' pending-btn'
                              }`}
                            >
                              Pay
                            </button>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
            <div className='new-wallet__transactions__list__mobile'>
              <div className='new-wallet__transactions__list__mobile__wrap'>
                {loading
                  ? [...Array(take)].map((_, i) => (
                      <div
                        key={i}
                        className='new-wallet__transactions__list__mobile__row'
                      >
                        <div className='new-wallet__transactions__list__mobile__row__wrap'>
                          <div className='new-wallet__transactions__list__mobile__row__body'>
                            <div className='new-wallet__transactions__list__mobile__row__body__wrap'>
                              {[
                                { key: 'amount' },
                                { key: 'description' },
                                { key: 'action' }
                              ].map(keyPair => (
                                <div
                                  key={keyPair.key}
                                  className='new-wallet__transactions__list__mobile__row__pair'
                                >
                                  <div className='new-wallet__transactions__list__mobile__row__key'>
                                    <span>{keyPair.key}</span>
                                  </div>
                                  <div className='new-wallet__transactions__list__mobile__row__value w-75'>
                                    <Skeleton sx={{ width: '100%' }} />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className='new-wallet__transactions__list__mobile__row__footer'>
                            <div
                              className='new-wallet__transactions__list__mobile__row__time'
                              style={{ justifyContent: 'space-between' }}
                            >
                              <TimerOutlined />
                              <Skeleton sx={{ width: '75%' }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : transactions?.list?.map((transaction, i) => (
                      <div
                        key={i}
                        className='new-wallet__transactions__list__mobile__row'
                      >
                        <div className='new-wallet__transactions__list__mobile__row__wrap'>
                          <div className='new-wallet__transactions__list__mobile__row__body'>
                            <div className='new-wallet__transactions__list__mobile__row__body__wrap'>
                              {renderRowKeyPairs(transaction).map(keyPair => (
                                <div
                                  key={keyPair.key}
                                  className='new-wallet__transactions__list__mobile__row__pair'
                                >
                                  <div className='new-wallet__transactions__list__mobile__row__key'>
                                    <span>{keyPair.key}</span>
                                  </div>
                                  <div
                                    style={{
                                      width: '50%',
                                      textAlign: 'right'
                                    }}
                                    className='new-wallet__transactions__list__mobile__row__pending-value'
                                  >
                                    <span
                                      className={`${
                                        keyPair.key === 'Status'
                                          ? ` ${keyPair.value}`
                                          : ''
                                      }`}
                                    >
                                      {keyPair.value}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div
                              style={{
                                marginTop: '15px',
                                alignItems: 'center'
                              }}
                              className='new-wallet__transactions__list__mobile__row__pair'
                            >
                              <div className='new-wallet__transactions__list__mobile__row__key'>
                                <span>Action</span>
                              </div>
                              <div
                                style={{
                                  width: '60%',
                                  textAlign: 'right'
                                }}
                                className='new-wallet__transactions__list__mobile__row__pending-value'
                              >
                                <button
                                  disabled={
                                    transaction.transactionStatus === 'Success'
                                  }
                                  onClick={() => {
                                    handlePayNow(transaction.transactionId);
                                  }}
                                  className={`charge-btn pending-charges${
                                    transaction.transactionStatus === 'Success'
                                      ? ' success-btn'
                                      : ' pending-btn'
                                  }`}
                                >
                                  Pay
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className='new-wallet__transactions__list__mobile__row__footer'>
                            <div className='new-wallet__transactions__list__mobile__row__time'>
                              <TimerOutlined />
                              <span>
                                {conveneDate(transaction.updatedDate)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
            {take > 5 ? <Pagination {...paginationSettings} /> : null}
          </>
        )}
      </div>
      <SelectCurrencyPrompt
        openPrompt={openCurrencyPrompt}
        setOpenPrompt={setOpenCurrencyPrompt}
        onOpenPaymentPrompt={({ currency }) => {
          setOpenCurrencyPrompt(false);

          if (currency === currencies[0].currency) {
            setOpenPaymentMethodPrompt(true);
          } else {
            history.push(
              `/shipment-request-foreign-exchange-payment?additionalChargeID=${paymentID}&currency=${currency}`
            );
          }
        }}
      />

      <PaymentMethodPrompt
        openPrompt={openPaymentMethodPrompt}
        setOpenPrompt={setOpenPaymentMethodPrompt}
        paymentID={paymentID}
        transactionType={transactionTypesEnum.AdditionalCharge}
        pendingChargeAmount={paymentAmount}
        onPromptSuccess={() => {
          setOpenPaymentMethodPrompt(false);
          setTransactions({
            ...transactions,
            list: transactions.list.filter(item => item.id !== paymentID)
          });
        }}
      />
    </>
  );
};

export default PendingTransactions;

import { atom } from 'recoil';
import implementPersist from './usePersist';
import Verification from '../pages/Dashboard/Settings/modals/Verification/components/email-verification';
import Certification from '../pages/Dashboard/Settings/modals/Verification/components/certification';
import Directors from '../pages/Dashboard/Settings/modals/Verification/components/directors';
import DirectorCertification from '../pages/Dashboard/Settings/modals/Verification/components/director-certification';
import Confidentiality from '../pages/Dashboard/Settings/modals/Verification/components/confidentiality-modal';
import BusinessLocation from '../pages/Dashboard/Settings/modals/Verification/components/business-location';
import {
  shipmentMethods,
  shipmentOptions
} from '../constants/book-shipment-contants';
import { shippingFromOptions } from '../constants/shopnship-constants';

const loginState = atom({
  key: 'loginState',
  default: false
});

const shopNShipDetailsState = atom({
  key: 'shopNShipDetails',
  default: {},
  effects_UNSTABLE: implementPersist('shopNShipDetails')
});

const filteredShopNShipLinksState = atom({
  key: 'filteredShopNShipLinksState',
  default: [],
  effects_UNSTABLE: implementPersist('filteredShopNShipLinksState')
});

const chinaImportState = atom({
  key: 'chinaImportState',
  default: false,
  effects_UNSTABLE: implementPersist('chinaImportState')
});
const shopNShipValidationState = atom({
  key: 'shopNShipValidationState',
  default: {
    isValidated: false,
    enforceValidation: false
  }
});

const shipmentRequestState = atom({
  key: 'shipmentRequestState',
  default: 0
});

const blackFridayState = atom({
  key: 'blackFridayState',
  default: false
});

const userUpdateLoadingState = atom({
  key: 'userUpdateLoadingState',
  default: false
});
const userRoundUpDataState = atom({
  key: 'userRoundUpDataState',
  default: {}
});

const selectedBlackFridayState = atom({
  key: 'selectedBlackFridayState',
  default: {}
});

const shipmentPaymentState = atom({
  key: 'shipmentPaymentState',
  default: 0
});

const quoteRequestState = atom({
  key: 'quoteRequestState',
  default: 0
});

const selectedRateState = atom({
  key: 'selectedRateState',
  default: {}
});

const budgetDeliveryState = atom({
  key: 'budgetDeliveryState',
  default: 0
});

const shipmentTypeState = atom({
  key: 'shipmentTypeState',
  default: {}
});

const selectedInsurancePlan = atom({
  key: 'selectedInsurancePlan',
  default: {}
});

const selectedLastMileDeliveryOption = atom({
  key: 'selectedLastMileDeliveryOption',
  default: {}
});

const selectedPickupPartnerState = atom({
  key: 'selectedPickupPartnerState',
  default: {}
});

const selectedShippingMethod = atom({
  key: 'selectedShippingMethod',
  default: {}
});

const selectedPaymentMethod = atom({
  key: 'selectedPaymentMethod',
  default: {
    value: ''
  }
});

const selectedWalletFundingOption = atom({
  key: 'selectedWalletFundingOption',
  default: {}
});

const transactionReferenceState = atom({
  key: 'transactionReferenceState',
  default: null
});

const saveShipmentDetailsState = atom({
  key: 'saveShipmentDetailsState',
  default: {
    itemCollectionMode: shipmentMethods[0].value,
    itemPickupMode: '',
    initialReceiverAddress: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      city: '',
      state: '',
      country: '',
      countryCode: '',
      postalCode: ''
    },
    senderDetail: {
      isCompleted: false
    },
    receiverDetail: {
      isCompleted: false
    },
    items: [
      {
        category: '',
        weight: '',
        quantity: '',
        value: '',
        description: '',
        isCompleted: false,
        boxNumber: 1
      }
    ],

    pricingTier: '',
    insuranceType: '',
    insuranceCharge: 0,
    pickupCharge: 0,
    pickupDate: '',
    shipmentCharge: 0,
    budgetDeliveryCharge: 0,
    valueAddedTaxCharge: 0,
    shipmentRoute: shipmentOptions[0].value,
    additionalInformation: '',
    hubSlug: '',
    destinationType: '',
    isCompleted: false,
    pickupPartner: '',
    partnerLogoUrl: '',
    deliveryLocation: '',
    pickupId: '',
    multipleReceivers: [
      {
        receiverDetail: {
          name: '',
          email: '',
          phoneNumber: '',
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          country: '',
          state: '',
          city: '',
          countryCode: '',
          postalCode: '',
          shouldSave: false,
          isCompleted: false
        },
        items: [
          {
            category: '',
            weight: '',
            quantity: '',
            value: '',
            description: '',
            isCompleted: false,
            boxNumber: 1
          }
        ],
        couponCode: '',
        pricingTier: '',
        insuranceType: '',
        shipmentCharge: 0,
        insuranceCharge: 0,
        isCompleted: false
      }
    ]
  },
  effects_UNSTABLE: implementPersist('saveShipmentDetailsState')
});

const multipleShipmentCounponListState = atom({
  default: [],
  key: 'multipleShipmentCounponListState',
  effects_UNSTABLE: implementPersist('multipleShipmentCounponListState')
});

const saveShipmentRateState = atom({
  key: 'saveShipmentRate',
  default: []
});

const saveShopNShipRateState = atom({
  key: 'saveShopNShipRate',
  default: []
});

const savePickupRateState = atom({
  key: 'savePickupRateState',
  default: []
});

const saveLastMileState = atom({
  key: 'saveLastMileState',
  default: []
});

const shipmentRateInformationState = atom({
  key: 'saveShipmentRateInformation',
  default: {}
});

const walletState = atom({
  key: 'walletState',
  default: {
    walletId: '',
    walletBalance: 0
  }
});

const busyOverlayState = atom({
  key: 'busyOverlayState',
  default: false
});

const termsOverlayState = atom({
  key: 'termsOverlayState',
  default: false
});

const feedbackOverlayState = atom({
  key: 'feedbackOverlayState',
  default: false
});

const quotesSenderAddress = atom({
  key: 'quotesSenderAddress',
  default: {}
});
const quotesReceiverAddress = atom({
  key: 'quotesReceiverAddress',
  default: {}
});

const quotesAddressesState = atom({
  key: 'quotesAddressesState',
  default: {
    receiver: {},
    sender: {}
  }
});
const quotesWeight = atom({
  key: 'quotesWeight',
  default: null
});

// local & exports quotes state

const localAndExportsQuotesSenderAddress = atom({
  key: 'localAndExportsQuotesSenderAddress',
  default: {}
});
const localAndExportsQuotesReceiverAddress = atom({
  key: 'localAndExportsQuotesReceiverAddress',
  default: {}
});

const localAndExportsQuotesAddressesState = atom({
  key: 'localAndExportsQuotesAddressesState',
  default: {
    receiver: {},
    sender: {}
  }
});
const localAndExportsQuotesWeight = atom({
  key: 'localAndExportsQuotesWeight',
  default: null
});

const localAndExportsQuotesLocationDataState = atom({
  key: 'localAndExportsQuotesLocationDataState',
  default: {
    sender: {
      countries: [],
      states: [],
      cities: []
    },
    receiver: {
      countries: [],
      states: [],
      cities: []
    }
  }
});

// imports quotes state

const importsQuotesSenderAddress = atom({
  key: 'importsQuotesSenderAddress',
  default: {}
});
const importsQuotesReceiverAddress = atom({
  key: 'importsQuotesReceiverAddress',
  default: {}
});

const importsQuotesAddressesState = atom({
  key: 'importsQuotesAddressesState',
  default: {
    receiver: {},
    sender: {}
  }
});
const importsQuotesWeight = atom({
  key: 'importsQuotesWeight',
  default: null
});

const importsQuotesLocationDataState = atom({
  key: 'importsQuotesLocationDataState',
  default: {
    sender: {
      countries: [],
      states: [],
      cities: []
    },
    receiver: {
      countries: [],
      states: [],
      cities: []
    }
  }
});

const shipmentSenderAddressVerified = atom({
  key: 'shipmentSenderAddressVerified',
  default: false
});

const cancelShipment = atom({
  key: 'cancelShipment'
});

const warningState = atom({
  key: 'warningState',
  default: false
});

const receiverDetailsSchema = atom({
  key: 'receiverDetailsSchema',
  default: {
    name: {
      required: true,
      minWords: 2,
      label: 'Name'
    },
    email: {
      required: true,
      inputType: 'email',
      label: 'Email'
    },
    phoneNumber: {
      required: true,
      label: 'Phone Number'
    },
    addressLine1: {
      required: true,
      maxLength: 40,
      label: 'Address'
    },
    addressLine2: {
      required: false,
      maxLength: 40,
      label: 'Address'
    },
    addressLine3: {
      required: false,
      maxLength: 40,
      label: 'Address'
    },
    city: {
      required: true,
      maxLength: 35,
      label: 'City'
    },
    state: {
      required: true,
      maxLength: 35,
      label: 'State'
    },
    country: {
      required: true,
      maxLength: 35,
      label: 'Country'
    },
    postalCode: {
      required: true,
      label: 'Postal Code'
    }
  }
});

const senderDetailSchema = atom({
  key: 'senderDetailSchema',
  default: {
    name: {
      required: true,
      minWords: 2,
      label: 'Name'
    },
    email: {
      required: true,
      inputType: 'email',
      label: 'Email'
    },
    phoneNumber: {
      required: true,
      label: 'Phone Number'
    },
    addressLine1: {
      required: true,
      maxLength: 40,
      label: 'Address'
    },
    addressLine2: {
      required: false,
      maxLength: 40,
      label: 'Address'
    },
    addressLine3: {
      required: false,
      maxLength: 40,
      label: 'Address'
    },
    city: {
      required: true,
      maxLength: 35,
      label: 'City'
    },
    state: {
      required: true,
      maxLength: 35,
      label: 'State'
    },
    country: {
      required: true,
      maxLength: 35,
      label: 'Country'
    },
    postalCode: {
      required: false,
      label: 'Postal Code'
    }
  }
});

const transactionState = atom({
  key: 'transactionState',
  default: { currentPage: 1, list: [] }
});

const showOtpState = atom({
  key: 'showOtpState',
  default: false
});

const showBankListState = atom({
  key: 'showBankListState',
  default: false
});

const showErrorOverlayState = atom({
  key: 'showErrorOverlayState',
  default: true
});

const showTooltipOverlayState = atom({
  key: 'showTooltipOverlayState',
  default: {
    show: false,
    content: {}
  }
});

const allBankDetailsState = atom({
  key: 'allBankDetailsState',
  default: []
});

const quotesLocationDataState = atom({
  key: 'quotesLocationDataState',
  default: {
    sender: {
      countries: [],
      states: [],
      cities: []
    },
    receiver: {
      countries: [],
      states: [],
      cities: []
    }
  }
});
const bonusDiscountAmountState = atom({
  key: 'bonusDiscountAmountState',
  default: 0
});

const shippingRouteState = atom({
  key: 'shippingRouteState',
  default: 'export'
});

const shippingTermsAndConditionsState = atom({
  key: 'shippingTermsAndConditionsState',
  default: false
});

const shipmentNumberState = atom({
  key: 'shipmentNumberState',
  default: 0
});

const shipmentDataState = atom({
  key: 'shipmentDataState',
  default: []
});

const shipmentDataLoadingState = atom({
  key: 'shipmentDataLoadingState',
  default: false
});

const multipleRateLoadingState = atom({
  key: 'multipleRateLoadingState',
  default: false
});

const shipmentOptionSelectionState = atom({
  key: 'shipmentOptionSelectionState',
  default: {}
});

const shipmentMethodSelectionState = atom({
  key: 'shipmentMethodSelectionState',
  default: {}
});

const shipmentDestinationSelectionState = atom({
  key: 'shipmentDestinationSelectionState',
  default: {}
});
const importOptionState = atom({
  key: 'importOptionState',
  default: {}
});
const shipmentHubSelectionState = atom({
  key: 'shipmentHubSelectionState',
  default: {
    title: '',
    description: '',
    slug: '',
    value: {
      addressLine1: '',
      addressLine2: '',
      country: '',
      countryCode: '',
      state: '',
      city: ''
    }
  }
});

const saveOrderSummaryPathState = atom({
  key: 'saveOrderSummaryPathState',
  default: {
    path: ''
  },
  effects_UNSTABLE: implementPersist('saveOrderSummaryPathState')
});

const shopnshipSelectedCountryState = atom({
  key: 'shopnshipSelectedCountryState',
  default: shippingFromOptions[0],
  effects_UNSTABLE: implementPersist('shopnshipSelectedCountryState')
});

const shopnshipSelectedProductState = atom({
  key: 'shopnshipSelectedProductState',
  default: {
    label: ''
  },
  effects_UNSTABLE: implementPersist('shopnshipSelectedProductState')
});

const multipleShipmentRatesState = atom({
  key: 'multipleShipmentRatesState',
  default: [],
  effects_UNSTABLE: implementPersist('multipleShipmentRatesState')
});

const multipleShipmentResultsState = atom({
  key: 'multipleShipmentResultsState',
  default: [],
  effects_UNSTABLE: implementPersist('multipleShipmentResultsState')
});

const multipleShopnshipResultsState = atom({
  key: 'multipleShopnshipResultsState',
  default: [],
  effects_UNSTABLE: implementPersist('multipleShopnshipResultsState')
});

const multiplePhoneNumbersState = atom({
  key: 'multiplePhoneNumbersState',
  default: { sender: [], receiver: [] },
  effects_UNSTABLE: implementPersist('multiplePhoneNumbersState')
});

const multipleSaveShipmentLoadingState = atom({
  key: 'multipleSaveShipmentLoadingState',
  default: {
    loading: false,
    completed: false
  }
});

const addressTotalState = atom({
  key: 'addressTotalState',
  default: 1
});

const shopNShipResultState = atom({
  key: 'shopNShipResultState',
  default: {},
  effects_UNSTABLE: implementPersist('shopNShipResultState')
});

const shopnshipListState = atom({
  key: 'shopnshipListState',
  default: []
});
const chinaImportListState = atom({
  key: 'chinaImportListState',
  default: []
});
const userState = atom({
  key: 'userState',
  default: {
    topshipId: '',
    id: '',
    fullName: '',
    phoneNumber: '',
    email: '',
    displayPictureUrl: '',
    accountType: '',
    referredUsers: [],
    isEmailVerified: false,
    percentageCommissionOnUser: null,
    businessInformation: {
      id: '',
      logo: '',
      businessName: '',
      compliance: '',
      isVerified: false,
      directors: [{ fullName: '', compliance: '' }],
      location: {
        address: '',
        state: '',
        city: ''
      }
    }
  }
});

const shopnshipRateState = atom({
  key: 'shopnshipRateState',
  default: {
    rate: 0,
    fee: 0,
    finalAmount: 0,
    currency: '',
    amount: ''
  },
  effects_UNSTABLE: implementPersist('shopnshipRateState')
});

const openRoundUpPromptState = atom({
  key: 'openRoundUpPromptState',
  default: false,
  effects_UNSTABLE: implementPersist('openRoundUpPromptState')
});

const logisticsBusinessIdState = atom({
  key: 'logisticsBusinessIdState',
  default: '',
  effects_UNSTABLE: implementPersist('logisticsBusinessIdState')
});

const verificationStepsState = atom({
  key: 'verificationStepsState',
  default: [
    {
      isActive: true,
      isCompleted: false,
      component: Verification
    },
    {
      isActive: false,
      isCompleted: false,
      component: BusinessLocation
    },
    {
      isActive: false,
      isCompleted: false,
      component: Certification
    },
    {
      isActive: false,
      isCompleted: false,
      component: Directors
    },
    {
      isActive: false,
      isCompleted: false,
      component: DirectorCertification
    },
    {
      isActive: false,
      isCompleted: false,
      component: Confidentiality
    }
  ]
});

const disableShippingLinkState = atom({
  default: false,
  key: 'disableShippingLinkState'
});

export {
  loginState,
  shipmentRequestState,
  shipmentPaymentState,
  quoteRequestState,
  selectedRateState,
  shipmentTypeState,
  selectedInsurancePlan,
  selectedShippingMethod,
  selectedPaymentMethod,
  selectedWalletFundingOption,
  transactionReferenceState,
  saveShipmentDetailsState,
  walletState,
  saveShipmentRateState,
  shipmentRateInformationState,
  busyOverlayState,
  termsOverlayState,
  feedbackOverlayState,
  quotesReceiverAddress,
  quotesSenderAddress,
  quotesWeight,
  shipmentSenderAddressVerified,
  warningState,
  receiverDetailsSchema,
  senderDetailSchema,
  transactionState,
  showOtpState,
  showBankListState,
  cancelShipment,
  showErrorOverlayState,
  allBankDetailsState,
  quotesAddressesState,
  quotesLocationDataState,
  bonusDiscountAmountState,
  shippingRouteState,
  localAndExportsQuotesSenderAddress,
  localAndExportsQuotesReceiverAddress,
  localAndExportsQuotesAddressesState,
  localAndExportsQuotesWeight,
  localAndExportsQuotesLocationDataState,
  importsQuotesSenderAddress,
  importsQuotesReceiverAddress,
  importsQuotesAddressesState,
  importsQuotesWeight,
  importsQuotesLocationDataState,
  showTooltipOverlayState,
  blackFridayState,
  selectedBlackFridayState,
  shippingTermsAndConditionsState,
  shipmentNumberState,
  shipmentDataState,
  shipmentDataLoadingState,
  shopNShipDetailsState,
  shopNShipValidationState,
  shipmentOptionSelectionState,
  shipmentMethodSelectionState,
  shipmentDestinationSelectionState,
  shipmentHubSelectionState,
  saveOrderSummaryPathState,
  multipleShipmentRatesState,
  multipleShipmentResultsState,
  multipleRateLoadingState,
  multipleSaveShipmentLoadingState,
  multiplePhoneNumbersState,
  addressTotalState,
  shopNShipResultState,
  shopnshipListState,
  userState,
  shopnshipRateState,
  userRoundUpDataState,
  openRoundUpPromptState,
  userUpdateLoadingState,
  logisticsBusinessIdState,
  verificationStepsState,
  shopnshipSelectedCountryState,
  budgetDeliveryState,
  importOptionState,
  chinaImportState,
  filteredShopNShipLinksState,
  saveShopNShipRateState,
  chinaImportListState,
  multipleShipmentCounponListState,
  selectedPickupPartnerState,
  savePickupRateState,
  shopnshipSelectedProductState,
  saveLastMileState,
  selectedLastMileDeliveryOption,
  multipleShopnshipResultsState,
  disableShippingLinkState
  // seaFreightStepsState
};

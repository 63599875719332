export const pickupLocations = [
  {
    city: 'Dallas Hub',
    address: `1234 N Belt Line Rd Irving, TX 75061, USA`,
    state: 'Texas',
    postalCode: '75061'
  }
];

export const doorstepOption = {
  city: 'Doorstep Delivery',
  address:
    'A price quote will be sent to your email after the booking is confirmed'
};

import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { shipmentOptions } from '../constants/book-shipment-contants';
import {
  chinaImportState,
  filteredShopNShipLinksState,
  saveShipmentDetailsState,
  shipmentOptionSelectionState,
  shipmentRequestState,
  warningState
} from '../recoil/atoms';
import BookShipmentContentItem from './Commons/book-shipment-content-item';
import BusyOverlay from './busy-overlay';
import { useEffect, useRef } from 'react';
import { shopNShipLinks } from '../constants/shopnship-links';
import StickyNoteAlert from './Commons/sticky-note';

const BookShipmentOptions = ({ scroll }) => {
  const modal = useRef();
  const isMiniMobile = window.innerWidth <= 425;
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useRecoilState(
    shipmentOptionSelectionState
  );
  const [, setRequestPage] = useRecoilState(shipmentRequestState);
  const [shipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  const [isChinaImport, setIsChinaImport] = useRecoilState(chinaImportState);
  const [filteredLinks, setFilteredLinks] = useRecoilState(
    filteredShopNShipLinksState
  );
  const [, setShow] = useRecoilState(warningState);
  const handleShipmentOptionSelection = option => () => {
    setSelectedOption(option);
    handleNextButtonClick(option);
  };
  useEffect(() => {
    const filteredShopNShipLinks = shopNShipLinks.filter(link =>
      isChinaImport
        ? link.name !== 'Personal Information' &&
          link.name !== 'Shop & Ship Summary' &&
          link.name !== 'Shop & Ship Payment' &&
          link.name !== 'Shop & Ship Successful'
        : link.name !== 'Delivery Option' &&
          link.name !== 'Order Summary' &&
          link.name !== 'Payment' &&
          link.name !== 'Delivery Address'
    );
    setFilteredLinks(filteredShopNShipLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChinaImport]);

  const handleCloseButtonClick = () => setShow(true);

  const handleShowShopNShipPrompt = () => {
    setIsChinaImport(false);

    history.push(filteredLinks[0].link);
  };

  const handleNextButtonClick = selectedOption => {
    if (selectedOption?.value?.toLowerCase() === 'shopnship') {
      handleShowShopNShipPrompt();
    } else {
      setRequestPage(requestPage => requestPage + 1);
      setSaveShipmentDetail({
        ...shipmentDetail,
        shipmentRoute: selectedOption?.value
      });
    }
    if (selectedOption?.value === shipmentOptions[0].value) {
      const scrollValue = isMiniMobile ? modal.current?.clientHeight + 70 : 550;
      scroll && scroll(scrollValue);
    } else if (selectedOption?.value === shipmentOptions[1].value) {
      const scrollValue = isMiniMobile ? modal.current?.clientHeight + 70 : 550;
      scroll && scroll(scrollValue);
    }
  };

  return (
    <div ref={modal} className='book-shipment-modal-contents'>
      <BusyOverlay />
      <div className='book-shipment-modal-header'>
        <h3>Book Shipment</h3>
        <div className='book-shipment-modal-close-button'>
          <IconButton
            onClick={() => {
              setShow(true);
            }}
          >
            <p>
              <Close id='close-button' />
            </p>
          </IconButton>
        </div>
      </div>
      <div className='book-shipment-modal-content'>
        <StickyNoteAlert>
          Please <b>note</b> that shipments booked <b>after 2 p.m. WAT</b> will
          be processed on the <b>next business day.</b> Pick-up times vary
          depending on rider availability and pick-up location. Please be
          patient with us as we attend to your orders.
        </StickyNoteAlert>

        {shipmentOptions.map((option, index) => (
          <BookShipmentContentItem
            key={index}
            onClick={handleShipmentOptionSelection(option)}
            selected={selectedOption?.title === option.title}
            option={option}
          />
        ))}
      </div>

      <div className='book-shipment-modal-footer shipment-option-footer'>
        <div
          className='book-shipment-modal-button book-shipment-modal-cancel-button'
          onClick={handleCloseButtonClick}
        >
          <span>Close</span>
        </div>
      </div>
    </div>
  );
};

export default BookShipmentOptions;

import React from 'react';
import shipmentDictionary from '../../constants/shipment-status-dictionary';

const ShipmentStatus = ({ shipment }) => {
  return (
    <div
      className={`status recent-shipment-item ${shipment?.shipmentStatus?.toLocaleLowerCase()}`}
    >
      {shipmentDictionary(shipment?.shipmentStatus)}
    </div>
  );
};

export default ShipmentStatus;

import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FileIcon, NextArrow } from '../constants/asset-contants';

const ShipmentHeader = ({
  shipmentType,
  setShow,
  setOpenResources,
  setOpenGetHelpModal
}) => {
  return (
    <div className='sea-freight__header'>
      <div className='header-wrap'>
        <div className='title-close-wrap'>
          <h2>{shipmentType}</h2>
          <IconButton className='close-btn' onClick={() => setShow(true)}>
            <Close />
          </IconButton>
        </div>
        <div className='cta-wrap'>
          <div className='guides-tip-container'>
            <img
              style={{
                height: '20px'
              }}
              src={FileIcon}
              alt=''
            />
            <h1
              style={{
                fontSize: '13px',
                color: '#22428F',
                fontWeight: 700,
                cursor: 'pointer',
                marginLeft: '6px',
                marginBottom: 0
              }}
              onClick={() => setOpenResources(true)}
            >
              Guides and Resources
            </h1>
          </div>
          <button
            className='get-help-button'
            onClick={() => setOpenGetHelpModal(true)}
          >
            <span
              style={{
                fontSize: '10px'
              }}
            >
              Get Help
            </span>
            <img src={NextArrow} alt='live chat' />
          </button>
        </div>

        <div className='web-close-wrap'>
          <IconButton className='close-btn' onClick={() => setShow(true)}>
            <Close />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default ShipmentHeader;

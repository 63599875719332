import extractFilename from './extract-filename';

const fileTypes = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf'
};

export const convertUrlToFile = async (url = '') => {
  if (!url) {
    return null;
  }

  const fileName = extractFilename(url);

  const response = await fetch(url, { mode: 'no-cors' });

  const blob = await response.blob();

  return new File([blob], fileName, {
    type: fileTypes[fileName.split('.')[fileName.split('.').length - 1]]
  });
};

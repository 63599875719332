const shipmentMetricDurations = [
  { type: 'This Month', value: 'Current', timeFrame: 'this month' },
  {
    type: 'Previous Month',
    value: 'Previous',
    timeFrame: 'in the previous month'
  },
  { type: '2 Months Ago', value: 'TwoMonthsAgo', timeFrame: '2 months ago' }
];

export default shipmentMetricDurations;

import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import omit from 'lodash.omit';
import { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  shipmentMethods,
  shipmentOptions
} from '../constants/book-shipment-contants';
import { useGetSavedAddresses } from '../operations/queries';
import {
  saveShipmentDetailsState,
  shipmentMethodSelectionState,
  shipmentRequestState,
  warningState
} from '../recoil/atoms';
import { useAuthToken } from '../token';
import BusyOverlay from './busy-overlay';
import BookShipmentContentItem from './Commons/book-shipment-content-item';
import DatePicker from './modals/date-picker';

const BookShipmentMethods = ({ onCustomNext, scroll, firstHeight }) => {
  const modal = useRef();
  const isMiniMobile = window.innerWidth <= 425;
  const [, , , getToken] = useAuthToken();
  const isLoggedIn = Boolean(getToken());
  const [selectedMethod, setSelectedMethod] = useRecoilState(
    shipmentMethodSelectionState
  );
  const [, setRequestPage] = useRecoilState(shipmentRequestState);
  const [shipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );

  const initialPickupDate = useMemo(() => {
    if (shipmentDetail?.pickupDate) {
      return new Date(shipmentDetail?.pickupDate).getTime();
    }

    return Date.now();
  }, [shipmentDetail?.pickupDate]);

  const [pickupDate, setPickupDate] = useState(initialPickupDate);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [, setShow] = useRecoilState(warningState);

  const selectedOption = useMemo(() => {
    return shipmentOptions.find(
      option => shipmentDetail.shipmentRoute === option.value
    );
  }, [shipmentDetail.shipmentRoute]);

  const move = () => {
    const total = modal.current?.clientHeight + firstHeight + 70 * 2;

    scroll?.(isMiniMobile ? total : 650 * 2);
  };

  const handleShipmentMethodSelection = option => () => {
    setSelectedMethod(option);

    if (option?.value === shipmentMethods[1].value) {
      return setOpenDatePicker(true);
    }

    handleNextButtonClick(option);
  };

  const [
    getAddresses,
    { data: addressListData, loading }
  ] = useGetSavedAddresses(undefined, undefined, 'no-cache');

  const handleNextButtonClick = (selectedMethod, pickupDate) => {
    const updatedShipmentDetail = { ...shipmentDetail };

    updatedShipmentDetail.itemCollectionMode = selectedMethod?.value;

    if (selectedMethod?.value === shipmentMethods[1].value) {
      if (pickupDate) {
        updatedShipmentDetail.pickupDate = new Date(pickupDate).toISOString();
      }

      if (shipmentDetail?.hubSlug) {
        updatedShipmentDetail.senderDetail = {
          ...shipmentDetail?.senderDetail,
          addressLine1: '',
          addressLine2: '',
          country: '',
          countryCode: '',
          state: '',
          city: ''
        };

        updatedShipmentDetail.hubSlug = '';
      }
    }

    setSaveShipmentDetail(updatedShipmentDetail);

    if (onCustomNext) {
      return onCustomNext();
    } else {
      if (selectedMethod?.dropoff) {
        move();

        setRequestPage(requestPage => requestPage + 1);
      } else {
        if (isLoggedIn && selectedOption?.value === shipmentOptions[0].value) {
          getAddresses({
            take: 10,
            page: 1
          });
        } else {
          move();

          setRequestPage(requestPage => requestPage + 2);
        }
      }
    }
  };

  const handleBackButtonClick = () => {
    setRequestPage(requestPage => requestPage - 1);

    setSaveShipmentDetail({
      ...shipmentDetail,
      itemCollectionMode: ''
    });

    scroll?.(0);
  };

  const handleDateChange = date => {
    setPickupDate(date);

    handleNextButtonClick(shipmentMethods[1], date);
  };

  const handleShipmentData = async address => {
    return setSaveShipmentDetail({
      ...shipmentDetail,
      senderDetail: omit(address, ['updatedDate', 'isDefault'])
    });
  };

  useEffect(() => {
    if (shipmentDetail.itemCollectionMode) {
      setSelectedMethod(
        shipmentMethods.find(
          method => method.value === shipmentDetail?.itemCollectionMode
        )
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (addressListData) {
      const address = addressListData?.getAddresses?.list?.find(
        address => address.isDefault
      );

      if (address) {
        handleShipmentData(address).then(() => {
          move();

          setRequestPage(requestPage => requestPage + 2);
        });
      } else {
        move();

        setRequestPage(requestPage => requestPage + 2);
      }
    }
    // eslint-disable-next-line
  }, [addressListData]);

  return (
    <div ref={modal} className='book-shipment-modal-contents'>
      <BusyOverlay loading={loading} />
      <div className='book-shipment-modal-header'>
        <h3>Shipment Method</h3>
        <div className='book-shipment-modal-close-button'>
          <IconButton
            onClick={() => {
              setShow(true);
            }}
          >
            <p>
              <Close id='close-button' />
            </p>
          </IconButton>
        </div>
      </div>
      <div className='book-shipment-modal-content'>
        <p className='book-shipment-content-title'>Select a preferred option</p>

        {shipmentMethods
          .filter(option =>
            selectedOption?.id === 2 ? option.id !== 2 : option
          )
          .map((option, index) => (
            <BookShipmentContentItem
              key={index}
              onClick={handleShipmentMethodSelection(option)}
              selected={selectedMethod?.title === option.title}
              option={option}
            />
          ))}
        {selectedMethod?.value?.toLowerCase() === 'pickup' && (
          <div className='select-pickup-date'>
            <p>Select pickup date</p>
            <DatePicker
              openDatePicker={openDatePicker}
              setOpenDatePicker={setOpenDatePicker}
              onChange={handleDateChange}
              value={pickupDate}
            />
          </div>
        )}
      </div>

      <div className='book-shipment-modal-footer'>
        <div
          className='book-shipment-modal-button book-shipment-modal-cancel-button'
          onClick={handleBackButtonClick}
        >
          <span>Back</span>
        </div>
      </div>
    </div>
  );
};

export default BookShipmentMethods;

import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, IconButton, InputAdornment } from '@mui/material';
import { Close, SwapVert } from '@mui/icons-material';
import SubmitButton from '../submit-button';
import Input from '../new-dashboard/custom-input';
import debounce from 'lodash.debounce';
import {
  useGetCountriesLazyQuery,
  useGetSavedAddresses,
  useGetStatesQuery
} from '../../operations/queries';
import { useFormik } from 'formik';
import BusyOverlay from '../busy-overlay';
import * as yup from 'yup';
import {
  useGetCitiesMutation,
  useSaveAddressMutation,
  useUpdateAddressMutation
} from '../../operations/mutations';
import PaymentSuccessfull from '../../pages/Dashboard/Wallet/FundWallet/wallet-funding-successful';
import { warningState } from '../../recoil/atoms';
import { useRecoilState } from 'recoil';
import CustomAutoComplete from '../new-dashboard/custom-autocomplete';
import usePlaces from '../../utilities/use-places';
import { extractLocation } from '../../utilities/extract-location';
import ReactGA from 'react-ga';
import { defaultDetail } from '../../constants/book-shipment-contants';
const initialAddress = {
  name: '',
  email: '',
  phoneNumber: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  country: 'Nigeria',
  state: '',
  city: '',
  countryCode: defaultDetail.countryCode.toUpperCase(),
  postalCode: '100001'
};
const NewAddressesModal = ({
  openModal,
  setOpenModal,
  address,
  setAddress,
  setIsDirty
}) => {
  const [, setShow] = useRecoilState(warningState);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isExecuted, setIsExecuted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [getCountries, countries] = useGetCountriesLazyQuery();
  const [initialValues, setInitialValues] = useState(initialAddress);
  const [getStates, states] = useGetStatesQuery();
  const [getCities, citiesResult] = useGetCitiesMutation();
  const addressLine1Ref = useRef();
  const addressLine2Ref = useRef();
  const addressLine3Ref = useRef();
  const [isAutomated, setIsAutomated] = useState(false);
  const [place, setPlace] = useState({
    description: ''
  });
  const [getAddresses] = useGetSavedAddresses();
  const onComplete = () => {
    setIsCompleted(true);
    getAddresses();
  };
  const [updateAddress] = useUpdateAddressMutation(onComplete);
  const [saveAddress, saveAddressResult] = useSaveAddressMutation(onComplete);

  const { getPlaces, placesService, placesResult } = usePlaces();

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required('Please enter a name')
      .test(
        'number of words',
        'Name should not be less than 2 words',
        value => value?.split(' ')?.length >= 2
      ),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('please enter an email address'),
    phoneNumber: yup.string().required('Please enter a phone number'),
    addressLine1: yup
      .string()
      .test(
        'contains the + character',
        'Please enter a valid address',
        value => !value?.includes('+')
      )
      .required('Please enter an address'),
    addressLine2: yup
      .string()
      .test(
        'contains the + character',
        'Please enter a valid address',
        value => !value?.includes('+')
      )
      .nullable(),
    addressLine3: yup
      .string()
      .test(
        'contains the + character',
        'Please enter a valid address',
        value => !value?.includes('+')
      )
      .nullable(),
    country: yup.string().required('Please select a country'),
    state: yup.string().required('Please select a state'),
    city: yup.string().required('Please select a city'),
    countryCode: yup.string().required(),
    postalCode: yup.string().when(['countryCode'], {
      is: countryCode =>
        countryCode?.toLowerCase() !== defaultDetail.countryCode,
      then: yup.string().required('Please provide a postal code')
    })
  });

  const {
    values,
    errors,
    dirty,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, props) => {
      if (!Boolean(address)) {
        saveAddress(values);
        props.resetForm(initialAddress);
      } else {
        const update = { ...address, ...values };
        delete update?.createdDate;
        delete update?.updatedDate;
        delete update?.addressType;
        delete update?.user;
        delete update?.id;
        delete update?.__typename;
        updateAddress(update, address.id);
        props.resetForm(initialAddress);
      }
    }
  });

  const handleSelectPlace = place => {
    placesService?.getDetails(
      { placeId: place?.place_id },
      (placeInfo, placeStatus) => {
        ReactGA.event({
          category: 'Shipment Booking',
          action: `User used autocomplete and got this status in new address modal - ${placeStatus}`
        });
        const { countryCode, city, country, state, address } = extractLocation(
          placeInfo
        );
        getStates(countryCode);
        getCities({ countryCode, cityName: city });
        const choseNigeria =
          countryCode.toLowerCase() === defaultDetail.countryCode;
        const cleanAddress = address
          ?.split(', ')
          ?.slice(0, address?.split(', ').length - 2)
          ?.join(', ');
        const addressLine1 = cleanAddress.substring(0, 40);
        const addressLine2 = cleanAddress.substring(40, 80);
        const addressLine3 = cleanAddress.substring(80, 120);
        const newAddress = {
          ...values,
          ...(addressLine2 && { addressLine2 }),
          ...(addressLine3 && { addressLine3 }),
          ...(choseNigeria && { postalCode: '100001' }),
          city,
          addressLine1,
          country,
          state,
          countryCode
        };
        setInitialValues(newAddress);
        setIsAutomated(false);
      }
    );
  };

  const handleValidation = event => {
    event.preventDefault();
    const choseNigeria =
      values?.countryCode?.toLowerCase() === defaultDetail.countryCode;
    if (!choseNigeria && !values.postalCode) {
      setFieldError('postalCode', 'Please provide a postal code');
    } else {
      handleSubmit(event);
    }
  };

  const getCountryCode = input => {
    const term = input?.terms[input?.terms?.length - 1];
    const value = term?.value === 'USA' ? 'United states' : term.value;
    return countries.data?.getCountries
      ? countries.data?.getCountries
          .find(country => country?.name.toLowerCase() === value?.toLowerCase())
          ?.code?.toLowerCase() || defaultDetail.countryCode
      : defaultDetail.countryCode;
  };

  const exit = () => {
    setAddress(null);
    setOpenModal(false);
    setInitialValues(initialAddress);
    setSuccess(false);
    setIsExecuted(false);
    setIsAutomated(false);
    setPlace({ description: '' });
    resetForm();
  };

  useEffect(() => {
    getCountries();
    getStates(values.countryCode);
    getCities({ countryCode: values.countryCode, cityName: 'a' });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Boolean(address) && !isExecuted) {
      setInitialValues(address);
      setPlace(place => ({ ...place, description: address?.addressLine1 }));
      getPlaces(address?.addressLine1);
      getStates(address.countryCode);
      getCities({
        countryCode: address.countryCode,
        cityName: address.city
      });
      setIsExecuted(true);
    }
    // eslint-disable-next-line
  }, [address, isExecuted]);

  useEffect(() => {
    if (isCompleted) {
      setInitialValues(initialAddress);
      setSuccess(true);
      setIsCompleted(false);
    }
    // eslint-disable-next-line
  }, [isCompleted]);

  useEffect(() => {
    if (!openModal) {
      exit();
    }
    // eslint-disable-next-line
  }, [openModal]);

  const loading =
    countries.loading ||
    states.loading ||
    citiesResult.loading ||
    placesResult.loading;

  return (
    <div className={`new-addresses__modal fade${openModal ? ' show' : ''}`}>
      <BusyOverlay
        loading={countries.loading || saveAddressResult.loading}
        text={saveAddressResult.loading && 'Saving your address...'}
      />
      {success ? (
        <PaymentSuccessfull
          isNotWalletRelated
          buttonText='Close'
          heading='Successfully saved your address'
          subHeading="We've successfully saved your new address, you can now close this."
          path='/addresses'
          onClickButton={exit}
        />
      ) : (
        <div className='new-addresses__modal__wrap'>
          <BusyOverlay loading={loading} />
          <div className='new-addresses__modal__header'>
            <div className='new-addresses__modal__header__wrap'>
              <h1>{address ? 'Edit' : 'Add an'} address</h1>
              <IconButton
                onClick={() => {
                  if (dirty) {
                    setIsDirty(dirty);
                    setShow(true);
                  } else {
                    exit();
                  }
                }}
              >
                <Close />
              </IconButton>
            </div>
          </div>
          <div className='new-addresses__modal__body'>
            <div className='new-addresses__modal__body__wrap'>
              <div className='new-addresses__modal__body__label'>
                <span>Address Details</span>
              </div>
              <div className='new-addresses__modal__body__content'>
                <form
                  onSubmit={handleValidation}
                  className='new-addresses__modal__body__form'
                >
                  <label
                    htmlFor='amount'
                    className='new-addresses__modal__body__form__item__label'
                  >
                    Enter address details
                  </label>
                  <Grid container columnSpacing='10px' rowSpacing='16px'>
                    <Input
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                      value={values.name}
                      onChange={handleChange}
                      customLabel='Full Name'
                      name='name'
                      id='name'
                      type='text'
                      containerClassName='new-addresses__modal__body__form__item__input'
                      placeholder='Garrick Sokari Braide'
                      fullWidth
                    />
                    <Input
                      error={Boolean(errors.email)}
                      helperText={errors.email}
                      value={values.email}
                      onChange={handleChange}
                      customLabel='Email address'
                      name='email'
                      id='email'
                      type='email'
                      containerClassName='new-addresses__modal__body__form__item__input'
                      placeholder='garrick@topship.com'
                      fullWidth
                      lg={6}
                    />
                    <Input
                      id='phoneNumber'
                      name='phoneNumber'
                      error={Boolean(errors.phoneNumber)}
                      helperText={errors.phoneNumber}
                      onChange={value => setFieldValue('phoneNumber', value)}
                      value={values.phoneNumber}
                      type='tel'
                      placeholder='+234 801 234 5678'
                      containerClassName='new-addresses__modal__body__form__item__input'
                      customLabel='Phone Number'
                      lg={6}
                    />
                  </Grid>
                  <div className='mt-3 item-description__action switcher'>
                    <Button
                      variant='text'
                      onClick={() => setIsAutomated(bool => !bool)}
                    >
                      <span>
                        {isAutomated
                          ? 'Switch to manually fill address details'
                          : 'Switch to automatically fill address details'}
                      </span>
                      <SwapVert />
                    </Button>
                  </div>
                  <Grid container columnSpacing='10px' rowSpacing='16px'>
                    {isAutomated ? (
                      <CustomAutoComplete
                        options={placesResult.data}
                        getOptionLabel={option =>
                          option ? option?.description : ''
                        }
                        isOptionEqualToValue={(option, value) =>
                          option?.place_id === value?.place_id
                        }
                        renderOption={(props, option, data) => (
                          <Box
                            component='li'
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...props}
                            key={props.id}
                          >
                            {data && (
                              <img
                                loading='lazy'
                                width='30'
                                height='20'
                                src={`https://flagcdn.com/w20/${getCountryCode(
                                  option
                                )}.png`}
                                srcSet={`https://flagcdn.com/w40/${getCountryCode(
                                  option
                                )}.png 2x`}
                                alt=''
                              />
                            )}
                            {option?.description}
                          </Box>
                        )}
                        value={place}
                        filterOptions={option => option}
                        noOptionsText={
                          !place?.description
                            ? 'Please enter your address'
                            : placesResult.loading
                            ? 'Addresses loading...'
                            : 'Address not found'
                        }
                        onChange={(event, value, reason) => {
                          if (reason === 'clear') {
                            setPlace({ description: '' });
                          } else {
                            handleSelectPlace(value);
                          }
                        }}
                        textfieldProps={{
                          lg: 12,
                          md: 12,
                          name: 'addressLine1',
                          customLabel: 'Address',
                          containerClassName:
                            'new-addresses__modal__body__form__item__input',
                          value: place?.description,
                          error: Boolean(
                            !place.description && errors.addressLine1
                          ),
                          helperText: errors.addressLine1,
                          onChange: event => {
                            setPlace(place => ({
                              ...place,
                              description: event.target.value
                            }));
                            getPlaces(event.target.value);
                          }
                        }}
                      />
                    ) : (
                      <>
                        <Input
                          error={Boolean(errors.addressLine1)}
                          helperText={errors.addressLine1}
                          value={values.addressLine1}
                          onChange={event => {
                            const {
                              target: { name, value }
                            } = event;
                            if (value.length < 41) {
                              handleChange(event);
                            } else {
                              handleChange({
                                target: {
                                  name,
                                  value: value.substring(0, 40)
                                }
                              });
                              setFieldValue(
                                'addressLine2',
                                value.substring(
                                  40,
                                  value.length > 80 ? 80 : value.length
                                )
                              );
                              if (value.length > 80) {
                                setFieldValue(
                                  'addressLine3',
                                  value.substring(
                                    80,
                                    value.length > 120 ? 120 : value.length
                                  )
                                );
                              }
                              addressLine2Ref.current?.focus();
                            }
                          }}
                          customLabel='Address'
                          name='addressLine1'
                          id='addressLine1'
                          type='text'
                          containerClassName='new-addresses__modal__body__form__item__input'
                          placeholder='1, Idumota lane, Iweka Road'
                          fullWidth
                          inputProps={{
                            ref: addressLine1Ref
                          }}
                        />
                        <Input
                          error={Boolean(errors.addressLine2)}
                          helperText={errors.addressLine2}
                          value={values.addressLine2}
                          onChange={event => {
                            const {
                              target: { name, value }
                            } = event;
                            if (value.length < 41) {
                              handleChange(event);
                              if (!value) {
                                addressLine1Ref.current?.focus();
                              }
                            } else {
                              handleChange({
                                target: {
                                  name,
                                  value: value.substring(0, 40)
                                }
                              });
                              setFieldValue(
                                'addressLine3',
                                value.substring(40, value.length)
                              );
                              addressLine3Ref.current?.focus();
                            }
                          }}
                          customLabel='Nearest Landmark'
                          name='addressLine2'
                          id='addressLine2'
                          type='text'
                          containerClassName='new-addresses__modal__body__form__item__input'
                          placeholder='Oando Petrol Station, Iweka Road'
                          fullWidth
                          inputProps={{
                            ref: addressLine2Ref
                          }}
                        />
                        <Input
                          error={Boolean(errors.addressLine3)}
                          helperText={errors.addressLine3}
                          value={values.addressLine3}
                          onChange={event => {
                            if (event.target.value.length < 41) {
                              handleChange(event);
                              if (!event.target.value) {
                                addressLine2Ref.current?.focus();
                              }
                            } else {
                              handleChange({
                                target: {
                                  name: event.target.name,
                                  value: event.target.value.substring(0, 40)
                                }
                              });
                            }
                          }}
                          customLabel='Address Line 3'
                          name='addressLine3'
                          id='addressLine3'
                          type='text'
                          containerClassName='new-addresses__modal__body__form__item__input'
                          placeholder='Iweka Road, Agege, Lagos.'
                          fullWidth
                          inputProps={{
                            ref: addressLine3Ref
                          }}
                        />
                        <CustomAutoComplete
                          options={
                            countries.data ? countries.data?.getCountries : []
                          }
                          getOptionLabel={option => option?.name}
                          isOptionEqualToValue={(option, value) =>
                            option?.name === value?.name
                          }
                          renderOption={(props, option, country) => (
                            <Box
                              component='li'
                              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                              {...props}
                              key={props.id}
                            >
                              {country && (
                                <img
                                  loading='lazy'
                                  width='30'
                                  height='20'
                                  src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
                                  alt=''
                                />
                              )}
                              {option.name}
                            </Box>
                          )}
                          value={
                            countries?.data
                              ? countries?.data?.getCountries?.find(
                                  country => country.name === values.country
                                )
                              : { name: initialValues.country || '' }
                          }
                          noOptionsText={'Country not found'}
                          onChange={(event, value) => {
                            setFieldValue('country', value?.name);
                            setFieldValue('countryCode', value?.code);
                            getStates(value?.code);
                            getCities({
                              countryCode: value?.code,
                              cityName: 'a'
                            });
                          }}
                          textfieldProps={{
                            lg: 4,
                            md: 12,
                            name: 'country',
                            customLabel: 'Country',
                            containerClassName:
                              'new-addresses__modal__body__form__item__input',
                            error: Boolean(errors.country),
                            helperText: errors.country,
                            value: values.country,
                            InputProps: {
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <img
                                    loading='lazy'
                                    width='20'
                                    height='14'
                                    src={`https://flagcdn.com/w20/${
                                      values.countryCode?.toLowerCase() ||
                                      defaultDetail.countryCode
                                    }.png`}
                                    srcSet={`https://flagcdn.com/w40/${
                                      values.countryCode?.toLowerCase() ||
                                      defaultDetail.countryCode
                                    }.png 2x`}
                                    alt=''
                                  />
                                </InputAdornment>
                              )
                            }
                          }}
                        />
                        <CustomAutoComplete
                          options={states.data ? states.data?.getStates : []}
                          getOptionLabel={option => option?.name}
                          isOptionEqualToValue={(option, value) =>
                            option?.name === value?.name
                          }
                          renderOption={(props, option) => (
                            <Box
                              component='li'
                              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                              {...props}
                              key={props.id}
                            >
                              {option.name}
                            </Box>
                          )}
                          value={
                            states?.data?.getStates?.length
                              ? states?.data?.getStates?.find(
                                  state =>
                                    state.name?.toLowerCase() ===
                                    values.state?.toLowerCase()
                                )
                              : { name: initialValues.state || '' }
                          }
                          noOptionsText={
                            !values.country
                              ? 'Please Select a country'
                              : 'State not found'
                          }
                          onChange={(event, value) =>
                            setFieldValue('state', value?.name)
                          }
                          textfieldProps={{
                            lg: 4,
                            md: 12,
                            name: 'state',
                            customLabel: 'State',
                            containerClassName:
                              'new-addresses__modal__body__form__item__input',
                            error: Boolean(errors.state),
                            helperText: errors.state,
                            value: values.state || initialValues.state
                          }}
                        />
                        <CustomAutoComplete
                          options={
                            citiesResult.data?.length ? citiesResult.data : []
                          }
                          getOptionLabel={option =>
                            option?.city
                              ?.split(' ')
                              ?.map(
                                word =>
                                  `${word
                                    .charAt()
                                    .toUpperCase()}${word
                                    .substring(1, word.length)
                                    ?.toLowerCase()}`
                              )
                              ?.join(' ')
                          }
                          isOptionEqualToValue={(option, value) =>
                            option?.city === value?.city
                          }
                          renderOption={(props, option) => (
                            <Box
                              key={props.id}
                              component='li'
                              sx={{
                                textTransform: 'capitalize'
                              }}
                              {...props}
                            >
                              {`${option?.city?.toLowerCase()} ${
                                option?.postalCode
                                  ? `(${option?.postalCode})`
                                  : ''
                              }`}
                            </Box>
                          )}
                          value={citiesResult.data?.find(
                            city =>
                              city?.city?.toLowerCase() ===
                              values?.city?.toLowerCase()
                          )}
                          noOptionsText={
                            !values.country
                              ? 'Please select a country'
                              : citiesResult.loading
                              ? 'Cities loading...'
                              : 'City not found'
                          }
                          onChange={(event, value) =>
                            setFieldValue('city', value?.city)
                          }
                          textfieldProps={{
                            lg: 4,
                            md: 12,
                            name: 'city',
                            customLabel: 'City',
                            containerClassName:
                              'new-addresses__modal__body__form__item__input',
                            error: Boolean(errors.city),
                            helperText: errors.city,
                            value: values.city,
                            onChange: debounce(
                              event =>
                                getCities({
                                  countryCode: values.countryCode,
                                  cityName: event?.target?.value
                                }),
                              500
                            )
                          }}
                        />
                      </>
                    )}
                    <Input
                      error={Boolean(errors.postalCode)}
                      helperText={errors.postalCode}
                      value={values.postalCode}
                      onChange={handleChange}
                      customLabel='Postal Code'
                      name='postalCode'
                      id='postalCode'
                      type='text'
                      containerClassName='new-addresses__modal__body__form__item__input'
                      placeholder='100001'
                      fullWidth
                      lg={12}
                    />
                  </Grid>
                  <SubmitButton
                    text='Save'
                    loading={false}
                    type='submit'
                    className='new-addresses__modal__body__form__item__button'
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewAddressesModal;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import SubmitButton from '../submit-button';
import { toCurrency } from '../../utilities/to-currency';
import Input from '../new-dashboard/custom-input';
import numberWithCommas from '../../utilities/number-with-commas';
import { useRecoilValue } from 'recoil';
import { walletState } from '../../recoil/atoms';
import { checkValidations, ErrorMessage } from '../../utilities/use-validation';
import { useGetWalletQuery } from '../../operations/queries';
import { transactionTypesEnum } from '../../utilities/enums/transaction-types.enum';
import PaymentMethodPrompt from './payment-method-prompt';
import SelectCurrencyPrompt from './select-currency-prompt';
import { currencies } from '../../constants/currencies';

const schemas = {
  amount: {
    required: true,
    inputType: 'number',
    greaterThan: 0,
    label: 'Amount'
  }
};

const NewFundWalletModal = ({ openModal, setOpenModal, onModalSuccess }) => {
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [amount, setAmount] = useState('');
  const { walletId } = useRecoilValue(walletState);
  const [openPaymentMethodPrompt, setOpenPaymentMethodPrompt] = useState(false);
  const [openCurrencyPrompt, setOpenCurrencyPrompt] = useState(false);

  useGetWalletQuery();

  const handleChange = event => {
    const { value, name } = event.target;
    if (!(value && value.length)) {
      setAmount('');
      handleValidations({ amount: '' }, name);
    } else if (!isNaN(value.split(',').join(''))) {
      const fundingAmount = parseInt(value.split(',').join(''), 10);
      handleValidations({ amount: fundingAmount }, name);
      setAmount(fundingAmount);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const { success } = handleValidations({ amount });

    if (success) {
      setOpenCurrencyPrompt(true);
    }
  };

  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });

    setErrors(errors);
    return { success };
  };

  return (
    <>
      <div className={`new-wallet__fund fade${openModal ? ' show' : ''}`}>
        <PaymentMethodPrompt
          openPrompt={openPaymentMethodPrompt}
          setOpenPrompt={setOpenPaymentMethodPrompt}
          paymentID={walletId}
          transactionType={transactionTypesEnum.deposit}
          amountToFund={amount}
          onPromptSuccess={() => {
            if (onModalSuccess) {
              return onModalSuccess();
            }

            history.push('/wallet/wallet-funding-successful');
          }}
        />
        <SelectCurrencyPrompt
          openPrompt={openCurrencyPrompt}
          setOpenPrompt={setOpenCurrencyPrompt}
          onOpenPaymentPrompt={({ currency }) => {
            setOpenCurrencyPrompt(false);

            if (currency === currencies[0].currency) {
              setOpenPaymentMethodPrompt(true);
            } else {
              history.push(
                `/shipment-request-foreign-exchange-payment?amount=${
                  amount * 100
                }&currency=${currency}`
              );
            }
          }}
        />
        <div className='new-wallet__fund__wrap'>
          <div className='new-wallet__fund__header'>
            <div className='new-wallet__fund__header__wrap'>
              <h1>Fund Wallet</h1>
              <IconButton
                onClick={() => {
                  setOpenModal(false);
                  setErrors({});
                  setAmount('');
                }}
              >
                <Close />
              </IconButton>
            </div>
          </div>
          <div className='new-wallet__fund__body'>
            <div className='new-wallet__fund__body__wrap'>
              <div className='new-wallet__fund__body__label'>
                <span>Funding Details</span>
              </div>
              <div className='new-wallet__fund__body__content'>
                <form
                  onSubmit={handleSubmit}
                  className='new-wallet__fund__body__form'
                >
                  <div className='new-wallet__fund__body__form__item'>
                    <label
                      htmlFor='amount'
                      className='new-wallet__fund__body__form__item__label'
                    >
                      Enter amount to add
                    </label>
                    <Input
                      error={Boolean(errors.amount)}
                      name='amount'
                      id='amount'
                      type='text'
                      className='new-wallet__fund__body__form__item__input'
                      fullWidth
                      placeholder={toCurrency(1000)}
                      onChange={handleChange}
                      value={`${numberWithCommas(amount)}`}
                    />
                    <ErrorMessage
                      message={errors.amount && errors.amount.message}
                    />
                    <SubmitButton
                      text='Next'
                      loading={false}
                      type='submit'
                      className='new-wallet__fund__body__form__item__button'
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewFundWalletModal;

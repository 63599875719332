import shipmentDictionary, {
  itemCategoryDocumentSettings
} from '../../constants/shipment-status-dictionary';
import { toCurrency } from '../../utilities/to-currency';
import blueArrow from '../../assets/svg/blue-arrow.svg';
import clock from '../../assets/svg/clock.svg';
import { useRecoilState } from 'recoil';
import {
  chinaImportState,
  saveShipmentDetailsState,
  shopNShipDetailsState,
  shopNShipResultState
} from '../../recoil/atoms';
import {
  seaFreightImportLinks,
  singleShipmentLinks
} from '../../constants/shipment-links';
import {
  shipmentDestinationTypes,
  shipmentHubs,
  shipmentMethods
} from '../../constants/book-shipment-contants';
import { useEffect, useMemo, useState } from 'react';
import { shopNShipLinks } from '../../constants/shopnship-links';
import omit from 'lodash.omit';
import Moment from 'react-moment';
import { itemCategories } from '../../constants/item-categories';
import { currencies } from '../../constants/currencies';
import SelectCurrencyPrompt from '../modals/select-currency-prompt';
import { getPickupDate } from '../../utilities/convert-date';
import { Alert, Tooltip } from '@mui/material';
import { useUploadShipmentDocumentsMutation } from '../../operations/mutations';
import axios from 'axios';
import { useSendAlert } from '../../utilities/send-alert';
import MultiFileUploadModal from '../modals/multi-file-upload-modal';
import { processingShipmentStatus } from '../../pages/Dashboard/helper-functions';

const statuses = { draft: 'draft', paymentPending: 'paymentpending' };

const isMobile = window.innerWidth <= 1200;

const RecentShipment = ({
  shipment,
  shopnship,
  isShopnship = false,
  isPendingShopnship = false,
  onPayShopnship,
  refetch
}) => {
  const [openShipmentFlow, setOpenShipmentFlow] = useState({
    shipAgain: false,
    payNow: false
  });
  const [, setIsChinaImport] = useRecoilState(chinaImportState);

  const [showTooltip, setShowTooltip] = useState(isMobile);
  const [fileLoading, setFileLoading] = useState({ loading: false, index: 0 });
  const [uploadDocs] = useUploadShipmentDocumentsMutation();
  const sendAlert = useSendAlert();
  const [, setShipmentDetail] = useRecoilState(saveShipmentDetailsState);
  const [, setShopnshipDetails] = useRecoilState(shopNShipDetailsState);
  const [, setShopnshipResult] = useRecoilState(shopNShipResultState);
  const [openCurrencyPrompt, setOpenCurrencyPrompt] = useState(false);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const [documents, setDocuments] = useState([]);

  const removeFile = index => {
    documents[index].content = '';

    setDocuments([...documents]);
  };

  const requiresDocuments = useMemo(() => {
    if (!shipment || !shipment?.items || !shipment?.documents) {
      return false;
    }

    return (
      shipment?.isPaid &&
      (shipment?.documents || [])?.some(doc => !doc?.content)
    );
  }, [shipment]);

  useEffect(() => {
    if (
      shipment &&
      shipment?.documents &&
      shipment?.documents?.length &&
      !documents.length
    ) {
      setDocuments(
        shipment?.documents?.map(document => ({
          ...document,
          description:
            itemCategoryDocumentSettings[document?.name]?.uploadMessage
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment]);

  const setShipmentData = isPayNow => {
    if (isShopnship) {
      if (shopnship?.shippingFrom === 'CHINA') {
        setIsChinaImport(true);
      }
      setShopnshipDetails(
        omit(
          {
            ...shopnship,
            deliveryState: shopnship.deliveryState ?? 'Lagos',
            deliveryCity: shopnship.deliveryCity ?? 'Lagos'
          },
          [
            ...(!isPayNow ? ['id'] : []),
            'finalCharge',
            'createdDate',
            'updatedDate',
            'isInitialChargePaid',
            'isFinalChargePaid',
            'discount',
            'totalCharge',
            'user',
            'status'
          ]
        )
      );
      setShopnshipResult(shopnship);
    } else {
      setShipmentDetail({
        ...omit(shipment, ['documents']),
        ...(shipment?.itemCollectionMode === 'DropOff' &&
        shipmentHubs.find(
          hub => hub.value.addressLine1 === shipment?.senderDetail?.addressLine1
        )
          ? {
              hubSlug: shipmentHubs.find(
                hub =>
                  hub.value.addressLine1 ===
                  shipment?.senderDetail?.addressLine1
              ).slug
            }
          : {}),
        ...(shipment?.itemCollectionMode === shipmentMethods[1].value
          ? { pickupDate: getPickupDate(shipment?.pickupDate) }
          : {}),
        senderDetail: {
          ...shipment.senderDetail,
          isCompleted: true
        },
        receiverDetail: {
          ...shipment.receiverDetail,
          isCompleted: true
        },
        items: shipment.items.map(item => ({
          ...item,
          category:
            itemCategories.find(
              category =>
                category.value === item?.category ||
                category.category === item?.category
            )?.value || '-',
          isCompleted: true
        })),
        destinationType: shipmentDestinationTypes[0].value,
        isCompleted: true
      });
    }
  };

  const uploadFile = (file, index, setIsUploaded) => {
    if (file) {
      setFileLoading({ loading: true, index });

      const url = `${process.env.REACT_APP_REST_API}/upload`;

      const formData = new FormData();

      formData.append('file', file);

      formData.append('fileName', file.name);

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      axios
        .post(url, formData, config)
        .then(response => {
          setFileLoading({ loading: false, index });

          documents[index].content = response.data?.Location;

          setDocuments([...documents]);

          setIsUploaded(true);
        })
        .catch(err => {
          sendAlert(err?.message, 'error');

          setFileLoading({ loading: false, index });
        });
    }
  };

  const submit = () => {
    uploadDocs({
      id: shipment.id,
      documents: documents.map(document => omit(document, ['description']))
    }).then(result => {
      if (result?.data) {
        sendAlert('Successfully uploaded your documents');

        setOpenFileUploadModal(false);

        refetch?.();
      }
    });
  };

  const shipAgain = () => {
    setShipmentData();
    setOpenShipmentFlow({ payNow: false, shipAgain: true });
  };

  const payNow = () => {
    if (isPendingShopnship) {
      onPayShopnship && onPayShopnship(shipment?.id);
    } else {
      setShipmentData(true);
      if (isShopnship) {
        window.location.replace(
          `/shop-and-ship-other-information/?shopnshipID=${shipment?.id}`
        );
      } else {
        setOpenShipmentFlow({ payNow: true, shipAgain: false });
      }
    }
  };

  const shipOrUpload = () => {
    if (requiresDocuments) {
      return setOpenFileUploadModal(true);
    }

    return shipAgain();
  };

  const canPayZeroShipments = shipment => {
    if (!shipment) {
      return false;
    }

    if (shipment.shipmentStatus?.toLowerCase() === statuses.paymentPending) {
      return true;
    }

    if (shipment.shipmentStatus?.toLowerCase() === statuses.draft) {
      return false;
    }
  };

  const canPayNow = useMemo(() => {
    if (
      ['Standard', 'SeaImport'].includes(shipment?.pricingTier) &&
      !isShopnship
    ) {
      return canPayZeroShipments(shipment);
    }

    return (
      (shipment.shipmentStatus?.toLowerCase() === statuses.draft ||
        isPendingShopnship) &&
      Boolean(
        shipment.trackingId &&
          (isShopnship || isPendingShopnship ? shipment?.initialCharge : true)
      )
    );
  }, [shipment, isPendingShopnship, isShopnship]);

  const canShipAgain = useMemo(() => {
    if (!shipment) {
      return false;
    }

    return shipment?.pricingTier !== 'Standard' || shipment?.totalCharge > 0;
  }, [shipment]);

  useEffect(() => {
    if (openShipmentFlow.shipAgain) {
      if (isShopnship) {
        window.location.replace(shopNShipLinks[0].link);
      } else if (shipment?.pricingTier === 'SeaImport') {
        window.location.replace(seaFreightImportLinks[0].link);
      } else {
        window.location.replace(singleShipmentLinks[0].link);
      }
    } else if (openShipmentFlow.payNow) {
      setOpenCurrencyPrompt(true);
    }
    // eslint-disable-next-line
  }, [openShipmentFlow]);

  return (
    <div className='recent-shipment-item'>
      <div className='show-on-tablet-and-above sender-receiver'>
        <div className='recent-shipment-item-data sender-receiver-mobile recent-shipment-bottom-border recent-shipment-tracking-id column-above-mobile'>
          <span id='show-on-tablet-and-above' className='shipment-item-label'>
            SENDER
          </span>
          <span className='recent-shipment-tracking-id'>
            {shipment?.senderDetail?.name}
          </span>
        </div>
        <img
          src={blueArrow}
          className='sender-receiver-mobile'
          alt='blue arrow'
        />
        <div className='recent-shipment-item-data amount sender-receiver-mobile column-above-mobile'>
          <span id='show-on-tablet-and-above' className='shipment-item-label'>
            RECEIVER
          </span>
          <span>{shipment?.receiverDetail?.name}</span>
        </div>
      </div>
      <div
        style={{ borderTop: '1px solid #e2e4e8' }}
        className='show-on-tablet-and-above content-mobile'
      >
        <div className='recent-shipment-item-data recent-shipment-bottom-border recent-shipment-tracking-id column-above-mobile'>
          <span id='show-on-tablet-and-above' className='shipment-item-label'>
            TRACKING ID
          </span>
          <span className='recent-shipment-tracking-id'>
            {shipment.trackingId}
          </span>
          <span
            id='show-only-on-mobile'
            className={`recent-shipment-item-status ${shipment.shipmentStatus.toLocaleLowerCase()}`}
          >
            {shipmentDictionary(shipment.shipmentStatus)}
          </span>
        </div>
        <div className='recent-shipment-item-data amount column-above-mobile'>
          <span className='shipment-item-label'>
            {isPendingShopnship ? 'Pending Amount' : 'Amount'}
          </span>
          <span>{toCurrency(shipment.totalCharge / 100)}</span>
        </div>
        <div className='recent-shipment-item-data pickup column-above-mobile'>
          <span className='shipment-item-label'>Pickup At</span>
          <span className='location'>
            <img
              loading='lazy'
              width='30'
              height='20'
              src={`https://flagcdn.com/w20/${shipment?.senderDetail?.countryCode?.toLocaleLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${shipment?.senderDetail?.countryCode?.toLocaleLowerCase()}.png 2x`}
              alt=''
            />
            {`${
              shipment.senderDetail.state
                ? `${shipment.senderDetail.state}, `
                : ''
            }${shipment.senderDetail.countryCode}`}
          </span>
        </div>
        <div className='recent-shipment-item-data delivery recent-shipment-bottom-border column-above-mobile'>
          <span className='shipment-item-label'>Delivery To</span>
          <span className='location'>
            <img
              loading='lazy'
              width='30'
              height='20'
              src={`https://flagcdn.com/w20/${shipment?.receiverDetail?.countryCode?.toLocaleLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${shipment?.receiverDetail?.countryCode?.toLocaleLowerCase()}.png 2x`}
              alt=''
            />
            {`${shipment.receiverDetail.state}, ${shipment.receiverDetail.countryCode}`}
          </span>
        </div>
        <div
          id='show-on-tablet-and-above'
          className='recent-shipment-item-data recent-shipment-bottom-border recent-shipment-tracking-id column-above-mobile'
        >
          <span
            className={`recent-shipment-item-status ${shipment.shipmentStatus.toLocaleLowerCase()}`}
          >
            {shipmentDictionary(shipment.shipmentStatus)}
          </span>
        </div>
      </div>
      <div className='recent-shipment-item-data time'>
        <div className='d-flex w-100'>
          {processingShipmentStatus.includes(shipment.shipmentStatus) ? (
            <div style={{ gap: '12px' }} className='d-flex w-100 flex-column'>
              <span
                id='show-on-tablet-and-above'
                className='shipment-item-label'
              >
                Processing time
              </span>
              <span className='w-100'>
                <img src={clock} alt='clock' />
                <b className='w-100'>
                  {shipment.pricingTier === 'Express' ? '24' : '48'} hours
                </b>
              </span>
            </div>
          ) : (
            <div style={{ gap: '12px' }} className='d-flex flex-column'>
              <span
                id='show-on-tablet-and-above'
                className='shipment-item-label'
              >
                ESTIMATED DELIVERY DATE
              </span>
              <span>
                <img src={clock} alt='clock' />
                <Moment format='DD/MM/YYYY '>
                  {new Date(shipment?.estimatedDeliveryDate)}
                </Moment>
              </span>
            </div>
          )}
          {requiresDocuments && !isMobile && (
            <Alert
              severity={'info'}
              sx={{
                alignSelf: 'flex-end',
                fontSize: '14px',
                fontFamily: 'General Sans',
                backgroundColor: '#fff',
                color: '#EFA516',
                border: 'none',
                '& .MuiAlert-icon': {
                  color: '#EFA516'
                }
              }}
            >
              Required document yet to be uploaded
            </Alert>
          )}
        </div>

        <div className='recent-shipment-item-actions'>
          {!isPendingShopnship &&
            (isMobile && requiresDocuments ? (
              <Tooltip
                open={showTooltip}
                onClose={() => setShowTooltip(false)}
                title={
                  <Alert
                    severity={'info'}
                    sx={{
                      alignSelf: 'flex-end',
                      fontSize: '14px',
                      fontFamily: 'General Sans',
                      backgroundColor: 'inherit',
                      color: '#EFA516',
                      border: 'none',
                      '& .MuiAlert-icon': {
                        color: '#EFA516'
                      }
                    }}
                    show
                  >
                    Required document yet to be uploaded
                  </Alert>
                }
                arrow
                placement='top'
              >
                <span className='ship-again' onClick={shipOrUpload}>
                  {requiresDocuments ? 'Upload Documents' : 'Ship Again'}
                </span>
              </Tooltip>
            ) : (
              canShipAgain && (
                <span className='ship-again' onClick={shipOrUpload}>
                  {requiresDocuments ? 'Upload Documents' : 'Ship Again'}
                </span>
              )
            ))}
          {canPayNow && (
            <span className='pay-now' onClick={payNow}>
              Pay Now
            </span>
          )}
        </div>
      </div>
      <SelectCurrencyPrompt
        openPrompt={openCurrencyPrompt}
        setOpenPrompt={setOpenCurrencyPrompt}
        onOpenPaymentPrompt={({ currency }) => {
          setOpenCurrencyPrompt(false);
          if (currency === currencies[0].currency) {
            if (!isShopnship) {
              window.location.replace(
                `${singleShipmentLinks[5].link}?shipmentID=${shipment.id}`
              );
            }
          } else {
            if (!isShopnship) {
              window.location.replace(
                `/shipment-request-foreign-exchange-payment?shipmentID=${shipment?.id}&currency=${currency}`
              );
            }
          }
        }}
      />
      <MultiFileUploadModal
        openPrompt={openFileUploadModal}
        onCancel={() => setOpenFileUploadModal(false)}
        files={documents}
        title='Upload Required documents'
        onUploadFile={uploadFile}
        loading={fileLoading}
        submit={submit}
        removeFile={removeFile}
      />
    </div>
  );
};

export default RecentShipment;

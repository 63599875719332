import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  chinaImportState,
  filteredShopNShipLinksState,
  saveShopNShipRateState,
  selectedRateState,
  shopNShipDetailsState,
  shopnshipRateState
} from '../../../../../recoil/atoms';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Alert, Radio, Skeleton } from '@mui/material';
import NewShopNShipStepsLayout from '../../../../../components/Layouts/shop-n-ship-steps-layout';
import SubmitButton from '../../../../../components/submit-button';
import AddressGuidePrompt from '../../../../../components/modals/address-guide-prompt';
import {
  useGetShopnshipCurrencyRates,
  useGetShopNShipRateQuery
} from '../../../../../operations/queries';
import { toCurrency } from '../../../../../utilities/to-currency';
import { lightColors } from '../../../../../constants/shipping-rates';
import insurancePlans, {
  extendedInsuranceLimits
} from '../../../../../constants/insurance-plans';
import { useSendAlert } from '../../../../../utilities/send-alert';
import BusyOverlay from '../../../../../components/busy-overlay';
import testJSON from '../../../../../utilities/test-json';
import EmptyList from '../../../../../components/empty-list';
import emptyStateIcon from '../../../../../assets/svg/empty.svg';
import { WhiteCheck } from '../../../../../constants/asset-contants';
import { TermsAndConditionsPrompt } from '../../../../../constants/terms-&-condition';

const ShopnshipDeliveryOption = () => {
  const [shopNshipDetails, setSaveShopNshipDetails] = useRecoilState(
    shopNShipDetailsState
  );
  const history = useHistory();
  const sendAlert = useSendAlert();
  const [isChinaImport] = useRecoilState(chinaImportState);

  const [showAddressGuide, setShowAddressGuide] = useState(false);
  const handleRateSelection = rate => {
    setSelectedRate(rate);
    setFieldValue('pricingTier', rate?.pricingTier);
  };

  const [filteredLinks] = useRecoilState(filteredShopNShipLinksState);
  const [isChecked, setIsChecked] = useState(false);

  const [shopnshipRate] = useRecoilState(shopnshipRateState);
  const finalAmount = shopnshipRate?.finalAmount;
  const currentIndex = filteredLinks.findIndex(
    link => link.link === history.location.pathname
  );
  const [selectedRate, setSelectedRate] = useRecoilState(selectedRateState);
  const validationSchema = yup.object().shape({
    pricingTier: yup.string().required('Please select an option'),
    insuranceType: yup.string().required('Please select an insurance option')
  });
  const [getShopNshipRates, { loading }] = useGetShopNShipRateQuery(() => {});
  const [
    getShopnshipRates,
    getShopnshipRatesResult
  ] = useGetShopnshipCurrencyRates();
  const loadingState = getShopnshipRatesResult.isLoading;
  const isArray = testJSON(shopNshipDetails?.itemDescription);
  const [openInAppPrompt, setOpenInAppPrompt] = useState(false);
  const [shopNShipRates] = useRecoilState(saveShopNShipRateState);
  const totalItemValue =
    (isArray
      ? JSON.parse(shopNshipDetails?.itemDescription)?.reduce(
          (sum, item) => sum + item?.amountDue,
          0
        )
      : shopNshipDetails?.amountDue) || 0;
  const { values, handleSubmit, setFieldValue } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      pricingTier: '',
      insuranceType: '',
      insuranceCharge: 0
    },

    onSubmit: values => {
      if (!values.insuranceType || !values.pricingTier) {
        sendAlert('Please select your Insurance Type', 'error');
        sendAlert('Please select your Shipping Option', 'error');
        window.scroll(0, 0);
      } else {
        const items = JSON.parse(shopNshipDetails?.itemDescription);

        const trackingNumber = isArray
          ? items?.map(item => item?.trackingNumber)?.join()
          : shopNshipDetails?.trackingNumber;

        const proofOfId = isArray
          ? items.map(item => item?.proofOfId)?.join()
          : shopNshipDetails?.proofOfId;

        const evidenceOfPurchase = isArray
          ? items.map(item => item?.evidenceOfPurchase)?.join()
          : shopNshipDetails?.evidenceOfPurchase;

        const initialCharge = finalAmount * 100;
        setSaveShopNshipDetails({
          ...shopNshipDetails,
          ...values,
          initialCharge,
          proofOfId,
          trackingNumber,
          evidenceOfPurchase
        });
        if (shopNshipDetails.id) {
          history.push(filteredLinks[currentIndex + 1].link);
        } else {
          setOpenInAppPrompt(true);
        }
      }
    }
  });

  const handleInsuranceSelection = plan => {
    const insuranceCharge =
      plan.value === insurancePlans[2].value
        ? totalItemValue * 0.05
        : plan.amount;

    setFieldValue('insuranceType', plan.value);
    setFieldValue('insuranceCharge', insuranceCharge);
  };

  useEffect(() => {
    setSelectedRate({});
    const items = JSON.parse(shopNshipDetails?.itemDescription);
    const requestRatesData = items.map(item => {
      return { weight: item.estimatedWeight, category: item.category };
    });

    getShopNshipRates({ isChinaImport: true, items: requestRatesData });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFieldValue('insuranceCharge', shopNshipDetails.insuranceCharge);
    setFieldValue('insuranceType', shopNshipDetails.insuranceType);
    setFieldValue('pricingTier', shopNshipDetails.pricingTier);

    if (shopNShipRates.length > 0) {
      shopNShipRates?.map(item => {
        setSelectedRate(item);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopNshipDetails, shopNShipRates]);

  useEffect(() => {
    if (selectedRate?.cost) {
      const params = {
        shippingFrom: shopNshipDetails?.shippingFrom?.split(' ')[0],
        chinaCost: selectedRate?.cost
      };

      getShopnshipRates(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRate?.cost, shopNshipDetails.shippingFrom]);
  const checkValidity =
    !values.insuranceType || !values.pricingTier || !isChecked;

  return (
    <>
      <NewShopNShipStepsLayout mobileTitle='Package Information'>
        <div className='new-shop-n-ship__modal__body'>
          <div className='new-shop-n-ship__modal__body__wrap'>
            <div className='new-shop-n-ship__modal__body__content'>
              <form onSubmit={handleSubmit} className='delivery-options'>
                <div>
                  <label className='delivery-options__label'>
                    Choose a Shipping Option
                  </label>
                  <div className='delivery-options__items mt-3'>
                    {loading
                      ? [...Array(2).keys()].map(key => (
                          <div key={key} className={`delivery-options__item`}>
                            <div className='delivery-options__item__wrap discount'>
                              <div className='delivery-options__item__content w-100'>
                                <div className='delivery-options__item__name w-100'>
                                  <Skeleton className='w-100' />
                                </div>
                                <div className='delivery-options__item__address w-100'>
                                  <Skeleton className='w-100' />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : shopNShipRates?.map((rate, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div
                                onClick={() => {
                                  !shopNshipDetails.id &&
                                    handleRateSelection(rate);
                                }}
                                style={{ background: lightColors[index % 5] }}
                                className={`delivery-options__item${
                                  values.pricingTier?.toLowerCase() ===
                                  rate.pricingTier?.toLowerCase()
                                    ? ' active'
                                    : ''
                                }${!!shopNshipDetails.id ? ' disabled' : ''}`}
                              >
                                <div
                                  className={`delivery-options__item__radio`}
                                >
                                  <Radio
                                    checked={
                                      values.pricingTier?.toLowerCase() ===
                                      rate.pricingTier?.toLowerCase()
                                    }
                                    sx={{ transform: 'scale(1.5)' }}
                                  />
                                </div>
                                <div className='delivery-options__item__wrap'>
                                  <div className='delivery-options__item__content'>
                                    <div className='delivery-options__item__name'>
                                      <div className=' delivery-options__item__name-wrap'>
                                        <h3
                                          style={{
                                            fontWeight: '700'
                                          }}
                                          className=''
                                        >
                                          {rate.pricingTier}
                                        </h3>
                                      </div>
                                    </div>
                                    <div className='delivery-options__item__address'>
                                      <span>{rate?.duration}</span>
                                    </div>
                                  </div>
                                  <div className='delivery-options__item__price'>
                                    <span>
                                      {toCurrency(rate?.cost, rate?.currency)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    {!shopNShipRates.length && !loading ? (
                      <EmptyList
                        emptyIcon={emptyStateIcon}
                        heading='No Available Shipment Rates'
                      />
                    ) : null}
                  </div>
                </div>
                <div className='delivery-options'>
                  <label className='delivery-options__label'>
                    Choose an Insurance Option
                  </label>
                  <div className='delivery-options__items'>
                    {insurancePlans
                      .filter(
                        (_, index) =>
                          totalItemValue >= extendedInsuranceLimits.min ||
                          index < insurancePlans.length - 1
                      )
                      .map((plan, index) => (
                        <div
                          onClick={() =>
                            !shopNshipDetails.id &&
                            handleInsuranceSelection(plan)
                          }
                          style={{ background: lightColors[index % 5] }}
                          key={plan.value}
                          className={`delivery-options__item${
                            values.insuranceType === plan.value ? ' active' : ''
                          }${!!shopNshipDetails.id ? ' disabled' : ''}`}
                        >
                          <div className={`delivery-options__item__radio`}>
                            <Radio
                              checked={values.insuranceType === plan.value}
                              sx={{ transform: 'scale(1.5)' }}
                            />
                          </div>
                          <div className='delivery-options__item__wrap'>
                            <div className='delivery-options__item__content'>
                              <div className='delivery-options__item__name'>
                                <h3>{plan.name}</h3>
                              </div>
                              <div className='delivery-options__item__address'>
                                <span>{plan.summary}</span>
                              </div>
                            </div>
                            <div className='delivery-options__item__price'>
                              <span>
                                {plan?.price
                                  ? toCurrency(plan?.price(totalItemValue))
                                  : plan.amount
                                  ? toCurrency(plan.amount)
                                  : plan.cost}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {isChinaImport ? (
                  <div className='mb-2'>
                    <Alert
                      severity={'info'}
                      sx={{
                        marginTop: '10px',
                        fontSize: '1.5rem',
                        fontWeight: 'medium',
                        fontFamily: 'General Sans',
                        backgroundColor: '#E1F9FF',
                        color: '#416EB2',
                        border: 'none',
                        '& .MuiAlert-icon': {
                          fontSize: '1.6rem',
                          marginRight: '5px',
                          marginTop: '2px',
                          color: '#416EB2'
                        }
                      }}
                    >
                      Please note that pick-up isn't available for this
                      shipment. You will be contacted upon arrival to process
                      the payment for delivery.
                    </Alert>
                  </div>
                ) : null}
                <div className='mt-5'>
                  <div
                    className={`verification__content
                     
                    `}
                  >
                    <div
                      style={{
                        textAlign: 'left'
                      }}
                      className='verification__header text-left'
                    >
                      <p
                        style={{
                          fontSize: '14px',
                          lineHeight: '24px'
                        }}
                      >
                        I certify that I’m using this for genuine purposes;
                        else, Topship may involve law enforcement agencies if
                        faulted. And I do understand that knowingly making any
                        false or fraudulent representations to any online
                        businesses may violate federal or state criminal
                        statutes, and may result in a fine, imprisonment, or
                        both.
                      </p>
                    </div>

                    <div
                      className='mt-2'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                      }}
                      onClick={() => setIsChecked(!isChecked)}
                    >
                      <div
                        style={{
                          width: '24px',
                          height: '24px',
                          borderRadius: '6px',
                          backgroundColor: isChecked
                            ? '#6B50F6'
                            : 'transparent',
                          border: '2px solid #ddd',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: '8px'
                        }}
                      >
                        {isChecked && (
                          <img width={20} src={WhiteCheck} alt='white check' />
                        )}
                      </div>
                      <label
                        className='mb-0'
                        style={{
                          color: 'black',
                          fontSize: '14px',
                          marginLeft: '10px'
                        }}
                      >
                        I Agree to the above terms
                      </label>
                    </div>
                  </div>
                </div>
                <div className='delivery-options__footer'>
                  <div
                    onClick={() =>
                      !shopNshipDetails?.id &&
                      history.push(filteredLinks[currentIndex - 1].link)
                    }
                    className='delivery-options__footer__action'
                  >
                    Go Back
                  </div>
                  <SubmitButton text='Continue' disabled={checkValidity} />
                </div>
              </form>
            </div>
          </div>
        </div>
        <BusyOverlay loading={loadingState} speed='1.7s' />
      </NewShopNShipStepsLayout>
      <AddressGuidePrompt
        show={showAddressGuide}
        setShow={setShowAddressGuide}
      />
      <TermsAndConditionsPrompt
        isChinaImport={isChinaImport}
        openPrompt={openInAppPrompt}
        setOpenPrompt={setOpenInAppPrompt}
        openOrderSummary={() =>
          history.push(filteredLinks[currentIndex + 1].link)
        }
      />
    </>
  );
};

export default ShopnshipDeliveryOption;

import { useSendAlert } from './send-alert';

export const useHandleCopyLink = () => {
  const sendAlert = useSendAlert();

  return (link = '') => {
    if (link) {
      if (window.navigator) {
        window.navigator.clipboard.writeText(link);
        sendAlert('Copied to Clipboard!', 'success');
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = link;
        textArea.style = {
          top: '0',
          left: '0',
          position: 'fixed'
        };
        window.document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        const success = window.document.execCommand('copy');
        if (success) {
          sendAlert('Copied to Clipboard!', 'success');
        } else {
          sendAlert("Couldn't copy to Clipboard.", 'error');
        }
      }
    }
  };
};

import React from 'react';
import {
  AddressGuideMobile,
  AddressGuideTablet,
  AddressGuideWeb
} from '../../constants/asset-contants';

const AddressGuidePrompt = ({ show, setShow }) => {
  const handleCancel = () => {
    setShow(false);
  };

  return (
    <div className={`delete-overlay ${show && 'delete-overlay--show'}`}>
      <div
        style={{
          padding: 0
        }}
        className={`delete-overlay__content ${
          show && 'delete-overlay__content--show'
        }`}
      >
        <img
          style={{
            width: '700px'
          }}
          className='web-address-guide'
          src={AddressGuideWeb}
          alt='address-guide'
        />
        <img
          className='tab-address-guide'
          style={{
            width: '500px'
          }}
          src={AddressGuideTablet}
          alt='address-guide'
        />
        <img
          className='mobile-address-guide'
          src={AddressGuideMobile}
          alt='address-guide'
        />

        <div className='delete-overlay__buttons'>
          <button onClick={handleCancel}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default AddressGuidePrompt;

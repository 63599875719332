import { useEffect, useMemo, useRef, useState } from 'react';
import { DropdownIcon } from '../../../../../constants/asset-contants';
import DatePicker from '../../../../../components/modals/date-picker';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'yup-phone-lite';
import debounce from 'lodash.debounce';
import { Box, Grid, InputAdornment } from '@mui/material';
import CustomAutoComplete from '../../../../../components/new-dashboard/custom-autocomplete';
import Input from '../../../../../components/new-dashboard/custom-input';
import {
  defaultDetail,
  shipmentMethods,
  shopnshipHubs
} from '../../../../../constants/book-shipment-contants';
import { useGetStatesQuery } from '../../../../../operations/queries';
import { useGetCitiesMutation } from '../../../../../operations/mutations';
import { saveShipmentDetailsState } from '../../../../../recoil/atoms';
import { useRecoilState } from 'recoil';
import { useHistory, useLocation } from 'react-router-dom';
import { seaFreightImportLinks } from '../../../../../constants/shipment-links';
import SeaFreightQuoteLayout from '../../../../../components/Layouts/sea-freight-layout';
import { countries } from '../../../../../constants/countries';
import omit from 'lodash.omit';

const initialAddress = {
  itemCollectionMode: shipmentMethods[0].value,
  pickupDate: Date.now(),
  addressLine1: '',
  country: '',
  state: '',
  city: '',
  countryCode: '',
  postalCode: ''
};

const importCountries = [countries[1], countries[2]];

const validationSchema = yup.object().shape({
  itemCollectionMode: yup.string().required('Please select pickup method'),
  pickupDate: yup.string().when('itemCollectionMode', {
    is: shipmentMethods[1].value,
    then: yup.string().required('Please select pickup date')
  }),
  addressLine1: yup.string().when('itemCollectionMode', {
    is: shipmentMethods[1].value,
    then: yup
      .string()
      .test(
        'no-plus-character',
        'Please enter a valid address',
        value => !value?.includes('+')
      )
      .required('Please enter an address'),
    otherwise: yup
      .string()
      .test(
        'no-plus-character',
        'Please enter a valid address',
        value => !value?.includes('+')
      )
  }),
  country: yup.string().when('itemCollectionMode', {
    is: shipmentMethods[1].value,
    then: yup.string().required('Please select a country'),
    otherwise: yup.string()
  }),
  state: yup.string().when('itemCollectionMode', {
    is: shipmentMethods[1].value,
    then: yup.string().required('Please select a state'),
    otherwise: yup.string()
  }),
  city: yup.string().when('itemCollectionMode', {
    is: shipmentMethods[1].value,
    then: yup.string().required('Please select a city'),
    otherwise: yup.string()
  }),
  countryCode: yup.string().when('itemCollectionMode', {
    is: shipmentMethods[1].value,
    then: yup.string().required('Please select countryCode'),
    otherwise: yup.string()
  }),
  postalCode: yup.string().when('itemCollectionMode', {
    is: shipmentMethods[1].value,
    then: yup.string().required('Please enter postalCode'),
    otherwise: yup.string()
  })
});

const SeaFreightSenderInfo = ({ nextStep }) => {
  const [openPickupToggle, setOpenPickupToggle] = useState(false);

  const [openDatePicker, setOpenDatePicker] = useState(false);

  const { pathname } = useLocation();

  const history = useHistory();

  const [shipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );

  const initialValues = useMemo(() => {
    if (!shipmentDetail?.senderDetail) {
      return initialAddress;
    }

    return {
      city: shipmentDetail?.senderDetail?.city || '',
      state: shipmentDetail?.senderDetail?.state || '',
      country: shipmentDetail?.senderDetail?.country || '',
      postalCode: shipmentDetail?.senderDetail?.postalCode || '',
      countryCode: shipmentDetail?.senderDetail?.countryCode || '',
      pickupDate: shipmentDetail?.pickupDate || initialAddress.pickupDate,
      itemCollectionMode:
        shipmentDetail?.itemCollectionMode || initialAddress.itemCollectionMode,
      addressLine1: `${shipmentDetail?.senderDetail?.addressLine1 || ''}${
        shipmentDetail?.senderDetail?.addressLine2 || ''
      }${shipmentDetail?.senderDetail?.addressLine3 || ''}`
    };
  }, [shipmentDetail?.senderDetail]);

  const currentIndex = useMemo(() => {
    if (!pathname) {
      return -1;
    }

    return seaFreightImportLinks.findIndex(link => link.link === pathname);
  }, [pathname]);

  const addressLine1Ref = useRef();

  const [getStates, states] = useGetStatesQuery();

  const [getCities, citiesResult] = useGetCitiesMutation();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: senderDetail => {
      if (!values.countryCode) {
        return;
      }

      senderDetail.isCompleted = true;

      const newShipmentDetail = { ...shipmentDetail };

      newShipmentDetail.itemCollectionMode = senderDetail.itemCollectionMode;

      if (senderDetail.itemCollectionMode === shipmentMethods[1].value) {
        newShipmentDetail.pickupDate = new Date(
          senderDetail.pickupDate
        ).toISOString();

        const addressLine1 = senderDetail.addressLine1.substring(0, 40);

        const addressLine2 = senderDetail.addressLine1.substring(40, 80);

        const addressLine3 = senderDetail.addressLine1.substring(80, 120);

        senderDetail.addressLine1 = addressLine1;

        senderDetail.addressLine2 = addressLine2 || '';

        senderDetail.addressLine3 = addressLine3 || '';

        newShipmentDetail.senderDetail = omit(senderDetail, [
          'itemCollectionMode',
          'pickupDate'
        ]);
      } else {
        newShipmentDetail.senderDetail = {
          ...shopnshipHubs[values?.countryCode?.toLowerCase()]?.value,
          isCompleted: true
        };
      }

      setSaveShipmentDetail(newShipmentDetail);

      history.push(seaFreightImportLinks[currentIndex + 1].link);
    }
  });

  useEffect(() => {
    if (values.countryCode) {
      getStates(values.countryCode);

      getCities({
        countryCode: values.countryCode,
        cityName: values.city || 'a'
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <SeaFreightQuoteLayout>
      <form onSubmit={handleSubmit}>
        <div>
          <label
            style={{
              fontSize: '12px',
              fontWeight: 500
            }}
            htmlFor=''
          >
            Need pick-up?
          </label>
          <div
            style={{
              position: 'relative'
            }}
          >
            <div
              className='mb-1'
              onClick={() => setOpenPickupToggle(!openPickupToggle)}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                border: '1px solid #E2E4E8',
                // height: '48px',
                padding: '16.5px 14px',
                borderRadius: '8px',
                cursor: 'pointer',
                marginTop: '6px'
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: '18px'
                }}
              >
                {values.itemCollectionMode === shipmentMethods[0].value
                  ? 'No'
                  : 'Yes'}
              </span>
              <img src={DropdownIcon} alt='' />
            </div>
            {openPickupToggle && (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '70px',
                  left: 0,
                  background: '#fff',
                  border: '1px solid #E2E4E8',
                  padding: '16.5px 14px',
                  borderRadius: '8px',
                  zIndex: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    fontWeight: 500,
                    fontSize: '18px'
                  }}
                >
                  {shipmentMethods.map((method, index) => (
                    <span
                      key={index}
                      onClick={() => {
                        setFieldValue('itemCollectionMode', method.value);
                        // setPickUpOption(method.value);
                        setOpenPickupToggle(false);
                      }}
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                      {index === 1 ? 'Yes' : 'No'}
                    </span>
                  ))}
                </div>
              </div>
            )}
            <span
              style={{
                fontSize: '12px'
              }}
            >
              Would you like us to pickup your package?
            </span>
          </div>
          {values.itemCollectionMode === shipmentMethods[1].value && (
            <div className=''>
              <div>
                <div className='select-pickup-date'>
                  <label
                    style={{
                      fontSize: '12px',
                      fontWeight: 500
                    }}
                  >
                    Select Pick-up Date
                  </label>
                  <DatePicker
                    openDatePicker={openDatePicker}
                    setOpenDatePicker={setOpenDatePicker}
                    onChange={date => setFieldValue('pickupDate', date)}
                    value={values.pickupDate}
                  />
                </div>

                <div
                  style={{
                    borderTop: '1px solid #EEF1F8',
                    marginTop: '40px',
                    paddingTop: '40px'
                  }}
                >
                  <h3 className='mb-2' style={{ fontWeight: 600 }}>
                    Pickup Addresses
                  </h3>
                  <Grid container columnSpacing='10px' rowSpacing='16px'>
                    <Input
                      error={Boolean(errors.addressLine1)}
                      helperText={errors.addressLine1}
                      value={values.addressLine1}
                      onChange={handleChange}
                      customLabel='Address'
                      name='addressLine1'
                      id='addressLine1'
                      type='text'
                      containerClassName='new-addresses__modal__body__form__item__input'
                      placeholder='1, Idumota lane, Iweka Road'
                      fullWidth
                      inputProps={{
                        ref: addressLine1Ref
                      }}
                    />
                    <CustomAutoComplete
                      options={importCountries}
                      getOptionLabel={option => option?.name}
                      isOptionEqualToValue={(option, value) =>
                        option?.name === value?.name
                      }
                      renderOption={(props, option, country) => (
                        <Box
                          component='li'
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={props.id}
                        >
                          {country && (
                            <img
                              loading='lazy'
                              width='30'
                              height='20'
                              src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
                              alt=''
                            />
                          )}
                          {option.name}
                        </Box>
                      )}
                      value={
                        importCountries.find(
                          country => country.name === values.country
                        ) || { name: '' }
                      }
                      noOptionsText={'Country not found'}
                      onChange={(event, value) => {
                        setValues({
                          ...values,
                          country: value?.name,
                          countryCode: value?.code
                        });

                        getStates(value?.code);

                        getCities({
                          cityName: 'a',
                          countryCode: value?.code
                        });
                      }}
                      textfieldProps={{
                        lg: 12,
                        md: 12,
                        name: 'country',
                        customLabel: 'Country',
                        containerClassName:
                          'new-addresses__modal__body__form__item__input',
                        error: Boolean(errors.country),
                        helperText: errors.country,
                        value: values.country,
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position='start'>
                              <img
                                loading='lazy'
                                width='20'
                                height='14'
                                src={`https://flagcdn.com/w20/${(
                                  values.countryCode || importCountries[0].code
                                ).toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${(
                                  values.countryCode || importCountries[0].code
                                ).toLowerCase()}.png 2x`}
                                alt=''
                              />
                            </InputAdornment>
                          )
                        }
                      }}
                    />
                    <CustomAutoComplete
                      options={states.data?.getStates || []}
                      getOptionLabel={option => option?.name}
                      isOptionEqualToValue={(option, value) =>
                        option?.name === value?.name
                      }
                      renderOption={(props, option) => (
                        <Box
                          component='li'
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={props.id}
                        >
                          {option.name}
                        </Box>
                      )}
                      value={
                        states?.data?.getStates?.find(
                          state =>
                            state.name?.toLowerCase() ===
                            values.state?.toLowerCase()
                        ) || { name: '' }
                      }
                      noOptionsText={
                        !values.country
                          ? 'Please Select a country'
                          : 'State not found'
                      }
                      onChange={(event, value) =>
                        setFieldValue('state', value?.name)
                      }
                      textfieldProps={{
                        lg: 12,
                        md: 12,
                        name: 'state',
                        customLabel: 'State',
                        containerClassName:
                          'new-addresses__modal__body__form__item__input',
                        error: Boolean(errors.state),
                        helperText: errors.state,
                        value: values.state
                      }}
                    />
                    <CustomAutoComplete
                      options={citiesResult.data || []}
                      getOptionLabel={option =>
                        option?.city
                          ?.split(' ')
                          ?.map(
                            word =>
                              `${word.charAt().toUpperCase()}${word
                                .substring(1, word.length)
                                ?.toLowerCase()}`
                          )
                          ?.join(' ')
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?.city?.toLowerCase() ===
                        value?.city?.toLowerCase()
                      }
                      renderOption={(props, option) => (
                        <Box
                          key={props.id}
                          component='li'
                          sx={{
                            textTransform: 'capitalize'
                          }}
                          {...props}
                        >
                          {`${option?.city?.toLowerCase()} ${
                            option?.postalCode ? `(${option?.postalCode})` : ''
                          }`}
                        </Box>
                      )}
                      value={citiesResult?.data?.find(
                        city =>
                          city?.city?.toLowerCase() ===
                          values?.city?.toLowerCase()
                      )}
                      noOptionsText={
                        !values.country
                          ? 'Please select a country'
                          : citiesResult.loading
                          ? 'Cities loading...'
                          : 'City not found'
                      }
                      onChange={(event, value) =>
                        setFieldValue('city', value?.city)
                      }
                      textfieldProps={{
                        lg: 12,
                        md: 12,
                        name: 'city',
                        customLabel: 'City',
                        containerClassName:
                          'new-addresses__modal__body__form__item__input',
                        error: Boolean(errors.city),
                        helperText: errors.city,
                        value: values.city,
                        onChange: debounce(event => {
                          getCities({
                            countryCode: values.countryCode,
                            cityName: event?.target?.value
                          });
                        }, 500)
                      }}
                    />
                    <Input
                      error={Boolean(errors.postalCode)}
                      helperText={errors.postalCode}
                      value={values.postalCode}
                      onChange={handleChange}
                      customLabel='Postal Code'
                      name='postalCode'
                      id='postalCode'
                      type='text'
                      containerClassName='new-addresses__modal__body__form__item__input'
                      placeholder='100001'
                      fullWidth
                      lg={12}
                      md={12}
                    />
                  </Grid>
                </div>
              </div>
            </div>
          )}

          {values.itemCollectionMode === shipmentMethods[0].value && (
            <CustomAutoComplete
              options={importCountries}
              getOptionLabel={option => option?.name}
              isOptionEqualToValue={(option, value) =>
                option?.name === value?.name
              }
              renderOption={(props, option, country) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                  key={props.id}
                >
                  {country && (
                    <img
                      loading='lazy'
                      width='30'
                      height='20'
                      src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
                      alt=''
                    />
                  )}
                  {option.name}
                </Box>
              )}
              value={
                importCountries.find(
                  country => country.name === values.country
                ) || { name: '' }
              }
              noOptionsText={'Country not found'}
              onChange={(event, value) => {
                setValues({
                  ...values,
                  country: value?.name,
                  countryCode: value?.code
                });

                getStates(value?.code);

                getCities({
                  cityName: 'a',
                  countryCode: value?.code
                });
              }}
              textfieldProps={{
                lg: 12,
                md: 12,
                name: 'country',
                customLabel: 'Country',
                containerClassName:
                  'mt-2 new-addresses__modal__body__form__item__input',
                error: Boolean(errors.country),
                helperText: errors.country,
                value: values.country,
                InputProps: {
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img
                        loading='lazy'
                        width='20'
                        height='14'
                        src={`https://flagcdn.com/w20/${(
                          values.countryCode || importCountries[0].code
                        ).toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${(
                          values.countryCode || importCountries[0].code
                        ).toLowerCase()}.png 2x`}
                        alt=''
                      />
                    </InputAdornment>
                  )
                }
              }}
            />
          )}
        </div>
        <button
          type='submit'
          className='continue-btn'
          // onClick={e => {
          //   e.preventDefault();
          //   setCurrentTab(2);
          // }}
        >
          Continue
        </button>
      </form>
    </SeaFreightQuoteLayout>
  );
};

export default SeaFreightSenderInfo;

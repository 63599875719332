import * as jwt from 'jsonwebtoken';

/**
 * scrambles string data
 * @param {String} token - input string data
 * @returns {String} - scrambled data
 */
const shuffleToken = token => token.split('').reverse().join('');

const verifyToken = token => {
  if (!token)
    return {
      Error: 'Failed to authenticate token',
      success: false
    };
  const reshuffledToken = shuffleToken(token);
  let output = {};
  return jwt.verify(
    reshuffledToken,
    process.env.REACT_APP_JWT_SECRET,
    (err, decoded) => {
      if (err) {
        output = {
          Error: 'Failed to authenticate token',
          success: false
        };
      } else {
        output = { success: true, ...decoded };
      }
      return output;
    }
  );
};

export { verifyToken, shuffleToken };

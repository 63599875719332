import React from 'react';
import Header from '../../../components/Commons/header';
import verifyIcon from '../../../assets/svg/email-sent.svg';

const VerifyMail = () => {
  return (
    <div className='verify-mail'>
      <Header />

      <div className='verify-email__wrapper'>
        <figure>
          <img src={verifyIcon} alt='' />
        </figure>
        <h1 className='authentication__form__fields__heading '>
          Check your email
        </h1>
        <p className='authentication__form__fields__sub-heading verify-email__sub-heading'>
          We have just sent you the password reset link. Click the link in the
          email and follow the instructions to reset your password
        </p>
      </div>
    </div>
  );
};

export default VerifyMail;

import { Alert, Box, LinearProgress } from '@mui/material';
import FileUpload from '../../../../../components/file-upload';
import {
  DeleteFileIcon,
  FileUploadedIcon,
  FileUploadingIcon,
  InfoYellowIcon
} from '../../../../../constants/asset-contants';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useSendAlert } from '../../../../../utilities/send-alert';
import SeaFreightQuoteLayout from '../../../../../components/Layouts/sea-freight-layout';
import {
  saveShipmentDetailsState,
  userState
} from '../../../../../recoil/atoms';
import { useRecoilState } from 'recoil';
import { toFixed } from '../../../../../utilities/to-fixed';
import { useHistory } from 'react-router-dom';
import { seaFreightImportLinks } from '../../../../../constants/shipment-links';
import { itemCategories } from '../../../../../constants/item-categories';
import insurancePlans from '../../../../../constants/insurance-plans';
import {
  shipmentMethods,
  shipmentOptions,
  shopnshipHubs
} from '../../../../../constants/book-shipment-contants';
import { cleanShipmentDetail } from '../../../helper-functions';
import omit from 'lodash.omit';
import { useSaveShipmentMutation } from '../../../../../operations/mutations';
import BusyOverlay from '../../../../../components/busy-overlay';
import { convertUrlToFile } from '../../../../../utilities/convert-url-to-file';

const url = `${process.env.REACT_APP_REST_API}/upload`;

const FileUploadComponent = ({ image, handleDeleteFile, cancelUpload }) => {
  const convertToKB = (size = 0) => {
    if (!size || isNaN(size)) {
      return '';
    }

    const fileSize = toFixed(size / 1024);

    if (fileSize < 1024) {
      return `${fileSize}KB`;
    }

    return `${toFixed(fileSize / 1024)}MB`;
  };

  const isUploading = useMemo(() => {
    if (isNaN(image?.progress)) {
      return false;
    }

    return ![0, 100].includes(image.progress);
  }, [image?.progress]);

  return (
    <div
      style={{
        backgroundColor: '#F8FBFF',
        borderRadius: '6px',
        border: '1px solid #EEF1F8',
        padding: '20px'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <img
            src={isUploading ? FileUploadingIcon : FileUploadedIcon}
            alt=''
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <span
              style={{
                fontWeight: 700,
                fontSize: '12px'
              }}
            >
              {image?.file?.name}
            </span>
            <span
              style={{
                fontSize: '10px',
                fontWeight: 500
              }}
            >
              {convertToKB(image?.file?.size)}
            </span>
          </div>
        </div>
        <div
          onClick={handleDeleteFile}
          style={{
            cursor: 'pointer'
          }}
        >
          {isUploading ? (
            <div className='x-cancel' onClick={cancelUpload}>
              <span></span>
              <span></span>
            </div>
          ) : (
            <img src={DeleteFileIcon} alt='delete icon' />
          )}
        </div>
      </div>
      {isUploading && (
        <div className='d-flex mt-2 align-items-center' style={{ gap: '1em' }}>
          <Box sx={{ width: '90%' }}>
            <LinearProgress
              sx={{
                height: '6px',
                borderRadius: '8px',
                '> .MuiLinearProgress-bar': {
                  backgroundColor: '#22428f'
                }
              }}
              value={image?.progress}
              variant='determinate'
            />
          </Box>
          <span className='d-block'>{image?.progress}%</span>
        </div>
      )}
    </div>
  );
};

const SeaFreightPackageUpload = () => {
  const history = useHistory();

  const [user] = useRecoilState(userState);

  const sendAlert = useSendAlert();

  const [images, setImages] = useState([]);

  const [controllers, setControllers] = useState([]);

  const [shipmentDetail, setShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );

  const currentIndex = useMemo(() => {
    if (!history.location.pathname) {
      return -1;
    }

    return seaFreightImportLinks.findIndex(
      link => history.location.pathname === link.link
    );
  });

  const [saveShipmentDetails, { loading }] = useSaveShipmentMutation(() => {
    history.push(seaFreightImportLinks[currentIndex + 1].link);
  });

  const handleDeleteFile = index => {
    setImages(images.filter((_, imageIndex) => imageIndex !== index));

    setControllers(
      controllers.filter((_, controllerIndex) => controllerIndex !== index)
    );
  };

  const handleCancelFile = index => {
    images[index].isCancelled = true;

    setImages([...images]);
  };

  const handleUploadFile = (file, index) => {
    if (!file || isNaN(index)) {
      return;
    }

    const formData = new FormData();

    formData.append('file', file);

    formData.append('fileName', file.name);

    axios
      .post(url, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        },
        signal: controllers[index]?.signal,
        onUploadProgress: progress => {
          if (progress.lengthComputable) {
            images[index].progress = toFixed(
              (progress.loaded / progress.total) * 100
            );

            setImages([...images]);
          }
        }
      })
      .then(response => {
        images[index].url = response.data?.Location;

        delete images[index]?.index;

        setImages([...images]);
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          return handleCancelFile(index);
        }

        sendAlert(err?.message, 'error');
      });
  };

  const handleImages = () => {
    images
      .map((image, index) => ({ ...image, index }))
      .filter(image => image?.file && image?.progress === 0)
      .forEach(image => handleUploadFile(image.file, image.index));
  };

  const handleSubmit = event => {
    event.preventDefault();

    const updatedShipmentDetail = {
      ...shipmentDetail,
      pickupCharge: 0,
      shipmentCharge: 0,
      valueAddedTaxCharge: 0,
      pricingTier: 'SeaImport',
      insuranceType: insurancePlans[0].value,
      shipmentRoute: shipmentOptions[1].value,
      insuranceCharge: insurancePlans[0].amount,
      images: images.filter(image => !image?.isCancelled).map(({ url }) => url),
      items: shipmentDetail?.items?.map(item => ({
        ...omit(item, ['manualBoxType']),
        quantity: 1,
        category: itemCategories[itemCategories.length - 1].value
      }))
    };

    setShipmentDetail(updatedShipmentDetail);

    if (user.id) {
      saveShipment(updatedShipmentDetail);
    }
  };

  const saveShipment = shipmentDetail => {
    const cleanedShipmentDetail = cleanShipmentDetail(shipmentDetail);

    delete cleanedShipmentDetail.multipleReceivers;
    delete cleanedShipmentDetail.pickupPartner;
    delete cleanedShipmentDetail.deliveryLocation;
    delete cleanedShipmentDetail.pickupId;

    cleanedShipmentDetail.receiverDetail = omit(
      cleanedShipmentDetail?.receiverDetail,
      ['isDefault', 'updatedDate']
    );

    cleanedShipmentDetail.senderDetail = {
      ...cleanedShipmentDetail.senderDetail,
      email: user.email,
      name: user.fullName,
      phoneNumber: user.phoneNumber
    };

    if (
      cleanedShipmentDetail?.itemCollectionMode === shipmentMethods[0].value
    ) {
      cleanedShipmentDetail.senderDetail.name = `${user.fullName} - ${
        shopnshipHubs[
          cleanedShipmentDetail?.senderDetail?.countryCode?.toLowerCase()
        ]?.slug
      }`;
    }

    cleanedShipmentDetail.receiverDetail = {
      ...cleanedShipmentDetail.receiverDetail,
      email: user.email,
      name: user.fullName,
      phoneNumber: user.phoneNumber
    };

    saveShipmentDetails([cleanedShipmentDetail], null);
  };

  const goBack = () => {
    setShipmentDetail({
      ...shipmentDetail,
      images: images.map(({ url }) => url)
    });

    history.push(seaFreightImportLinks[currentIndex - 1].link);
  };

  useEffect(() => {
    handleImages();
  }, [images.length]);

  const initializeImages = async () => {
    setImages(
      await Promise.all(
        shipmentDetail?.images?.map(async image => ({
          url: image,
          progress: 100,
          file: await convertUrlToFile(image)
        }))
      )
    );
  };

  useEffect(() => {
    if (shipmentDetail?.images && shipmentDetail?.images?.length) {
      initializeImages();
    }
  }, []);

  return (
    <SeaFreightQuoteLayout>
      <BusyOverlay loading={loading} />
      <h3 className='mb-2' style={{ fontWeight: 600 }}>
        Upload Images of Packages
      </h3>
      <FileUpload
        accept='image/jpg, image/png, image/jpeg'
        isMultiple
        onUploadMultipleFiles={files => {
          setImages([
            ...images,
            ...files.map(file => ({
              file,
              url: '',
              progress: 0
            }))
          ]);

          setControllers([
            ...controllers,
            ...files?.map(() => new AbortController())
          ]);
        }}
      />
      <div
        className='mt-2 '
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        {images
          .filter(image => !image.isCancelled)
          .map((file, index) => {
            return (
              <FileUploadComponent
                image={file}
                index={index}
                handleDeleteFile={() => handleDeleteFile(index)}
                cancelUpload={() => {
                  if (controllers?.[index]) {
                    controllers?.[index]?.abort?.();
                  }
                }}
              />
            );
          })}
      </div>
      <button className='continue-btn' onClick={handleSubmit}>
        Complete
      </button>
      <div
        onClick={handleSubmit}
        className='delivery-options__footer__action mt-2'
      >
        Skip & Upload Later
      </div>
      <Alert
        severity={'info'}
        sx={{
          marginTop: '10px',
          fontSize: '1.3rem',
          fontWeight: 'normal',
          fontFamily: 'General Sans',
          border: '1px solid #EAC682',
          borderRadius: '8px',
          backgroundColor: 'rgba(239, 165, 22, 0.04)',
          alignItems: 'flex-start',
          color: '#0F1414',
          '& .MuiAlert-icon': {
            fontSize: '2rem',
            marginRight: '5px'
          }
        }}
        icon={<img src={InfoYellowIcon} alt='info' />}
      >
        You will receive a quote, but your shipment will NOT be processed
        without this.
      </Alert>
      <div onClick={goBack} className='go-back-btn'>
        Go Back
      </div>
    </SeaFreightQuoteLayout>
  );
};

export default SeaFreightPackageUpload;

import React, { useEffect, useState } from 'react';
import { ChevronLeft, Close } from '@mui/icons-material';
import { Button, IconButton, Skeleton } from '@mui/material';
import Input from '../new-dashboard/custom-input';
import SubmitButton from '../submit-button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTrackShipmentLazyQuery } from '../../operations/queries';
import { useSendAlert } from '../../utilities/send-alert';
import { useHistory } from 'react-router-dom';
import BookingSuccessfulPrompt from './booking-successful-prompt';
import {
  CompletedIcon,
  ProgressIcon,
  SelectArrowBlue
} from '../../constants/asset-contants';
import { shipmentMessageSplitter } from '../../constants/shipment-links';

const NewTrackingModal = ({ openModal, setOpenModal, trackingId }) => {
  const [getTrackingDetails, { loading, data }] = useTrackShipmentLazyQuery();
  const history = useHistory();
  const [
    openBookingSuccessfulPrompt,
    setOpenBookingSuccessfulPrompt
  ] = useState(false);
  const validationSchema = yup.object().shape({
    trackingNo: yup
      .string()
      .length(10, 'Please enter a valid tracking number')
      .required('This field is required')
  });
  const [resetData, setResetData] = useState(true);
  const [expandData, setExpandData] = useState(false);
  const sendAlert = useSendAlert();

  const [openToggle, setOpenToggle] = useState({
    id: false,
    list: true,
    receiver: false
  });

  const handleToggle = (key = '') => {
    if (openToggle[key] === undefined) {
      return;
    }

    openToggle[key] = !openToggle[key];

    setOpenToggle({ ...openToggle });
  };

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    resetForm,
    setFieldValue
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      trackingNo: ''
    },
    validationSchema,
    onSubmit: values => {
      getTrackingDetails(values.trackingNo);
      setResetData(false);
    }
  });

  useEffect(() => {
    if (trackingId && openModal) {
      setFieldValue('trackingNo', trackingId);

      getTrackingDetails(trackingId);
      setFieldValue('trackingNo', trackingId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingId, openModal]);

  useEffect(() => {
    if (data?.trackShipment?.length && !loading) {
      setResetData(false);
    } else {
      setResetData(true);
    }
  }, [data, loading]);
  const conveneShipmentDate = initialDate => {
    const date = new Date(initialDate);
    const month = date.toDateString().split(' ')[1];
    const day = date.toDateString().split(' ')[2];
    const initTime = date.toLocaleTimeString('en-US', {
      timeStyle: 'short'
    });
    const initHour = parseFloat(initTime.split(':')[0]);
    const hours = initHour < 10 ? `0${initHour}` : initHour;
    const time = `${hours}:${initTime.split(':')[1]}`;
    return `${month} ${day}, ${time}`;
  };

  const exitModal = () => {
    if (window.location.pathname.includes('/tracking')) {
      history.push('/');
      setOpenBookingSuccessfulPrompt(true);
    } else {
      setOpenModal(false);
      setExpandData(false);
      setResetData(true);
      resetForm();
    }
  };

  const openRateDelivery = () => {
    if (window.location.pathname.includes('/tracking')) {
      setOpenBookingSuccessfulPrompt(true);
    } else {
      setOpenModal(false);
      setExpandData(false);
      setResetData(true);
      resetForm();
      setOpenBookingSuccessfulPrompt(true);
    }
  };
  const copyTrackingID = () => {
    if (window.navigator) {
      window.navigator.clipboard.writeText(values.trackingNo);
      sendAlert('Copied to Clipboard!', 'success');
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = values.trackingNo;
      textArea.style = {
        top: '0',
        left: '0',
        position: 'fixed'
      };
      window.document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      const success = window.document.execCommand('copy');
      if (success) {
        sendAlert('Copied to Clipboard!', 'success');
      } else {
        sendAlert("Couldn't copy to Clipboard.", 'error');
      }
    }
  };

  return (
    <>
      <div className={`new-tracking__modal fade${openModal ? ' show' : ''}`}>
        {!resetData || loading ? (
          <div className='new-tracking__modal__success'>
            <div className='new-tracking__modal__success__wrap'>
              <div className='new-tracking__modal__header'>
                <IconButton onClick={exitModal}>
                  <ChevronLeft />
                </IconButton>
                <h2>Track Shipment</h2>
                {/* <IconButton onClick={exitModal}>
                  <Close />
                </IconButton> */}
              </div>
              <div className='new-tracking__modal__success__body'>
                <div className='new-tracking__modal__success__body__wrap delivery-options'>
                  <div>
                    <div
                      onClick={() => handleToggle('list')}
                      className={`toggle-container ${
                        !openToggle.list ? 'border-shadow' : 'open'
                      }`}
                    >
                      <label className='toggle-header'>Tracking Status</label>
                      <img
                        className={`arrow-icon ${
                          openToggle.list ? 'upside-down' : ''
                        }`}
                        src={SelectArrowBlue}
                        alt=''
                      />
                    </div>
                    {openToggle.list && (
                      <div className='new-tracking__modal__success__items'>
                        {loading
                          ? [...Array(3).keys()].map(key => (
                              <div
                                key={key}
                                className={`new-tracking__modal__success__item`}
                              >
                                <div className='new-tracking__modal__success__item__wrap'>
                                  <div className='new-tracking__modal__success__item__status'>
                                    <Skeleton />
                                  </div>
                                  <div className='new-tracking__modal__success__item__date'>
                                    <Skeleton />
                                  </div>
                                </div>
                              </div>
                            ))
                          : data?.trackShipment
                              ?.slice(
                                0,
                                expandData ? data?.trackShipment.length : 5
                              )
                              ?.sort(
                                (a, b) =>
                                  new Date(a?.createdDate) -
                                  new Date(b?.createdDate)
                              )
                              .map((shipmentStatus, shipmentStatusIndex) => {
                                const messages = shipmentStatus.message?.split(
                                  shipmentMessageSplitter
                                );

                                return (
                                  <div
                                    key={shipmentStatus.id}
                                    className={`new-tracking__modal__success__item ${shipmentStatus.status.toLowerCase()}`}
                                  >
                                    {shipmentStatusIndex === 4 &&
                                    !expandData ? (
                                      <div className='new-tracking__modal__success__item__wrap'>
                                        <span className='see-more'></span>
                                        <div className='new-tracking__modal__success__item__expand-more'>
                                          <Button
                                            onClick={() => setExpandData(true)}
                                            variant='text'
                                          >
                                            {`See ${
                                              data?.trackShipment.length - 3
                                            } more updates`}
                                          </Button>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className='new-tracking__modal__success__item__wrap'>
                                        <img
                                          className='status-icon'
                                          src={
                                            shipmentStatusIndex ===
                                              data?.trackShipment.length - 1 &&
                                            !shipmentStatus?.message?.includes(
                                              'Delivered'
                                            )
                                              ? ProgressIcon
                                              : CompletedIcon
                                          }
                                          alt='status icon'
                                        />
                                        <div className='new-tracking__modal__success__item__status'>
                                          <h3>{messages[0]}</h3>
                                          {messages.length > 1 &&
                                            messages
                                              .slice(1)
                                              .map((message, index) => (
                                                <div key={index}>
                                                  <span className='new-addresses__list__item__divider'></span>
                                                  <p>{message}</p>
                                                </div>
                                              ))}
                                        </div>
                                        <div className='new-tracking__modal__success__item__date'>
                                          <span>
                                            {conveneShipmentDate(
                                              shipmentStatus.updatedDate
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                        {expandData && (
                          <div className='new-tracking__modal__success__item'>
                            <div className='new-tracking__modal__success__item__wrap'>
                              <span className='see-more'></span>
                              <div className='new-tracking__modal__success__item__expand-more'>
                                <Button
                                  onClick={() => setExpandData(false)}
                                  variant='text'
                                >
                                  See less updates
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      border: '4px solid #EEF1F8'
                    }}
                  ></div>

                  <div>
                    <div
                      onClick={() => handleToggle('receiver')}
                      className={`toggle-container ${
                        !openToggle.receiver ? 'border-shadow' : 'open'
                      }`}
                    >
                      <label className='toggle-header'>
                        Receiver's Details
                      </label>
                      <img
                        className={`arrow-icon ${
                          openToggle.receiver ? 'upside-down' : ''
                        }`}
                        src={SelectArrowBlue}
                        alt=''
                      />
                    </div>
                    {openToggle.receiver && data?.trackShipment?.[0] && (
                      <div className='new-tracking__modal__success__info my-4'>
                        <div className='new-tracking__modal__success__info__wrap'>
                          <div className='new-tracking__modal__success__info__content'>
                            <div
                              className='new-tracking__modal__success__info__label d-flex align-items-center'
                              style={{ gap: '12px', flexWrap: 'wrap' }}
                            >
                              <h4>
                                {
                                  data?.trackShipment?.[0]?.shipment
                                    ?.receiverDetail?.name
                                }
                              </h4>{' '}
                              <span className='new-addresses__list__item__divider'></span>{' '}
                              <h4>
                                {
                                  data?.trackShipment?.[0]?.shipment
                                    ?.receiverDetail?.addressLine1
                                }
                              </h4>
                            </div>

                            <div className='new-tracking__modal__success__info__label mt-2'>
                              <h4>
                                {
                                  data?.trackShipment?.[0]?.shipment
                                    ?.receiverDetail?.postalCode
                                }
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      border: '4px solid #EEF1F8'
                    }}
                  ></div>
                  <div>
                    <div
                      onClick={() => handleToggle('id')}
                      className={`toggle-container ${
                        !openToggle.id ? 'border-shadow' : 'open'
                      }`}
                    >
                      <label className='toggle-header'>Tracking ID</label>
                      <img
                        className={`arrow-icon ${
                          openToggle.id ? 'upside-down' : ''
                        }`}
                        src={SelectArrowBlue}
                        alt=''
                      />
                    </div>
                    {openToggle.id && (
                      <div className='new-tracking__modal__success__info my-4'>
                        <div className='new-tracking__modal__success__info__wrap'>
                          <div className='new-tracking__modal__success__info__content'>
                            <div className='new-tracking__modal__success__info__label'>
                              <h4>{values.trackingNo}</h4>
                            </div>
                          </div>
                          <div className='new-tracking__modal__success__info__cta'>
                            <Button onClick={copyTrackingID} variant='text'>
                              Copy
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      border: '4px solid #EEF1F8'
                    }}
                  ></div>
                  <div className='new-tracking__modal__success__info pt-4'>
                    <SubmitButton
                      text='Rate your delivery'
                      onClick={openRateDelivery}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='new-tracking__modal__wrap noData'>
            <div
              className='new-tracking__modal__header'
              style={{
                justifyContent: 'space-between'
              }}
            >
              <h2>Track Shipment</h2>
              <IconButton onClick={exitModal}>
                <Close />
              </IconButton>
            </div>
            <div className='new-tracking__modal__body'>
              <form
                onSubmit={handleSubmit}
                className='new-tracking__modal__body__wrap'
              >
                <Input
                  name='trackingNo'
                  id='trackingNo'
                  type='text'
                  placeholder='Enter ID'
                  customLabel='Enter your tracking ID'
                  containerClassName='new-tracking__modal__input'
                  error={touched.trackingNo && Boolean(errors.trackingNo)}
                  helperText={touched.trackingNo && errors.trackingNo}
                  value={values.trackingNo}
                  onChange={handleChange}
                />
                <SubmitButton
                  text='Track'
                  className='new-tracking__modal__button'
                />
              </form>
            </div>
          </div>
        )}
      </div>
      <div
        className={`new-tracking__modal__backdrop fade${
          openModal ? ' show' : ''
        }`}
      ></div>

      <BookingSuccessfulPrompt
        isTrackingMode
        setCloseTrackingModal={setOpenModal}
        openPrompt={openBookingSuccessfulPrompt}
        setOpenPrompt={setOpenBookingSuccessfulPrompt}
      />
    </>
  );
};

export default NewTrackingModal;

import React from 'react';
import GetQuotesForm from '../../../../components/get-quotes-form';
import ShippingOptionsLayout from '../../../../components/Layouts/shipping-options-layout';

const GetQuotes = () => {
  return (
    <ShippingOptionsLayout
      onBackClick={'/'}
      linkTo={'/'}
      description={{
        heading: 'Get Pricing',
        subHeading: ' Enter the details of your shipment below'
      }}
    >
      <GetQuotesForm />
    </ShippingOptionsLayout>
  );
};

export default GetQuotes;

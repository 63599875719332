import React, { useEffect, useState } from 'react';
import { TimerOutlined } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { conveneDate } from '../helper-functions';
import { useGetTransactionsLazyQuery } from '../../../operations/queries';
import EmptyList from '../../../components/empty-list';
import emptyAddressIcon from '../../../assets/svg/wallet-empty.svg';

import { toCurrency } from '../../../utilities/to-currency';
import usePagination from '../../../utilities/usePagination';
import Pagination from '../../../components/Commons/pagination';
const NewTransactions = () => {
  const filters = {
    all: 'all',
    fund: 'Credit',
    debit: 'Debit'
  };
  const take = 10;
  const [
    getTransactions,
    { loading, data, error }
  ] = useGetTransactionsLazyQuery();
  const list = data ? data?.getTransactions?.list : [];
  const {
    start,
    end,
    disableNext,
    disablePrevious,
    currentPage,
    totalCount,
    onNextPage,
    onPreviousPage,
    setStart,
    setEnd,
    setCurrentPage
  } = usePagination({
    currentPage: data?.getTransactions?.currentPage,
    totalCount: data?.getTransactions?.totalCount,
    take
  });
  const [filter, setFilter] = useState(filters.all);
  useEffect(() => {
    if (filter !== filters.all) {
      getTransactions({
        take,
        page: currentPage,
        transactionType: filter
      });
    } else {
      getTransactions({
        take,
        page: currentPage
      });
    }
    // eslint-disable-next-line
  }, [data, currentPage, filter]);
  const handleSetFilter = value => {
    setCurrentPage(1);
    setStart(1);
    setEnd(take);
    setFilter(value);
  };
  const renderRowKeyPairs = transaction => {
    const keyPairs = [];
    for (const key in transaction) {
      if (Object.hasOwnProperty.call(transaction, key)) {
        const element = transaction[key];
        const initKey = key.replace('transaction', '');
        if (initKey.match(/amount|Type|Status/)) {
          if (initKey === 'amount') {
            keyPairs.push({ key: initKey, value: toCurrency(element / 100) });
          } else if (initKey === 'Type') {
            keyPairs.push({
              key: initKey,
              value: element.match(new RegExp('payment', 'i'))
                ? `${element.split('P')[0]} P${element.split('P')[1]}`
                : element
            });
          } else {
            keyPairs.push({ key: initKey, value: element });
          }
        }
      }
    }
    return keyPairs;
  };
  const filterSkeletonStyles = {
    borderRadius: '50%',
    backgroundColor: 'rgba(34, 66, 143, 0.1)'
  };
  const paginationSettings = {
    start,
    end,
    take,
    disableNext,
    disablePrevious,
    totalCount,
    loading,
    onNextPage,
    onPreviousPage
  };
  return (
    <div className='new-wallet__transactions__container'>
      {!error && data && !data.getTransactions?.totalCount ? (
        <>
          <div
            aria-label='Transaction History'
            className='new-wallet__transactions__header'
          >
            <h1
              style={{
                width: 'fit-content'
              }}
            >
              Transaction History
            </h1>
          </div>
          <div className='new-wallet__transactions__filters'>
            <div
              onClick={() => handleSetFilter(filters.all)}
              className={`new-wallet__transactions__filter${
                filter === filters.all ? ' active' : ''
              }`}
            >
              <div className='new-wallet__transactions__filter__wrap'>
                <div className='new-wallet__transactions__filter__content'>
                  <div className='new-wallet__transactions__filter__content__name'>
                    <span>All</span>
                  </div>
                  <div className='new-wallet__transactions__filter__content__value'>
                    {filter !== filters.all ? (
                      <Skeleton sx={filterSkeletonStyles} />
                    ) : (
                      <span>{data && 0}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => handleSetFilter(filters.fund)}
              className={`new-wallet__transactions__filter${
                filter === filters.fund ? ' active' : ''
              }`}
            >
              <div className='new-wallet__transactions__filter__wrap'>
                <div className='new-wallet__transactions__filter__content'>
                  <div className='new-wallet__transactions__filter__content__name'>
                    <span>Wallet Funding</span>
                  </div>
                  <div className='new-wallet__transactions__filter__content__value'>
                    {filter !== filters.fund ? (
                      <Skeleton sx={filterSkeletonStyles} />
                    ) : (
                      <span>{data && 0}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => handleSetFilter(filters.debit)}
              className={`new-wallet__transactions__filter${
                filter === filters.debit ? ' active' : ''
              }`}
            >
              <div className='new-wallet__transactions__filter__wrap'>
                <div className='new-wallet__transactions__filter__content'>
                  <div className='new-wallet__transactions__filter__content__name'>
                    <span>Debit</span>
                  </div>
                  <div className='new-wallet__transactions__filter__content__value'>
                    {filter !== filters.debit ? (
                      <Skeleton sx={filterSkeletonStyles} />
                    ) : (
                      <span>{data && 0}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <EmptyList
            style={{
              width: '100%'
            }}
            emptyIcon={emptyAddressIcon}
            heading={`You don’t have any ${
              filter === filters.fund
                ? 'Credit '
                : filter === filters.debit
                ? 'Debit '
                : ' '
            }Transactions yet`}
            subHeading='Request a new shipment and the log of all your transactions would appear here.'
          />
        </>
      ) : (
        <>
          <div
            aria-label='Transaction History'
            className='new-wallet__transactions__header'
          >
            <h1
              style={{
                width: 'fit-content'
              }}
            >
              Transaction History
            </h1>
          </div>
          <div className='new-wallet__transactions__filters'>
            <div
              onClick={() => handleSetFilter(filters.all)}
              className={`new-wallet__transactions__filter${
                filter === filters.all ? ' active' : ''
              }`}
            >
              <div className='new-wallet__transactions__filter__wrap'>
                <div className='new-wallet__transactions__filter__content'>
                  <div className='new-wallet__transactions__filter__content__name'>
                    <span>All</span>
                  </div>
                  <div className='new-wallet__transactions__filter__content__value'>
                    {filter !== filters.all ? (
                      <Skeleton sx={filterSkeletonStyles} />
                    ) : (
                      <span>{data && totalCount}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => handleSetFilter(filters.fund)}
              className={`new-wallet__transactions__filter${
                filter === filters.fund ? ' active' : ''
              }`}
            >
              <div className='new-wallet__transactions__filter__wrap'>
                <div className='new-wallet__transactions__filter__content'>
                  <div className='new-wallet__transactions__filter__content__name'>
                    <span>Wallet Funding</span>
                  </div>
                  <div className='new-wallet__transactions__filter__content__value'>
                    {filter !== filters.fund ? (
                      <Skeleton sx={filterSkeletonStyles} />
                    ) : (
                      <span>{data && totalCount}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => handleSetFilter(filters.debit)}
              className={`new-wallet__transactions__filter${
                filter === filters.debit ? ' active' : ''
              }`}
            >
              <div className='new-wallet__transactions__filter__wrap'>
                <div className='new-wallet__transactions__filter__content'>
                  <div className='new-wallet__transactions__filter__content__name'>
                    <span>Debit</span>
                  </div>
                  <div className='new-wallet__transactions__filter__content__value'>
                    {filter !== filters.debit ? (
                      <Skeleton sx={filterSkeletonStyles} />
                    ) : (
                      <span>{data && totalCount}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='new-wallet__transactions__list'>
            <table className='table table-borderless'>
              <thead>
                <tr>
                  <th>amount</th>
                  <th>type</th>
                  <th>date</th>
                  <th>status</th>
                </tr>
              </thead>
              <tbody>
                {loading
                  ? [...Array(take)].map((_, i) => (
                      <tr
                        key={i}
                        className={`new-wallet__transactions__list__row${
                          i % 2 === 0 ? ' odd' : ''
                        }`}
                      >
                        <td className='new-wallet__transactions__list__amount'>
                          <Skeleton />
                        </td>
                        <td className='new-wallet__transactions__list__type'>
                          <Skeleton />
                        </td>
                        <td className='new-wallet__transactions__list__date'>
                          <Skeleton />
                        </td>
                        <td
                          className={`new-wallet__transactions__list__status`}
                        >
                          <Skeleton />
                        </td>
                      </tr>
                    ))
                  : list.map((transaction, i) => (
                      <tr
                        key={transaction.transactionId}
                        className={`new-wallet__transactions__list__row${
                          i % 2 === 0 ? ' odd' : ''
                        }`}
                      >
                        <td className='new-wallet__transactions__list__amount'>
                          {toCurrency(transaction.amount / 100)}
                        </td>
                        <td className='new-wallet__transactions__list__type'>
                          {transaction.transactionType.match(
                            new RegExp('payment', 'i')
                          )
                            ? `${transaction.transactionType.split('P')[0]} P${
                                transaction.transactionType.split('P')[1]
                              }`
                            : transaction.transactionType}
                        </td>
                        <td className='new-wallet__transactions__list__date'>
                          {conveneDate(transaction.updatedDate)}
                        </td>
                        <td
                          className={`new-wallet__transactions__list__status ${transaction.transactionStatus}`}
                        >
                          {transaction.transactionStatus}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
          <div className='new-wallet__transactions__list__mobile'>
            <div className='new-wallet__transactions__list__mobile__wrap'>
              {loading
                ? [...Array(take)].map((_, i) => (
                    <div
                      key={i}
                      className='new-wallet__transactions__list__mobile__row'
                    >
                      <div className='new-wallet__transactions__list__mobile__row__wrap'>
                        <div className='new-wallet__transactions__list__mobile__row__body'>
                          <div className='new-wallet__transactions__list__mobile__row__body__wrap'>
                            {[
                              { key: 'amount' },
                              { key: 'type' },
                              { key: 'status' }
                            ].map(keyPair => (
                              <div
                                key={keyPair.key}
                                className='new-wallet__transactions__list__mobile__row__pair'
                              >
                                <div className='new-wallet__transactions__list__mobile__row__key'>
                                  <span>{keyPair.key}</span>
                                </div>
                                <div className='new-wallet__transactions__list__mobile__row__value w-75'>
                                  <Skeleton sx={{ width: '100%' }} />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className='new-wallet__transactions__list__mobile__row__footer'>
                          <div
                            className='new-wallet__transactions__list__mobile__row__time'
                            style={{ justifyContent: 'space-between' }}
                          >
                            <TimerOutlined />
                            <Skeleton sx={{ width: '75%' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : list.map(transaction => (
                    <div
                      key={transaction.transactionId}
                      className='new-wallet__transactions__list__mobile__row'
                    >
                      <div className='new-wallet__transactions__list__mobile__row__wrap'>
                        <div className='new-wallet__transactions__list__mobile__row__body'>
                          <div className='new-wallet__transactions__list__mobile__row__body__wrap'>
                            {renderRowKeyPairs(transaction).map(keyPair => (
                              <div
                                key={keyPair.key}
                                className='new-wallet__transactions__list__mobile__row__pair'
                              >
                                <div className='new-wallet__transactions__list__mobile__row__key'>
                                  <span>{keyPair.key}</span>
                                </div>
                                <div className='new-wallet__transactions__list__mobile__row__value'>
                                  <span
                                    className={`${
                                      keyPair.key === 'Status'
                                        ? ` ${keyPair.value}`
                                        : ''
                                    }`}
                                  >
                                    {keyPair.value}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className='new-wallet__transactions__list__mobile__row__footer'>
                          <div className='new-wallet__transactions__list__mobile__row__time'>
                            <TimerOutlined />
                            <span>{conveneDate(transaction.updatedDate)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
          <Pagination {...paginationSettings} />
        </>
      )}
    </div>
  );
};

export default NewTransactions;

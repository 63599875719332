import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search-shipment.svg';
import { useGetShipmentsQuery } from '../../../operations/queries';
import EmptyList from '../../../components/empty-list';
import RecentShipment from '../../../components/Commons/recent-shipment';
import Wrapper from '../../../components/Layouts/wrapper';
import { Box, IconButton, Skeleton } from '@mui/material';
import BusyOverlay from '../../../components/busy-overlay';
import PastShipmentDetailsModal from './past-shipment-details-modal';
import Pagination from '../../../components/Commons/pagination';
import usePagination from '../../../utilities/usePagination';
import PastShipmentLoader from './past-shipment-loader';
import { useRecoilState } from 'recoil';
import {
  shipmentDataLoadingState,
  shipmentDataState
} from '../../../recoil/atoms';
import Input from '../../../components/new-dashboard/custom-input';
import CustomAutoComplete from '../../../components/new-dashboard/custom-autocomplete';
import BookShipmentModal from '../../../components/modals/book-shipment-modal';
import emptyStateIcon from '../../../assets/svg/empty.svg';

const PastShipmentList = () => {
  const take = 10;
  const [openModal, setOpenModal] = useState(false);
  const [shipmentItem, setShipmentItem] = useState();
  const [shipmentType, setShipmentType] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [openBookShipmentModal, setOpenBookShipmentModal] = useState(false);
  const [shipmentData] = useRecoilState(shipmentDataState);
  const [shipmentDataLoading] = useRecoilState(shipmentDataLoadingState);

  const filterSkeletonStyles = {
    borderRadius: '50%',
    backgroundColor: 'rgba(34, 66, 143, 0.1)'
  };

  const chipEnums = {
    ALL: 'All',
    PAID: 'Paid',
    IMPORT: 'Import',
    EXPORT: 'Export',
    DOMESTIC: 'Domestic',
    FAILED_DELIVERIES: 'FailedDeliveries',
    CLARIFICATION_NEEDED: 'ClarificationNeeded'
  };

  const filters = {
    all: 'all',
    intransit: 'deliveryinprogress',
    delivered: 'delivered',
    cancelled: 'cancelled',
    shipmentType
  };

  const shipmentTypeFiltersData = [
    { shipmentType: 'Export' },
    { shipmentType: 'Import' },
    { shipmentType: 'Domestic' }
  ];

  const [filter, setFilter] = useState({
    status: chipEnums.ALL,
    data: ''
  });

  const [filterShipments, setFilterShipment] = useState(filters.all);

  const {
    start,
    end,
    totalCount,
    disableNext,
    disablePrevious,
    currentPage,
    onPreviousPage,
    onNextPage,
    setStart,
    setEnd,
    setCurrentPage
  } = usePagination({
    take,
    currentPage: shipmentData?.getShipments?.currentPage,
    totalCount: shipmentData?.getShipments?.totalCount
  });

  const dateStyles = { marginTop: 'calc(2% - 3px)' };
  const paginationSettings = {
    start,
    end,
    take,
    disableNext,
    disablePrevious,
    totalCount,
    loading: shipmentDataLoading,
    onNextPage,
    onPreviousPage
  };

  const getShipmentsQuery = useGetShipmentsQuery();

  const handleSearchQuery = debounce(
    e =>
      onShipmentsQuery({
        page: 0,
        pageSize: take,
        search: e.target.value || ''
      }),
    500
  );

  const onShipmentsQuery = query => {
    return new Promise((resolve, reject) => {
      getShipmentsQuery(
        query.page + 1,
        query.pageSize,
        query.search,
        filter.data
      )
        .then(response => {
          resolve({
            data: response.getShipments?.list || [],
            page: response.getShipments?.currentPage - 1,
            totalCount: response.getShipments?.totalCount
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  const handleGetShipments = () => {
    if (filterShipments !== filters.all) {
      getShipmentsQuery(currentPage, take, filterShipments, filter?.data);
    } else if (filter?.data) {
      getShipmentsQuery(currentPage, take, '', filter?.data);
    } else {
      getShipmentsQuery(currentPage, take);
    }
  };

  useEffect(() => {
    const updatedItem = shipmentData?.getShipments?.list?.find(
      shipment => shipment?.id === shipmentItem?.id
    );

    if (shipmentItem && updatedItem) {
      setShipmentItem(updatedItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentData]);

  useEffect(() => {
    handleGetShipments();
    // eslint-disable-next-line
  }, [currentPage, filterShipments, take, filter]);

  const handleSetFilter = value => {
    setCurrentPage(1);
    setStart(1);
    setEnd(take);
    setFilterShipment(value);
  };

  const handleShipmentTypeSelect = (event, value) => {
    setShipmentType(value?.shipmentType);
    handleSetFilter(value?.shipmentType);
  };

  const onFilterChange = data => {
    setFilter(data);
  };

  return (
    <>
      {openModal && (
        <PastShipmentDetailsModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          shipmentItem={shipmentItem}
          setShipmentItem={setShipmentItem}
          currentPage={currentPage}
        />
      )}
      <BookShipmentModal
        openModal={openBookShipmentModal}
        setOpenModal={setOpenBookShipmentModal}
      />
      <Wrapper>
        <BusyOverlay loading={shipmentDataLoading} />
        <div className='recent-shipments-container'>
          <div className='recent-shipments-filters-container'>
            <div className='new-wallet__transactions__filters recent-shipment-filters'>
              <div
                onClick={() => {
                  onFilterChange(filter?.data);
                  handleSetFilter(filters.all);
                }}
                className={`new-wallet__transactions__filter${
                  filterShipments === filters.all ? ' active' : ''
                }`}
              >
                <div className='new-wallet__transactions__filter__wrap'>
                  <div className='new-wallet__transactions__filter__content'>
                    <div className='new-wallet__transactions__filter__content__name'>
                      <span>All</span>
                    </div>
                    <div className='new-wallet__transactions__filter__content__value'>
                      {filterShipments !== filters.all ? (
                        <Skeleton sx={filterSkeletonStyles} />
                      ) : !shipmentDataLoading &&
                        filterShipments === shipmentType ? (
                        <span>
                          {shipmentData?.getShipments?.list &&
                            shipmentData?.getShipments?.totalCount}
                        </span>
                      ) : (
                        <span>
                          {shipmentData?.getShipments?.list &&
                            shipmentData?.getShipments?.totalCount}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                onClick={() => handleSetFilter(filters.intransit)}
                className={`new-wallet__transactions__filter${
                  filterShipments === filters.intransit ? ' active' : ''
                }`}
              >
                <div className='new-wallet__transactions__filter__wrap'>
                  <div className='new-wallet__transactions__filter__content'>
                    <div className='new-wallet__transactions__filter__content__name'>
                      <span>In Progress</span>
                    </div>
                    <div className='new-wallet__transactions__filter__content__value'>
                      {filterShipments !== filters.intransit ? (
                        <Skeleton sx={filterSkeletonStyles} />
                      ) : (
                        !shipmentDataLoading && (
                          <span>
                            {shipmentData?.getShipments?.list &&
                              shipmentData?.getShipments?.totalCount}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                onClick={() => handleSetFilter(filters.delivered)}
                className={`new-wallet__transactions__filter${
                  filterShipments === filters.delivered ? ' active' : ''
                }`}
              >
                <div className='new-wallet__transactions__filter__wrap'>
                  <div className='new-wallet__transactions__filter__content'>
                    <div className='new-wallet__transactions__filter__content__name'>
                      <span>Delivered</span>
                    </div>
                    <div className='new-wallet__transactions__filter__content__value'>
                      {filterShipments !== filters.delivered ? (
                        <Skeleton sx={filterSkeletonStyles} />
                      ) : (
                        !shipmentDataLoading && (
                          <span>
                            {shipmentData?.getShipments?.list &&
                              shipmentData?.getShipments?.totalCount}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                onClick={() => handleSetFilter(filters.cancelled)}
                className={`new-wallet__transactions__filter${
                  filterShipments === filters.cancelled ? ' active' : ''
                }`}
              >
                <div className='new-wallet__transactions__filter__wrap'>
                  <div className='new-wallet__transactions__filter__content'>
                    <div className='new-wallet__transactions__filter__content__name'>
                      <span>Cancelled</span>
                    </div>
                    <div className='new-wallet__transactions__filter__content__value'>
                      {filterShipments !== filters.cancelled ? (
                        <Skeleton sx={filterSkeletonStyles} />
                      ) : (
                        !shipmentDataLoading && (
                          <span>
                            {shipmentData?.getShipments?.list &&
                              shipmentData?.getShipments?.totalCount}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='recent-shipments-ship-now'>
              <button onClick={() => setOpenBookShipmentModal(true)}>
                Ship Now
              </button>
            </div>
          </div>
          <div className='recent-shipments-controls'>
            <div id='search' className='recent-shipments-control-items'>
              <Input
                onChange={handleSearchQuery}
                type='text'
                containerClassName='new-addresses__modal__item__input'
                customLabel={'Tracking ID'}
                placeholder='Search by Tracking ID'
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  )
                }}
                fullWidth
              />
            </div>

            <div
              id='shipment-type'
              className='recent-shipments-control-items recent-shipments-control-items-shipment-type'
            >
              <CustomAutoComplete
                id='shipment-type'
                value={shipmentType}
                options={shipmentTypeFiltersData}
                getOptionLabel={option => option}
                xs
                isOptionEqualToValue={(option, value) =>
                  option?.shipmentType === value
                }
                onChange={handleShipmentTypeSelect}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                    key={props.id}
                  >
                    {option?.shipmentType}
                  </Box>
                )}
                textfieldProps={{
                  lg: 4,
                  md: 12,
                  containerClassName:
                    'new-addresses__modal__item__input past-shipment-type',
                  value: shipmentType,
                  placeholder: 'Shipment type',
                  customLabel: 'Shipment Type'
                }}
              />
            </div>
            <div className='recent-shipments-control-items' style={dateStyles}>
              <div className='recent-shipments-date-filter-controls form-container'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <label className='form-item-label'>From</label>
                  <KeyboardDatePicker
                    margin='normal'
                    fullWidth
                    id='startDate'
                    format='dd/MM/yyyy'
                    value={startDate}
                    onChange={date => {
                      if (date) {
                        setStartDate(date);
                        onFilterChange({
                          status: filter?.status,
                          data: {
                            ...(filter?.data || {}),
                            dateRange: { from: date, to: endDate }
                          }
                        });
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className='recent-shipments-control-items' style={dateStyles}>
              <div className='recent-shipments-date-filter-controls form-container'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <label className='form-item-label'>To</label>
                  <KeyboardDatePicker
                    fullWidth
                    margin='normal'
                    id='endDate'
                    format='dd/MM/yyyy'
                    sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 }
                    }}
                    value={endDate}
                    onChange={date => {
                      if (date) {
                        setEndDate(date);
                        onFilterChange({
                          status: filter?.status,
                          data: {
                            ...(filter?.data || {}),
                            dateRange: { from: startDate, to: date }
                          }
                        });
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>

          <div className='recent-shipment-list'>
            {shipmentDataLoading
              ? [...Array(take).keys()].map(key => (
                  <PastShipmentLoader key={key} loading={shipmentDataLoading} />
                ))
              : shipmentData?.getShipments?.list?.map(shipment => (
                  <div key={shipment?.id}>
                    <div
                      key={shipment?.id}
                      className='shipment-item'
                      onClick={() => {
                        setOpenModal(true);
                        setShipmentItem(shipment);
                      }}
                    >
                      <RecentShipment
                        shipment={shipment}
                        key={shipment?.id}
                        refetch={() => {
                          handleGetShipments();
                        }}
                      />
                    </div>
                  </div>
                ))}
          </div>

          {!shipmentDataLoading &&
          shipmentData?.getShipments?.list.length >= 1 ? (
            <div className='past-shipment-list-pagination'>
              <Pagination {...paginationSettings} />
            </div>
          ) : null}
        </div>
        {!shipmentDataLoading &&
        shipmentData?.getShipments?.list?.length === 0 ? (
          <EmptyList
            emptyIcon={emptyStateIcon}
            heading='You don’t have any Shipments yet'
            subHeading='Request a new shipment and the log of all your shipments would appear here.'
          />
        ) : null}
      </Wrapper>
    </>
  );
};

export default PastShipmentList;

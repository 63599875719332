import React, { useEffect, useRef, useState } from 'react';
import icon from '../assets/svg/file-upload-icon.svg';
import SubmitButton from './submit-button';
import { useSendAlert } from '../utilities/send-alert';
import extractFilename from '../utilities/extract-filename';
import {
  DeleteFileIcon,
  FileDeleteIcon,
  FileUploadedIcon,
  FileUploadingIcon
} from '../constants/asset-contants';
import CustomSpinner from './custom-spinner';

const fileTypes = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf'
};

const FileUploadComponent = ({ file, isInprogress }) => {
  return (
    <div
      style={{
        backgroundColor: '#F8FBFF',
        borderRadius: '6px',
        border: '1px solid #EEF1F8',
        padding: '20px'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '10px'
          }}
        >
          <img
            src={isInprogress ? FileUploadingIcon : FileUploadedIcon}
            alt=''
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <span
              style={{
                fontWeight: 700,
                fontSize: '12px',
                paddingBottom: '7px'
              }}
            >
              {file.name}
            </span>
            <span
              style={{
                fontSize: '10px',
                fontWeight: 500
              }}
            >
              {file?.fileSize}
            </span>
          </div>
        </div>
        <div
          style={{
            cursor: 'pointer'
          }}
        >
          <img src={DeleteFileIcon} alt='delete icon' />
        </div>
      </div>
    </div>
  );
};

const FileUpload = ({
  onUploadFile,
  onUploadMultipleFiles,
  uploadedFile,
  onCancel,
  isMultiple = false,
  isModal = false,
  loading = false,
  accept = 'image/jpg, image/png, application/pdf, image/jpeg'
}) => {
  const sendAlert = useSendAlert();

  const [file, setFile] = useState(null);

  const [isUploaded, setIsUploaded] = useState(false);

  const [isExecuted, setIsExecuted] = useState(false);

  const [dragging, setDragging] = useState(false);

  const [dragCounter, setDragCounter] = useState(0);

  const InputRef = useRef(null);

  const dragEnter = event => {
    event.preventDefault();
    event.stopPropagation();
    setDragCounter(val => val + 1);
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const dragOver = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const dragLeave = event => {
    event.preventDefault();
    event.stopPropagation();
    if (dragCounter > 0) {
      return;
    }
    setDragCounter(val => val - 1);
    setDragging(false);
  };

  const drop = event => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      setFile(event.dataTransfer.files[0]);
    }
    event.dataTransfer.clearData();
    setDragCounter(0);
  };

  const openFileSystem = () => {
    InputRef.current.click();
  };

  const validateFile = (file, index) => {
    const fileType = new RegExp('png|jpeg|jpg|pdf');

    if (file?.size > 5 * 1024 * 1024) {
      sendAlert(
        `File${!isNaN(index) ? ` (${index + 1})` : ''} Size is above 5MB`,
        'error'
      );

      return false;
    }

    if (!file?.type || !file?.type.match(fileType)) {
      sendAlert(
        `File${!isNaN(index) ? ` (${index + 1})` : ''} format not supported`,
        'error'
      );

      return false;
    }

    return true;
  };

  const handleUploadFile = event => {
    const {
      target: { files }
    } = event;

    if (files && files.length > 0) {
      if (isMultiple) {
        const newFiles = Array.from(files)?.filter((file, index) => {
          if (!file) {
            return false;
          }

          return validateFile(file, index);
        });

        if (newFiles?.length !== files?.length) {
          return;
        }

        return onUploadMultipleFiles?.(newFiles);
      }

      if (validateFile(files[0])) {
        if (isModal) {
          const file = files[0];

          if (file.type.includes('image')) {
            const reader = new FileReader();

            reader.onloadend = () => {
              file.previewURL = reader.result;

              setFile(file);
            };

            return reader.readAsDataURL(file);
          }

          return setFile(file);
        }

        setFile(files[0]);
      }
    }
  };

  const convertURLtoFile = async (url = '') => {
    if (url) {
      const fileName = extractFilename(url);

      const response = await fetch(url, { mode: 'no-cors' });

      const blob = await response.blob();

      const file = new File([blob], fileName, {
        type: fileTypes[fileName.split('.')[fileName.split('.').length - 1]]
      });

      setIsUploaded(true);

      setFile(file);

      setIsExecuted(true);
    }
  };

  const removeFile = () => {
    if (isUploaded) {
      onCancel?.();
      setIsUploaded(false);
    }
    setFile(null);
  };

  useEffect(() => {
    if (uploadedFile && !file && !isExecuted) {
      convertURLtoFile(uploadedFile);
    }
    // eslint-disable-next-line
  }, [uploadedFile]);

  return (
    <div
      className='file-upload'
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDragOver={dragOver}
      onDrop={drop}
    >
      <div className='file-upload__wrap'>
        {(!isModal || (isModal && !isUploaded)) && (
          <>
            <div className={`file-upload__image${isModal ? ' is-modal' : ''}`}>
              <img src={icon} alt='' className='img-fluid' />
            </div>
            <p className='file-upload__heading'>
              {dragging ? (
                <>Drop here</>
              ) : (
                <>
                  Drop your file here or{' '}
                  <span onClick={openFileSystem}>browse</span>
                  <input
                    accept={accept}
                    hidden
                    multiple={isMultiple}
                    type='file'
                    ref={InputRef}
                    onChange={handleUploadFile}
                  />
                </>
              )}
            </p>
          </>
        )}
        {!isModal || (isModal && file && !isUploaded) ? (
          <>
            <p className='file-upload__text'>
              {file ? file.name : 'JPG, PNG, PDF - 5MB max'}
            </p>

            {file && (
              <div className='file-upload__actions'>
                <div
                  onClick={removeFile}
                  className='item-description__footer__action'
                >
                  {isUploaded ? 'Remove' : 'Cancel'}
                </div>
                <SubmitButton
                  disabled={isUploaded}
                  text={isUploaded ? 'Confirmed' : 'Confirm'}
                  onClick={() => {
                    if (!isUploaded) {
                      onUploadFile && onUploadFile(file, setIsUploaded);
                      setFile(null);
                    }
                  }}
                />
              </div>
            )}
          </>
        ) : file && isUploaded ? (
          <div
            className={`file-upload__result${
              !file?.previewURL ? ' no-image' : ''
            }`}
          >
            {file?.previewURL && (
              <img src={file?.previewURL} alt='' className='img-fluid' />
            )}
            <span>{file.name}</span>
            <button onClick={removeFile}>
              <img src={FileDeleteIcon} alt='' className='img-fluid' />
              <span>Delete</span>
            </button>
          </div>
        ) : (
          <p className='file-upload__text'>
            {file ? file.name : 'JPG, PNG, PDF - 5MB max'}
          </p>
        )}

        {loading && (
          <CustomSpinner
            text={'Uploading...'}
            textSize='1.5rem'
            textColor='var(--color-primary)'
            size='4rem'
            thickness='.6rem'
            background='#e6e3df'
            spinColor='var(--color-blue-dark)'
          />
        )}
      </div>
    </div>
  );
};

export default FileUpload;

import { Grid, InputAdornment, TextField } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useEffect, useState } from 'react';
import { EyeOpen, EyeClosed } from '../../constants/asset-contants';
import { useStyles } from '../../constants/custom-styles';
import checkBrowser from '../../utilities/check-browser';

const Input = ({
  fullWidth = false,
  customLabel,
  xs,
  sm,
  md,
  lg,
  containerClassName,
  children,
  InputProps,
  name,
  defaultCountry,
  customLabelMessage,
  multiline,
  value,
  ...props
}) => {
  const classes = useStyles();
  const [phoneNumberValue, setPhoneNumberValue] = useState('');
  const [displayButton, setDisplayButton] = useState('none');
  const [passwordValue, setPasswordValue] = useState({ showPassword: false });

  const handleClickShowPassword = () => {
    setPasswordValue({
      ...passwordValue,
      showPassword: !passwordValue.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    if (window.document.readyState === 'complete') {
      setDisplayButton('inline-flex');
    }
    //eslint-disable-next-line
  }, [window.document.readyState]);

  useEffect(() => {
    if (name && name.match(new RegExp('phone', 'i'))) {
      if (value?.substring(0, 2) === '+0' || value?.substring(0, 1) === '0') {
        setPhoneNumberValue(`+234${value?.substring(1)}`);
      }
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <Grid
      item
      className={`form-container ${containerClassName}`}
      xs={xs ? xs : 12}
      sm={sm ? sm : 12}
      md={md ? md : fullWidth ? 12 : 6}
      lg={lg ? lg : fullWidth ? 12 : 6}
    >
      {customLabel && (
        <label className='form-item-label' htmlFor={props.name}>
          {customLabel}
        </label>
      )}
      {name && name.match(new RegExp('phone', 'i')) ? (
        <MuiPhoneNumber
          enableLongNumbers
          defaultCountry={defaultCountry || 'ng'}
          value={phoneNumberValue || value}
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            '& button.MuiPhoneNumber-flagButton': {
              display: checkBrowser() === 'Firefox' ? displayButton : ''
            }
          }}
          name={name}
          {...props}
          fullWidth={true}
          classes={{
            TextField: classes.textfield
          }}
          variant={props.variant || 'outlined'}
          inputProps={{
            style: { fontSize: '1.5rem', ...props?.inputProps?.style }
          }}
          type={
            props?.id?.match('password')
              ? passwordValue.showPassword
                ? 'text'
                : 'password'
              : props?.type
          }
          InputProps={
            InputProps
              ? InputProps
              : {
                  endAdornment: props.id?.match('password') && (
                    <InputAdornment position='end'>
                      <img
                        style={{ cursor: 'pointer' }}
                        src={passwordValue.showPassword ? EyeOpen : EyeClosed}
                        className='img-fluid'
                        alt='show or hide password icon'
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      />
                    </InputAdornment>
                  )
                }
          }
        >
          {children}
        </MuiPhoneNumber>
      ) : (
        <>
          <TextField
            name={name}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 }
            }}
            {...props}
            value={value}
            fullWidth={true}
            classes={{
              TextField: classes.textfield
            }}
            multiline={multiline}
            variant={props.variant || 'outlined'}
            inputProps={{
              style: {
                fontSize: '1.5rem',
                padding: !multiline ? '16.5px 14px' : '',
                lineHeight: multiline ? '24px' : '',
                ...props?.inputProps?.style
              },
              ...props?.inputProps
            }}
            type={
              props?.id?.match('password')
                ? passwordValue.showPassword
                  ? 'text'
                  : 'password'
                : props?.type
            }
            InputProps={
              InputProps
                ? InputProps
                : {
                    endAdornment: props.id?.match('password') && (
                      <InputAdornment position='end'>
                        <img
                          style={{ cursor: 'pointer' }}
                          src={passwordValue.showPassword ? EyeOpen : EyeClosed}
                          className='img-fluid'
                          alt='show or hide password icon'
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        />
                      </InputAdornment>
                    )
                  }
            }
          >
            {children}
          </TextField>
          {customLabelMessage && (
            <p
              style={{
                fontWeight: '500',
                fontSize: '10px',
                lineHeight: '20px',
                color: '#545859'
              }}
            >
              {customLabelMessage}
            </p>
          )}
        </>
      )}
    </Grid>
  );
};

export default Input;

import React, { useEffect } from 'react';
import Wrapper from '../../../components/Layouts/wrapper';
import { Avatar, Button, Skeleton } from '@mui/material';
import {
  FaceBookBlue,
  LinkedInBlue,
  TwitterBlue,
  VerifyIcon
} from '../../../constants/asset-contants';
import { useGetUserLazyQuery } from '../../../operations/queries';
import EmptyList from '../../../components/empty-list';
import CustomSpinner from '../../../components/custom-spinner';
import Helmet from 'react-helmet';
import { useHandleCopyLink } from '../../../utilities/copy-link';
import emptyReferralIcon from '../../../assets/svg/referral-empty.svg';

const NewReferral = () => {
  const handleCopyLink = useHandleCopyLink();
  const [getUserDetails, { loading, data }] = useGetUserLazyQuery();
  const responsive = {
    mobile: {
      maximum: 500
    }
  };
  const link = `https://ship.topship.africa/ref/${
    data ? data?.user?.topshipId : ''
  }`;

  const handleShareLink = async social => {
    const socials = {
      facebook: 'Facebook',
      twitter: 'Twitter',
      linkedin: 'LinkedIn'
    };
    switch (social) {
      case socials.facebook:
        window
          .open(`https://web.facebook.com/sharer?u=${link}`, '_blank')
          .focus();
        break;
      case socials.twitter:
        window
          .open(
            `https://twitter.com/intent/tweet?url=${link}&text=Hi, I am using Topship and I would like to refer you to them, Use my referral link here.`,
            '_blank'
          )
          .focus();
        break;
      case socials.linkedin:
        window
          .open(
            `https://www.linkedin.com/sharing/share-offsite/?url=${link}`,
            '_blank'
          )
          .focus();
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line
  }, []);
  return (
    <Wrapper>
      <Helmet>
        <meta name='description' content='Topship Africa Referral' />
        <title>Topship Africa Referral</title>
        <link rel='canonical' href='https://topship.africa/referral' />
      </Helmet>
      <div className='new-referral'>
        <div className='new-referral__wrap'>
          <div className='new-referral__content'>
            <div className='new-referral__content__groups'>
              <div className='new-referral__content__group'>
                <div className='new-referral__content__header'>
                  <h2>Refer a Business, Earn ₦5,000</h2>
                </div>
                <div className='new-referral__content__text'>
                  <span>
                    Share your referral link with a business and earn ₦5,000{' '}
                    {window.innerWidth >= responsive.mobile.maximum && <br />}
                    when they sign up {'&'} book a shipment
                  </span>
                </div>
              </div>
              <div className='new-referral__content__group'>
                <div className='new-referral__content__header'>
                  <h2>Share the referral link</h2>
                </div>
                <div className='new-referral__content__text'>
                  <span>
                    Share the referral link by copying or sharing it on social
                    media
                  </span>
                </div>
              </div>
            </div>
            <div className='new-referral__content__cta'>
              <div className='new-referral__content__cta__link'>
                {loading ? (
                  <CustomSpinner
                    text='Getting your link ...'
                    textSize='1.2rem'
                    textColor='var(--color-primary)'
                    size='2rem'
                    thickness='.3rem'
                    background='#e6e3df'
                    spinColor='var(--color-blue-dark)'
                  />
                ) : (
                  <>
                    <span>{link}</span>
                    <Button variant='text' onClick={() => handleCopyLink(link)}>
                      Copy link
                    </Button>
                  </>
                )}
              </div>
              <div className='new-referral__content__cta__socials'>
                <span
                  className='new-referral__content__cta__social'
                  onClick={() => handleShareLink('Facebook')}
                >
                  <img
                    src={FaceBookBlue}
                    alt='Facebook'
                    className='img-fluid'
                  />
                </span>
                <span
                  className='new-referral__content__cta__social'
                  onClick={() => handleShareLink('Twitter')}
                >
                  <img src={TwitterBlue} alt='Twitter' className='img-fluid' />
                </span>
                <span
                  className='new-referral__content__cta__social'
                  onClick={() => handleShareLink('LinkedIn')}
                >
                  <img
                    src={LinkedInBlue}
                    alt='LinkedIn'
                    className='img-fluid'
                  />
                </span>
              </div>
            </div>
          </div>
          {(!loading && !data?.user?.referredUsers) ||
          data?.user?.referredUsers?.length === 0 ? (
            <EmptyList
              style={{
                width: '100%'
              }}
              emptyIcon={emptyReferralIcon}
              subHeading='You do not have any referrals yet.'
            />
          ) : (
            <div className='new-referral__list'>
              <div className='new-referral__list__wrap'>
                <div className='new-referral__list__header'>
                  <h2>Your referrals</h2>
                </div>
                <div className='new-referral__list__items'>
                  <div className='new-referral__list__items__wrap'>
                    {loading
                      ? [...Array(5).keys()].map(key => (
                          <div key={key} className='new-referral__list__item'>
                            <div className='new-referral__list__item__image'>
                              <Avatar></Avatar>
                            </div>
                            <div className='new-referral__list__item__meta w-50'>
                              <div className='new-referral__list__item__name w-100'>
                                <h3 className='w-100'>
                                  <Skeleton />
                                </h3>
                                <img
                                  src={VerifyIcon}
                                  alt=''
                                  className='img-fluid'
                                />
                              </div>
                              <div className='new-referral__list__item__date w-100'>
                                <span className='w-100'>
                                  <Skeleton />
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : data?.user?.referredUsers
                          ?.sort(
                            (a, b) =>
                              new Date(b?.createdDate).getTime() -
                              new Date(a?.createdDate).getTime()
                          )
                          .slice(0, 5)
                          .map(user => (
                            <div
                              key={user?.topshipId}
                              className='new-referral__list__item'
                            >
                              <div className='new-referral__list__item__image'>
                                <Avatar
                                  src={user?.displayPictureUrl}
                                  alt={user?.fullName}
                                  className='user-avatar'
                                >
                                  {user?.fullName
                                    ?.split(' ')[0]
                                    .charAt()
                                    .toUpperCase()}
                                </Avatar>
                              </div>
                              <div className='new-referral__list__item__meta'>
                                <div className='new-referral__list__item__name'>
                                  <h3>{`${
                                    window.innerWidth <=
                                    responsive.mobile.maximum
                                      ? user?.fullName?.substring(0, 14)
                                      : user?.fullName
                                  }${
                                    window.innerWidth <=
                                      responsive.mobile.maximum &&
                                    user?.fullName?.length > 14
                                      ? '...'
                                      : ''
                                  }`}</h3>
                                  <img
                                    src={VerifyIcon}
                                    alt=''
                                    className='img-fluid'
                                  />
                                </div>
                                <div className='new-referral__list__item__date'>
                                  <span>{`Joined since ${
                                    new Date(user?.createdDate)
                                      .toDateString()
                                      .split(' ')[1]
                                  } ${
                                    new Date(user?.createdDate)
                                      .toDateString()
                                      .split(' ')[2]
                                  }`}</span>
                                </div>
                              </div>
                            </div>
                          ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default NewReferral;

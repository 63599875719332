import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { lightColors } from '../../constants/shipping-rates';
import {
  doorstepOption,
  pickupLocations
} from '../../constants/sea-freight-addresses';

const PickUpOptionCard = ({
  option,
  index = 0,
  selectedPickUpOption,
  handleClick
}) => {
  return (
    <div
      className={`new-get-quote__prompt__options__list__item${
        selectedPickUpOption?.city === option?.city ? ' active' : ''
      }
                    `}
      style={{
        background: lightColors[index % 5],
        padding: '16px'
      }}
      onClick={handleClick}
    >
      <h3
        className={`mb-2`}
        style={{
          fontSize: '14px',
          fontWeight: '700',
          textTransform: 'uppercase',
          color: selectedPickUpOption?.city === option?.city ? '#22428f' : ''
        }}
      >
        {option.city}
      </h3>
      <p
        className='mb-0'
        style={{
          fontSize: '16px',
          fontWeight: 500,
          color: '#545859'
        }}
      >
        {option.address}
      </p>
    </div>
  );
};

const SeaFreightPickupPrompt = ({
  openPrompt,
  setOpenPrompt,
  selectedPickUpOption,
  setSelectedPickUpOption
}) => {
  const onClosePrompt = () => {
    setOpenPrompt(false);
  };

  return (
    <>
      <div
        className={`in-app__prompt fade   ${openPrompt ? ' show' : ''}
      `}
        style={{
          width: '44%',
          height: '70vh',
          maxHeight: '90vh',
          overflowX: 'auto',
          paddingBottom: '15px',
          backgroundColor: '#fff',
          marginTop: '50px'
        }}
      >
        <div className='in-app__prompt__wrap isSaver'>
          <div className='in-app__prompt__header mt-2'>
            <h2>Select Options</h2>
            <IconButton
              onClick={() => {
                setOpenPrompt(false);
              }}
            >
              <p>
                <Close id='close-button' />
              </p>
            </IconButton>
          </div>
          <div
            className={`in-app__prompt__body
              `}
          >
            <p
              style={{
                fontSize: '14px',
                lineHeight: '26px'
              }}
            >
              Shipment can be delivered to your home or picked up from a
              warehouse, please select your preference:
            </p>
            <div className='delivery-options__items'>
              <PickUpOptionCard
                option={doorstepOption}
                handleClick={() => setSelectedPickUpOption(doorstepOption)}
                selectedPickUpOption={selectedPickUpOption}
              />
              <div
                className='my-4 '
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                <span
                  style={{
                    flexGrow: 1,
                    height: '1px',
                    backgroundColor: '#E2E4E8',
                    marginRight: '6px'
                  }}
                ></span>
                <span
                  style={{
                    fontSize: '18px'
                  }}
                >
                  OR
                </span>
                <span
                  style={{
                    flexGrow: 1,
                    height: '1px',
                    backgroundColor: '#E2E4E8',
                    marginLeft: '6px'
                  }}
                ></span>
              </div>

              <div>
                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: '26px'
                  }}
                >
                  Select your preferred pick-up location
                </p>
                <div className='delivery-options__items'>
                  {pickupLocations.map((pickup, index) => {
                    return (
                      <PickUpOptionCard
                        option={pickup}
                        index={index}
                        handleClick={() => setSelectedPickUpOption(pickup)}
                        selectedPickUpOption={selectedPickUpOption}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='in-app__prompt__footer mb-2 mt-1'>
          <div className='in-app__prompt__footer__wrap'>
            <div className='in-app__prompt__footer__actions'>
              <button
                style={{
                  width: '100%',
                  fontWeight: '500 !important'
                }}
                onClick={onClosePrompt}
              >
                Proceed with{' '}
                {selectedPickUpOption?.city
                  ?.toUpperCase()
                  ?.includes?.('DOORSTEP')
                  ? 'Doorstep Delivery'
                  : `Pick-up from ${selectedPickUpOption?.city} Hub`}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`in-app__prompt__backdrop fade${openPrompt ? ' show' : ''}`}
      ></div>
    </>
  );
};

export default SeaFreightPickupPrompt;

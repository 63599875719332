import { PowerSettingsNew } from '@mui/icons-material';
import { Avatar, IconButton } from '@mui/material';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation, Link } from 'react-router-dom';
import {
  TopShipWhiteLogo,
  GatewayLogoWhite,
  NextArrow
} from '../../constants/asset-contants';
import { sidebarLinks } from '../../constants/sidebar-links';
import Client from '../../services/client';
import { useAuthToken } from '../../token';
import { useRecoilState } from 'recoil';
import { loginState, userState } from '../../recoil/atoms';
import {
  useGetBusinessInformation,
  useGetUserLazyQuery
} from '../../operations/queries';
import BusyOverlay from '../busy-overlay';
import { accountType } from '../../constants/authentication';
import CustomSpinner from '../custom-spinner';
import GetHelpModal from '../modals/get-help-modal';

const LogoutModal = ({
  show,
  warningMessage,
  handleCancel,
  handleDelete,
  deleteText
}) => {
  return (
    <div className={`delete-overlay ${show && 'delete-overlay--show'}`}>
      <div
        className={`delete-overlay__content ${
          show && 'delete-overlay__content--show'
        }`}
      >
        <figure>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z' />
          </svg>
        </figure>
        <h2>Are you sure?</h2>
        <p>{warningMessage || 'You will not be able to recover this data'}</p>

        <div className='delete-overlay__buttons'>
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleDelete}>{deleteText || 'Delete'}</button>
        </div>
      </div>
    </div>
  );
};

const Wrapper = ({ children, topbarTitle, activeLink }) => {
  const [getUser, getUserResult] = useGetUserLazyQuery(
    undefined,
    undefined,
    true
  );
  const [headway, setHeadway] = useState({
    element: document.createElement('span'),
    isLoaded: false
  });
  const [user] = useRecoilState(userState);
  const { pathname } = useLocation();
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [open, setOpen] = useState(false);
  const [hamburger, setHamburger] = useState(false);
  const client = Client();
  const [openModal, setOpenModal] = useState(false);
  const [, , eraseToken] = useAuthToken();
  const [, setLoggedIn] = useRecoilState(loginState);
  const [openGetHelpModal, setOpenGetHelpModal] = useState(false);
  const [getBusinessInfo, businessInfoResult] = useGetBusinessInformation();
  const isMobile = window.innerWidth <= 768;
  const businessId = localStorage.getItem('lspId');

  const handleLogout = () => {
    setLoggedIn(false);
    setOpenModal(false);
    eraseToken();
    client.resetStore();
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.delete('isLsp');
    history.push({ search: searchParams.toString() });
    localStorage.removeItem('lspId');
  };

  const handleMenu = () => {
    setOpen(p => !p);
    setTimeout(() => {
      setHamburger(p => !p);
    }, 100);
  };

  const handleCloseMenu = () => {
    if (open) {
      setOpen(false);
      setTimeout(() => {
        setHamburger(false);
      }, 100);
    }
  };

  function loadScript(src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.id = 'topship-headway';
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  useEffect(() => {
    loadScript('https://cdn.headwayapp.co/widget.js')
      .then(() => {
        window.Headway.init({
          selector: '.headway',
          account: 'J0Eq0y',
          callbacks: {
            onWidgetReady: function (widget) {
              setHeadway({
                isLoaded: false,
                element: widget?.elements?.badgeCont
              });
            }
          }
        });
      })
      .catch(error => {
        console.error('Error loading script:', error);
      });
  }, []);

  useEffect(() => {
    if (headway?.element && !headway?.isLoaded) {
      headway?.element?.insertAdjacentHTML(
        'beforeend',
        `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" style='width:50px;height:50px;' >
        <path d="M16.0001 4C21.548 4 26.0466 8.51312 26.0466 14.0811V24.093H5.95361V14.0811C5.95361 8.51312 10.4522 4 16.0001 4ZM13.2094 25.2093H18.7908C18.7908 25.9494 18.4968 26.6593 17.9734 27.1826C17.4501 27.706 16.7403 28 16.0001 28C15.26 28 14.5502 27.706 14.0268 27.1826C13.5034 26.6593 13.2094 25.9494 13.2094 25.2093Z" fill="#545859"/>
      </svg>`
      );
      headway.isLoaded = true;
    }
    // eslint-disable-next-line
  }, [headway?.isLoaded]);

  useEffect(() => {
    const sidebarLink = sidebarLinks.find(s =>
      activeLink ? pathname === activeLink : pathname === s.link
    );
    setTitle(topbarTitle || sidebarLink.name);
  }, [pathname, topbarTitle, activeLink]);

  useLayoutEffect(() => {
    getUser();

    if (businessId !== 'null') {
      getBusinessInfo(businessId);
    }
    //eslint-disable-next-line
  }, [businessId]);

  const loading = getUserResult?.loading;

  const isLspUser = useMemo(
    () => Boolean(!!businessInfoResult?.data && !businessInfoResult?.loading),
    [businessInfoResult]
  );

  const isLspLoggedIn = useMemo(
    () => user?.accountType === accountType[2]?.value,
    [user]
  );

  const imageSrc = useMemo(() => {
    if (isLspUser) {
      return businessInfoResult?.data?.getBusinessInformationByUser?.logo;
    } else if (isLspLoggedIn) {
      if (
        !!user?.businessInformation?.logo &&
        !user.businessInformation?.logo.includes('Gateway')
      ) {
        return user?.businessInformation?.logo;
      } else {
        return GatewayLogoWhite;
      }
    } else {
      return TopShipWhiteLogo;
    }
  }, [isLspUser, isLspLoggedIn, businessInfoResult, user]);

  const links = useMemo(() => {
    return sidebarLinks
      .map(link => {
        // if (link.name === 'VIB') {
        //   if (
        //     !(
        //       user?.accountType === accountType[1]?.value &&
        //       businessId === 'null'
        //     )
        //   ) {
        //     return null;
        //   }
        // }

        if (link.name === 'Settings') {
          if (!(user.accountType === accountType[2]?.value)) {
            return null;
          }
        }

        return (
          <Link
            key={link.name}
            to={{
              pathname: link.link
            }}
            className={`sidebar__link ${
              pathname === link.link ? 'active' : ''
            }`}
          >
            <img
              src={pathname === link.link ? link.activeIcon : link.icon}
              alt={link.name}
            />
            <span aria-label={`${link.label}`}>{link.name}</span>
          </Link>
        );
      })
      .filter(link => link !== null);
  }, [user, pathname]);

  return (
    <>
      <LogoutModal
        show={openModal}
        handleCancel={() => setOpenModal(false)}
        handleDelete={handleLogout}
        warningMessage={'You might have unsaved changes'}
        deleteText={'Log Out'}
      />
      <GetHelpModal
        openModal={openGetHelpModal}
        handleClose={() => setOpenGetHelpModal(false)}
      />
      <BusyOverlay loading={loading} />
      <main className='dashboard__wrapper'>
        <header className={`${open ? 'open' : ''}`}>
          {isMobile ? (
            <div className='header__mobile-wrap'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <div
                    onClick={handleMenu}
                    className={`header__hamburger ${hamburger ? 'open' : ''}`}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>

                  <div
                    style={{
                      paddingLeft: '10px'
                    }}
                    className='header__avatar'
                  >
                    <div className='headway'></div>
                    <Avatar
                      sx={{ width: '35px', height: '35px' }}
                      src={user?.displayPictureUrl}
                      alt={user?.fullName}
                      className='user-avatar'
                    >
                      {user?.fullName?.split(' ')[0].charAt().toUpperCase()}
                    </Avatar>

                    <IconButton
                      id='logout'
                      size='small'
                      className='text-danger'
                      color='inherit'
                      onClick={() => setOpenModal(true)}
                      sx={{
                        transform: 'scale(2)'
                      }}
                    >
                      <PowerSettingsNew />
                    </IconButton>
                  </div>
                </div>
                <button
                  className='get-help-button'
                  onClick={() => setOpenGetHelpModal(true)}
                >
                  <span
                    style={{
                      fontSize: '10px'
                    }}
                  >
                    Get Help
                  </span>
                  <img src={NextArrow} alt='live chat' />
                </button>
              </div>

              <div className='header__title mt-4 '>
                <h1>{title}</h1>
              </div>
            </div>
          ) : (
            <div className='header__wrap'>
              <div
                onClick={handleMenu}
                className={`header__hamburger ${hamburger ? 'open' : ''}`}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className='header__title'>
                <h1>{title}</h1>
              </div>
              <div className='header__avatar'>
                <button
                  className='get-help-button'
                  onClick={() => setOpenGetHelpModal(true)}
                >
                  <span
                    style={{
                      fontSize: '10px'
                    }}
                  >
                    Get Help
                  </span>
                  <img src={NextArrow} alt='live chat' />
                </button>

                <div className='headway'></div>
                <Avatar
                  sx={{ width: '35px', height: '35px' }}
                  src={user?.displayPictureUrl}
                  alt={user?.fullName}
                  className='user-avatar'
                >
                  {user?.fullName?.split(' ')[0].charAt().toUpperCase()}
                </Avatar>

                <IconButton
                  id='logout'
                  size='small'
                  className='text-danger'
                  color='inherit'
                  onClick={() => setOpenModal(true)}
                  sx={{
                    transform: 'scale(2)'
                  }}
                >
                  <PowerSettingsNew />
                </IconButton>
              </div>
            </div>
          )}
        </header>
        <aside id='dashboard__sidebar' className={`${open ? 'open' : ''}`}>
          <div className='sidebar__wrap'>
            <div className='sidebar__header'>
              {businessInfoResult?.loading ? (
                <div>
                  <CustomSpinner
                    text=''
                    textSize='1.5rem'
                    textColor='var(--color-primary)'
                    size='4rem'
                    thickness='.6rem'
                    background='#e6e3df'
                    spinColor='var(--color-blue-dark)'
                  />
                </div>
              ) : isLspUser || isLspLoggedIn ? (
                <div className={`${isLspUser || isLspLoggedIn ? 'w-25' : ''}`}>
                  <img
                    onClick={() => history.push('/')}
                    className='img-fluid w-full'
                    src={imageSrc}
                    alt='business logo'
                  />
                </div>
              ) : (
                <img
                  onClick={() => history.push('/')}
                  className='img-fluid w-full'
                  src={imageSrc}
                  alt='business logo'
                />
              )}

              <div
                onClick={handleMenu}
                className={`sidebar__hamburger ${hamburger ? 'open' : ''}`}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className='sidebar__links'>{links}</div>
            {(isLspUser || isLspLoggedIn) && (
              <div className='sidebar__footer'>
                <p>Powered by</p>
                <img
                  src={TopShipWhiteLogo}
                  alt='Topship'
                  className='img-fluid'
                />
              </div>
            )}
          </div>
        </aside>
        <div
          onClick={() => window.innerWidth <= 768 && handleCloseMenu()}
          className={`dashboard__wrapper__container${hamburger ? ' open' : ''}`}
        >
          {children}
        </div>
      </main>
    </>
  );
};

export default Wrapper;

import React, { useEffect } from 'react';
import debounce from 'lodash.debounce';
import SubmitButton from '../../../../../../components/submit-button';
import BusyOverlay from '../../../../../../components/busy-overlay';
import {
  useGetCitiesMutation,
  useUpdateBusinessMutation
} from '../../../../../../operations/mutations';
import { useRecoilState } from 'recoil';
import { userState } from '../../../../../../recoil/atoms';
import { useFormik } from 'formik';
import CustomAutoComplete from '../../../../../../components/new-dashboard/custom-autocomplete';
import { Box, Grid } from '@mui/material';
import { useGetStatesQuery } from '../../../../../../operations/queries';
import Input from '../../../../../../components/new-dashboard/custom-input';
import * as yup from 'yup';

const BusinessLocation = ({ nextStep, previousStep }) => {
  const [user, setUser] = useRecoilState(userState);
  const { values, errors, handleSubmit, handleChange, setValues } = useFormik({
    initialValues: {
      address: '',
      state: '',
      city: ''
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      address: yup.string().required().label('Address'),
      state: yup.string().required().label('State'),
      city: yup.string().required().label('City')
    }),
    onSubmit: values => {
      updateBusiness({
        businessId: user?.businessInformation?.id,
        update: { location: values }
      });
    }
  });

  const [updateBusiness, updateBusinessResult] = useUpdateBusinessMutation(
    () => {
      setUser({
        ...user,
        businessInformation: {
          ...user.businessInformation,
          location: values
        }
      });

      nextStep();
    }
  );

  const goBack = () => {
    previousStep();
  };

  const [getStates, states] = useGetStatesQuery();
  const [getCities, citiesResult] = useGetCitiesMutation();
  const handleLocationSelect = place => {
    const { name, value } = place;
    handleChange({ target: { name, value } });
  };
  const loading = updateBusinessResult.loading;

  useEffect(() => {
    if (user?.businessInformation?.location) {
      setValues({
        address: user?.businessInformation?.location?.address || '',
        state: user?.businessInformation?.location?.state || '',
        city: user?.businessInformation?.location?.city || ''
      });
    }

    getStates('NG');
    getCities({
      countryCode: 'NG',
      cityName: user?.businessInformation?.location
        ? user?.businessInformation?.location?.city
        : 'a'
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <form
      className='verification__body'
      style={{
        textAlign: 'left'
      }}
    >
      {/* <div className='verification__body'> */}
      <div className={`verification__content`}>
        <div
          style={{
            alignSelf: 'flex-start'
          }}
          className='verification__header'
        >
          <h1
            style={{
              padding: 0
            }}
          >
            Enter your business address
          </h1>
        </div>
        <div className='verification__description'>
          <p
            style={{
              textAlign: 'left',
              padding: 0
            }}
          >
            Please input the address where your company's operation currently
            takes place in
          </p>
        </div>
      </div>
      <div className='sender-info'>
        <Grid container columnSpacing='10px' rowSpacing='16px'>
          <Input
            customLabel={`Business address`}
            name={`address`}
            error={Boolean(errors.address)}
            helperText={errors.address}
            onChange={handleChange}
            value={values.address}
            placeholder='Enter your business address'
            containerClassName='new-addresses__modal__item__input text-left'
            lg={12}
            md={12}
          />
          <CustomAutoComplete
            options={states.data ? states.data?.getStates : []}
            getOptionLabel={option => option?.name}
            isOptionEqualToValue={(option, value) =>
              option?.name === value?.name
            }
            renderOption={(props, option) => (
              <Box
                component='li'
                // sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
                key={props.id}
              >
                {option.name}
              </Box>
            )}
            value={
              states?.data?.getStates?.length
                ? states?.data?.getStates?.find(
                    state =>
                      state.name?.toLowerCase() === values.state?.toLowerCase()
                  )
                : { name: '' }
            }
            onChange={(event, value) =>
              handleLocationSelect({ name: 'state', value: value?.name })
            }
            textfieldProps={{
              lg: 12,
              md: 12,
              name: 'state',
              customLabel: 'State',
              containerClassName: 'new-addresses__modal__item__input',
              error: Boolean(errors.state),
              helperText: errors.state,
              value: values.state
            }}
          />
          <CustomAutoComplete
            options={citiesResult.data.length ? citiesResult.data : []}
            getOptionLabel={option =>
              option?.city
                ?.split(' ')
                ?.map(
                  word =>
                    `${word.charAt().toUpperCase()}${word
                      .substring(1, word.length)
                      ?.toLowerCase()}`
                )
                ?.join(' ')
            }
            isOptionEqualToValue={(option, value) =>
              option?.postalCode
                ? option?.postalCode === values.postalCode &&
                  option?.city === value?.city
                : option?.city === value?.city
            }
            renderOption={(props, option) => (
              <Box
                key={props.id}
                component='li'
                sx={{
                  textTransform: 'capitalize'
                }}
                {...props}
              >
                {`${option?.city?.toLowerCase()}${
                  option?.postalCode ? ` (${option?.postalCode})` : ''
                }`}
              </Box>
            )}
            value={citiesResult.data?.find(
              city => city.city?.toLowerCase() === values.city?.toLowerCase()
            )}
            noOptionsText={
              citiesResult.loading ? 'Cities loading...' : 'City not found'
            }
            onChange={(event, value, reason) => {
              if (!!values.state) {
                if (reason === 'clear') {
                  handleLocationSelect({
                    name: 'city',
                    value: ''
                  });
                } else {
                  handleLocationSelect({
                    name: 'city',
                    value: value?.city?.toLowerCase()
                  });
                }
              }
            }}
            textfieldProps={{
              lg: 12,
              md: 12,
              name: 'city',
              customLabel: 'City',
              containerClassName: 'new-addresses__modal__item__input',
              error: Boolean(errors.city),
              helperText: errors.city,
              value: values.city?.toLowerCase(),
              onChange:
                !!values.state &&
                debounce(event => {
                  getCities({
                    countryCode: 'NG',
                    cityName: event?.target?.value || 'a'
                  });
                }, 500)
            }}
          />
        </Grid>
      </div>
      <div className='verification__action'>
        <div onClick={goBack} className='item-description__footer__action'>
          Go Back
        </div>
        <SubmitButton
          disabled={!values.city && !values.state && !values.address}
          onClick={handleSubmit}
          text={'Continue'}
        />
      </div>
      <BusyOverlay loading={loading} />
    </form>
    // </div>
  );
};

export default BusinessLocation;

import React, { useEffect, useState } from 'react';
import {
  multipleShipmentLinks,
  singleShipmentLinks
} from '../../constants/shipment-links';
import { IconButton, LinearProgress } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom';
import DeleteOverlay from '../delete-overlay';
import { useRecoilState } from 'recoil';
import {
  disableShippingLinkState,
  multipleShipmentRatesState,
  saveShipmentDetailsState,
  saveShipmentRateState,
  warningState
} from '../../recoil/atoms';
import { useMediaQuery } from '@material-ui/core';
import { shipmentDestinationTypes } from '../../constants/book-shipment-contants';
import useClearLocalStorage from '../../utilities/clear-localstorage';
import { checkIsCompleted as checkData } from '../../pages/Dashboard/helper-functions';
import GuidesAndResourcesPrompt from '../modals/guides-and-resources';
import { FileIcon, PrevIcon } from '../../constants/asset-contants';

import ShipmentHeader from '../shipment-header';
import GetHelpModal from '../modals/get-help-modal';
const responsive = { smaller: 1000 };

const responsiveSmallest = { smallest: 830 };

const NewShippingStepsLayout = ({ children }) => {
  const history = useHistory();
  const clearStorage = useClearLocalStorage();
  const { pathname } = useLocation();
  const [, setShow] = useRecoilState(warningState);
  const [openGetHelpModal, setOpenGetHelpModal] = useState(false);
  const [, setCurrentRates] = useRecoilState(saveShipmentRateState);
  const [, setMultipleShipmentRates] = useRecoilState(
    multipleShipmentRatesState
  );
  const [disableShippingLink] = useRecoilState(disableShippingLinkState);
  const [shipmentDetail, setShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  const destinationType = shipmentDestinationTypes.find(
    destination => shipmentDetail.destinationType === destination.value
  );
  const handleClose = () => {
    clearStorage(() => {
      history.push('/');
    });
  };
  const isMobile = useMediaQuery('(max-width:1100px)');

  const [openResources, setOpenResources] = useState(false);
  const shipmentLinks =
    destinationType?.value === shipmentDestinationTypes[1].value
      ? multipleShipmentLinks
      : singleShipmentLinks;
  const checkIsCompleted = index => {
    return checkData(index, shipmentDetail);
  };
  const pushRoute = index => {
    const currentIndex = shipmentLinks.findIndex(
      link => link.link === pathname
    );
    if (currentIndex < index) {
      if (checkIsCompleted(currentIndex)) {
        history.push(shipmentLinks[index].link);
      } else {
        history.push(shipmentLinks[currentIndex].link, { reValidate: true });
      }
    } else {
      history.push(shipmentLinks[index].link);
    }
  };
  useEffect(() => {
    if (destinationType?.value === shipmentDestinationTypes[1].value) {
      if (multipleShipmentLinks.findIndex(link => link.link === pathname) < 2) {
        const cleanMultipleReceivers = shipmentDetail?.multipleReceivers?.map(
          ({
            pricingTier,
            insuranceType,
            shipmentCharge,
            insuranceCharge,
            ...rest
          }) => rest
        );
        setShipmentDetail({
          ...shipmentDetail,
          multipleReceivers: cleanMultipleReceivers
        });
        setMultipleShipmentRates([]);
      }
    } else {
      if (singleShipmentLinks.findIndex(link => link.link === pathname) < 3) {
        setCurrentRates([]);
      }
    }
    // eslint-disable-next-line
  }, [pathname]);
  return (
    <div className={`new-shipping-steps-layout`}>
      <DeleteOverlay
        warningMessage={'You might have unsaved changes'}
        deleteText={'Close'}
        onDelete={handleClose}
      />
      <GetHelpModal
        openModal={openGetHelpModal}
        handleClose={() => setOpenGetHelpModal(false)}
      />
      <GuidesAndResourcesPrompt
        openPrompt={openResources}
        closePrompt={setOpenResources}
      />
      <ShipmentHeader
        shipmentType={
          isMobile
            ? shipmentLinks.find(value => value.link === pathname)?.name
            : 'Book Shipment'
        }
        setShow={setShow}
        setOpenResources={setOpenResources}
        setOpenGetHelpModal={setOpenGetHelpModal}
      />
      <div className='new-shipping-steps-layout__wrap '>
        {/* <div className='new-shipping-steps-layout__header'>
          {isMobile ? (
            <div className='new-shipping-steps-layout__header__wrap'>
              <div
                onClick={() =>
                  history.push(
                    shipmentLinks.find(value => value.link === pathname)
                      .initialLink
                  )
                }
              >
                <img src={PrevIcon} alt='' />
              </div>
              <h1>
                {shipmentLinks.find(value => value.link === pathname)?.name}
              </h1>

              <div>
                <IconButton onClick={() => setShow(true)}>
                  <Close />
                </IconButton>
              </div>
            </div>
          ) : (
            <div className='new-shipping-steps-layout__header__wrap'>
              <h1>Book Shipment</h1>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                  // width: '70%'
                }}
              >
                <IconButton onClick={() => setShow(true)}>
                  <Close />
                </IconButton>
              </div>
            </div>
          )}
        </div> */}
        <div className='new-shipping-steps-layout__body'>
          <div className='new-shipping-steps-layout__body__wrap'>
            <div className='new-shipping-steps-layout__body__label'>
              {shipmentLinks.map((link, index) => {
                const isCompleted = checkIsCompleted(index);

                const currentLinkIndex = shipmentLinks.findIndex(
                  value => value.link === pathname
                );

                return (
                  <span
                    key={link.name}
                    onClick={() => {
                      if (!disableShippingLink || index < currentLinkIndex) {
                        pushRoute(index);
                      }
                    }}
                    className={
                      disableShippingLink && index > currentLinkIndex
                        ? 'disabled'
                        : pathname === link.link
                        ? `active`
                        : index < currentLinkIndex
                        ? isCompleted
                          ? 'completed'
                          : ''
                        : ''
                    }
                  >
                    {window.innerWidth >= responsiveSmallest.smallest &&
                    window.innerWidth <= responsive.smaller
                      ? link.icon
                      : link.name}

                    {index < currentLinkIndex && isCompleted && (
                      <Check
                        className='check'
                        sx={{
                          transform: 'scale(1.5)',
                          '& > path': { fill: 'green' }
                        }}
                      />
                    )}
                  </span>
                );
              })}
            </div>
            <div className='new-shipping-steps-layout__body__progress'>
              <LinearProgress
                variant='determinate'
                value={Math.round(
                  ((shipmentLinks.findIndex(value => value.link === pathname) +
                    1) /
                    shipmentLinks.length) *
                    100
                )}
              />
            </div>
            <div className='new-shipping-steps-layout__body__content'>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewShippingStepsLayout;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useGetUserPayload } from './get-payload';

export const usePageViews = () => {
  const location = useLocation();
  const payload = useGetUserPayload();
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
        gaOptions: {
          userId: payload.username
        }
      });
      window.GA_INITIALIZED = true;
    }

    ReactGA.set({ page: location.pathname });
    ReactGA.set({ location: location.pathname });
    ReactGA.pageview(location.pathname);
    //eslint-disable-next-line
  }, [location]);
};

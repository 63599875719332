import React, { useEffect, useMemo } from 'react';
import { IconButton } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom';
import DeleteOverlay from '../delete-overlay';
import { useRecoilState, useResetRecoilState } from 'recoil';
import {
  chinaImportState,
  filteredShopNShipLinksState,
  shopNShipDetailsState,
  shopNShipValidationState,
  warningState
} from '../../recoil/atoms';
import { LinearProgress } from '@material-ui/core';
import { shopNShipLinks } from '../../constants/shopnship-links';

const NewShopNShipStepsLayout = ({ children, mobileTitle }) => {
  const responsive = { smaller: 1000 };
  const history = useHistory();
  const { pathname } = useLocation();
  const [, setShow] = useRecoilState(warningState);
  const [shopNShipValidation, setShopNShipValidation] = useRecoilState(
    shopNShipValidationState
  );
  const [shopNshipDetails] = useRecoilState(shopNShipDetailsState);
  const [isChinaImport, setIsChinaImport] = useRecoilState(chinaImportState);
  const [filteredLinks, setFilteredLinks] = useRecoilState(
    filteredShopNShipLinksState
  );

  const resetShopNShipDetails = useResetRecoilState(shopNShipDetailsState);
  const resetShopNShipValidation = useResetRecoilState(
    shopNShipValidationState
  );

  const isImportBooking = useMemo(() => {
    if (shopNshipDetails?.deliveryState) {
      return shopNshipDetails?.deliveryState?.toLowerCase() !== 'lagos';
    }
  }, [shopNshipDetails]);

  useEffect(() => {
    const filteredShopNShipLinks = shopNShipLinks.filter(link => {
      if (isChinaImport) {
        return (
          link.name !== 'Shipping Information' &&
          link.name !== 'Shop & Ship Summary' &&
          link.name !== 'Shop & Ship Successful'
        );
      } else {
        return (
          link.name !== 'Order Summary' &&
          link.name !== 'Payment' &&
          link.name !== 'Delivery Address'
        );
      }
    });

    // Add the appropriate payment link
    const multipleLink = {
      name: 'Shop & Ship Multiple Payment',
      link: '/shop-and-ship-multiple-payment'
    };
    const singleLink = {
      name: 'Shop & Ship Payment',
      link: '/shop-and-ship-payment'
    };
    if (isImportBooking) {
      if (
        !filteredShopNShipLinks.some(link => link.name === multipleLink.name)
      ) {
        filteredShopNShipLinks.push(multipleLink);
      }
    } else {
      if (!filteredShopNShipLinks.some(link => link.name === singleLink.name)) {
        filteredShopNShipLinks.push(singleLink);
      }
    }

    setFilteredLinks(filteredShopNShipLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopNshipDetails.shippingFrom, isImportBooking, isChinaImport]);

  const handleClose = () => {
    resetShopNShipDetails();
    localStorage.clear();
    history.push('/');
  };

  const pushRoute = index => {
    if (!shopNshipDetails.id) {
      if (filteredLinks.findIndex(link => link.link === pathname) < index) {
        if (shopNShipValidation.isValidated) {
          history.push(filteredLinks[index].link);
          resetShopNShipValidation();
        } else {
          setShopNShipValidation({
            ...shopNShipValidation,
            enforceValidation: true
          });
        }
      } else {
        history.push(filteredLinks[index].link);
      }
    }
  };
  const screenSize = window.innerWidth;

  return (
    <div className={`new-shipping-steps-layout`}>
      <DeleteOverlay
        warningMessage={'You might have unsaved changes'}
        deleteText={'Close'}
        onDelete={handleClose}
      />
      <div className='new-shipping-steps-layout__wrap'>
        <div className='new-shipping-steps-layout__header'>
          <div className='new-shipping-steps-layout__header__wrap'>
            <h1>{isChinaImport ? 'China Imports' : 'Shop & Ship'}</h1>
            <IconButton onClick={() => setShow(true)}>
              <Close
                style={{
                  fontSize: screenSize < 500 ? '14px' : ''
                }}
              />
            </IconButton>
          </div>
        </div>
        <div className='new-shipping-steps-layout__body'>
          <div className='new-shipping-steps-layout__body__wrap'>
            <div className='new-shipping-steps-layout__body__label'>
              {filteredLinks
                // .slice(0, filteredLinks.length - 1)
                .map((link, index) => (
                  <span
                    key={link.name}
                    onClick={() =>
                      pathname === '/shop-and-ship-delivery-address'
                        ? null
                        : pushRoute(index)
                    }
                    className={
                      pathname === link.link
                        ? `active`
                        : index <
                          filteredLinks.findIndex(
                            value => value.link === pathname
                          )
                        ? 'completed'
                        : ''
                    }
                  >
                    {window.innerWidth <= responsive.smaller ? '' : link.name}
                    {index <
                      filteredLinks.findIndex(
                        value => value.link === pathname
                      ) && (
                      <Check
                        className='check'
                        sx={{
                          transform: 'scale(1.5)',
                          '& > path': { fill: 'green' }
                        }}
                      />
                    )}
                  </span>
                ))}
            </div>

            {!pathname.includes('/shop-and-ship-welcome') ? (
              <div className='new-shipping-steps-layout__body__progress'>
                <LinearProgress
                  variant='determinate'
                  value={Math.round(
                    ((filteredLinks
                      .filter(
                        link => !link.link.includes('/shop-and-ship-welcome')
                      )
                      .findIndex(value => value.link === pathname) +
                      1) /
                      filteredLinks.length) *
                      100
                  )}
                />
              </div>
            ) : null}
            <div className='new-shipping-steps-layout__body__content'>
              <h3 className='mobile-header'>{mobileTitle}</h3>

              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewShopNShipStepsLayout;

import {
  CustomerServiceIcon,
  Customs,
  InsuranceIcon,
  ItemValue,
  Label,
  LiveTrackingIcon,
  RatesIcon,
  Returns
} from './asset-contants';

const packagesArray = [
  {
    title: 'Budget',
    amount: 'NGN 64,000',
    tags: [
      'Delivery in 7 - 15 working days',
      'Doorstep Delivery',
      'Inclusive of customs clearance charges'
    ],
    isRecommended: true,
    weighsLess: true,
    additionalNote: `Best used for heavy packages`
  },
  {
    title: 'Budget',
    amount: 'NGN 17,000',
    tags: [
      'Delivery in 7 - 15 working days',
      'Doorstep Delivery',
      'Inclusive of customs clearance charges'
    ],
    isRecommended: true,
    weighsMore: true,
    additionalNote: `Best used for heavy packages.`
  },
  {
    title: 'Saver',
    amount: 'NGN 28,485',
    tags: [
      'Delivery in 7 - 10 working days',
      'Ships to 150+ cities worldwide (including cities in Nigeria)',
      'Doorstep Delivery',
      'Not inclusive of customs clearance charges'
    ],
    weighsLess: true
  },
  {
    title: 'Saver',
    amount: 'NGN 123,985',
    tags: [
      'Delivery in 7 - 10 working days',
      'Ships to 150+ cities worldwide (including cities in Nigeria)',
      'Doorstep Delivery',
      'Not inclusive of customs clearance charges'
    ],
    weighsMore: true
  },
  {
    title: 'Express',
    amount: 'NGN 55,655',
    tags: [
      'Delivery in 3 - 7 working days',
      'Ships to 150+ cities worldwide (including cities in Nigeria)',
      'Doorstep Delivery',
      'Not inclusive of customs clearance charges'
    ],
    weighsLess: true
  },
  {
    title: 'Express',
    amount: 'NGN 280,187',
    tags: [
      'Delivery in 3 - 7 working days',
      'Ships to 150+ cities worldwide (including cities in Nigeria)',
      'Doorstep Delivery',
      'Not inclusive of customs clearance charges'
    ],
    weighsMore: true
  }
];

const deliveryBenefitsArray = [
  {
    icon: CustomerServiceIcon,
    content: 'Reliable  and friendly customer support representatives'
  },
  {
    icon: RatesIcon,
    content: 'Affordable Rates'
  },
  {
    icon: LiveTrackingIcon,
    content: 'Live-tracking and notifications on shipments'
  },
  {
    icon: InsuranceIcon,
    content: 'Flexible insurance options'
  }
];

export const flatRates = [
  '0.5kg - 2kg - ₦50,000',
  '2.1kg - 3kg - ₦55,000',
  '3.1kg - 5kg - ₦70,000',
  '5.1kg - 9.4kg - ₦107,000'
];

// Non-flat rates
export const nonFlatRates = ['9.5kg and above - ₦14,500/kg'];

const chinaListNotice = [
  {
    content:
      'Please note that the standard 14 - 24 days delivery window will begin when your package is shipped out of our China partner hub'
  },
  {
    content: `All packages are shipped out of our Lagos hub. Delivery to the receiver's address will be charged separately`
  }
];

const shipmentNotice = [
  {
    icon: Label,
    title: 'Proper Labelling',
    content: `If you selected a pick-up, label your package with your tracking
                number for easy identification and faster processing`
  },
  {
    icon: Customs,
    title: 'Customs Clearance Fees',
    content: `Most international shipments (exports or imports) will require
                you to pay customs clearance fees (usually 30 - 50% of item
                value). To avoid this fee, consider shipping with Topship's
                Budget service (exports) or Shop & Ship service (imports).`
  },
  {
    icon: Returns,
    title: 'Returns',
    content: `If we need to return your package due to the inability to pay
                customs fees or provide required shipping documents, an
                additional return fee will be charged to the sender.`
  },
  {
    icon: ItemValue,
    title: 'Declared Item Value',
    content: `Declaring a lower value than your item is worth could attract an
                additional fine from customs officials and does not necessarily
                reduce customs clearance charges. Please declare honestly.`
  }
];
export {
  deliveryBenefitsArray,
  packagesArray,
  shipmentNotice,
  chinaListNotice
};

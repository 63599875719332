import { useQuery, useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import ReactGA from 'react-ga';
import {
  GET_WALLET_BALANCE,
  GET_TRANSACTION_REFERENCE,
  GET_USER,
  GET_WALLET,
  GET_TRANSACTIONS,
  GET_SHIPMENTS,
  GET_SHIPMENT_DETAILS,
  GET_SAVED_ADDRESSES,
  GET_CURRENT_MONTH_SHIPMENTS,
  GET_SHIPMENT_RATES,
  TRACK_SHIPMENT,
  GET_BANK_LIST,
  GET_BANK_ACCOUNT_NAME,
  GET_BANK_ACCOUNTS,
  GET_WITHDRAWAL_LOGS,
  GET_COUPON,
  GET_COUNTRIES,
  GET_STATES,
  GET_CITIES,
  GET_SHOPNSHIP,
  GET_SHOPNSHIPS,
  GET_USER_SHOPNSHIP_FINAL_CHARGES,
  GET_SHOPNSHIP_CURRENCY_RATES,
  USER_ROUNDUP_DATA,
  GET_LOGISTICS_BUSINESS_CONFIGS,
  GET_BUSINESS_INFORMATION,
  GET_SHIPMENT_QUOTE,
  GET_FOREIGN_RATES,
  GET_PAYMENT_INTENT,
  GET_VIRTUAL_BANK_ACCOUNT,
  GET_SHOPNSHIP_RATES,
  GET_PICKUP_RATES,
  GET_SESSION,
  GET_LAST_MILE_RATES,
  GET_PAYPAL_CHEKOUT_URL
} from './definitions/queries.def';
import { useRecoilState } from 'recoil';
import {
  transactionReferenceState,
  walletState,
  saveShipmentRateState,
  saveShipmentDetailsState,
  allBankDetailsState,
  quotesLocationDataState,
  shipmentNumberState,
  shipmentDataState,
  shipmentDataLoadingState,
  multipleShipmentRatesState,
  multipleRateLoadingState,
  shopnshipListState,
  userState,
  shopnshipRateState,
  userRoundUpDataState,
  saveShopNShipRateState,
  chinaImportListState,
  savePickupRateState,
  saveLastMileState,
  selectedRateState,
  disableShippingLinkState
} from '../recoil/atoms';
import shipmentStatusDictionary from '../constants/shipment-rate-modes-dictionary';
import { parseGraphqlError } from '../utilities/parse-graphql-error';
import { formatErrorMessage } from '../utilities/format-error';
import { useSendAlert } from '../utilities/send-alert';
import { useGetUserPayload } from '../utilities/get-payload';
import { shipmentOptions } from '../constants/book-shipment-contants';
import { useMutation } from 'react-query';
import { toFixed } from '../utilities/to-fixed';

const useGetWalletBalanceQuery = onCompleted => {
  return useQuery(GET_WALLET_BALANCE, {
    onCompleted: data => {
      localStorage.setItem('tempToken', null);
      onCompleted && onCompleted(data);
    },
    onError: error => {}
  });
};

const useGetUserQuery = () => {
  const userPayload = useGetUserPayload();
  const [shipmentDetails, setShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  return useQuery(GET_USER, {
    variables: { sub: userPayload.sub },
    onCompleted: data => {
      const { fullName, email, phoneNumber } = data.user;
      setShipmentDetail({
        ...shipmentDetails,
        senderDetail: {
          ...shipmentDetails.senderDetail,
          name: fullName,
          email,
          phoneNumber
        }
      });
    },
    fetchPolicy: 'no-cache'
  });
};

const useUserRoundupDataQuery = () => {
  const [, setRoundUpData] = useRecoilState(userRoundUpDataState);
  return useLazyQuery(USER_ROUNDUP_DATA, {
    onCompleted: data => {
      setRoundUpData(data?.getUserRoundupData);
    }
  });
};

const useGetUserLazyQuery = (setInitialValues, type, isProfile) => {
  const userPayload = useGetUserPayload();
  const [, setUser] = useRecoilState(userState);
  const [shipmentDetails, setShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  const selectedOption = shipmentOptions.find(
    option => shipmentDetails?.shipmentRoute === option.value
  );
  const [getUser, getUserResult] = useLazyQuery(GET_USER, {
    onCompleted: data => {
      const { fullName, email, phoneNumber } = data.user;

      const isImport = Boolean(
        selectedOption?.id === 2 &&
          !shipmentDetails?.receiverDetail?.name &&
          type &&
          type === 'receiver'
      );

      const isExport = Boolean(
        selectedOption?.id === 1 &&
          !shipmentDetails?.senderDetail?.name &&
          type &&
          type === 'sender'
      );

      if ((isImport || isExport) && !!setInitialValues) {
        setInitialValues(values => ({
          ...values,
          name: fullName,
          email,
          phoneNumber
        }));
      }

      if ((isImport || isExport) && !setInitialValues) {
        const key =
          selectedOption?.id === 2 ? 'receiverDetail' : 'senderDetail';
        setShipmentDetail({
          ...shipmentDetails,
          [key]: {
            ...shipmentDetails[key],
            name: fullName,
            email,
            phoneNumber
          }
        });
      }

      if (isProfile) {
        setUser(data.user);
      }
    },
    fetchPolicy: 'no-cache'
  });

  const getUserDetails = () => {
    getUser({
      variables: {
        sub: userPayload.sub
      }
    });
  };
  return [getUserDetails, getUserResult];
};

const useGetCurrentMonthShipments = () => {
  const [getMonthlyShipments, getMonthlyShipmentsResult] = useLazyQuery(
    GET_CURRENT_MONTH_SHIPMENTS,
    {
      onCompleted: data => {}
    }
  );

  const getMonthlyShipmentNumbers = shipmentType => {
    getMonthlyShipments({
      variables: {
        shipmentType: shipmentType
      }
    });
  };

  return [getMonthlyShipmentNumbers, getMonthlyShipmentsResult];
};

const useGenerateTransactionReferenceQuery = () => {
  const [, setReference] = useRecoilState(transactionReferenceState);
  const sendAlert = useSendAlert();
  const [generateReference, generateReferenceResult] = useLazyQuery(
    GET_TRANSACTION_REFERENCE,
    {
      onCompleted: data => {
        const { id } = data.generateTransactionReference;
        setReference(id);
      },
      onError: error => {
        sendAlert(parseGraphqlError(error), 'error');
      }
    }
  );

  const generateTransactionReference = (id, transactionType) => {
    generateReference({
      variables: {
        transaction: {
          id,
          transactionType
        }
      }
    });
  };

  return [generateTransactionReference, generateReferenceResult];
};

const useGetWalletQuery = () => {
  const [, setWalletState] = useRecoilState(walletState);

  return useQuery(GET_WALLET, {
    onCompleted: data => {
      if (data) {
        const { id: walletId, totalReceived, totalSent } = data.getWallet;
        setWalletState({
          walletId,
          walletBalance: totalReceived - totalSent
        });
      }
    }
  });
};

const useGetTransactions = () => {
  return useQuery(GET_TRANSACTIONS, {
    onCompleted: data => {}
  });
};

const useGetTransactionsLazyQuery = onCompleted => {
  const sendAlert = useSendAlert();
  const [getTransactions, transactionsResults] = useLazyQuery(
    GET_TRANSACTIONS,
    {
      onCompleted: data => onCompleted && onCompleted(data),
      onError: error => sendAlert(parseGraphqlError(error), 'error')
    }
  );

  const getTransactionList = filter => {
    getTransactions({ variables: { filter } });
  };

  return [getTransactionList, transactionsResults];
};

const useGetUserShipmentsQuery = () => {
  // const history = useHistory();
  // const [, setOpenRoundUpPrompt] = useRecoilState(openRoundUpPromptState);
  const [getShipments, getUserShipmentsResult] = useLazyQuery(GET_SHIPMENTS, {
    // onCompleted: data => {
    //   if (
    //     data?.getShipments?.totalCount &&
    //     history.location.state?.openRoundUpPrompt
    //   ) {
    //     setOpenRoundUpPrompt(true);
    //     history.replace({ state: {} });
    //   }
    // }
  });
  const getUserShipments = filter => {
    getShipments({
      variables: {
        filter
      }
    });
  };
  return [getUserShipments, getUserShipmentsResult];
};

const useGetShipmentsQuery = () => {
  const client = useApolloClient();
  const sendAlert = useSendAlert();
  const [, setShipmentNumber] = useRecoilState(shipmentNumberState);
  const [, setShipmentData] = useRecoilState(shipmentDataState);
  const [, setShipmentDataLoading] = useRecoilState(shipmentDataLoadingState);

  const getShipmentsQuery = async (page, take, search, otherFilters) => {
    setShipmentDataLoading(true);

    const { data, error } = await client.query({
      query: GET_SHIPMENTS,
      variables: {
        filter: {
          page,
          take,
          keyword: search,
          ...(otherFilters || {})
        }
      },
      fetchPolicy: 'no-cache'
    });

    setShipmentNumber(data?.getShipments?.totalCount);
    setShipmentData(data);

    if (data) {
      setShipmentDataLoading(false);
    }

    if (error) {
      sendAlert(parseGraphqlError(error), 'error');
    }

    return data;
  };

  return getShipmentsQuery;
};

const useGetShopnshipsQuery = isChinaImport => {
  const client = useApolloClient();
  const sendAlert = useSendAlert();
  const [, setShopnshipList] = useRecoilState(shopnshipListState);
  const [, setChinaImportList] = useRecoilState(chinaImportListState);
  const [, setShipmentDataLoading] = useRecoilState(shipmentDataLoadingState);

  const getShopnshipsQuery = async (page, take, search, otherFilters) => {
    setShipmentDataLoading(true);

    const { data, error } = await client.query({
      query: GET_SHOPNSHIPS,
      variables: {
        filter: {
          page,
          take,
          keyword: search,

          ...(otherFilters || {})
        }
      },
      fetchPolicy: 'no-cache'
    });

    if (isChinaImport) {
      setChinaImportList(data?.getShopnships);
    } else {
      setShopnshipList(data?.getShopnships);
    }

    if (data) {
      setShipmentDataLoading(false);
    }

    if (error) {
      setShipmentDataLoading(false);
      sendAlert(parseGraphqlError(error), 'error');
    }

    return data;
  };

  return getShopnshipsQuery;
};

const useGetShipmentDetailsQuery = () => {
  const [getShipmentDetails, getShipmentResult] = useLazyQuery(
    GET_SHIPMENT_DETAILS,
    {
      onCompleted: data => {}
    }
  );

  const getShipment = id => {
    return getShipmentDetails({
      variables: { shipmentId: id }
    });
  };

  return [getShipment, getShipmentResult];
};

const useGetSavedAddresses = (query, onCompleted, fetchPolicy) => {
  const [getAddresses, getAddressesResults] = useLazyQuery(
    query || GET_SAVED_ADDRESSES,
    {
      onCompleted: data => {
        onCompleted && onCompleted(data);
      },
      ...(fetchPolicy && { fetchPolicy })
    }
  );
  const getAddressesList = filter => {
    getAddresses({ variables: { filter } });
  };
  return [getAddressesList, getAddressesResults];
};

const useGetShopNShipRateQuery = ({ onCompleted, onError }) => {
  const sendAlert = useSendAlert();
  const [, setShopNShipRates] = useRecoilState(saveShopNShipRateState);
  const [getShopNShipRate, getShopNShipRateResult] = useLazyQuery(
    GET_SHOPNSHIP_RATES,
    {
      onCompleted: data => {
        setShopNShipRates(data.getShopnshipRates);

        onCompleted?.(data);
      },
      onError: error => {
        onError && onError(formatErrorMessage(error));
        sendAlert(parseGraphqlError(error), 'error');
      },
      fetchPolicy: 'no-cache'
    }
  );

  const getShopNShipRates = input => {
    getShopNShipRate({
      variables: {
        input
      }
    });
  };

  return [getShopNShipRates, getShopNShipRateResult];
};

const useGetPickupRatesQuery = ({ onCompleted, onError }) => {
  const sendAlert = useSendAlert();
  const [, setPickupRates] = useRecoilState(savePickupRateState);
  const [getPickupRate, getPickupRateResult] = useLazyQuery(GET_PICKUP_RATES, {
    onCompleted: data => {
      setPickupRates(data?.getPickupRates);

      onCompleted && onCompleted();
    },
    onError: error => {
      onError && onError(formatErrorMessage(error));
      sendAlert(parseGraphqlError(error), 'error');
    }
  });

  const getPickupRates = input => {
    getPickupRate({
      variables: {
        input
      }
    });
  };

  return [getPickupRates, getPickupRateResult];
};

const useGetLastMileRatesQuery = ({ onCompleted, onError, fetchPolicy }) => {
  const sendAlert = useSendAlert();
  const [, setLastMileRates] = useRecoilState(saveLastMileState);
  const [getLastMileRate, getLastMileRateResult] = useLazyQuery(
    GET_LAST_MILE_RATES,
    {
      onCompleted: data => {
        setLastMileRates(data?.getLastMileRates);

        onCompleted && onCompleted();
      },
      onError: error => {
        onError && onError(formatErrorMessage(error));
        sendAlert(parseGraphqlError(error), 'error');
      },
      ...(fetchPolicy && { fetchPolicy })
    }
  );

  const getLastMileRates = input => {
    getLastMileRate({
      variables: {
        input
      }
    });
  };

  return [getLastMileRates, getLastMileRateResult];
};

const useGetShipmentRateQuery = ({
  transactionType,
  destinationType,
  onCompleted,
  onError,
  handleRateSelection
}) => {
  const sendAlert = useSendAlert();

  const [, setCurrentRates] = useRecoilState(saveShipmentRateState);

  const [selectedRate] = useRecoilState(selectedRateState);

  const [shipmentDetail, setShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );

  const [disableShippingLink, setDisableShippingLink] = useRecoilState(
    disableShippingLinkState
  );

  const [getRate, getShipmentRateResult] = useLazyQuery(GET_SHIPMENT_RATES, {
    onCompleted: data => {
      const filtered = data?.getShipmentRate?.filter(shipment =>
        shipmentDetail?.items?.some(item =>
          new RegExp('gadgets|beauty|ComputerSupplies|Document', 'i').test(
            item?.category
          )
        )
          ? shipment?.pricingTier !== 'Premium'
          : shipment
      );
      setCurrentRates(
        transactionType === 'quote' ? data?.getShipmentRate : filtered
      );
      if (handleRateSelection && transactionType === 'quote') {
        if (data.getShipmentRate?.length === 1) {
          handleRateSelection(data.getShipmentRate[0]);
        }
      }
      if (handleRateSelection && transactionType === 'shipment') {
        const rate = filtered?.find(
          rate =>
            shipmentStatusDictionary(rate?.pricingTier) ===
            shipmentDetail.pricingTier
        );
        if (data.getShipmentRate.length === 1) {
          handleRateSelection(data.getShipmentRate[0]);
        }

        if (rate) {
          handleRateSelection(rate);

          if (selectedRate) {
            setShipmentDetail({
              ...shipmentDetail,
              shipmentCharge: rate?.cost
            });

            if (disableShippingLink) {
              setDisableShippingLink(false);
            }
          }
        }
      }
      onCompleted && onCompleted();
    },
    onError: error => {
      onError && onError(formatErrorMessage(error));
      if (transactionType === 'shipment') {
        if (destinationType === 'sender') {
          ReactGA.exception({
            description: 'Senders address not supported',
            fatal: true
          });
        }

        if (destinationType === 'receiver') {
          ReactGA.exception({
            description: 'Receivers address not supported',
            fatal: true
          });
        }
      }

      sendAlert(parseGraphqlError(error), 'error');
    }
  });

  const getShipmentRates = shipmentDetail => {
    getRate({
      variables: {
        shipmentDetail
      }
    });
  };

  return [getShipmentRates, getShipmentRateResult];
};

const useGetShipmentRateQueryMultiple = (receivers, setInitialValues) => {
  const allRates = [];
  const client = useApolloClient();
  const businessId = localStorage.getItem('lspId');

  const sendAlert = useSendAlert();
  const [, setMultipleShipmentRates] = useRecoilState(
    multipleShipmentRatesState
  );
  const [, setMultipleRateLoading] = useRecoilState(multipleRateLoadingState);
  const [{ senderDetail: sender }] = useRecoilState(saveShipmentDetailsState);
  const getShipmentRates = async shipmentDetail => {
    const { data, error } = await client.query({
      query: GET_SHIPMENT_RATES,
      variables: {
        shipmentDetail
      },
      fetchPolicy: 'no-cache'
    });

    if (error) {
      formatErrorMessage(error);
      sendAlert(parseGraphqlError(error), 'error');
      setMultipleRateLoading(false);
    }
    return data;
  };

  const handleRateSelection = (rate, index) => {
    setInitialValues(initialValues => {
      const updatedValue = {
        ...initialValues[index],
        pricingTier: shipmentStatusDictionary(rate?.mode),
        shipmentCharge: toFixed(rate?.cost)
      };
      return initialValues.map((value, valueIndex) =>
        valueIndex === index ? updatedValue : value
      );
    });
  };

  const getMultipleShipmentRates = async () => {
    setMultipleRateLoading(true);

    return await Promise.all(
      receivers.map(async receiver => {
        let totaWeightValue = 0;
        receiver.items.forEach(item => {
          if (item.isVolumetric) {
            const volumetricWeight = Number(
              ((item.width * item.height * item.length) / 5000).toFixed(2)
            );
            totaWeightValue += volumetricWeight;
          } else {
            totaWeightValue += item.weight;
          }
        });

        const requestRatesData = {
          senderDetails: {
            cityName: sender?.city,
            countryCode: sender?.countryCode?.toUpperCase(),
            postalCode: null
          },
          receiverDetails: {
            cityName: receiver.receiverDetail.city,
            countryCode: receiver.receiverDetail.countryCode,
            postalCode: receiver?.receiverDetail?.postalCode
          },
          totalWeight: parseFloat(totaWeightValue, 10),
          lsp: businessId !== 'null' ? businessId : null
        };

        return await getShipmentRates(requestRatesData).catch(err => {
          setMultipleRateLoading(false);

          formatErrorMessage(err);
          sendAlert(parseGraphqlError(err), 'error');
        });
      })
    ).then(response => {
      const filteredRes = response?.map((res, resItem) => {
        return {
          ...res,
          getShipmentRate: receivers?.[resItem]?.items?.some(item =>
            new RegExp('gadgets|beauty|ComputerSupplies|Document', 'i').test(
              item?.category
            )
          )
            ? res?.getShipmentRate?.filter(
                rate => rate?.pricingTier !== 'Premium'
              )
            : res?.getShipmentRate
        };
      });

      const formattedResponse = filteredRes?.map((res, idx) => {
        return {
          index: idx,
          list: res && res?.getShipmentRate ? res?.getShipmentRate : []
        };
      });

      formattedResponse.forEach((rate, rateIndex) => {
        if (
          rate.list.length === 1 &&
          (!receivers[rateIndex]?.pricingTier ||
            receivers[rateIndex]?.pricingTier === '')
        ) {
          handleRateSelection(rate.list[0], rateIndex);
        }

        allRates.push(rate);
      });

      setMultipleRateLoading(false);
      setMultipleShipmentRates(allRates);
      return response;
    });
  };

  return getMultipleShipmentRates;
};

const useTrackShipmentLazyQuery = () => {
  const sendAlert = useSendAlert();
  const [trackShipment, trackShipmentResult] = useLazyQuery(TRACK_SHIPMENT, {
    onCompleted: data => {},
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
    },
    fetchPolicy: 'no-cache'
  });

  const getTrackingDetails = trackingId => {
    trackShipment({
      variables: {
        trackingId
      }
    });
  };

  return [getTrackingDetails, trackShipmentResult];
};

const useTrackShipmentQuery = trackingId => {
  const sendAlert = useSendAlert();
  return useQuery(TRACK_SHIPMENT, {
    variables: {
      trackingId
    },
    onError: error => {
      sendAlert(parseGraphqlError(error), 'error');
    }
  });
};

const useGetBankListQuery = () => {
  return useQuery(GET_BANK_LIST, {
    onCompleted: data => {}
  });
};

const useGetBankAccountNameQuery = () => {
  const sendAlert = useSendAlert();
  const [getBankName, getBankNameResult] = useLazyQuery(GET_BANK_ACCOUNT_NAME, {
    onError: error => sendAlert(parseGraphqlError(error), 'error')
  });

  const getAccountName = bankDetail => {
    getBankName({
      variables: { bankDetail }
    });
  };
  return [getAccountName, getBankNameResult];
};

const useGetBankAccountListQuery = logs => {
  const sendAlert = useSendAlert();
  const [getLogs] = useGetWithdrawalLogsLazyQuery();
  const [, setAllBanks] = useRecoilState(allBankDetailsState);
  return useQuery(GET_BANK_ACCOUNTS, {
    onCompleted: data => {
      const bankList = data.getUserBankDetails;
      setAllBanks(bankList);
      if (logs) {
        getLogs();
      }
    },
    onError: error => sendAlert(parseGraphqlError(error), 'error')
  });
};

const useGetBankAccountListLazyQuery = logs => {
  const sendAlert = useSendAlert();

  const [getAccountList, getAccountListResult] = useLazyQuery(
    GET_BANK_ACCOUNTS,
    {
      onError: error => sendAlert(parseGraphqlError(error), 'error')
    }
  );

  const getBankAccounts = () => {
    getAccountList();
  };
  return [getBankAccounts, getAccountListResult];
};

const useGetWithdrawalLogsLazyQuery = () => {
  const sendAlert = useSendAlert();
  const [getLogs, getLogsResult] = useLazyQuery(GET_WITHDRAWAL_LOGS, {
    onCompleted: data => {},
    onError: error => sendAlert(parseGraphqlError(error), 'error')
  });

  const getWithdrawalLogs = filter => {
    getLogs({ variables: { filter } });
  };

  return [getWithdrawalLogs, getLogsResult];
};

const useGetCouponQuery = onCompleted => {
  const sendAlert = useSendAlert();

  const [getCoupon, couponResult] = useLazyQuery(GET_COUPON, {
    onError: error => sendAlert(parseGraphqlError(error), 'error'),
    onCompleted: data => {
      onCompleted && onCompleted(data);
    },
    fetchPolicy: 'no-cache'
  });

  const getCouponValue = code => {
    getCoupon({ variables: { code } });
  };

  return [getCouponValue, couponResult];
};

const useGetCountriesQuery = () => {
  return useQuery(GET_COUNTRIES, {
    onCompleted: data => {}
  });
};

const useGetCountriesLazyQuery = (onCompleted, fetchPolicy = undefined) => {
  const [getCountries, countriesResult] = useLazyQuery(GET_COUNTRIES, {
    onCompleted: data => {
      const countries = data.getCountries;
      onCompleted && onCompleted(countries);
    },
    fetchPolicy
  });

  return [getCountries, countriesResult];
};

const useGetStatesQuery = (locationType, locationDataState, onCompleted) => {
  const sendAlert = useSendAlert();
  const [quotesLocation, setQuotesLocation] = useRecoilState(
    locationDataState || quotesLocationDataState
  );
  const [getStates, statesResult] = useLazyQuery(GET_STATES, {
    onError: error => sendAlert(parseGraphqlError(error), 'error'),
    onCompleted: data => {
      setQuotesLocation({
        ...quotesLocation,
        [locationType]: {
          ...quotesLocation[locationType],
          states: data.getStates
        }
      });
      onCompleted && onCompleted(data?.getStates);
    },
    fetchPolicy: 'no-cache'
  });

  const getStatesList = countryCode => {
    getStates({ variables: { countryCode } });
  };

  return [getStatesList, statesResult];
};

const useGetCitiesQuery = (locationType, locationDataState) => {
  const [quotesLocation, setQuotesLocation] = useRecoilState(
    locationDataState || quotesLocationDataState
  );
  const sendAlert = useSendAlert();
  const [getCities, citiesResult] = useLazyQuery(GET_CITIES, {
    onError: error => sendAlert(parseGraphqlError(error), 'error'),
    onCompleted: data => {
      setQuotesLocation({
        ...quotesLocation,
        [locationType]: {
          ...quotesLocation[locationType],
          cities: data.getCities
        }
      });
    },
    fetchPolicy: 'no-cache'
  });

  const getCitiesList = countryCode => {
    getCities({ variables: { countryCode } });
  };

  return [getCitiesList, citiesResult];
};

const useGetShopnshipQuery = onCompleted => {
  const sendAlert = useSendAlert();
  const [getShopnship, shopNShipResult] = useLazyQuery(GET_SHOPNSHIP, {
    onCompleted: data => {
      onCompleted && onCompleted(data);
    },
    onError: error => sendAlert(parseGraphqlError(error), 'error')
  });

  const getShopnshipQuery = id => {
    getShopnship({
      variables: { id }
    });
  };

  return [getShopnshipQuery, shopNShipResult];
};

const useGetUserFinalShopnshipChargesQuery = onCompleted => {
  return useLazyQuery(GET_USER_SHOPNSHIP_FINAL_CHARGES, {
    onCompleted: data => {
      if (onCompleted) {
        onCompleted(data);
      }
    }
  });
};

const useGetShopnshipCurrencyRates = () => {
  const [, setShopnshipRate] = useRecoilState(shopnshipRateState);
  const {
    mutateAsync: getShopnshipCurrencyRates,
    ...getShopnshipCurrencyRatesResult
  } = useMutation(GET_SHOPNSHIP_CURRENCY_RATES, {
    onSuccess: data => {
      setShopnshipRate(data.data);
    }
  });

  return [getShopnshipCurrencyRates, getShopnshipCurrencyRatesResult];
};

const useGetLogisticsBusinessConfigs = () => {
  return useLazyQuery(GET_LOGISTICS_BUSINESS_CONFIGS);
};

const useGetBusinessInformation = onCompleted => {
  const [getBusinessInfo, businessInfoResult] = useLazyQuery(
    GET_BUSINESS_INFORMATION,
    {
      onCompleted: data => {
        onCompleted && onCompleted(data);
      }
      // onError: error => sendAlert(parseGraphqlError(error), 'error')
    }
  );

  const getBusinessInfoQuery = id => {
    getBusinessInfo({
      variables: { topshipId: id }
    });
  };

  return [getBusinessInfoQuery, businessInfoResult];
};

const useGetShipmentQuote = onCompleted => {
  const sendAlert = useSendAlert();
  const [getShipmentQuote, getShipmentQuoteResult] = useLazyQuery(
    GET_SHIPMENT_QUOTE,
    {
      onCompleted: data => {
        if (onCompleted) {
          onCompleted(data);
        }
      },
      onError: error => sendAlert(parseGraphqlError(error), 'error')
    }
  );

  const getShipmentQuoteQuery = variables => {
    getShipmentQuote({
      variables
    });
  };

  return [getShipmentQuoteQuery, getShipmentQuoteResult];
};

const useGetForeignRates = onCompleted => {
  const { mutateAsync, ...getForeignRatesResult } = useMutation(
    GET_FOREIGN_RATES,
    {
      onSuccess: data => {
        if (onCompleted) {
          onCompleted(data);
        }
      }
    }
  );

  const getForeignRates = async from => {
    return await mutateAsync({ from });
  };

  return [getForeignRates, getForeignRatesResult];
};

const useGetPaymentIntent = onCompleted => {
  const [getIntent, getPaymentIntentResult] = useLazyQuery(GET_PAYMENT_INTENT, {
    onCompleted: data => {
      if (data) {
        onCompleted(data);
      }
    }
  });

  const getPaymentIntent = input => {
    getIntent({ variables: { input } });
  };

  return [getPaymentIntent, getPaymentIntentResult];
};

const useGetVirtualBankAccount = onCompleted => {
  const sendAlert = useSendAlert();
  const [getAccount, getVirtualBankAccountResult] = useLazyQuery(
    GET_VIRTUAL_BANK_ACCOUNT,
    {
      onCompleted: data => {
        onCompleted && onCompleted(data);
      },
      onError: error => sendAlert(parseGraphqlError(error), 'error')
    }
  );

  const getVirtualBankAccount = input => {
    getAccount({
      variables: { input }
    });
  };

  return [getVirtualBankAccount, getVirtualBankAccountResult];
};

const useGenerateSession = onCompleted => {
  const sendAlert = useSendAlert();
  const [getSession, getSessionResult] = useLazyQuery(GET_SESSION, {
    onCompleted: data => {
      if (data) {
        onCompleted(data.generateSession);
      }
    },
    onError: error => sendAlert(parseGraphqlError(error), 'error')
  });

  const getSessionDetails = input => {
    getSession({
      variables: { input }
    });
  };

  return [getSessionDetails, getSessionResult];
};

const useGeneratePayPalCheckoutUrl = onCompleted => {
  const sendAlert = useSendAlert();
  const [getCheckoutUrl, getCheckoutUrlResult] = useLazyQuery(
    GET_PAYPAL_CHEKOUT_URL,
    {
      onCompleted: data => {
        if (data) {
          onCompleted(data.generatePayPalCheckoutUrl);
        }
      },
      onError: error => sendAlert(parseGraphqlError(error), 'error')
    }
  );

  const getPayPalDetails = input => {
    getCheckoutUrl({
      variables: { input }
    });
  };

  return [getPayPalDetails, getCheckoutUrlResult];
};
export {
  useGeneratePayPalCheckoutUrl,
  useGenerateSession,
  useGetWalletBalanceQuery,
  useGenerateTransactionReferenceQuery,
  useGetUserQuery,
  useGetWalletQuery,
  useGetTransactions,
  useGetShipmentsQuery,
  useGetShipmentDetailsQuery,
  useGetSavedAddresses,
  useGetCurrentMonthShipments,
  useGetShipmentRateQuery,
  useTrackShipmentLazyQuery,
  useTrackShipmentQuery,
  useGetTransactionsLazyQuery,
  useGetBankListQuery,
  useGetBankAccountNameQuery,
  useGetBankAccountListQuery,
  useGetBankAccountListLazyQuery,
  useGetWithdrawalLogsLazyQuery,
  useGetCouponQuery,
  useGetCountriesQuery,
  useGetStatesQuery,
  useGetCitiesQuery,
  useGetCountriesLazyQuery,
  useGetUserLazyQuery,
  useGetUserShipmentsQuery,
  useGetShipmentRateQueryMultiple,
  useGetShopnshipQuery,
  useGetShopnshipsQuery,
  useGetShopnshipCurrencyRates,
  useGetUserFinalShopnshipChargesQuery,
  useUserRoundupDataQuery,
  useGetLogisticsBusinessConfigs,
  useGetBusinessInformation,
  useGetShipmentQuote,
  useGetForeignRates,
  useGetPaymentIntent,
  useGetVirtualBankAccount,
  useGetShopNShipRateQuery,
  useGetPickupRatesQuery,
  useGetLastMileRatesQuery
};

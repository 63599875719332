import React from 'react';
import { Link } from 'react-router-dom';
import Robot from './../../assets/svg/robot.svg';

const NotFound = ({
  heading = 'Nothing here',
  body = 'Come back later',
  children
}) => (
  <div className='notfound'>
    <div className='text-center'>
      <figure>
        <img src={Robot} alt='not found' className='img' />
      </figure>

      <h1>{heading}</h1>

      {!children && (
        <div>
          <h3>{body}</h3>
          <div className='button'>
            <Link to='/'>
              <button className='submit-button'>Back To Dashboard</button>
            </Link>
          </div>
        </div>
      )}

      {children}
    </div>
  </div>
);

export default NotFound;

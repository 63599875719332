import { LargeBox, MediumBox, WardrobeBox } from './asset-contants';

export const shipmentOptions = [
  {
    id: 1,
    title: 'Book a Delivery',
    description: 'Send out a parcel, locally or internationally',
    value: 'Export'
  },
  {
    id: 2,
    title: 'Book an Import',
    description: 'Receive a package from anywhere in the world',
    value: 'Import'
  },
  {
    id: 3,
    title: 'Shop & ship',
    description: 'Shop & ship to our US, UK or Canada addresses',
    value: 'ShopNShip'
  }
];

export const shipmentMethods = [
  {
    id: 1,
    title: 'Drop Off',
    description: 'Drop off your items at the nearest Topship processing center',
    dropoff: 'Free',
    value: 'DropOff',
    amount: 0
  },
  {
    id: 2,
    title: 'Request a Pickup',
    description: `A dispatch rider will pick up your items at your preferred location.`,
    pickup: '',
    value: 'PickUp',
    amount: 0
  }
];

export const shipmentDestinationTypes = [
  {
    id: 1,
    title: ' Single Destination',
    description: 'Send your items to only one destination.',
    value: 'Single'
  },
  {
    id: 2,
    title: 'Multiple Destinations',
    description: (
      <>
        <p>Send items to multiple destinations.</p>
        <br />
        <p className='text-danger'>
          <b>Maximum limit of 4 receivers per booking</b>
        </p>
      </>
    ),
    value: 'Multiple'
  }
];

export const bookImportTypes = [
  {
    id: 1,
    title: 'Worldwide Imports',
    description: 'Import from any city in the world'
  },
  {
    id: 2,
    title: 'China Imports',
    description: 'Ship from suppliers and manufacturers in China'
  },
  {
    id: 3,
    title: 'Import Via Sea',
    description: 'Import heavy items at low rates with our Sea Freight service'
  }
];

export const shipmentHubs = [
  {
    title: 'Abuja',
    description: '30 Howeidy A. Street, Kado',
    slug: 'abuja',
    value: {
      addressLine1: '30 Howeidy A. Street',
      addressLine2: 'Kado',
      country: 'Nigeria',
      countryCode: 'NG',
      state: 'Abuja',
      city: 'Abuja Fct'
    }
  },
  {
    title: 'Lagos Island',
    description: '25B Bisola Durosinmi Etti Drive Lekki Phase 1',
    slug: 'lagos-island',
    value: {
      addressLine1: '25B Bisola Durosinmi Etti Drive',
      addressLine2: 'Lekki Phase 1',
      country: 'Nigeria',
      countryCode: 'NG',
      state: 'Lagos',
      city: 'Lekki Phase 1'
    }
  },
  {
    title: 'Lagos Mainland',
    description: '3 Dapo Bode Thomas Street, Sabo, Yaba',
    slug: 'lagos-mainland',
    value: {
      addressLine1: '3 Dapo Bode Thomas Street',
      addressLine2: 'Sabo, Yaba',
      country: 'Nigeria',
      countryCode: 'NG',
      state: 'Lagos',
      city: 'Yaba'
    }
  },
  {
    title: 'Ibadan',
    description: 'Shop A24, Grand Mall, Secretariat Road, Bodija Market',
    slug: 'ibadan',
    value: {
      addressLine1: 'Shop A24, Grand Mall, Secretariat Road',
      addressLine2: 'Bodija Market',
      country: 'Nigeria',
      countryCode: 'NG',
      state: 'Oyo',
      city: 'Ibadan'
    }
  },
  {
    title: 'Port Harcourt',
    description: '24 Eze Gbaka-Gbaka Avenue, Woji ',
    slug: 'port-harcourt',
    value: {
      addressLine1: '24 Eze Gbaka-Gbaka Avenue, Woji ',
      addressLine2: 'Woji',
      country: 'Nigeria',
      countryCode: 'NG',
      state: 'Rivers',
      city: 'Portharcourt'
    }
  }
];

export const allowedIntraCityUsers = [];

export const defaultDetail = {
  country: 'Nigeria',
  countryCode: 'ng'
};

export const flatRates = [
  '0.5kg - 2kg - ₦50,000',
  '2.1kg - 3kg - ₦55,000',
  '3.1kg - 5kg - ₦70,000',
  '5.1kg - 9.4kg - ₦107,000'
];

// Non-flat rates
export const nonFlatRates = ['10kg and above - ₦14,500/kg'];

export const EU_countries = [
  'Albania',
  'Andorra',
  'Austria',
  'Belarus',
  'Belgium',
  'Bosnia and Herzegovina',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czech',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Iceland',
  'Ireland',
  'Italy',
  'Latvia',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Moldova',
  'Monaco',
  'Montenegro',
  'Netherlands',
  'North Macedonia',
  'Norway',
  'Poland',
  'Portugal',
  'Romania',
  'San Marino',
  'Serbia',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland'
];

export const budgetRatePerCountries = [
  {
    country: '🇺🇸 United States of America',
    rates: {
      nonFlats: '9.1kg and above - ₦21,000 per kg',
      flats: [
        '0.5kg - 1kg - ₦74,500',
        '1.1kg - 2kg - ₦88,600',
        '2.1kg - 3kg - ₦98,800',
        '3.1kg - 4kg - ₦112,500',
        '4.1kg - 5kg - ₦128,000',
        '5.1kg - 6kg - ₦143,300',
        '6.1kg - 7kg - ₦158,800',
        '7.1kg - 8kg - ₦159,000',
        '8.1kg - 9kg - ₦180,000'
      ]
    }
  },
  {
    country: '🇬🇧 United Kingdom',
    rates: {
      nonFlats: '9.1kg and above - ₦19,500 per kg',
      flats: [
        '0.5kg - 1kg - ₦64,000',
        '1.1kg - 2kg - ₦74,300',
        '2.1kg - 3kg - ₦85,500',
        '3.1kg - 4kg - ₦96,000',
        '4.1kg - 5kg - ₦108,000',
        '5.1kg - 6kg - ₦121,000',
        '6.1kg - 7kg - ₦132,000',
        '7.1kg - 8kg - ₦145,000',
        '8.1kg - 9kg - ₦147,000'
      ]
    }
  },
  {
    country: '🇨🇦 Canada',
    rates: {
      nonFlats: '9.1kg - 10kg and above = N24,500 per kg',
      flats: [
        '0.5kg - 1kg - ₦109,500',
        '1.1kg - 2kg - ₦123,600',
        '2.1kg - 3kg - ₦133,800',
        '3.1kg - 4kg - ₦147,500',
        '4.1kg - 5kg - ₦163,000',
        '5.1kg - 6kg - ₦178,500',
        '6.1kg - 7kg - ₦194,000',
        '7.1kg - 8kg - ₦207,800',
        '8.1kg - 9kg - ₦227,000'
      ]
    }
  },
  {
    country: '🇪🇺 Rest of Europe',
    rates: {
      nonFlats: '10kg and above - ₦17,000 per kg',
      flats: [
        '1kg - ₦72,000',
        '2kg - ₦87,000',
        '3kg - ₦102,000',
        '4kg - ₦112,000',
        '5kg - ₦127,000',
        '6kg - ₦142,000',
        '7kg - ₦157,000',
        '8kg - ₦162,000',
        '9kg - ₦177,000'
      ]
    }
  },
  {
    country: '🌍 Rest of Africa',
    rates: {
      nonFlats: '',
      flats: []
    }
  }
];

export const ItemWeightBand = [
  {
    boxType: 'Medium',
    width: 18,
    length: 18,
    height: 18,
    boxImg: MediumBox
  },
  {
    boxType: 'Wardrobe',
    width: 18,
    length: 18,
    height: 48,
    boxImg: WardrobeBox
  },
  {
    boxType: 'Large',
    width: 18,
    length: 18,
    height: 24,
    boxImg: LargeBox
  },
  {
    boxType: 'Others',
    width: 0,
    length: 0,
    height: 0,
    size: 'Enter manually'
  }
];

export const shopnshipHubs = {
  us: {
    title: 'United States Hub',
    description: '172-30 Baisley Blvd',
    slug: 'UAC-mujeq',
    value: {
      addressLine1: '172-30 Baisley Blvd',
      addressLine2: 'Jamaica',
      country: 'United States',
      countryCode: 'US',
      state: 'New York',
      city: 'New York',
      postalCode: '11434'
    }
  },
  gb: {
    title: 'United Kingdom Hub',
    description: '2b Herbert Road',
    slug: 'UAC-ytroe',
    value: {
      addressLine1: '2b Herbert Road',
      addressLine2: 'off Plumstead Common',
      country: 'United Kingdom',
      countryCode: 'GB',
      state: 'London',
      city: 'London',
      postalCode: 'SE18 3SH'
    }
  }
};

import FileUpload from '../file-upload';
import { Close, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import {
  CheckboxCircleLine,
  ErrorWarningLine
} from '../../constants/asset-contants';

const MultiFileUploadModal = ({
  openPrompt = false,
  title = '',
  onUploadFile,
  loading = { loading: false, index: 0 },
  onCancel,
  removeFile,
  submit,
  files = []
}) => {
  return (
    <>
      <div
        className={`file-upload__modal multi new-shop-n-ship__prompt fade${
          openPrompt ? ' show' : ''
        }`}
      >
        <div className='file-upload__modal__header'>
          <h1>{title || 'Upload your files'}</h1>
          <Close onClick={onCancel} sx={{ cursor: 'pointer' }} />
        </div>
        <div className='file-upload__modal__body'>
          <div className='file-upload__modal__body__wrap'>
            <div className='file-upload__modal__files'>
              {files.map((file, index) => (
                <Accordion
                  sx={{ boxShadow: 'none', borderBottom: '1px solid grey' }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls='panel1-content'
                    id='panel1-header'
                    className='file-upload__modal__accordion'
                    sx={{
                      color: '#22428f',
                      fontSize: '16px',
                      fontWeight: '500',
                      fontFamily: 'General Sans'
                    }}
                  >
                    <span>{`${index + 1}. Upload ${
                      file?.name || 'File'
                    }`}</span>
                    <img
                      src={
                        file?.content ? CheckboxCircleLine : ErrorWarningLine
                      }
                      alt=''
                      width={'20px'}
                      height={'20px'}
                      className='img-fluid'
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='d-flex flex-column'>
                      <div className='file-upload__modal__description mb-2'>
                        <span>
                          {file?.description
                            ? `${file?.description} Please upload below:`
                            : 'Please upload your file'}
                        </span>
                      </div>
                      <div className='file-upload__modal__main'>
                        <FileUpload
                          isModal
                          loading={
                            openPrompt &&
                            loading?.loading &&
                            loading?.index === index
                          }
                          onUploadFile={(uploadedFile, setIsUploaded) =>
                            onUploadFile?.(uploadedFile, index, setIsUploaded)
                          }
                          uploadedFile={file?.content}
                          onCancel={() => removeFile?.(index)}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
            <div className='file-upload__modal__footer'>
              <div className='file-upload__modal__footer__wrap'>
                <button
                  onClick={onCancel}
                  className='file-upload__modal__footer__button secondary'
                >
                  Cancel
                </button>
                <button
                  onClick={submit}
                  className='file-upload__modal__footer__button primary'
                >
                  Upload & Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`new-shop-n-ship__prompt__backdrop fade${
          openPrompt ? ' show' : ''
        }`}
      ></div>
    </>
  );
};

export default MultiFileUploadModal;

import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, InputAdornment, Alert } from '@mui/material';
import { useFormik } from 'formik';
import Input from '../new-dashboard/custom-input';
import * as yup from 'yup';
import debounce from 'lodash.debounce';
import {
  useGetCountriesLazyQuery,
  useGetShipmentQuote,
  useGetShipmentRateQuery
} from '../../operations/queries';
import { useRecoilState } from 'recoil';
import {
  budgetDeliveryState,
  quotesLocationDataState,
  saveShipmentDetailsState,
  selectedRateState,
  shippingRouteState,
  userState
} from '../../recoil/atoms';
import CustomAutoComplete from '../new-dashboard/custom-autocomplete';
import BusyOverlay from '../busy-overlay';
import { useGetCitiesMutation } from '../../operations/mutations';
import { shipmentOptions } from '../../constants/book-shipment-contants';
import shipmentRateModeDictionary from '../../constants/shipment-rate-modes-dictionary';
import { Scale } from '@mui/icons-material';
import { useMemo } from 'react';
import { roundUp } from '../../utilities/round-up';
import StickyNoteAlert from '../Commons/sticky-note';
import getPlan from '../../utilities/getPlans';
import VolumetricWeight from '../volumetric-weight-switch';
import { useSendAlert } from '../../utilities/send-alert';
import { accountType as accountTypes } from '../../constants/authentication';
import { toFixed } from '../../utilities/to-fixed';
import { InfoYellowIcon } from '../../constants/asset-contants';
import { DeliveryOptions1 } from '../shipping-options';

const defaultDetail = { countryCode: 'NG', country: 'Nigeria' };

const NewGetQuotePrompt = ({
  openPrompt,
  setOpenPrompt,
  setOpenBookShipmentModal
}) => {
  const [user] = useRecoilState(userState);

  const [selectedBudgetDelivery, setSelectedBudgetDelivery] = useRecoilState(
    budgetDeliveryState
  );

  const sendAlert = useSendAlert();

  const [initialValues, setInitialValues] = useState({
    weight: '',
    width: '',
    height: '',
    length: '',
    isVolumetric: false,
    senderDetail: {
      country: defaultDetail.country,
      city: '',
      countryCode: defaultDetail.countryCode,
      postalCode: ''
    },
    receiverDetail: {
      country: '',
      city: '',
      countryCode: '',
      postalCode: ''
    }
  });
  const methods = useMemo(() => ({ exports: 'export', imports: 'import' }), []);
  const [method, setMethod] = useRecoilState(shippingRouteState);
  const [selectedRate, setSelectedRate] = useRecoilState(selectedRateState);
  const [success, setSuccess] = useState(false);
  const [getCountries, countries] = useGetCountriesLazyQuery();
  const [isExecuted, setIsExecuted] = useState(false);
  const [info, setInfo] = useState(null);
  const [shipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );

  const [getSenderCities, senderCitiesResult] = useGetCitiesMutation(
    undefined,
    'sender'
  );
  const [getReceiverCities, receiverCitiesResult] = useGetCitiesMutation(
    undefined,
    'receiver'
  );

  const handleShipmentQuote = async data => {
    if (data?.getShipmentQuote) {
      try {
        const response = await fetch(
          `data:application/pdf;base64,${data?.getShipmentQuote?.file}`
        );

        const blob = await response.blob();

        const link = document.createElement('a');

        link.href = URL.createObjectURL(blob);

        link.download = `${
          getPlan('shipping', selectedRate?.pricingTier)?.name
        } Quote - ${new Date(data?.getShipmentQuote?.date).toUTCString()}.pdf`;

        link.style.display = 'none';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      } catch (error) {
        sendAlert('An Error occured while getting your quote', 'error');
      } finally {
        URL.revokeObjectURL(data?.getShipmentQuote?.file);
      }
    }
  };

  const [getShipmentQuote, getShipmentQuoteResult] = useGetShipmentQuote(
    handleShipmentQuote
  );

  const businessId = localStorage.getItem('lspId');

  const [
    {
      sender: { cities: senderCities },
      receiver: { cities: receiverCities }
    }
  ] = useRecoilState(quotesLocationDataState);
  const [getShipmentRates, getShipmentRatesResult] = useGetShipmentRateQuery({
    onCompleted: () => setSuccess(true),
    onError: error => setInfo({ message: error, status: 'error' })
  });
  function calculateTotalWeight(values) {
    let totalWeightValue = 0;

    if (!!values.isVolumetric) {
      const volumetricWeight = Number(
        ((values.width * values.height * values.length) / 5000).toFixed(2)
      );
      totalWeightValue += volumetricWeight;
    } else {
      totalWeightValue += values.weight;
    }

    return totalWeightValue;
  }

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setFieldValue
  } = useFormik({
    enableReinitialize: true,
    initialValues,

    validationSchema: yup.object().shape({
      isVolumetric: yup.bool(),
      weight: yup.number().when(['isVolumetric'], {
        is: isVolumetric => isVolumetric === false,
        then: yup
          .number()
          .min(0.09999, 'Please enter a weight greater than zero (0)')
          .required('Please enter the weight of your item(s)'),
        otherwise: yup.number().nullable().notRequired()
      }),
      width: yup.number().when(['isVolumetric'], {
        is: isVolumetric => isVolumetric === true,

        then: yup
          .number()
          .min(0.09999, 'Please enter a width greater than zero (0)')
          .required('Please enter the width of your box'),
        otherwise: yup.number().nullable().notRequired()
      }),
      height: yup.number().when(['isVolumetric'], {
        is: isVolumetric => isVolumetric === true,

        then: yup
          .number()
          .min(0.09999, 'Please enter a height greater than zero (0)')
          .required('Please enter the height of your box'),
        otherwise: yup.number().nullable().notRequired()
      }),
      length: yup.number().when(['isVolumetric'], {
        is: isVolumetric => isVolumetric === true,

        then: yup
          .number()
          .min(0.09999, 'Please enter a length greater than zero (0)')
          .required('Please enter the length of your box'),
        otherwise: yup.number().nullable().notRequired()
      }),
      senderDetail: yup.object().shape({
        country: yup.string().required(`Please enter pickup country`),
        city: yup.string().required(`Please enter pickup city`),
        postalCode: yup.string().nullable().notRequired()
      }),
      receiverDetail: yup.object().shape({
        country: yup.string().required(`Please enter destination country`),
        city: yup.string().required(`Please enter destination city`),
        postalCode: yup.string().nullable().notRequired()
      })
    }),

    onSubmit: values => {
      const totalWeight = calculateTotalWeight(values);

      const requestRatesData = {
        senderDetails: {
          cityName: values.senderDetail.city,
          countryCode: values.senderDetail.countryCode,
          postalCode: values.senderDetail.postalCode
        },
        receiverDetails: {
          cityName: values.receiverDetail.city,
          countryCode: values.receiverDetail.countryCode,
          postalCode: values.receiverDetail.postalCode
        },
        totalWeight,

        lsp: businessId !== 'null' ? businessId : null
      };

      getShipmentRates(requestRatesData);
    }
  });

  const totalWeight = useMemo(() => {
    return calculateTotalWeight(values);
  }, [values]);

  const resetPromptForm = () => {
    setSelectedRate({});
    setSuccess(false);
    setMethod(methods.exports);
  };

  const onClosePrompt = () => {
    setOpenPrompt(false);
    resetPromptForm();
  };

  const handleBudgetDelivery = option => {
    setSelectedBudgetDelivery(option);
  };

  const onNextPrompt = () => {
    const allItems = [
      {
        value: '',
        category: '',
        quantity: '',
        description: '',
        isCompleted: false,
        weight: calculateTotalWeight(values)
      }
    ];

    const allReceivers = [
      {
        receiverDetail: {
          name: '',
          email: '',
          phoneNumber: '',
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          state: '',
          postalCode: values?.receiverDetail?.postalCode || '',
          shouldSave: false,
          isCompleted: false,
          city: values.receiverDetail.city,
          country: values.receiverDetail.country,
          countryCode: values.receiverDetail.countryCode.toUpperCase()
        },
        items: allItems,
        couponCode: '',
        insuranceType: '',
        insuranceCharge: 0,
        isCompleted: false,
        pricingTier: shipmentRateModeDictionary(selectedRate?.pricingTier),
        shipmentCharge: toFixed(selectedRate?.cost)
      }
    ];

    const isExport =
      values.senderDetail.countryCode.toLowerCase() ===
      defaultDetail.countryCode;

    const isImport =
      values.receiverDetail.countryCode.toLowerCase() ===
      defaultDetail.countryCode;

    setSaveShipmentDetail({
      ...shipmentDetail,
      senderDetail: {
        city: values.senderDetail.city,
        countryCode: values.senderDetail.countryCode.toUpperCase(),
        country: isExport ? defaultDetail.country : '',
        postalCode: values?.senderDetail?.postalCode
      },
      receiverDetail: {
        city: values.receiverDetail.city,
        countryCode: values.receiverDetail.countryCode.toUpperCase(),
        country: isImport ? defaultDetail.country : '',
        postalCode: values?.receiverDetail?.postalCode
      },
      items: allItems,
      multipleReceivers: allReceivers,
      shipmentCharge: toFixed(selectedRate?.cost),
      pricingTier: shipmentRateModeDictionary(selectedRate?.mode),
      shipmentRoute: isImport
        ? shipmentOptions[1].value
        : shipmentOptions[0].value
    });
    setOpenBookShipmentModal(true);
    setOpenPrompt(false);
  };

  const handleMethodChange = route => {
    if (route === methods.exports) {
      setMethod(methods.exports);
      setInitialValues({
        receiverDetail: {
          country: '',
          countryCode: '',
          city: '',
          postalCode: ''
        },
        senderDetail: {
          country: defaultDetail.country,
          countryCode: defaultDetail.countryCode,
          city: '',
          postalCode: ''
        },
        weight: roundUp(values.weight)
      });
      getSenderCities({
        countryCode: defaultDetail.countryCode,
        cityName: 'a'
      });
    } else {
      setMethod(methods.imports);
      setInitialValues({
        receiverDetail: {
          country: defaultDetail.country,
          countryCode: defaultDetail.countryCode,
          city: '',
          postalCode: ''
        },
        senderDetail: {
          country: '',
          countryCode: '',
          city: '',
          postalCode: ''
        },
        weight: roundUp(values.weight)
      });
      getReceiverCities({
        countryCode: defaultDetail.countryCode,
        cityName: 'a'
      });
    }
  };
  const handleRateSelection = rate => {
    setSelectedRate(rate);
  };
  useEffect(() => {
    getCountries();
    getSenderCities({
      countryCode: values.senderDetail.countryCode,
      cityName: 'a'
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (values.receiverDetail.countryCode && !isExecuted) {
      getReceiverCities({
        countryCode: values.receiverDetail.countryCode,
        cityName: 'a'
      });
      setIsExecuted(true);
    }
    // eslint-disable-next-line
  }, [isExecuted, values.receiverDetail.countryCode]);

  useEffect(() => {
    if (
      getShipmentRatesResult.data &&
      getShipmentRatesResult.data?.getShipmentRate?.length === 1
    ) {
      setSelectedRate(getShipmentRatesResult.data?.getShipmentRate[0]);
    }
    // eslint-disable-next-line
  }, [getShipmentRatesResult.data]);

  const loading =
    countries.loading ||
    senderCitiesResult.loading ||
    receiverCitiesResult.loading;

  const getCountryList = useCallback(
    route => {
      if (route === method) {
        return [
          { code: defaultDetail.countryCode, name: defaultDetail.country }
        ];
      } else {
        return countries.data
          ? countries.data?.getCountries?.filter(
              country =>
                route === methods.imports ||
                country.code !== defaultDetail.countryCode
            )
          : [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countries, method, defaultDetail, methods]
  );

  return (
    <>
      <div className={`new-get-quote__prompt fade${openPrompt ? ' show' : ''}`}>
        <div className='new-get-quote__prompt__wrap'>
          <div className='new-get-quote__prompt__header'>
            <h2>Get Pricing</h2>
            {success &&
              user?.accountType === accountTypes[2].value &&
              user?.businessInformation && (
                <button
                  disabled={!selectedRate.mode}
                  onClick={() => {
                    const shipmentDetail = {
                      senderDetails: {
                        cityName: values.senderDetail.city,
                        countryCode: values.senderDetail.countryCode
                      },
                      receiverDetails: {
                        cityName: values.receiverDetail.city,
                        countryCode: values.receiverDetail.countryCode
                      },
                      totalWeight: roundUp(values.weight),
                      lsp: businessId !== 'null' ? businessId : null
                    };

                    getShipmentQuote({
                      shipmentDetail,
                      rate: {
                        cost: selectedRate?.cost,
                        duration: selectedRate?.duration,
                        mode: getPlan('shipping', selectedRate?.pricingTier)
                          ?.name
                      }
                    });
                  }}
                >
                  Share Quote
                </button>
              )}
          </div>

          <div className='new-get-quote__prompt__body'>
            <div className='new-get-quote__prompt__body__wrap'>
              {success || getShipmentRatesResult.loading ? (
                <>
                  <div className='new-get-quote__prompt__results'>
                    <div className='new-get-quote__prompt__results__wrap'>
                      <div className='new-get-quote__prompt__results__item'>
                        <div className='new-get-quote__prompt__results__item__header'>
                          <span>Pickup</span>
                        </div>
                        <div className='new-get-quote__prompt__results__item__address'>
                          <img
                            loading='lazy'
                            width='20'
                            height='14'
                            src={`https://flagcdn.com/w20/${values.senderDetail?.countryCode?.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${values.senderDetail?.countryCode?.toLowerCase()}.png 2x`}
                            alt=''
                          />
                          <span>
                            {`${values.senderDetail.city?.toLowerCase()}, ${
                              values.senderDetail.countryCode
                            }`}
                          </span>
                        </div>
                      </div>
                      <div className='new-get-quote__prompt__results__item'>
                        <div className='new-get-quote__prompt__results__item__header'>
                          <span>Destination</span>
                        </div>
                        <div className='new-get-quote__prompt__results__item__address'>
                          <img
                            loading='lazy'
                            width='20'
                            height='14'
                            src={`https://flagcdn.com/w20/${values.receiverDetail?.countryCode?.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${values.receiverDetail?.countryCode?.toLowerCase()}.png 2x`}
                            alt=''
                          />
                          <span>
                            {`${values.receiverDetail.city?.toLowerCase()}, ${
                              values.receiverDetail.countryCode
                            }`}
                          </span>
                        </div>
                      </div>
                      <div className='new-get-quote__prompt__results__item'>
                        <div className='new-get-quote__prompt__results__item__header'>
                          <span>Weight</span>
                        </div>
                        <div className='new-get-quote__prompt__results__item__address'>
                          <Scale
                            sx={{
                              color: '#22428f',
                              width: '20px',
                              height: '14px'
                            }}
                          />
                          <span>{totalWeight} KG</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='new-get-quote__prompt__options'>
                    <div className='new-get-quote__prompt__options'>
                      <div className='new-get-quote__prompt__options__wrap'>
                        <div className='new-get-quote__prompt__options__header'>
                          <span>Choose a preferred Shipping Method</span>
                        </div>
                        <div className='mb-'>
                          <Alert
                            severity={'info'}
                            sx={{
                              marginTop: '',
                              fontSize: '1.4rem',
                              fontWeight: 'normal',
                              fontFamily: 'General Sans',
                              backgroundColor: 'rgba(239, 165, 22, 0.1)',
                              alignItems: 'flex-start',
                              color: '#0F1414',
                              border: 'none',
                              '& .MuiAlert-icon': {
                                fontSize: '2rem',
                                marginRight: '5px'
                              }
                            }}
                            icon={<img src={InfoYellowIcon} alt='info' />}
                          >
                            The price quoted is based on specified routes and
                            estimated weight. Price is exclusive of taxes,
                            pick-up fees, and customs clearance charges (where
                            applicable).
                          </Alert>
                        </div>
                        <DeliveryOptions1
                          showLabel={false}
                          loading={getShipmentRatesResult.loading}
                          rates={getShipmentRatesResult.data?.getShipmentRate}
                          selectedRate={selectedRate}
                          handleRateSelection={handleRateSelection}
                          handleBudgetDelivery={handleBudgetDelivery}
                          selectedBudgetDelivery={selectedBudgetDelivery}
                          isQuotePage={true}
                        />
                      </div>
                    </div>
                  </div>
                  <BusyOverlay loading={getShipmentQuoteResult.loading} />
                </>
              ) : (
                <>
                  {info && (
                    <Alert
                      severity={info.status}
                      sx={{
                        fontSize: '1.5rem',
                        fontFamily: 'General Sans',
                        backgroundColor: '#e6f2fe',
                        color: '#22428F',
                        border: 'none',
                        '& .MuiAlert-icon': {
                          color: '#22428F'
                        }
                      }}
                      onClose={() => setInfo(null)}
                    >
                      <span
                        className='d-block w-100'
                        style={{ fontSize: '16px' }}
                      >
                        {info.message}
                      </span>
                    </Alert>
                  )}
                  <div className='new-get-quote__prompt__body__options'>
                    <BusyOverlay loading={loading} />
                    <div className='new-get-quote__prompt__body__options__wrap'>
                      <div
                        onClick={() => handleMethodChange(methods.exports)}
                        className={`new-get-quote__prompt__body__options__item${
                          method === methods.exports ? ' active' : ''
                        }`}
                      >
                        <span>Deliveries {'&'} Exports</span>
                      </div>
                      <div
                        onClick={() => handleMethodChange(methods.imports)}
                        className={`new-get-quote__prompt__body__options__item${
                          method === methods.imports ? ' active' : ''
                        }`}
                      >
                        <span>Imports</span>
                      </div>
                    </div>
                  </div>
                  <h2>Pickup</h2>

                  <Grid container rowSpacing='16px' columnSpacing='10px'>
                    <CustomAutoComplete
                      options={getCountryList(methods.exports)}
                      getOptionLabel={option => option?.name}
                      isOptionEqualToValue={(option, value) =>
                        option?.name === value?.name
                      }
                      renderOption={(props, option, country) => (
                        <Box
                          component='li'
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={props.id}
                        >
                          {country && (
                            <img
                              loading='lazy'
                              width='30'
                              height='20'
                              src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
                              alt=''
                            />
                          )}
                          {option.name}
                        </Box>
                      )}
                      value={countries?.data?.getCountries?.find(
                        country => country.name === values.senderDetail.country
                      )}
                      noOptionsText={'Country not found'}
                      onChange={(event, value) => {
                        setFieldValue('senderDetail', {
                          ...values.senderDetail,
                          country: value?.name,
                          countryCode: value?.code
                        });
                        getSenderCities({
                          countryCode: value?.code,
                          cityName: 'a'
                        });
                      }}
                      textfieldProps={{
                        lg: 6,
                        md: 12,
                        name: 'senderDetail.country',
                        customLabel: 'Country',
                        containerClassName: 'new-get-quote__prompt__input',
                        error:
                          touched.senderDetail?.country &&
                          Boolean(errors.senderDetail?.country),
                        helperText:
                          touched.senderDetail?.country &&
                          errors.senderDetail?.country,
                        value: values.senderDetail.country,
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position='start'>
                              <img
                                style={{
                                  opacity: values.senderDetail.countryCode
                                    ? '1'
                                    : '0'
                                }}
                                loading='lazy'
                                width='20'
                                height='14'
                                src={`https://flagcdn.com/w20/${values.senderDetail?.countryCode?.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${values.senderDetail?.countryCode?.toLowerCase()}.png 2x`}
                                alt=''
                              />
                            </InputAdornment>
                          )
                        }
                      }}
                    />
                    <CustomAutoComplete
                      options={senderCities.length ? senderCities : []}
                      getOptionLabel={option =>
                        option?.city
                          ?.split(' ')
                          ?.map(
                            word =>
                              `${word.charAt().toUpperCase()}${word
                                .substring(1, word.length)
                                .toLowerCase()}`
                          )
                          ?.join(' ')
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?.city === value?.city
                      }
                      renderOption={(props, option) => (
                        <Box
                          key={props.id}
                          component='li'
                          sx={{
                            textTransform: 'capitalize'
                          }}
                          {...props}
                        >
                          {`${option?.city?.toLowerCase()} ${
                            option?.postalCode ? `(${option?.postalCode})` : ''
                          }`}
                        </Box>
                      )}
                      value={senderCities?.find(
                        city => city.city === values.senderDetail.city
                      )}
                      noOptionsText={
                        !values.senderDetail.countryCode
                          ? 'Please select a country for Pickup'
                          : senderCitiesResult.loading
                          ? 'Cities loading...'
                          : 'City not found'
                      }
                      onChange={(event, value) => {
                        let postalCode = null;

                        if (method === methods.imports) {
                          postalCode = value?.postalCode;
                        }

                        setFieldValue('senderDetail', {
                          ...values.senderDetail,
                          city: value?.city,
                          postalCode
                        });
                      }}
                      textfieldProps={{
                        lg: 6,
                        md: 12,
                        name: 'senderDetail.city',
                        customLabel: 'City',
                        containerClassName: 'new-get-quote__prompt__input',
                        error:
                          touched.senderDetail?.city &&
                          Boolean(errors.senderDetail?.city),
                        helperText:
                          touched.senderDetail?.city &&
                          errors.senderDetail?.city,
                        value: values.senderDetail.city,
                        onChange: debounce(
                          event =>
                            getSenderCities({
                              countryCode: values.senderDetail.countryCode,
                              cityName: event?.target?.value
                            }),
                          500
                        )
                      }}
                    />
                    <Input
                      containerClassName='new-get-quote__prompt__input'
                      name='senderDetail.postalCode'
                      type='text'
                      customLabel='Postal code (optional)'
                      error={
                        touched?.senderDetail?.postalCode &&
                        Boolean(errors?.senderDetail?.postalCode)
                      }
                      helperText={
                        touched?.senderDetail?.postalCode &&
                        errors?.senderDetail?.postalCode
                      }
                      value={values?.senderDetail?.postalCode}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>

                  <h2 className='mt-2'>Destination</h2>

                  <Grid container rowSpacing='16px' columnSpacing='10px'>
                    <CustomAutoComplete
                      options={getCountryList(methods.imports)}
                      getOptionLabel={option => option?.name}
                      isOptionEqualToValue={(option, value) =>
                        option?.name === value?.name
                      }
                      renderOption={(props, option, country) => (
                        <Box
                          component='li'
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={props.id}
                        >
                          {country && (
                            <img
                              loading='lazy'
                              width='30'
                              height='20'
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=''
                            />
                          )}
                          {option.name}
                        </Box>
                      )}
                      value={countries?.data?.getCountries?.find(
                        country =>
                          country.name === values.receiverDetail.country
                      )}
                      noOptionsText={'Country not found'}
                      onChange={(event, value) => {
                        setFieldValue('receiverDetail', {
                          ...values.receiverDetail,
                          country: value?.name,
                          countryCode: value?.code
                        });
                        getReceiverCities({
                          countryCode: value?.code,
                          cityName: 'a'
                        });
                      }}
                      textfieldProps={{
                        lg: 6,
                        md: 12,
                        name: 'receiverDetail.country',
                        customLabel: 'Country',
                        containerClassName: 'new-get-quote__prompt__input',
                        error:
                          touched.receiverDetail?.country &&
                          Boolean(errors.receiverDetail?.country),
                        helperText:
                          touched.receiverDetail?.country &&
                          errors.receiverDetail?.country,
                        value: values.receiverDetail.country,
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position='start'>
                              <img
                                style={{
                                  opacity: values.receiverDetail.countryCode
                                    ? '1'
                                    : '0'
                                }}
                                loading='lazy'
                                width='20'
                                height='14'
                                src={`https://flagcdn.com/w20/${values.receiverDetail?.countryCode?.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${values.receiverDetail?.countryCode?.toLowerCase()}.png 2x`}
                                alt=''
                              />
                            </InputAdornment>
                          )
                        }
                      }}
                    />
                    <CustomAutoComplete
                      options={receiverCities.length ? receiverCities : []}
                      getOptionLabel={option =>
                        option?.city
                          ?.split(' ')
                          ?.map(
                            word =>
                              `${word.charAt().toUpperCase()}${word
                                .substring(1, word.length)
                                .toLowerCase()}`
                          )
                          ?.join(' ')
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?.city === value?.city
                      }
                      renderOption={(props, option) => (
                        <Box
                          key={props.id}
                          component='li'
                          sx={{
                            textTransform: 'capitalize'
                          }}
                          {...props}
                        >
                          {`${option?.city?.toLowerCase()} ${
                            option?.postalCode ? `(${option?.postalCode})` : ''
                          }`}
                        </Box>
                      )}
                      value={receiverCities?.find(
                        city => city.city === values.receiverDetail.city
                      )}
                      noOptionsText={
                        !values.receiverDetail.countryCode
                          ? 'Please select a country for Destination'
                          : receiverCitiesResult.loading
                          ? 'Cities loading...'
                          : 'City not found'
                      }
                      onChange={(event, value) => {
                        let postalCode = value?.postalCode;

                        if (method === methods.imports) {
                          postalCode = null;
                        }

                        setFieldValue('receiverDetail', {
                          ...values.receiverDetail,
                          city: value?.city,
                          postalCode
                        });
                      }}
                      textfieldProps={{
                        lg: 6,
                        md: 12,
                        name: 'receiverDetail.city',
                        customLabel: 'City',
                        containerClassName: 'new-get-quote__prompt__input',
                        error:
                          touched.receiverDetail?.city &&
                          Boolean(errors.receiverDetail?.city),
                        helperText:
                          touched.receiverDetail?.city &&
                          errors.receiverDetail?.city,
                        value: values.receiverDetail.city,
                        onChange: debounce(
                          event =>
                            getReceiverCities({
                              countryCode: values.receiverDetail.countryCode,
                              cityName: event?.target?.value
                            }),
                          500
                        )
                      }}
                    />
                    <Input
                      containerClassName='new-get-quote__prompt__input'
                      name='receiverDetail.postalCode'
                      type='text'
                      error={
                        touched?.receiverDetail?.postalCode &&
                        Boolean(errors?.receiverDetail?.postalCode)
                      }
                      helperText={
                        touched?.receiverDetail?.postalCode &&
                        errors?.receiverDetail?.postalCode
                      }
                      value={values?.receiverDetail?.postalCode}
                      onChange={handleChange}
                      fullWidth
                      customLabel='Postal code (optional)'
                    />
                    <div className='ml-1'>
                      <VolumetricWeight
                        checkBoxName={`isVolumetric`}
                        isActive={values.isVolumetric}
                        handleToggleSwitch={() =>
                          setFieldValue(`isVolumetric`, !values.isVolumetric)
                        }
                      />
                    </div>

                    {values.isVolumetric ? (
                      <div className='new-get-quote__prompt__weight-wrapper'>
                        <Input
                          containerClassName='input'
                          name='width'
                          type='number'
                          customLabel='Width (CM)'
                          error={touched.width && Boolean(errors.width)}
                          helperText={touched.width && errors.width}
                          value={values.width}
                          onChange={handleChange}
                          fullWidth
                        />
                        <Input
                          containerClassName='input'
                          name='length'
                          type='text'
                          customLabel='Length (CM)'
                          error={touched.length && Boolean(errors.length)}
                          helperText={touched.length && errors.length}
                          value={values.length}
                          onChange={handleChange}
                          fullWidth
                        />
                        <Input
                          containerClassName='input'
                          name='height'
                          type='number'
                          customLabel='Height (CM)'
                          error={touched.height && Boolean(errors.height)}
                          helperText={touched.height && errors.height}
                          value={values.height}
                          onChange={handleChange}
                          fullWidth
                        />
                      </div>
                    ) : (
                      <Input
                        containerClassName='new-get-quote__prompt__input'
                        name='weight'
                        type='number'
                        customLabel='Item Weight (KG)'
                        error={touched.weight && Boolean(errors.weight)}
                        helperText={touched.weight && errors.weight}
                        value={values.weight}
                        onChange={handleChange}
                        fullWidth
                      />
                    )}
                  </Grid>
                </>
              )}
              <StickyNoteAlert isSmallerScreen={true} isVolumetric={true} />
            </div>
          </div>
          <div className='new-get-quote__prompt__footer'>
            <div className='new-get-quote__prompt__footer'>
              <div className='new-get-quote__prompt__footer__wrap'>
                <div className='new-get-quote__prompt__footer__actions'>
                  <button onClick={success ? resetPromptForm : onClosePrompt}>
                    {success ? 'Back' : 'Close'}
                  </button>
                  <button
                    disabled={success && !selectedRate.mode}
                    onClick={success ? onNextPrompt : handleSubmit}
                  >
                    {success ? 'Ship Now' : 'Submit'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`new-get-quote__prompt__backdrop fade${
          openPrompt ? ' show' : ''
        }`}
      ></div>
    </>
  );
};

export default NewGetQuotePrompt;

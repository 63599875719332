import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import {
  chinaImportState,
  shopNShipDetailsState
} from '../../../../../recoil/atoms';

import NewShopNShipStepsLayout from '../../../../../components/Layouts/shop-n-ship-steps-layout';
import SubmitButton from '../../../../../components/submit-button';
import AddressGuidePrompt from '../../../../../components/modals/address-guide-prompt';
import { useHandleCopyLink } from '../../../../../utilities/copy-link';
import { CopyLinkIcon } from '../../../../../constants/asset-contants';
import {
  receiverAddress,
  receiverAddressMandarin,
  receiverPhone
} from '../../../../../constants/china-address';

const ShopnshipDeliveryAddress = () => {
  const history = useHistory();
  const [, setIsChinaImport] = useRecoilState(chinaImportState);
  const resetShopNShipDetails = useResetRecoilState(shopNShipDetailsState);

  const [showAddressGuide, setShowAddressGuide] = useState(false);

  const handleCopy = useHandleCopyLink();

  return (
    <>
      <NewShopNShipStepsLayout mobileTitle='Package Information'>
        <section className='order-summary'>
          <div className='order-summary__wrap'>
            <h2 className='order-summary__header'>Delivery Address</h2>
            <div className='mb-3'>
              <p
                className='mb-1'
                style={{
                  fontWeight: 600,
                  fontSize: '18px'
                }}
              >
                In English
              </p>
              <div className='order-summary__shipment'>
                <div className='order-summary__shipment__wrap'>
                  <div className='order-summary__shipment__meta'>
                    <div className='order-summary__shipment__meta__wrap'>
                      <div className='order-summary__shipment__meta__content'>
                        <span className='order-summary__shipment__meta__content__label'>
                          Receiver Phone
                        </span>
                        <span className='order-summary__shipment__meta__content__value'>
                          {receiverPhone}
                        </span>
                      </div>
                      <div className='order-summary__shipment__address__action'>
                        <div
                          className='delivery-address-container'
                          onClick={() => handleCopy(`${receiverPhone}`)}
                        >
                          <p className='delivery-address-copy'>Copy</p>
                          <img src={CopyLinkIcon} alt='hyperlink' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='order-summary__shipment__meta'>
                    <div className='order-summary__shipment__meta__wrap'>
                      <div className='order-summary__shipment__meta__content'>
                        <span className='order-summary__shipment__meta__content__label'>
                          Receiver Address
                        </span>
                        <span className='order-summary__shipment__meta__content__value'>
                          {receiverAddress}
                        </span>
                      </div>
                      <div className='order-summary__shipment__address__action'>
                        <div
                          className='delivery-address-container'
                          onClick={() => handleCopy(receiverAddress)}
                        >
                          <p className='delivery-address-copy'>Copy</p>
                          <img src={CopyLinkIcon} alt='hyperlink' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='order-summary__shipment__meta'>
                    <div className='order-summary__shipment__meta__wrap'>
                      <div className='order-summary__shipment__meta__content'>
                        <span className='order-summary__shipment__meta__content__label'>
                          Street
                        </span>
                        <span className='order-summary__shipment__meta__content__value'>
                          Jiahe Street
                        </span>
                      </div>

                      <div className='order-summary__shipment__meta__action'></div>
                    </div>
                  </div>
                  <div className='order-summary__shipment__meta'>
                    <div className='order-summary__shipment__meta__wrap'>
                      <div className='order-summary__shipment__meta__content'>
                        <span className='order-summary__shipment__meta__content__label'>
                          City/Region
                        </span>
                        <span className='order-summary__shipment__meta__content__value'>
                          Guangzhou
                        </span>
                      </div>

                      <div className='order-summary__shipment__meta__action'></div>
                    </div>
                  </div>
                  <div className='order-summary__shipment__meta'>
                    <div className='order-summary__shipment__meta__wrap'>
                      <div className='order-summary__shipment__meta__content'>
                        <span className='order-summary__shipment__meta__content__label'>
                          State/Region
                        </span>
                        <span className='order-summary__shipment__meta__content__value'>
                          Guangdong
                        </span>
                      </div>

                      <div className='order-summary__shipment__meta__action'></div>
                    </div>
                  </div>
                  <div className='order-summary__shipment__meta'>
                    <div className='order-summary__shipment__meta__wrap'>
                      <div className='order-summary__shipment__meta__content'>
                        <span className='order-summary__shipment__meta__content__label'>
                          Country
                        </span>
                        <span className='order-summary__shipment__meta__content__value'>
                          China
                        </span>
                      </div>

                      <div className='order-summary__shipment__meta__action'></div>
                    </div>
                  </div>
                  <div className='order-summary__shipment__meta'>
                    <div className='order-summary__shipment__meta__wrap'>
                      <div className='order-summary__shipment__meta__content'>
                        <span className='order-summary__shipment__meta__content__label'>
                          Zip Code
                        </span>
                        <span className='order-summary__shipment__meta__content__value'>
                          510000
                        </span>
                      </div>
                      <div className='order-summary__shipment__meta__action'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p
                className='mb-1'
                style={{
                  fontWeight: 600,
                  fontSize: '18px'
                }}
              >
                In Mandarin
              </p>
              <div className='order-summary__shipment'>
                <div className='order-summary__shipment__wrap'>
                  <div className='order-summary__shipment__meta'>
                    <div className='order-summary__shipment__meta__wrap'>
                      <div className='order-summary__shipment__meta__content'>
                        <span className='order-summary__shipment__meta__content__label'>
                          电话号码
                        </span>
                        <span className='order-summary__shipment__meta__content__value'>
                          {receiverPhone}
                        </span>
                      </div>
                      <div className='order-summary__shipment__address__action'>
                        <div
                          className='delivery-address-container'
                          onClick={() => handleCopy(`${receiverPhone}`)}
                        >
                          <p className='delivery-address-copy'>Copy</p>
                          <img src={CopyLinkIcon} alt='hyperlink' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='order-summary__shipment__meta'>
                    <div className='order-summary__shipment__meta__wrap'>
                      <div className='order-summary__shipment__meta__content'>
                        <span className='order-summary__shipment__meta__content__label'>
                          地址
                        </span>
                        <span className='order-summary__shipment__meta__content__value'>
                          {receiverAddressMandarin}
                        </span>
                      </div>
                      <div className='order-summary__shipment__address__action'>
                        <div
                          className='delivery-address-container'
                          onClick={() =>
                            handleCopy(
                              `广州市白云区望岗西岭工业园路6-1号ELO75室 (稳兴百货旁边)`
                            )
                          }
                        >
                          <p className='delivery-address-copy'>Copy</p>
                          <img src={CopyLinkIcon} alt='hyperlink' />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='order-summary__shipment__meta'>
                    <div className='order-summary__shipment__meta__wrap'>
                      <div className='order-summary__shipment__meta__content'>
                        <span className='order-summary__shipment__meta__content__label'>
                          街道
                        </span>
                        <span className='order-summary__shipment__meta__content__value'>
                          嘉禾街道
                        </span>
                      </div>

                      <div className='order-summary__shipment__meta__action'></div>
                    </div>
                  </div>
                  <div className='order-summary__shipment__meta'>
                    <div className='order-summary__shipment__meta__wrap'>
                      <div className='order-summary__shipment__meta__content'>
                        <span className='order-summary__shipment__meta__content__label'>
                          城市
                        </span>
                        <span className='order-summary__shipment__meta__content__value'>
                          广州
                        </span>
                      </div>

                      <div className='order-summary__shipment__meta__action'></div>
                    </div>
                  </div>
                  <div className='order-summary__shipment__meta'>
                    <div className='order-summary__shipment__meta__wrap'>
                      <div className='order-summary__shipment__meta__content'>
                        <span className='order-summary__shipment__meta__content__label'>
                          国家
                        </span>
                        <span className='order-summary__shipment__meta__content__value'>
                          中国
                        </span>
                      </div>

                      <div className='order-summary__shipment__meta__action'></div>
                    </div>
                  </div>
                  <div className='order-summary__shipment__meta'>
                    <div className='order-summary__shipment__meta__wrap'>
                      <div className='order-summary__shipment__meta__content'>
                        <span className='order-summary__shipment__meta__content__label'>
                          邮编
                        </span>
                        <span className='order-summary__shipment__meta__content__value'>
                          510000
                        </span>
                      </div>
                      <div className='order-summary__shipment__meta__action'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='order-summary__terms'>
              <div className='order-summary__footer'>
                <SubmitButton
                  onClick={() => {
                    resetShopNShipDetails();
                    setIsChinaImport(false);
                    history.push('/past-china-imports');
                  }}
                  text={`Complete`}
                />
              </div>
            </div>
          </div>
        </section>
        {/* <BusyOverlay loading={loadingState} speed='1.7s' /> */}
      </NewShopNShipStepsLayout>
      <AddressGuidePrompt
        show={showAddressGuide}
        setShow={setShowAddressGuide}
      />
    </>
  );
};

export default ShopnshipDeliveryAddress;

import { useHistory } from 'react-router-dom';
import SuccessPage from '../../../components/Commons/success-page';
import thumbsUp from '../../../assets/svg/thumbs-up.svg';

const FeedbackSuccess = ({ location }) => {
  const history = useHistory();
  if (!(location.state && location.state.submitted)) {
    history.push('/');
  }

  return (
    <SuccessPage
      heading='Thank you for your feedback !'
      subHeading='We are constantly working towards offering the best and seamless service for you '
      successImage={thumbsUp}
    />
  );
};

export default FeedbackSuccess;

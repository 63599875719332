import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import signupSuccessIcon from '../../../assets/svg/payment-successful.svg';
import SubmitButton from '../../../components/submit-button';

const SignupSuccessful = () => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location?.search);
  const businessId = urlParams.get('lsp');
  const goToLogin = () => {
    if (!!businessId) {
      history.push({
        pathname: '/login',
        search: location?.search
      });
    } else {
      history.push('/login');
    }
  };
  return (
    <div className='payment-successful'>
      <meta name='description' content='Topship Africa Signup Successful' />
      <title>Topship Africa Signup Successful</title>
      <link rel='canonical' href='https://topship.africa/signup-successful' />
      <img src={signupSuccessIcon} alt='registration successfull' />

      <h1>Registration Successful</h1>

      <div className='mt-6 mb-3'>
        <SubmitButton
          text='Login'
          className='mt-3'
          disabled={false}
          loading={false}
          onClick={() => goToLogin()}
        />
      </div>
    </div>
  );
};

export default SignupSuccessful;

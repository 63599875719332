import {
  pickupOptions,
  lastMileShippingRates,
  shippingRates,
  shopnshipRates
} from '../constants/shipping-rates';
import insuranceOptions from '../constants/insurance-plans';
import vibStatusOptions from './enums/vib-status-types.enum';

const getPlan = (type, value) => {
  const plans = {
    shipping: shippingRates,
    shopnship: shopnshipRates,
    insurance: insuranceOptions,
    vibStatus: vibStatusOptions,
    pickup: pickupOptions,
    lastMile: lastMileShippingRates
  };

  if (!plans.hasOwnProperty(type)) return {};

  return plans[type].filter(plan => plan.value === value)[0];
};

export default getPlan;

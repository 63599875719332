import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

const DatePicker = ({
  onChange,
  value = Date.now(),
  openDatePicker,
  setOpenDatePicker
}) => {
  const ref = useRef();

  useEffect(() => {
    if (openDatePicker) {
      ref.current?.click();
      setOpenDatePicker(false);
    }
    //eslint-disable-next-line
  }, [openDatePicker]);

  const handleChange = newValue => {
    onChange?.(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        inputFormat='MM/dd/yyyy'
        disablePast
        shouldDisableDate={date => [0, 6].includes(date?.getDay())}
        value={value}
        OpenPickerButtonProps={{
          ref
        }}
        onChange={handleChange}
        renderInput={params => (
          <TextField
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 }
            }}
            {...params}
            variant='outlined'
            fullWidth
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;

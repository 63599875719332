import stickyNoteIcon from '../../assets/image/png/sticky-notes.png';
import {
  VolumetricWeightChina,
  VolumetricWeightIcon
} from '../../constants/asset-contants';

const StickyNoteAlert = ({
  children,
  isVolumetric,
  isSmallerScreen,
  chinaImport
}) => {
  return (
    <div
      className='alert alert-primary'
      role='alert'
      style={{
        fontSize: '1.5rem',
        marginBottom: '0px',
        backgroundColor: '#e6f2fe',
        color: '#22428F',
        border: 'none',
        borderRadius: '8px'
      }}
    >
      {isVolumetric ? (
        <div
          style={{
            position: 'relative',
            padding: '10px',
            fontSize: '1.3445rem'
          }}
        >
          <div
            style={{
              lineHeight: '2.3rem'
            }}
          >
            <strong>Volumetric Weight</strong> may apply if your package is
            shipped in a box OR has visible dimensions (i.e. square or
            rectangularly shaped).
            <br />
            <br />
            Volumetric weight formula: LxBxH/{chinaImport ? '6000' : '5000'}.
          </div>
          <div
            style={{
              marginTop: isSmallerScreen && '0'
            }}
            className='calculator-img-container'
          >
            <img
              src={chinaImport ? VolumetricWeightChina : VolumetricWeightIcon}
              alt='sticky note'
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                height: 'inherit',
                objectFit: 'contain'
              }}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            padding: '14px 0'
          }}
        >
          <img
            src={stickyNoteIcon}
            alt='sticky note'
            height={'40px'}
            style={{ marginRight: '10px' }}
          />
          <div
            style={{
              lineHeight: '2.3rem',
              paddingRight: '10px'
            }}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default StickyNoteAlert;

import React, { useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useHistory, useLocation } from 'react-router-dom';
import { seaFreightImportLinks } from '../../constants/shipment-links';
import { checkSeaFreightIsCompleted as checkData } from '../../pages/Dashboard/helper-functions';
import DeleteOverlay from '../delete-overlay';
import GetHelpModal from '../modals/get-help-modal';
import GuidesAndResourcesPrompt from '../modals/guides-and-resources';
import { saveShipmentDetailsState, warningState } from '../../recoil/atoms';
import useClearLocalStorage from '../../utilities/clear-localstorage';
import ShipmentHeader from '../shipment-header';

const SeaFreightQuoteLayout = ({ children }) => {
  const [shipmentDetail] = useRecoilState(saveShipmentDetailsState);

  const [openResources, setOpenResources] = useState(false);

  const [openGetHelpModal, setOpenGetHelpModal] = useState(false);

  const [, setShow] = useRecoilState(warningState);

  const history = useHistory();

  const clearStorage = useClearLocalStorage();

  const { pathname } = useLocation();

  const currentIndex = useMemo(() => {
    if (!pathname) {
      return -1;
    }

    return seaFreightImportLinks.findIndex(link => link.link === pathname);
  }, [pathname]);

  const handleClose = () => {
    clearStorage(() => {
      history.push('/');
    });
  };

  const checkSeaFreightIsCompleted = index => {
    return checkData(index, shipmentDetail);
  };

  const pushRoute = index => {
    if (currentIndex < index) {
      if (checkSeaFreightIsCompleted(currentIndex)) {
        history.push(seaFreightImportLinks[index].link);
      } else {
        history.push(seaFreightImportLinks[currentIndex].link, {
          reValidate: true
        });
      }
    } else {
      history.push(seaFreightImportLinks[index].link);
    }
  };

  const isStepActive = (index, link) => {
    return (
      index === 0 ||
      pathname === link.link ||
      checkSeaFreightIsCompleted(index) ||
      checkSeaFreightIsCompleted(index - 1)
    );
  };

  return (
    <div className='sea-freight'>
      <GetHelpModal
        openModal={openGetHelpModal}
        handleClose={() => setOpenGetHelpModal(false)}
      />
      <GuidesAndResourcesPrompt
        openPrompt={openResources}
        closePrompt={setOpenResources}
      />
      <DeleteOverlay
        warningMessage={'You might have unsaved changes'}
        deleteText={'Close'}
        onDelete={handleClose}
      />

      <ShipmentHeader
        shipmentType={'Import Via Sea'}
        setShow={setShow}
        setOpenResources={setOpenResources}
        setOpenGetHelpModal={setOpenGetHelpModal}
      />

      <div className='sea-freight__body'>
        <h2 className='heading mb-2'>Import Via Sea</h2>
        <p
          style={{
            color: '#545859',
            fontSize: '12px',
            fontWeight: 500
          }}
        >
          {currentIndex === 0
            ? `
            Please have these information ready before you start filling this form
            `
            : `Import heavy items at low rates with our Sea Freight service`}
        </p>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '40px 0'
            }}
          >
            {seaFreightImportLinks.map((link, index) => {
              return (
                <div
                  key={index}
                  onClick={() => pushRoute(index)}
                  style={{
                    flex: 1,
                    height: '8px',
                    margin: '0 5px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    backgroundColor: isStepActive(index, link)
                      ? '#22428F'
                      : '#E2E4E8',
                    color: 'white',
                    borderRadius: '5px'
                  }}
                ></div>
              );
            })}
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>{children}</div>
      </div>
    </div>
  );
};

export default SeaFreightQuoteLayout;

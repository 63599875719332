import { ReactComponent as WhatsappIcon } from '../assets/svg/whatsapp-3.svg';
import { ReactComponent as PhoneCall } from '../assets/svg/PhoneCall.svg';
import { ReactComponent as Email } from '../assets/svg/Envelope.svg';

const ContactSupport = () => {
  return (
    <section className='contact-support'>
      <div className='contact-support__container'>
        <div className='contact-support__text'>
          <h5>Need help? &nbsp; Talk to us!</h5>
        </div>
        <div className='contact-support__details'>
          <p>
            <a href='tel:02013302594' target='_blank' rel='noreferrer'>
              <PhoneCall className='contact-support__contact-icon' />
            </a>{' '}
            &nbsp;
            <a
              href='https://api.whatsapp.com/send?phone=2349080777728'
              target='_blank'
              rel='noreferrer'
            >
              <WhatsappIcon className='contact-support__contact-icon' />
            </a>{' '}
            &nbsp;
            <span>02013302594</span>
          </p>
          <p>
            <a
              href='mailto:hello@topship.africa'
              target='_blank'
              rel='noreferrer'
            >
              <Email className='contact-support__contact-icon' />
            </a>
            <span>hello@topship.africa</span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactSupport;

import {
  Attachment,
  NorthEast,
  Payment,
  People,
  Person,
  Settings,
  SouthEast,
  Summarize
} from '@mui/icons-material';

export const singleShipmentLinks = [
  {
    name: 'Sender Details',
    link: '/shipment-request-sender-information',
    initialLink: '/',
    icon: (
      <div className='two-icons'>
        <Person />
        <NorthEast />
      </div>
    )
  },
  {
    name: 'Receiver Details',
    link: '/shipment-request-receiver-information',
    initialLink: '/shipment-request-sender-information',
    icon: (
      <div className='two-icons'>
        <SouthEast /> <Person />
      </div>
    )
  },
  {
    name: 'Item Description',
    link: '/shipment-request-item-description',
    initialLink: '/shipment-request-receiver-information',
    icon: <Attachment />
  },
  {
    name: 'Delivery options',
    link: '/shipment-request-delivery-options',
    initialLink: '/shipment-request-item-description',
    icon: <Settings />
  },
  {
    name: 'Order Summary',
    link: '/shipment-request-order-summary',
    initialLink: '/shipment-request-delivery-options',
    icon: <Summarize />
  },
  {
    name: 'Payment',
    link: '/shipment-request-payment',
    initialLink: '/shipment-request-order-summary',
    icon: <Payment />
  }
];

export const multipleShipmentLinks = [
  {
    name: 'Sender Details',
    initialLink: '/',
    link: '/multiple-shipment-request-sender-information',
    icon: (
      <div className='two-icons'>
        <Person />
        <NorthEast />
      </div>
    )
  },
  {
    name: 'Receiver Details',
    link: '/multiple-shipment-request-receiver-information',
    initialLink: '/multiple-shipment-request-sender-information',
    icon: (
      <div className='two-icons'>
        <SouthEast /> <People />
      </div>
    )
  },
  {
    name: 'Delivery options',
    link: '/multiple-shipment-request-delivery-options',
    initialLink: '/multiple-shipment-request-receiver-information',
    icon: <Settings />
  },
  {
    name: 'Order Summary',
    link: '/multiple-shipment-request-order-summary',
    initialLink: '/multiple-shipment-request-delivery-options',
    icon: <Summarize />
  },
  {
    name: 'Payment',
    link: '/multiple-shipment-request-payment',
    initialLink: '/multiple-shipment-request-order-summary',
    icon: <Payment />
  }
];

export const seaFreightImportLinks = [
  {
    name: 'Welcome',
    link: '/sea-freight-welcome',
    initialLink: '/'
  },
  {
    name: 'Pickup Options',
    link: '/sea-freight-sender-information',
    initialLink: '/sea-freight-welcome'
  },
  {
    name: 'Item Description',
    link: '/sea-freight-item-description',
    initialLink: '/sea-freight-sender-information'
  },
  {
    name: 'Destination Address',
    link: '/sea-freight-receiver-information',
    initialLink: '/sea-freight-item-description'
  },
  {
    name: 'Package Upload',
    link: '/sea-freight-package-upload',
    initialLink: '/sea-freight-receiver-information'
  },
  {
    name: 'Booking Received',
    link: '/sea-freight-quote-successful',
    initialLink: '/sea-freight-package-upload'
  }
];

export const shipmentMessageSplitter = ';..';

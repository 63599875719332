import { FormControlLabel } from '@material-ui/core';
import { Button, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import paymentSuccessIcon from '../../assets/svg/booking-successful.svg';
import { useSaveFeedbackMutation } from '../../operations/mutations';
import useClearLocalStorage from '../../utilities/clear-localstorage';
import { useSendAlert } from '../../utilities/send-alert';
import BusyOverlay from '../busy-overlay';
import Input from '../new-dashboard/custom-input';
const BookingSuccessfulPrompt = ({
  openPrompt,
  setOpenPrompt,
  isChinaImport,
  handleRoute,
  isTrackingMode = false,
  setCloseTrackingModal
}) => {
  const feedbackOptions = {
    Error: 'Error',
    Suggestion: 'Suggestion',
    Others: 'Other'
  };
  const feedbackMessages = ['Excellent', 'Good', 'Okay', 'Poor', 'Very poor'];
  const sendAlert = useSendAlert();
  const [saveFeedback, { loading, data }] = useSaveFeedbackMutation();
  const clearStorage = useClearLocalStorage();
  const [rating, setRating] = useState(0);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedbackDetails, setFeedbackDetails] = useState('');
  const [feedbackOption, setFeedbackOption] = useState('');
  const history = useHistory();
  const changeRating = rating => {
    setRating(rating);
  };
  const handleOnDone = () => {
    saveFeedback({
      message: feedbackDetails || feedbackMessages.reverse()[rating - 1],
      rating,
      feedbackCategory: feedbackOption || feedbackOptions.Others
    });
  };
  const receiveFeedback = () => {
    setOpenFeedback(true);
  };
  useEffect(() => {
    ReactGA.event({
      category: 'Shipment Payment',
      action: 'User has successfully paid for shipment'
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (data) {
      clearStorage(() => {
        setOpenPrompt(false);
        sendAlert('Thank you for your feedback');
        if (isTrackingMode) {
          setCloseTrackingModal(false);
          history.push('/');
        } else {
          history.push('/shipment-payment-successful');
        }
      });
    }
    // eslint-disable-next-line
  }, [data]);
  return (
    <>
      <div
        className={`booking-successful__prompt fade${
          openPrompt ? ' show' : ''
        }`}
      >
        <BusyOverlay loading={loading} />
        <div className='booking-successful__prompt__wrap'>
          {isChinaImport ? null : (
            <div className='new-shop-n-ship__modal__header'>
              <div className='new-shop-n-ship__modal__header__wrap pl-3 pt-3 pb-3 pe-0'>
                <h1>Feedback</h1>
              </div>
            </div>
          )}
          <div
            className={`booking-successful__prompt__body__wrap${
              openFeedback ? ' openFeedback' : ''
            }`}
          >
            {openFeedback ? (
              <div className='new-shop-n-ship__prompt__body__options w-100'>
                <div className='new-shop-n-ship__prompt__body__options__wrap'>
                  <Input
                    customLabel='Tell us about your experience here'
                    placeholder='Do you have any suggestion or found some errors ? let us know.'
                    name='feedback'
                    value={feedbackDetails}
                    onChange={e => setFeedbackDetails(e.target.value)}
                    multiline
                    minRows={3}
                  />
                  <div className='new-shop-n-ship__prompt__body__options__list'>
                    {Object.keys(feedbackOptions).map(option => (
                      <FormControlLabel
                        style={{ transform: 'scale(1.5)' }}
                        label={option}
                        control={
                          <Checkbox
                            value={option}
                            onChange={e =>
                              setFeedbackOption(feedbackOptions[option])
                            }
                            checked={feedbackOption === feedbackOptions[option]}
                          />
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <>
                {isChinaImport ? (
                  <>
                    <div className='booking-successful__prompt__icon'>
                      <img src={paymentSuccessIcon} alt='Success' />
                    </div>
                    <div className='booking-successful__prompt__header'>
                      <h3>PAYMENT SUCCESSFUL</h3>
                    </div>
                    <div className='booking-successful__prompt__content'>
                      <span>
                        A receipt and your order booking information has been
                        sent to your email. You can edit or cancel this booking
                        within 2 hours.
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='booking-successful__prompt__icon'>
                      <img src={paymentSuccessIcon} alt='Success' />
                    </div>
                    <div className='booking-successful__prompt__header'>
                      <h3>BOOKING SUCCESSFUL</h3>
                    </div>
                    <div className='booking-successful__prompt__content'>
                      <span>
                        A receipt and your order booking information has been
                        sent to your email. Pick ups after 2pm will be handled
                        on the next business day.
                      </span>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className='booking-successful__prompt__footer'>
          {isChinaImport ? (
            <div
              style={{
                justifyContent: 'flex-end'
              }}
              className='booking-successful__prompt__footer__wrap'
            >
              <div className='booking-successful__prompt__actions'>
                <Button onClick={handleRoute}>Get Delivery Address</Button>
              </div>
            </div>
          ) : (
            <div className='booking-successful__prompt__footer__wrap'>
              <div className='booking-successful__prompt__footer__ratings'>
                <h4>RATE YOUR EXPERIENCE</h4>
                <StarRatings
                  rating={rating}
                  starDimension='2rem'
                  starSpacing='.3rem'
                  starRatedColor='gold'
                  changeRating={changeRating}
                />
              </div>
              <div className='booking-successful__prompt__actions'>
                <Button
                  disabled={!rating}
                  onClick={() => {
                    if (rating && rating < 5 && !openFeedback) {
                      receiveFeedback();
                    } else {
                      handleOnDone();
                    }
                  }}
                >
                  {openFeedback
                    ? 'Submit'
                    : rating && rating < 5
                    ? 'Continue'
                    : 'Done'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`booking-successful__prompt__backdrop fade${
          openPrompt ? ' show' : ''
        }`}
      ></div>
    </>
  );
};

export default BookingSuccessfulPrompt;

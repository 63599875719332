export const itemCategories = [
  // { category: 'Appliance', value: 'Appliance' },
  { category: 'Beauty products', value: 'BeautyProducts' },
  // { category: 'Jewelry', value: 'Jewellery' },
  { category: 'Computer supplies', value: 'ComputerSupplies' },
  { category: 'Home decor & accessories', value: 'HomeDecor' },
  { category: 'Baby supplies', value: 'BabySupplies' },
  // {
  //   category: 'Television & entertainment',
  //   value: 'TelevisionAndEntertainment'
  // },
  { category: 'Kitchen accessories', value: 'KitchenAccessories' },
  { category: 'Furniture', value: 'Furniture' },
  // { category: 'Fashion', value: 'Fashion' },
  { category: 'Education', value: 'Education' },
  // { category: 'Gadgets', value: 'Gadgets' },
  { category: 'Solar panels & inverter', value: 'SolarPanelsAndInverter' },
  { category: 'Vehicle parts', value: 'VehicleParts' },
  { category: 'Clothing & textile', value: 'ClothingAndTextile' },
  { category: 'Sport accessories', value: 'SportAccessories' },
  { category: 'Gym equipment', value: 'GymEquipment' },
  { category: 'Drones', value: 'Drones' },
  { category: 'Document', value: 'Document' },
  { category: 'Original Artwork', value: 'OriginalArtwork' },
  { category: 'Art Prints', value: 'ArtPrints' },
  // { category: 'Food Items', value: 'FoodItems' },
  { category: 'Medication', value: 'Medication' },
  // {
  //   category: 'Fish',
  //   value: 'Fish'
  // },
  // { category: 'Herbs', value: 'Herbs' },
  {
    category: 'Battery, liquid, electrical, and powder items',
    value: 'BatteryLiquidElectrical&PowderItems'
  },
  { category: 'Crayfish (Blended and Dried)', value: 'Crayfish' },
  { category: 'Dried Fish', value: 'Driedfish' },
  { category: 'Prawns', value: 'Prawns' },
  { category: 'Other Fish', value: 'Otherfish' },

  { category: 'Goat Meat', value: 'GoatMeat' },
  { category: 'Cow Skin ("Ponmo")', value: 'CowSkin' },
  { category: 'Beef', value: 'Beef' },
  { category: 'Snail', value: 'Snail' },
  { category: 'Other Meats', value: 'OtherMeats' },

  { category: 'Paintings, Drawings', value: 'PaintingsAndDrawings' },
  {
    category: 'Artifacts and historical monuments',
    value: 'ArtifactsAndHistoricalMonuments'
  },

  { category: 'Laptops, Tablets', value: 'LaptopsAndTablets' },
  { category: 'Phones', value: 'Phones' },
  {
    category: 'Headphones, Earphones, AirPods',
    value: 'HeadphonesOrEarphonesOrAirPods'
  },
  { category: 'Wristwatches', value: 'Wristwatches' },
  { category: 'Video games', value: 'VideoGames' },
  {
    category: 'Other Electronics or Gadgets',
    value: 'OtherElectronicsOrGadgets'
  },

  {
    category: 'Gold, Silver, and Fine Jewelry',
    value: 'GoldSilverAndFineJewelry'
  },
  { category: 'Precious stones and jewels', value: 'PreciousStonesAndJewels' },
  { category: 'Costume Jewelry', value: 'CostumeJewelry' },

  { category: 'Herbs & Plants', value: 'HerbsAndPlants' },

  { category: 'Foodstuff & Food products', value: 'FoodstuffAndFoodProducts' },
  {
    category: 'Drinks (alcoholic and non-alcoholic beverages)',
    value: 'Drinks'
  },
  { category: 'Others', value: 'Others' }
];

export const itemCategoriesChinaImport = [
  {
    category: 'Battery, liquid, electrical, and powder items',
    value: 'BatteryLiquidElectrical&PowderItems'
  },
  { category: 'Beauty products', value: 'BeautyProducts' },
  { category: 'Costume Jewelry', value: 'Jewellery' },
  { category: 'Medication', value: 'Medication' },

  // { category: 'Fashion', value: 'Fashion' },
  { category: 'Phones and Laptops', value: 'Gadgets' },
  { category: 'Clothing & textile', value: 'ClothingAndTextile' }
];

import { selector } from 'recoil';
import { loginState, shipmentRequestState } from './atoms';

const currentLoginState = selector({
  key: 'currentLoginState',
  get: ({ get }) => {
    return get(loginState);
  }
});

const currentRequestState = selector({
  key: 'currentRequestState',
  get: ({ get }) => get(shipmentRequestState)
});

export { currentLoginState, currentRequestState };

const currencyToLocaleMap = {
  NGN: 'en-NG',
  USD: 'en-US',
  GHS: 'en-GH',
  KES: 'en-KE',
  GBP: 'en-UK'
};

const getCurrencyLocale = currency => currencyToLocaleMap[currency];

export const toCurrency = (amount, currency) => {
  let selectedCountry = currency || 'NGN';
  selectedCountry = selectedCountry === 'GHC' ? 'GHS' : selectedCountry;
  return Number(amount).toLocaleString(getCurrencyLocale(selectedCountry), {
    style: 'currency',
    currency: selectedCountry
  });
};

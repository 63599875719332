import React, { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  Stack,
  Button,
  CardHeader,
  Alert
} from '@mui/material';
import blueArrow from '../../../assets/svg/blue-arrow.svg';
import Wrapper from '../../../components/Layouts/wrapper';
import Input from '../../../components/new-dashboard/custom-input';
import thirdPartyServices from '../../../constants/third-party-services';
import SubmitButton from '../../../components/submit-button';
import numberWithCommas from '../../../utilities/number-with-commas';
import { useRecoilState } from 'recoil';
import { userState, verificationStepsState } from '../../../recoil/atoms';
import { accountType } from '../../../constants/authentication';
import { useHistory } from 'react-router-dom';
import { useGetLogisticsBusinessConfigs } from '../../../operations/queries';
import BusyOverlay from '../../../components/busy-overlay';
import DeleteOverlay from '../../../components/delete-overlay';
import {
  useCreateOrUpdateLogisticsBusinessConfigs,
  useResendOTPMutation
} from '../../../operations/mutations';
import { useSendAlert } from '../../../utilities/send-alert';
import { useState } from 'react';
import omit from 'lodash.omit';
import { ChevronRight } from '@mui/icons-material';
import VerificationModal from './modals/Verification/verification.modal';
import VerificationPercentage from './modals/Verification/components/verification-progress';
import tickCircle from '../../../assets/image/tick-circle.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { shouldShowLocationPrompt } from '../helper-functions';

const defaultValues = {
  settings: thirdPartyServices.map(service => ({
    service: service.shortName,
    hasEmailNotification: false,
    hasSmsNotification: false,
    additionalCharge: 0,
    route: service.route,
    fullName: service.fullName
  }))
};

const Settings = () => {
  // const handleCopyLink = useHandleCopyLink();
  const [steps, setSteps] = useRecoilState(verificationStepsState);
  const history = useHistory();
  const [user] = useRecoilState(userState);
  const [getBusinessConfig, getConfigResult] = useGetLogisticsBusinessConfigs();
  const sendAlert = useSendAlert();
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [resendOtp, resendOtpResult] = useResendOTPMutation();
  const [openVerificationModal, setOpenVerificationModal] = useState(false);
  const [profilePrompt, setProfilePrompt] = useState(false);

  const validationSchema = yup.object().shape({
    settings: yup.array().of(
      yup.object().shape({
        service: yup.string().required('Please choose what service to update'),
        hasEmailNotification: yup.boolean(),
        hasSmsNotification: yup.boolean(),
        additionalCharge: yup
          .number()
          .min(0, 'Please enter your additional charge')
      })
    )
  });

  const newSteps = useMemo(
    () =>
      steps.map((step, index) => {
        if (index === 0) {
          return { ...step, isCompleted: user?.isEmailVerified };
        } else if (index === 1) {
          return {
            ...step,
            isCompleted: !!user?.businessInformation?.location
          };
        } else if (index === 2) {
          return {
            ...step,
            isCompleted: !!user?.businessInformation?.compliance
          };
        } else if (index === 3) {
          return {
            ...step,
            isCompleted: user?.businessInformation?.directors?.length
          };
        } else {
          return {
            ...step,
            isCompleted: user?.businessInformation?.directors?.every(
              director => !!director?.compliance
            )
          };
        }
      }),
    [steps, user]
  );
  const completedCount = useMemo(
    () => newSteps.filter(step => step.isCompleted).length,
    [newSteps]
  );

  const isCompletedButUnverified = useMemo(
    () =>
      newSteps.every(step => step.isCompleted) &&
      !user.businessInformation?.isVerified,
    [user, newSteps]
  );
  const percentage =
    completedCount > 0 ? Math.round((completedCount / 6) * 100) : 0;

  const [
    mutateConfig,
    mutateConfigResult
  ] = useCreateOrUpdateLogisticsBusinessConfigs(() =>
    sendAlert('Updated settings!')
  );

  const loading =
    getConfigResult.loading ||
    mutateConfigResult.loading ||
    resendOtpResult.loading;

  const { values, errors, handleChange } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {}
  });

  const handleUpdateSettings = setting => {
    mutateConfig(
      omit(setting, ['id', 'createdDate', 'updatedDate', 'fullName'])
    );
  };

  const handleSteps = () => {
    setSteps(newSteps);

    if (!newSteps[0].isCompleted) {
      resendOtp(user?.id);
    }

    setOpenVerificationModal(true);
  };

  useEffect(() => {
    getBusinessConfig();
    if (user.accountType && user.accountType !== accountType[2].value) {
      history.push('/');
    }

    //eslint-disable-next-line
  }, [user, history, getConfigResult.data]);
  useEffect(() => {
    const configs = getConfigResult.data?.getLogisticsBusinessConfigs || [];

    if (configs?.length) {
      const settings = [...values.settings];
      configs.forEach(config => {
        if (
          settings.find(
            setting =>
              setting.service === config?.service &&
              setting.route === config.route
          )
        ) {
          const index = settings.findIndex(
            setting =>
              setting.service === config?.service &&
              setting.route === config.route
          );
          if (index > -1) {
            settings[index] = { ...config, fullName: settings[index].fullName };
          }
        }
      });
      setInitialValues(values => ({
        ...values,
        settings
      }));
    }
    //eslint-disable-next-line
  }, [getConfigResult.data]);

  useEffect(() => {
    if (!!user?.businessInformation) {
      setProfilePrompt(false);
    } else {
      setProfilePrompt(true);
    }
  }, [profilePrompt, user?.businessInformation]);

  return (
    <Wrapper>
      <VerificationModal
        openModal={openVerificationModal}
        setOpenModal={setOpenVerificationModal}
      />
      <div className='config'>
        <div className='config__wrap'>
          <div className='new-referral'>
            <div className='config__heading'>
              {profilePrompt && (
                <div className='mb-3'>
                  <Alert
                    severity={'warning'}
                    sx={{
                      fontSize: '1.5rem',
                      fontFamily: 'General Sans'
                    }}
                  >
                    Your profile setup is incomplete. Click here to go to your{' '}
                    <a href='/profile'>profile page</a> and provide the required
                    information.
                  </Alert>
                </div>
              )}

              {shouldShowLocationPrompt(user) ? (
                <Link to={'/profile'}>
                  <div className='lsp-business-alert'>
                    <p className='mb-0 '>
                      📍 Add location to your profile to access features
                      <img className='pl-2' src={blueArrow} alt='' />
                    </p>
                  </div>
                </Link>
              ) : null}

              <div className='new-referral__content'>
                <div className='config__heading__wrap'>
                  <div className='new-referral__content__groups'>
                    <div className='new-referral__content__group'>
                      <div className='new-referral__content__header'>
                        <h2>
                          {/* Share your customized sign-up page with customers! */}
                          Set your pricing preference
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* {!user?.businessInformation?.isVerified ? (
                    <div className='new-referral__content__text'>
                      <span>
                        Your customized link will be generated once your
                        business has been verified.
                      </span>
                    </div>
                  ) : ( */}
                  <div>
                    <div className='new-referral__content__text'>
                      <span>
                        {/* Copy the link below and share it with new users
                          looking to access your rates. */}
                        Add your markup to Topship’s prices and click “Update”
                      </span>
                    </div>
                    {/* <div className='new-referral__content__cta mt-2'>
                        <div className='new-referral__content__cta__link'>
                          <span>
                            {window.innerWidth <= responsive.mobile.maximum
                              ? `https://ship.topship...`
                              : link}
                          </span>
                          <Button
                            disabled={!!profilePrompt}
                            variant='text'
                            onClick={() =>
                              handleCopyLink(
                                `${window.location.host}/signup?lsp=${user.topshipId}`
                              )
                            }
                          >
                            Copy link
                          </Button>
                        </div>
                      </div> */}
                  </div>
                  {/* )} */}
                </div>
                <div aria-label='Verify Business' className='config__status'>
                  <div className='config__status__wrap'>
                    {!user?.businessInformation?.isVerified ? (
                      <VerificationPercentage percentage={percentage} />
                    ) : (
                      <div className='config__status__icon'>
                        <img src={tickCircle} alt='' />
                      </div>
                    )}
                    <div className='config__status__heading'>
                      <p>Status</p>
                    </div>
                    <div className='config__status__text'>
                      <h4>
                        {isCompletedButUnverified
                          ? 'Pending'
                          : `${
                              !user?.businessInformation?.isVerified
                                ? 'Not '
                                : ''
                            }Verified`}
                      </h4>
                      {!user?.businessInformation?.isVerified && (
                        <div className='config__status__action mt-2'>
                          <Button
                            onClick={handleSteps}
                            disabled={!!profilePrompt}
                          >
                            <span>{`${
                              isCompletedButUnverified ? 'Update' : 'Complete'
                            } Verification`}</span>
                            <ChevronRight />
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*
          <div className='mt-1'></div>
          <div
            aria-label='Pricing Control'
            className='new-referral__content__group'
          >
             <div className='new-referral__content__header'>
              <h2
                style={{
                  width: 'fit-content'
                }}
              >
                Set your pricing preference
              </h2>
            </div>
            <div className='new-referral__content__text'>
              <span>
                Add your markup to Topship’s prices and click “Update”
              </span>
            </div> 
          </div>
            */}
          <TableContainer
            component={Paper}
            className={`table-container${
              !user?.businessInformation?.isVerified ? ' disabled' : ''
            }`}
          >
            <Table>
              <TableHead>
                <TableRow className=''>
                  <TableCell
                    align='left'
                    sx={{
                      width: '12vw'
                    }}
                  >
                    SERVICE
                  </TableCell>
                  <TableCell
                    align='left'
                    sx={{
                      width: '18vw'
                    }}
                  >
                    ADDITIONAL CHARGE
                  </TableCell>
                  {/* <TableCell align='left' className='notification-header'>
                    NOTIFICATIONS
                  </TableCell> */}
                  <TableCell align='left' className='action-header'>
                    ACTION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.settings.map((setting, index) => (
                  <TableRow
                    key={index}
                    sx={{ background: index % 2 ? '' : '#F8FBFF' }}
                  >
                    <TableCell
                      align='left'
                      sx={{
                        width: '12vw'
                      }}
                    >
                      <h3>{setting.fullName}</h3>
                    </TableCell>
                    <TableCell
                      align='left'
                      sx={{
                        width: '18vw'
                      }}
                    >
                      <div
                        style={{
                          width: '70%'
                        }}
                      >
                        <Input
                          name={`settings.${index}.additionalCharge`}
                          value={`${numberWithCommas(
                            setting.additionalCharge
                          )}`}
                          onChange={event => {
                            const { value, name } = event.target;
                            if (!(value && value.length)) {
                              handleChange({ target: { name, value: 0 } });
                            } else if (!isNaN(value.split(',').join(''))) {
                              const itemValue = parseInt(
                                value.split(',').join(''),
                                10
                              );
                              handleChange({
                                target: { name, value: itemValue }
                              });
                            }
                          }}
                          error={!!errors.settings?.[index]?.additionalCharge}
                          helperText={
                            errors.settings?.[index]?.additionalCharge
                          }
                        />
                      </div>
                    </TableCell>
                    {/* <TableCell align='left' className='notification-header'>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={setting.hasSmsNotification}
                            onChange={() =>
                              setFieldValue(
                                `settings.${index}.hasSmsNotification`,
                                !setting.hasSmsNotification
                              )
                            }
                          />
                        }
                        label='Enable SMS Notifications'
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={setting.hasEmailNotification}
                            onChange={() =>
                              setFieldValue(
                                `settings.${index}.hasEmailNotification`,
                                !setting.hasEmailNotification
                              )
                            }
                          />
                        }
                        label='Enable Email Notifications'
                      />
                    </TableCell> */}
                    <TableCell align='left' className='action-header'>
                      <div className=''>
                        <SubmitButton
                          className={'submit'}
                          onClick={() => handleUpdateSettings(setting)}
                          text={'Update'}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className='config__mobile'>
        <div className='config__mobile__wrap'>
          <div className='config__mobile__settings'>
            {values.settings.map((setting, index) => (
              <Card
                key={index}
                elevation={6}
                className='config__mobile__setting'
              >
                <CardHeader
                  title={setting.fullName}
                  titleTypographyProps={{ sx: { fontSize: '2rem' } }}
                />

                <CardContent>
                  <Stack className='config__mobile__setting__stack'>
                    {/* <FormControlLabel
                      sx={{ '& > span': { fontSize: '1.5rem' } }}
                      control={
                        <Switch
                          checked={setting.hasSmsNotification}
                          onChange={() =>
                            setFieldValue(
                              `settings.${index}.hasSmsNotification`,
                              !setting.hasSmsNotification
                            )
                          }
                        />
                      }
                      label='Enable SMS Notifications'
                    />
                    <FormControlLabel
                      sx={{ '& > span': { fontSize: '1.5rem' } }}
                      control={
                        <Switch
                          checked={setting.hasEmailNotification}
                          onChange={() =>
                            setFieldValue(
                              `settings.${index}.hasEmailNotification`,
                              !setting.hasEmailNotification
                            )
                          }
                        />
                      }
                      label='Enable Email Notifications'
                    /> */}
                    <Input
                      name={`settings.${index}.additionalCharge`}
                      value={`${numberWithCommas(setting.additionalCharge)}`}
                      onChange={event => {
                        const { value, name } = event.target;
                        if (!(value && value.length)) {
                          handleChange({ target: { name, value: 0 } });
                        } else if (!isNaN(value.split(',').join(''))) {
                          const itemValue = parseInt(
                            value.split(',').join(''),
                            10
                          );
                          handleChange({
                            target: { name, value: itemValue }
                          });
                        }
                      }}
                      error={!!errors.settings?.[index]?.additionalCharge}
                      helperText={errors.settings?.[index]?.additionalCharge}
                      customLabel='Additional Charge'
                    />
                  </Stack>
                </CardContent>
                <CardActionArea>
                  <CardActions>
                    <SubmitButton
                      text={'Update'}
                      className={'submit'}
                      onClick={() => handleUpdateSettings(setting)}
                      disabled={!user?.businessInformation?.isVerified}
                    />
                  </CardActions>
                </CardActionArea>
              </Card>
            ))}
          </div>
        </div>
      </div>

      <BusyOverlay loading={loading} />
      <DeleteOverlay />
    </Wrapper>
  );
};

export default Settings;

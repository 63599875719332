import gql from 'graphql-tag';
import axios from 'axios';

const CREATE_USER = gql`
  mutation createNewUser($newUser: RegistrationInput!) {
    signup(registrationInput: $newUser) {
      id
      accountType
    }
  }
`;

const VERIFY_EMAIL = gql`
  mutation verifyEmail($input: VerifyEmailInput!) {
    verifyEmail(verifyEmailInput: $input) {
      accessToken
      isActive
    }
  }
`;

const RESEND_OTP = gql`
  mutation resendOTP($id: String) {
    resendOTP(userId: $id) {
      status
      message
    }
  }
`;

const LOGIN_USER = gql`
  mutation loginUser($existingUser: LoginInput!) {
    login(loginInput: $existingUser) {
      accessToken
    }
  }
`;

const SAVE_SHIPMENT = gql`
  mutation saveShipment(
    $coupon: String
    $shipmentDetails: [AddShipmentInput!]!
  ) {
    saveShipment(coupon: $coupon, shipment: $shipmentDetails) {
      id
      trackingId
      isPaid
      shipmentStatus
      totalCharge
      shipmentRoute
      pricingTier
      valueAddedTaxCharge
      logisticsBusinessId {
        topshipId
      }
      receiverDetail {
        name
        addressLine1
        email
        phoneNumber
        state
        countryCode
      }
      pickupTrackingUrl
      currency
      user {
        id
        topshipId
        fullName
      }
      transaction {
        id
        finalBalance
      }
    }
  }
`;

const UPDATE_SHIPMENT = gql`
  mutation updateShipment($update: UpdateShipmentInput!, $id: String!) {
    updateShipment(update: $update, id: $id) {
      id
      shipmentStatus
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($update: UserUpdate, $topshipId: String!) {
    updateUser(update: $update, topshipId: $topshipId) {
      id
      topshipId
      accountType
      phoneNumber
      percentageCommissionOnUser
      displayPictureUrl
    }
  }
`;

const CANCEL_SHIPMENT = gql`
  mutation cancelShipment($id: String!) {
    cancelShipment(id: $id) {
      id
    }
  }
`;

const PAY_FROM_WALLET = gql`
  mutation payFromWallet($detail: PayFromWalletInput) {
    payFromWallet(detail: $detail) {
      id
      isPaid
    }
  }
`;

const PAY_ADDITIONAL_CHARGE_BY_WALLET = gql`
  mutation payAdditionalChargeByWallet($transactionId: ID!) {
    payAdditionalChargeByWallet(transactionId: $transactionId) {
      transactionId
      id
    }
  }
`;

const SAVE_ADDRESS = gql`
  mutation saveAddress($address: AddAddressInput!) {
    saveAddress(address: $address) {
      state
    }
  }
`;

const DELETE_ADDRESS = gql`
  mutation deleteAddress($id: String!) {
    deleteAddress(id: $id) {
      id
    }
  }
`;

const FORGOT_PASSWORD = gql`
  mutation forgotPassword($userData: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordInput: $userData) {
      fullName
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation updatePassword($newPassword: String!) {
    updatePassword(newPassword: $newPassword) {
      id
    }
  }
`;

const UPDATE_ADDRESS = gql`
  mutation updateAddress($update: UpdateAddressInput, $id: String!) {
    updateAddress(update: $update, id: $id) {
      id
    }
  }
`;

const SAVE_BANK_DETAILS = gql`
  mutation saveBankDetail($bankDetail: SaveBankDetailInput!) {
    saveBankDetail(bankDetail: $bankDetail) {
      id
    }
  }
`;

const DELETE_BANK_DETAILS = gql`
  mutation deleteBankDetail($id: String!) {
    deleteBankDetail(id: $id) {
      id
    }
  }
`;

const INITIATE_WITHDRAWAL = gql`
  mutation initiateWithdrawal($withdrawInput: WithdrawInput!) {
    initiateWithdrawal(withdrawInput: $withdrawInput) {
      status
      data {
        id
      }
    }
  }
`;

const CONFIRM_WITHDRAWAL_REQUEST = gql`
  mutation confirmWithdrawalRequest($confirmationInput: ConfirmationInput!) {
    confirmWithdrawalRequest(confirmationInput: $confirmationInput) {
      status
      message
    }
  }
`;

const SAVE_FEEDBACK = gql`
  mutation saveFeedback($feedback: SaveFeedbackInput!) {
    saveFeedback(feedback: $feedback) {
      rating
    }
  }
`;

const SHOP_N_SHIP = gql`
  mutation shopNShip($coupon: String, $shopnship: AddShopnshipInput!) {
    saveShopnship(coupon: $coupon, shopnship: $shopnship) {
      id
      initialCharge
      totalCharge
      pricingTier
      email
      phoneNumber
      shippingFrom
      packageName
      trackingNumber
      deliveryName
      deliveryAddress
      deliveryCity
      deliveryState
      lastMileShipmentId
      estimatedWeight
      itemDescription
      amountDue
      receiverPaymentDetails
      uploadingMyDocuments
      proofOfId
      category
      evidenceOfPurchase
      couponApplied
      couponAppliedAmount
      insuranceCharge
      insuranceType
      lastMileShipmentId
      user {
        id
        topshipId
        email
      }
    }
  }
`;

const UPDATE_SHOPNSHIP = gql`
  mutation updateShopnship($update: UpdateShopnshipInput, $id: String!) {
    updateShopnship(update: $update, id: $id) {
      id
      totalCharge
    }
  }
`;

const GET_CITIES_MUTATION = async params => {
  const { countryCode, cityName } = params;
  return await axios.get(
    `${process.env.REACT_APP_DHL_API}?city=${cityName || 'a'}&countryCode=${
      countryCode || 'NG'
    }`
  );
};

const CHANGE_PASSWORD = gql`
  mutation changePassword($resetPasswordInput: ResetPasswordInput!) {
    changePassword(resetPasswordInput: $resetPasswordInput) {
      id
    }
  }
`;

const CREATE_OR_UPDATE_LOGISTICS_BUSINESS_CONFIGS = gql`
  mutation createOrUpdateLogisticsBusinessConfig(
    $configInput: LogisticsBusinessConfigInput
  ) {
    createOrUpdateLogisticsBusinessConfig(configInput: $configInput) {
      service
      additionalCharge
      hasEmailNotification
      hasSmsNotification
      route
    }
  }
`;

const UPDATE_BUSINESS_INFORMATION = gql`
  mutation updateBusiness(
    $update: UpdateBusinessInformationInput!
    $businessId: String!
  ) {
    updateBusinessInformation(update: $update, businessId: $businessId) {
      id
    }
  }
`;

const CREATE_BUSINESS_INFORMATION = gql`
  mutation createBusinessInformation($input: CreateBusinessInformationInput!) {
    createBusinessInformation(input: $input) {
      id
      logo
      businessName
      isVerified
    }
  }
`;

const UPLOAD_SHIPMENT_DOCUMENTS = gql`
  mutation uploadShipmentDocuments($input: UploadShipmentDocumentsInput!) {
    uploadShipmentDocuments(input: $input) {
      id
      documents {
        name
      }
    }
  }
`;

export {
  CREATE_USER,
  LOGIN_USER,
  PAY_FROM_WALLET,
  SAVE_SHIPMENT,
  SAVE_ADDRESS,
  DELETE_ADDRESS,
  FORGOT_PASSWORD,
  UPDATE_PASSWORD,
  SAVE_BANK_DETAILS,
  DELETE_BANK_DETAILS,
  INITIATE_WITHDRAWAL,
  CONFIRM_WITHDRAWAL_REQUEST,
  SAVE_FEEDBACK,
  SHOP_N_SHIP,
  UPDATE_ADDRESS,
  CANCEL_SHIPMENT,
  CHANGE_PASSWORD,
  UPDATE_SHOPNSHIP,
  CREATE_OR_UPDATE_LOGISTICS_BUSINESS_CONFIGS,
  UPDATE_USER,
  VERIFY_EMAIL,
  RESEND_OTP,
  GET_CITIES_MUTATION,
  UPDATE_BUSINESS_INFORMATION,
  CREATE_BUSINESS_INFORMATION,
  PAY_ADDITIONAL_CHARGE_BY_WALLET,
  UPDATE_SHIPMENT,
  UPLOAD_SHIPMENT_DOCUMENTS
};

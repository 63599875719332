import React from 'react';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import GuidesAndResourcesComponent from '../resources-component';
function GuidesAndResourcesPrompt({ openPrompt, closePrompt }) {
  return (
    <div className={`delete-overlay ${openPrompt && 'delete-overlay--show'}`}>
      <div className='resource-prompt-container'>
        <div className='resources'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '20px',
              border: '1px solid #D8D8D8',
              backgroundColor: '#fff !important'
            }}
          >
            <h3 className='mb-0'>Guides & Resources</h3>
            <div>
              <IconButton onClick={() => closePrompt(false)}>
                <Close />
              </IconButton>
            </div>
          </div>
          <GuidesAndResourcesComponent />
        </div>
      </div>
    </div>
  );
}
export default GuidesAndResourcesPrompt;

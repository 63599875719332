const shipmentStatusDictionary = value => {
  const shipmentStatuses = {
    Draft: 'Draft',
    Confirmed: 'Confirmed',
    Cancelled: 'Cancelled',
    Delivered: 'Delivered',
    InTransit: 'In Transit',
    AwaitingPickUp: 'Awaiting PickUp',
    PickupCancelled: 'Pickup Cancelled',
    PickupDispatched: 'Pickup Dispatched',
    SuccessfullyPicked: 'Successfully Picked',
    PickupCancelledByCourier: 'Pickup Cancelled By Courier',
    PickupCancelledByMerchant: 'Pickup Cancelled By Merchant',
    PickupCancelledInternally: 'Pickup Cancelled Internally',
    PickupRescheduledByMerchant: 'Pickup Rescheduled ByMerchant',
    DeliveryFailed: 'Delivery Failed',
    AwaitingDropOff: 'Awaiting DropOff',
    DeliveryInProgress: 'Delivery In Progress',
    AssignedForDelivery: 'Assigned For Delivery',
    PendingConfirmation: 'Pending Confirmation',
    ClarificationNeeded: 'Clarification Needed',
    ReceivedAtHub: 'Received At Hub',
    ArrivedNigeria: 'Arrived Nigeria',
    PickupInProgress: 'Pickup In Progress',
    ShipmentProcessing: 'Shipment Processing',
    PaymentPending: 'Payment Pending',
    CancellationPending: 'Cancellation Pending',
    AwaitingDocumentUpload: 'Awaiting Document Upload',
    DocumentUploaded: 'Document Uploaded'
  };
  return shipmentStatuses[value] || 'Status Unavailable';
};

export const pickupPartnersWithBadImages = ['Dellyman', 'Fez'];

export const itemCategoryDocumentSettings = {
  'Snail Classification Form': {
    uploadMessage:
      'A snail classification form is required for customs clearance to process your shipment.'
  },
  'Phytosanitary Certificate': {
    uploadMessage:
      'All herbal products require a phytosanitary certificate for customs clearance.'
  },
  "Doctor's Prescription": {
    uploadMessage:
      "All pharmaceutical drugs require a doctor's prescription for customs clearance."
  },
  'FDA Form': {
    uploadMessage:
      'Food & drinks shipping to the USA must have an FDA form attached.'
  },
  Addendum: {
    uploadMessage:
      'Clothing and textiles shipping to the USA and Australia must have an Addendum attached.'
  },
  'Museum Certificate': {
    uploadMessage: 'A museum certificate is required for customs clearance.'
  }
};

export default shipmentStatusDictionary;

import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

export default function usePlaces() {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading
  } = usePlacesAutocompleteService({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    debounce: 1500
  });

  const getPlaces = input => {
    getPlacePredictions({
      input
    });
  };

  const placesResult = {
    loading: isPlacePredictionsLoading,
    data: placePredictions
  };

  return {
    getPlaces,
    placesService,
    placesResult
  };
}

import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { saveShipmentDetailsState } from '../../recoil/atoms';
import Input from '../new-dashboard/custom-input';

const AdditionalNotesPrompt = ({ openPrompt, setOpenPrompt }) => {
  const [shipmentDetail, setShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  const [additionalInformation, setAdditionalInformation] = useState(
    shipmentDetail.additionalInformation
  );
  const onClosePrompt = () => {
    setOpenPrompt(false);
  };
  const onNextFunction = () => {
    setShipmentDetail({
      ...shipmentDetail,
      additionalInformation
    });
    setOpenPrompt(false);
  };
  return (
    <>
      <div
        className={`new-shop-n-ship__prompt fade${openPrompt ? ' show' : ''}`}
      >
        <div className='new-shop-n-ship__prompt__wrap'>
          <div className='new-shop-n-ship__prompt__header'>
            <h2>Additional Notes</h2>
          </div>
          <div className='new-shop-n-ship__prompt__body'>
            <div className='new-shop-n-ship__prompt__body__wrap'>
              <div className='new-shop-n-ship__prompt__body__options'>
                <div className='new-shop-n-ship__prompt__body__options__wrap'>
                  <div className='new-shop-n-ship__prompt__body__options__header'>
                    <span>
                      Would you like to give us additional notes about the
                      shipment?
                    </span>
                  </div>
                  <Input
                    name='additionalInformation'
                    customLabel='Additional Notes'
                    multiline
                    minRows={4}
                    value={additionalInformation}
                    onChange={event =>
                      setAdditionalInformation(event.target.value)
                    }
                    placeholder='A piece of text that clearly describes how to handle this shipment, it should leave no room for guesses so that TopShip can know how best to handle it'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='new-shop-n-ship__prompt__footer'>
            <div className='new-shop-n-ship__prompt__footer__wrap'>
              <div className='new-shop-n-ship__prompt__footer__actions'>
                <button onClick={onClosePrompt}>Close</button>
                <button
                  disabled={
                    !(
                      additionalInformation ||
                      shipmentDetail.additionalInformation
                    )
                  }
                  onClick={onNextFunction}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`new-shop-n-ship__prompt__backdrop fade${
          openPrompt ? ' show' : ''
        }`}
      ></div>
    </>
  );
};

export default AdditionalNotesPrompt;

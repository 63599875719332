export const extractDimensions = manualBoxType => {
  const dimensions = manualBoxType.match(/\d+/g);

  if (dimensions && dimensions.length === 3) {
    const [width, length, height] = dimensions.map(Number);
    return { width, length, height };
  } else {
    throw new Error(
      'Invalid format. Please enter dimensions in the format: width x length x height.'
    );
  }
};

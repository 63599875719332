const BookShipmentContentItem = ({ onClick, selected, option }) => {
  return (
    <div
      onClick={onClick}
      className={`book-shipment-content-item ${
        selected && 'book-shipment-content-item-selected'
      }`}
    >
      <div className='book-shipment-method-title-container'>
        <p className='book-shipment-content-item-title'>{option?.title}</p>
        {option.dropoff && <p>FREE</p>}
        {option.pickup && <p>₦500</p>}
        {(option.title === 'Import Via Sea' || option.value === 'Import') && (
          <p className='mb-0 new-badge'>NEW</p>
        )}
      </div>
      <p>{option?.description}</p>
    </div>
  );
};

export default BookShipmentContentItem;

import * as moment from 'moment';

export const getNewUserDate = userDate => {
  const userCreatedDate = moment(new Date(userDate)).format('YYYY-MM-DD');
  const todaysDate = moment(new Date()).format('YYYY-MM-DD');
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() - 5);
  const isSameDay = userCreatedDate === todaysDate;
  const isWithinFiveDays =
    userCreatedDate >= moment(new Date(maxDate)).format('YYYY-MM-DD');
  return isSameDay || isWithinFiveDays;
};

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  input: {
    background: '#FFFFFF'
  },
  autoComplete: {
    fontSize: '16px'
  },
  option: {
    fontSize: '12px'
  },
  textField: {
    fontSize: '1.5rem'
  }
}));

export const defaultTestimonialData = {
  description:
    '"Topship has provided a very reliable service from our base in Lagos, Nigeria around the world."',
  author: 'Reni Abina - Owner and Creative Director, Rendoll',
  company: 'Rendoll',
  videoId: 'fa8WQ9CoSWo'
};

const faqQuestions = [
  {
    question: 'How much should I budget for Customs Clearance?',
    answer: (
      <div>
        <p>
          Customs clearance can be unpredictable as they are at the discretion
          of customs officials and import regulations in the recipient’s
          country. However, the charge depends on the declared value of your
          package and is typically 30% - 50% of the declared value.
        </p>
        <p>
          Please note that declaring a lower value than your item is worth could
          attract an additional fine from customs officials.
        </p>
        <p>
          To waive this charge, consider shipping with Topship’s Budget service
          (export) or Shop and Ship service (import).
        </p>
      </div>
    ),
    expand: false
  },

  {
    question: 'What food stuff can I ship?',
    answer: (
      <div>
        <p>
          You can ship grains, beans, spices, dry pepper, ogbono, egusi
          (grounded), Crayfish (Blended and Dried), prawns, dry fish, and oils
          (vegetable, palm, coconut, castor, etc.)
        </p>
        <p>
          If shipping dried fish, please note that you can only ship a maximum
          of 10kg per waybill
        </p>
        <p>
          If shipping goat meat, please note that you can only ship a maximum of
          2kg per waybill, and it can only be shipped with our Budget service.
        </p>
        <p>
          At the moment, you can ship foodstuff only to Canada, the UK, and the
          US guaranteed. If you're looking to ship to an unlisted country,
          please contact support to inquire about import laws. Email us at
          hello@topship.africa If shipping snails, please note that a snail
          classification form is required, and you can only ship a maximum of
          2kg per waybill. Also, snails can only be shipped with our Express or
          Budget service.
        </p>
      </div>
    ),
    expand: false
  },
  {
    question: 'What foodstuff is prohibited?',
    answer:
      'Prohibited foodstuffs include cow skin (ponmo), beef, fresh fish, and pre-cooked meals due to strict customs and import laws.',
    expand: false
  },
  {
    question: 'Where can i ship foodstuff to?',
    answer: (
      <div>
        <p>
          At the moment, you can ship foodstuff only to Canada, the UK, and the
          US guaranteed. If you're looking to ship to an unlisted country,
          please contact support to inquire about import laws. Email us at
          hello@topship.africa
        </p>
      </div>
    ),
    expand: false
  },

  {
    question: 'How much should I budget for Customs Clearance?',
    answer: (
      <div>
        <p>
          Customs clearance can be unpredictable as they are at the discretion
          of customs officials and import regulations in the recipient’s
          country. However, the charge depends on the declared value of your
          package and is typically 30% - 50% of the declared value.
        </p>{' '}
        <p>
          Please note that declaring a lower value than your item is worth could
          attract an additional fine from customs officials.
        </p>
        <p>
          To waive this charge, consider shipping with Topship’s{' '}
          <a
            target={'_blank'}
            rel='noreferrer'
            href='https://topship.africa/exports'
          >
            Budget service
          </a>{' '}
          (export) or{' '}
          <a
            target={'_blank'}
            rel='noreferrer'
            href='https://topship.africa/shop-&-ship'
          >
            Shop and Ship
          </a>{' '}
          service (import).
        </p>
      </div>
    ),
    expand: false
  },
  {
    question:
      'How do I import or export without paying customs clearance fees?',
    answer: (
      <div>
        <p>
          Consider shipping with Topship’s{' '}
          <a
            target={'_blank'}
            rel='noreferrer'
            href='https://topship.africa/exports'
          >
            Budget service
          </a>{' '}
          (export) or{' '}
          <a
            target={'_blank'}
            rel='noreferrer'
            href='https://topship.africa/shop-&-ship'
          >
            Shop and Ship
          </a>{' '}
          service (import).{' '}
        </p>
      </div>
    ),
    expand: false
  }
];

export const shopnshipFaqQuestions = [
  {
    question: (
      <p className='fs-3 shopnship-paragraph'>
        <span className='bold'>How do I begin?</span>
      </p>
    ),

    answer: (
      <div className='d-flex flex-column' style={{ gap: '10px' }}>
        <p className='shopnship-paragraph'>
          So, you’ve decided to buy an item from an international store like
          Amazon, Asos, or Zara. You add items to your cart, and upon checkout,
          you must input your delivery and contact information. If you’d like to
          receive your items via Topship Shop & Ship, follow these steps first:
        </p>
        <p className='shopnship-paragraph'>
          <span className='bold'>01. </span>
          To help us identify your package, add the appropriate identifier tag
          to your name. This differs depending on your selected service (i.e.
          Express, Standard). Please fill out the Shop & Ship form to find the
          right identification tag process for your order.
        </p>

        <p className='shopnship-paragraph'>
          <span className='bold'>02. </span>
          Once this is done, select and enter the address provided for your
          service of choice.
        </p>
        <p className='shopnship-paragraph'>
          <span className='bold'>03.</span>
          Need a phone number to complete your order. Use this:
          <br />
          <br />
          Standard Service:
          <br />
          UK: +447405702886
          <br />
          USA: +19152293261
          <br />
          <br />
          Express Service:
          <br />
          UK: 01753-210399
          <br />
          USA: 941 326 2501
          <br />
          CAN: 001 (905) 238 0440
          <br />
          <i>
            *For delivery purposes only. Not to be contacted for shipment
            updates or any enquiries.
          </i>
        </p>
      </div>
    ),
    expand: true
  },
  {
    question: (
      <p className='fs-3 shopnship-paragraph'>
        <span className='bold'>
          I’ve shipped my items to your UK/US/CANADA address, now what?
        </span>
      </p>
    ),
    answer: (
      <div className='d-flex flex-column' style={{ gap: '10px' }}>
        <p className='shopnship-paragraph'>
          <span className='bold'>01. </span>
          Click ‘Continue’ to fill out the Shop & Ship booking form.
        </p>
        <p className='shopnship-paragraph'>
          <span className='bold'>02. </span>
          Tell us about the items you’re shipping and ensure that the
          information you enter is accurate.
        </p>

        <p className='shopnship-paragraph'>
          <span className='bold'>03. </span>n line with our fraud protection
          policy, we require you to upload your proof of identification (a
          government-issued ID) and clear evidence of payment (bank statement or
          a transaction receipt from your bank). If you used someone else’s
          debit/credit card to shop, please provide said person’s ID and
          evidence of payment.
        </p>
        <p className='shopnship-paragraph'>
          <span className='bold'>04. </span>
          Once you complete the form and pay for your booking, you’re all set!
          Check your email for a booking confirmation.
        </p>
      </div>
    ),

    expand: false
  },
  {
    question: (
      <p className='fs-3 shopnship-paragraph'>
        <span className='bold'>
          What else do I need to know? (Important Notice)
        </span>
      </p>
    ),
    answer: (
      <div className='d-flex flex-column' style={{ gap: '10px' }}>
        <p className='shopnship-paragraph'>
          <span className='bold'>01. </span>
          For the Standard service, Items are shipped out every Friday. This
          means that the 7 - 14 day delivery window begins from the Friday of
          the week your package arrives at the International hub.
        </p>
        <p className='shopnship-paragraph'>
          <span className='bold'>02. </span>
          Sometimes, the weight of your items may be lower or higher than the
          weight you disclosed. When this happens, we will contact you (via
          email and phone) about the weight difference and either request
          payment of the balance or refund your wallet with the excess charge.
        </p>
        <p className='shopnship-paragraph'>
          <span className='bold'>03. </span>
          <strong>Volumetric Weight</strong> may apply if your package is
          shipped in a box OR has visible dimensions (e.g., square or
          rectangular). This means that your final shipping charge may be based
          on the Volumetric Weight of your package rather than the Actual
          Weight. The volumetric weight formula is LxBxH/5000.
        </p>

        <p className='shopnship-paragraph'>
          <span className='bold'>04. </span>
          If packages aren’t labelled with the appropriate identifier tag, we
          won’t be able to claim them or ship them to Nigeria
        </p>
        <p className='shopnship-paragraph'>
          <span className='bold'>05. </span>
          All packages are shipped to our Lagos hubs. Delivery to other states
          in Nigeria will be charged separately.
        </p>
      </div>
    ),

    expand: false
  }
];
export default faqQuestions;

import { useFormik } from 'formik';
import { useSendAlert } from '../../../utilities/send-alert';
import * as yup from 'yup';
import axios from 'axios';
import { PointerIcon } from '../../../constants/asset-contants';
import { parseGraphqlError } from '../../../utilities/parse-graphql-error';

export const ContactForm = ({ serviceTitle }) => {
  const sendAlert = useSendAlert();
  const SLACK_URL = process.env.REACT_APP_SLACK_API;

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,

    resetForm
  } = useFormik({
    initialValues: {
      phoneNumber: '',
      firstName: '',
      question: '',
      email: ''
    },
    validationSchema: yup.object().shape({
      phoneNumber: yup.number().required('Please enter a phone number'),
      firstName: yup.string().required('Please enter a name'),
      question: yup.string().required('Please enter your question'),
      email: yup.string().required('Please enter your email')
    }),
    onSubmit: async values => {
      try {
        const response = await axios.post(SLACK_URL, {
          text: `First Name: ${values.firstName}\nPhone Number: ${values.phoneNumber}\nEmail Address: ${values.email}\nQuestion: ${values.question}\nService: ${serviceTitle}`,
          channel: 'merchant-acquisition'
        });

        if (response.data) {
          sendAlert(
            'Question submitted successfully. We would contact you shortly!',
            'success'
          );
          resetForm();
        }
      } catch (error) {
        sendAlert(parseGraphqlError(error), 'error');

        throw error;
      }
    }
  });
  return (
    <div id='contact-form' className='get-help-form'>
      <div className={`get-help-form__contact-form`}>
        <div className='pointer-div'>
          <img src={PointerIcon} alt='' />
        </div>

        <div className='lets-chat'>
          <h3 className='heading mx-0 mb-2'>Contact Sales</h3>
          <p className='paragraph'>
            Need answers or assistance? We're here to help.
          </p>
        </div>
        <div className='form-container'>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                border: '1px solid #ddd',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '12px'
              }}
            >
              <div className='form-row'>
                <div className='form-group'>
                  <label htmlFor='firstName'>
                    Name <span className='required'>*</span>
                  </label>
                  <input
                    type='text'
                    id='firstName'
                    name='firstName'
                    value={values.firstName}
                    placeholder='Enter name'
                    onChange={handleChange}
                  />
                  {touched.firstName && errors.firstName && (
                    <span className='validation-error text-left footer-error-message'>
                      {errors.firstName}
                    </span>
                  )}
                </div>
                <div className='form-group'>
                  <label htmlFor='phoneNumber'>
                    Phone Number <span className='required'>*</span>
                  </label>
                  <input
                    type='number'
                    id='phoneNumber'
                    name='phoneNumber'
                    value={values.phoneNumber}
                    placeholder='Enter phone number'
                    onChange={handleChange}
                  />
                  {touched.phoneNumber && errors.phoneNumber && (
                    <span className='validation-error text-left footer-error-message'>
                      {errors.phoneNumber}
                    </span>
                  )}
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='email'>
                  Email address <span className='required'>*</span>
                </label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  value={values.email}
                  placeholder='Enter email address'
                  onChange={handleChange}
                />
                {touched.email && errors.email && (
                  <span className='validation-error text-left footer-error-message'>
                    {errors.email}
                  </span>
                )}
              </div>
              <div className='form-group mb-0'>
                <label htmlFor='question'>
                  How can we help you? <span className='required'>*</span>
                </label>
                <textarea
                  id='question'
                  name='question'
                  rows='4'
                  placeholder='Enter question'
                  value={values.question}
                  onChange={handleChange}
                ></textarea>
                {touched.question && errors.question && (
                  <span className='validation-error text-center footer-error-message'>
                    {errors.question}
                  </span>
                )}
              </div>
            </div>

            <button
              style={{
                width: '100%'
              }}
              className='blue-btn mt-2'
              type='submit'
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

import { loadStripe } from '@stripe/stripe-js';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetPaymentIntent } from '../../../../../operations/queries';
import BusyOverlay from '../../../../../components/busy-overlay';
import StripeCheckout from '../../../../../components/Commons/stripe-checkout';
import { Elements } from '@stripe/react-stripe-js';
import { toFixed } from '../../../../../utilities/to-fixed';
// import { useGeneratePayPalCheckoutUrl } from '../../../../../operations/queries';
// import { redirectUrlFunc } from '../../../helper-functions';
// import { productTypeEnum } from '../../../../../utilities/enums/product-type-enum';

const ForeignExchangeContext = createContext({
  amount: '',
  shipmentID: '',
  multipleShipmentID: '',
  multipleShopnshipID: '',
  lastMileShopnshipShipmentID: '',
  shopnshipID: '',
  additionalChargeID: '',
  currency: ''
});

export const useForeignExchangeContext = () =>
  useContext(ForeignExchangeContext);

export default function ForeignExchangePayment() {
  const location = useLocation();
  const [clientSecret, setClientSecret] = useState('');
  const [foreignExchangeCharge, setForeignExchangeCharge] = useState(0);

  // const [
  //   getPayPalDetails,
  //   { loading: payPalLoading }
  // ] = useGeneratePayPalCheckoutUrl(data => {
  //   if (data) {
  //     return window.location.replace(data);
  //   }
  // });

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const {
    shipmentID,
    shopnshipID,
    additionalChargeID,
    currency,
    amount,
    multipleShipmentID,
    multipleShopnshipID,
    lastMileShopnshipShipmentID
  } = useMemo(() => {
    if (!query) {
      return {
        amount: '',
        shipmentID: '',
        multipleShipmentID: '',
        multipleShopnshipID: '',
        lastMileShopnshipShipmentID: '',
        shopnshipID: '',
        additionalChargeID: '',
        currency: ''
      };
    }

    return {
      amount: query.get('amount'),
      currency: query.get('currency'),
      shipmentID: query.get('shipmentID'),
      shopnshipID: query.get('shopnshipID'),
      additionalChargeID: query.get('additionalChargeID'),
      multipleShipmentID: query.get('multipleShipmentID'),
      multipleShopnshipID: query.get('multipleShopnshipID'),
      lastMileShopnshipShipmentID: query.get('lastMileShopnshipShipmentID')
    };
  }, [query]);

  const [getPaymentIntent, getPaymentIntentResult] = useGetPaymentIntent(
    data => {
      setClientSecret(data?.getPaymentIntent?.clientSecret);

      setForeignExchangeCharge(
        toFixed(data?.getPaymentIntent?.foreignExchangeCharge / 100)
      );
    }
  );

  const stripePromise = useMemo(() => {
    if (!process.env.REACT_APP_STRIPE_PUB_KEY) {
      return null;
    }

    return loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);
    // eslint-disable-next-line
  }, [process.env.REACT_APP_STRIPE_PUB_KEY]);

  useEffect(() => {
    if (shipmentID) {
      getPaymentIntent({ shipmentId: shipmentID, currency });
    }

    if (shopnshipID) {
      getPaymentIntent({ shopnshipId: shopnshipID, currency });
    }

    if (additionalChargeID) {
      getPaymentIntent({ additionalChargeId: additionalChargeID, currency });
    }

    if (amount) {
      getPaymentIntent({ amount: Number(amount), currency });
    }

    if (multipleShipmentID) {
      getPaymentIntent({ shipmentId: multipleShipmentID, currency });
    }

    if (multipleShopnshipID) {
      getPaymentIntent({ shopnshipId: multipleShopnshipID, currency });
    }

    if (lastMileShopnshipShipmentID) {
      getPaymentIntent({ shipmentId: lastMileShopnshipShipmentID, currency });
    }
    // eslint-disable-next-line
  }, [
    shipmentID,
    shopnshipID,
    currency,
    amount,
    additionalChargeID,
    multipleShipmentID,
    multipleShopnshipID,
    lastMileShopnshipShipmentID
  ]);

  return (
    <ForeignExchangeContext.Provider
      value={{
        shipmentID,
        shopnshipID,
        additionalChargeID,
        currency,
        amount,
        multipleShipmentID,
        multipleShopnshipID,
        lastMileShopnshipShipmentID
      }}
    >
      <div className='payment-successful'>
        <h1>{'Pay With Stripe'}</h1>

        <p className='mt-1'>{'You are paying with a foreign currency'}</p>

        {stripePromise && clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <StripeCheckout foreignExchangeCharge={foreignExchangeCharge} />
          </Elements>
        )}
        <BusyOverlay loading={getPaymentIntentResult?.loading} />
      </div>
    </ForeignExchangeContext.Provider>
  );
}

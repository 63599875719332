export const currencies = [
  {
    name: 'Nigerian Naira',
    currency: 'NGN',
    countryCode: 'ng',
    logo: '🇳🇬'
  },
  {
    name: 'United States Dollar',
    currency: 'USD',
    countryCode: 'us',
    logo: '🇺🇸'
  },
  {
    name: 'British Pound Sterling',
    currency: 'GBP',
    countryCode: 'gb',
    logo: '🇬🇧'
  }
];

export const currencyEnum = Object.freeze({
  KOBO: 'KOBO',
  USD: 'USD',
  GBP: 'GBP'
});

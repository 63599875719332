import React from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { useStyles } from '../../constants/custom-styles';

const CustomAutoComplete = ({
  id,
  autoComplete,
  options,
  getOptionLabel,
  isOptionEqualToValue,
  renderOption,
  noOptionsText,
  onChange,
  value,
  textfieldProps,
  filterOptions,
  freeSolo
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      lg={textfieldProps.lg}
      md={textfieldProps.md}
      className={`form-container ${textfieldProps.containerClassName || ''}`}
    >
      {textfieldProps.customLabel && (
        <label className='form-item-label' htmlFor={textfieldProps.name}>
          {textfieldProps.customLabel}
        </label>
      )}
      <Autocomplete
        id={id}
        className={textfieldProps.className}
        freeSolo={freeSolo}
        autoComplete={autoComplete}
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        renderOption={(props, option, value) => {
          const renderProps = {
            ...props,
            style: { ...props.style, fontSize: '1.5rem' }
          };
          return renderOption(renderProps, option, value);
        }}
        noOptionsText={noOptionsText}
        onChange={onChange}
        filterOptions={filterOptions || undefined}
        value={value}
        renderInput={params => (
          <TextField
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 }
            }}
            autoComplete={textfieldProps.autoComplete || 'off'}
            inputProps={{
              ...params.inputProps,
              ...textfieldProps?.inputProps,
              style: {
                fontSize: '1.5rem',
                ...textfieldProps?.inputProps?.style
              }
            }}
            InputProps={{
              ...textfieldProps?.InputProps,
              // className: params.InputProps.className,
              endAdornment: params.InputProps.endAdornment,
              ref: params.InputProps.ref
            }}
            classes={{
              TextField: classes.textfield
            }}
            variant={textfieldProps.variant || 'outlined'}
            lg={textfieldProps.lg}
            md={textfieldProps.md}
            error={textfieldProps.error}
            helperText={textfieldProps.helperText}
            value={textfieldProps.value}
            placeholder={textfieldProps.placeholder}
            onChange={textfieldProps?.onChange}
            fullWidth={params.fullWidth}
            InputLabelProps={params.InputLabelProps}
            id={params.id}
            disabled={params.disabled}
            size={params.size}
          />
        )}
      />
    </Grid>
  );
};

export default CustomAutoComplete;

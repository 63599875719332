import React from 'react';
import CustomSpinner from '../../components/custom-spinner';

const ContentLayout = ({
  children,
  title,
  imageSource,
  imageAltText,
  businessUser,
  result
}) => {
  return (
    <>
      <section className='content-layout'>
        <div className='content-container'>
          {result?.loading ? (
            <div>
              <CustomSpinner
                text=''
                textSize='1.5rem'
                textColor='var(--color-primary)'
                size='4rem'
                thickness='.6rem'
                background='#e6e3df'
                spinColor='var(--color-blue-dark)'
              />
            </div>
          ) : !!result?.data && !result?.loading ? (
            <img
              style={{
                width: '170px',
                height: '50px',
                objectFit: 'cover'
              }}
              src={businessUser?.logo}
              alt={businessUser?.businessName}
            />
          ) : (
            <img src={imageSource} alt={imageAltText} />
          )}
          <div className='content-title'>
            <h1>{title}</h1>
          </div>
          {children}
        </div>
      </section>
    </>
  );
};

export default ContentLayout;

import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import SubmitButton from '../submit-button';
import { toCurrency } from '../../utilities/to-currency';
import { useForeignExchangeContext } from '../../pages/Dashboard/Requests/Shipment/Payment/foreign-exchange-payment';
import { redirectUrlFunc } from '../../pages/Dashboard/helper-functions';
import { chinaImportState } from '../../recoil/atoms';
import { useRecoilState } from 'recoil';
import { productTypeEnum } from '../../utilities/enums/product-type-enum';

const StripeCheckout = ({ foreignExchangeCharge = 0 }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isChinaImport] = useRecoilState(chinaImportState);

  const {
    shipmentID,
    shopnshipID,
    additionalChargeID,
    currency,
    amount,
    multipleShipmentID,
    multipleShopnshipID,
    lastMileShopnshipShipmentID
  } = useForeignExchangeContext();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    let return_url = redirectUrlFunc({
      shipmentID,
      type: productTypeEnum.shipmentID
    });

    if (shopnshipID) {
      return_url = redirectUrlFunc({
        shopnshipID,
        isChinaImport,
        type: productTypeEnum.shopnshipID
      });
    }

    if (additionalChargeID) {
      return_url = redirectUrlFunc({
        additionalChargeID,
        type: productTypeEnum.additionalChargeID
      });
    }

    if (amount) {
      return_url = redirectUrlFunc({ type: productTypeEnum.amount });
    }

    if (multipleShipmentID) {
      return_url = redirectUrlFunc({
        multipleShipmentID,
        type: productTypeEnum.multipleShipmentID
      });
    }

    if (multipleShopnshipID) {
      return_url = redirectUrlFunc({
        multipleShopnshipID,
        type: productTypeEnum.multipleShopnshipID
      });
    }

    if (lastMileShopnshipShipmentID) {
      return_url = redirectUrlFunc({
        lastMileShopnshipShipmentID,
        type: productTypeEnum.lastMileShopnshipShipmentID
      });
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url
      }
    });

    if (error) {
      setMessage(error?.message || 'An unexpected error occured.');
    }

    setIsProcessing(false);
  };

  return (
    <form id='payment-form' className='mt-5' onSubmit={handleSubmit}>
      <PaymentElement id='payment-element' />
      <div className='mt-3'>
        <SubmitButton
          text={
            isProcessing
              ? 'Processing...'
              : `Pay ${toCurrency(foreignExchangeCharge, currency)}`
          }
          disabled={isProcessing || !stripe || !elements}
          id='submit'
        />
      </div>
      {/* Show any error or success messages */}

      {message && <div id='payment-message'>{message}</div>}
    </form>
  );
};

export default StripeCheckout;

import React, { useState } from 'react';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Wrapper from '../../../components/Layouts/wrapper';
import { shopnshipFaqQuestions } from '../../../constants/faq-questions';
const Faq = () => {
  const [shopnshipQuestions, setShopnshipQuestions] = useState(
    shopnshipFaqQuestions
  );

  function toggleQuestionExpand(index, setQuestion) {
    setQuestion(questions =>
      questions.map((question, questionIndex) =>
        index === questionIndex
          ? { ...question, expand: !question.expand }
          : question
      )
    );
  }

  return (
    <Wrapper
      topbarTitle={
        window.innerWidth <= 375 ? 'FAQs' : 'Frequently Asked Questions'
      }
    >
      <div className='faq'>
        <div className='faq__wrap'>
          <div className='faq__questions'>
            <div className='new-shop-n-ship__modal__body shopnship-faq'>
              <div className='new-shop-n-ship__modal__body__wrap'>
                <div className='new-shop-n-ship__modal__body__content'>
                  <p className='mb-3 new-shop-n-ship__modal__body__content__how-it-works__description-heading'>
                    Understanding the Shop & Ship Process
                  </p>
                  <p
                    style={{ marginBottom: '20px' }}
                    className='new-shop-n-ship__modal__body__content__how-it-works__description'
                  >
                    The <span className='blue'>Topship Shop & Ship</span>{' '}
                    service allows you to easily shop from your favourite
                    international online stores in the United States, United
                    Kingdom, and more, and have your items delivered to your
                    doorstep in any location in Nigeria at a low cost and, in
                    some cases, without paying customs clearance fees.
                  </p>

                  <form
                    className='mb-5'
                    onSubmit={event => {
                      event.preventDefault();
                    }}
                  >
                    <div className='faq__questions'>
                      <div className='faq__questions__wrap'>
                        {shopnshipQuestions.map((question, index) => (
                          <Accordion
                            className={`faq__question shopnship${
                              question.expand ? ' active' : ''
                            }`}
                            style={{
                              width: '100%'
                            }}
                            key={index}
                            onChange={() =>
                              toggleQuestionExpand(index, setShopnshipQuestions)
                            }
                            expanded={question.expand}
                          >
                            <AccordionSummary
                              className='faq__question__button'
                              expandIcon={
                                question.expand ? (
                                  <RemoveCircleOutline />
                                ) : (
                                  <AddCircleOutline />
                                )
                              }
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <h2 className='faq__question__header'>
                                {question.question}
                              </h2>
                            </AccordionSummary>
                            <AccordionDetails className='faq__question__body'>
                              <p>{question.answer}</p>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </div>
                    </div>

                    <div
                      style={{
                        paddingBottom: '20px'
                      }}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Faq;

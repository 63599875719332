import omit from 'lodash.omit';
import { accountType } from '../../constants/authentication';
import {
  shipmentDestinationTypes,
  shipmentOptions
} from '../../constants/book-shipment-contants';
import { productTypeEnum } from '../../utilities/enums/product-type-enum';

export const conveneDate = initDate => {
  const date = new Date(initDate);
  const initTime = date.toLocaleTimeString('en-US', {
    timeStyle: 'short'
  });
  const initHour = parseFloat(initTime.split(':')[0]);
  const hours = initHour < 10 ? `0${initHour}` : initHour;
  const time = `${hours}:${initTime.split(':')[1]}`;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year} • ${time}`;
};

export const omitSingle = (key, { [key]: _, ...obj }) => obj;

export const checkIsCompleted = (index, shipmentDetail) => {
  const destinationType = shipmentDestinationTypes.find(
    destination => destination.value === shipmentDetail?.destinationType
  );
  switch (index) {
    case 0:
      return shipmentDetail?.senderDetail?.isCompleted;
    case 1:
      if (destinationType?.value === shipmentDestinationTypes[1].value) {
        return shipmentDetail?.multipleReceivers
          ?.map(receiver => ({
            receiverDetail: receiver.receiverDetail.isCompleted,
            items: receiver.items
              .map(item => item.isCompleted)
              .every(bool => bool)
          }))
          .every(bool => bool.items && bool.receiverDetail);
      } else {
        return shipmentDetail?.receiverDetail?.isCompleted;
      }
    case 2:
      if (destinationType?.value === shipmentDestinationTypes[1].value) {
        return shipmentDetail?.multipleReceivers
          ?.map(receiver => receiver.isCompleted)
          .every(bool => bool);
      } else {
        return shipmentDetail?.items
          ?.map(item => item.isCompleted)
          .every(bool => bool);
      }
    case 3:
      if (destinationType?.value === shipmentDestinationTypes[1].value) {
        return shipmentDetail?.multipleReceivers
          ?.map(receiver => receiver.isCompleted)
          .every(bool => bool);
      } else {
        return shipmentDetail?.isCompleted;
      }
    case 4:
      if (destinationType?.value === shipmentDestinationTypes[1].value) {
        return shipmentDetail?.multipleReceivers
          ?.map(receiver => receiver.isCompleted)
          .every(bool => bool);
      } else {
        return shipmentDetail?.isCompleted;
      }
    default:
      break;
  }
};

export const checkSeaFreightIsCompleted = (index, shipmentDetail) => {
  switch (index) {
    case 0:
      return shipmentDetail?.senderDetail?.isCompleted;
    case 1:
      return shipmentDetail?.items
        ?.map(item => item.isCompleted)
        ?.every(bool => bool);

    case 2:
      return shipmentDetail?.receiverDetail?.isCompleted;

    case 3:
      return shipmentDetail?.packageUpload?.isCompleted;
    default:
      break;
  }
};

export const isImportFromSupportedCountry = shipmentDetail => {
  const selectedOption = shipmentOptions.find(
    option => shipmentDetail.shipmentRoute === option.value
  );

  if (
    selectedOption?.value === shipmentOptions[1].value &&
    ['United States', 'United Kingdom', 'Canada'].includes(
      shipmentDetail.senderDetail.country
    )
  ) {
    return true;
  }

  return false;
};

export const shouldShowLocationPrompt = userData => {
  return (
    userData?.accountType === accountType[2].value &&
    !!userData?.businessInformation &&
    userData?.businessInformation?.location === null &&
    (userData?.businessInformation?.compliance !== null ||
      userData?.businessInformation?.directors !== null)
  );
};

export const shouldShowLspPrompt = (userData, percentage) => {
  return (
    userData?.accountType === accountType[2].value &&
    userData?.businessInformation?.isVerified === false &&
    percentage < 100
  );
};

export const segregatedSaverCountries = ['Spain', 'Italy', 'France'];

export const processingShipmentStatus = [
  'Confirmed',
  'Draft',
  'Received at hub'
];

export const redirectUrlFunc = ({
  type,
  shipmentID,
  shopnshipID,
  multipleShipmentID,
  multipleShopnshipID,
  lastMileShopnshipShipmentID,
  isChinaImport
}) => {
  const getUrl = url => `${window.location.origin}/${url}`;

  switch (type) {
    case productTypeEnum.shopnshipID:
      return isChinaImport
        ? getUrl('shop-and-ship-delivery-address')
        : getUrl(
            `shop-and-ship-successful?shopnshipID=${shopnshipID.split('/')[0]}`
          );
    case productTypeEnum.additionalChargeID:
      return getUrl('pending-charges');
    case productTypeEnum.amount:
      return getUrl('wallet/wallet-funding-successful');
    case productTypeEnum.multipleShipmentID:
      return getUrl(
        `multiple-shipment-request-payment?successID=${multipleShipmentID}`
      );
    case productTypeEnum.multipleShopnshipID:
      return getUrl(
        `shop-and-ship-multiple-payment?shopnshipSuccessID=${
          multipleShopnshipID.split('/')[0]
        }`
      );
    case productTypeEnum.lastMileShopnshipShipmentID:
      return getUrl(
        `shop-and-ship-multiple-payment?shipmentSuccessID=${lastMileShopnshipShipmentID}`
      );
    default:
      return getUrl(`shipment-payment-successful?shipmentID=${shipmentID}`);
  }
};

export const isUUID = (input = '') => {
  if (!input) {
    return false;
  }

  return Boolean(
    input.match(
      '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
    )
  );
};

export const formatUUID = (input = '') => {
  let id = '';

  if (!input) {
    return id;
  }

  id = input;

  if (!isUUID(id)) {
    id = id.split('?')[0];

    if (!isUUID(id)) {
      id = '';
    }
  }

  return id;
};

export const cleanShipmentDetail = shipmentDetail => {
  const cleanedShipmentDetail = omit(shipmentDetail, [
    'id',
    'createdDate',
    'updatedDate',
    'estimatedDeliveryDate',
    'trackingId',
    'shipmentStatus',
    'isPaid',
    'totalCharge',
    'destinationType',
    'hubSlug',
    'isCompleted',
    'shippingRoute',
    'partnerLogoUrl',
    'itemPickupMode',
    'initialReceiverAddress'
  ]);

  cleanedShipmentDetail.senderDetail = omit(
    cleanedShipmentDetail?.senderDetail,
    ['id', 'isDefault', 'updatedDate', 'isCompleted']
  );

  cleanedShipmentDetail.receiverDetail = omit(
    cleanedShipmentDetail?.receiverDetail,
    ['id', 'isDefault', 'updatedDate', 'isCompleted']
  );

  cleanedShipmentDetail.items = cleanedShipmentDetail.items.map(item =>
    omit(item, ['isCompleted', 'width', 'height', 'length', 'isVolumetric'])
  );

  return cleanedShipmentDetail;
};

import { useAuthToken } from '../token';
import { verifyToken } from '../authorization';

const useGetUserPayload = () => {
  const [authToken] = useAuthToken();
  const userPayload = verifyToken(authToken);
  return userPayload;
};

export { useGetUserPayload };

import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useRecoilState } from 'recoil';
import { shipmentPaymentState } from '../../../../recoil/atoms';
import { Link } from 'react-router-dom';
import paymentSuccessIcon from '../../../../assets/svg/payment-successful.svg';
import SubmitButton from '../../../../components/submit-button';

const PaymentSuccessfull = ({
  heading,
  subHeading,
  path,
  buttonText,
  successImage,
  isNotWalletRelated,
  onClickButton
}) => {
  const [, setRequestPage] = useRecoilState(shipmentPaymentState);
  useEffect(() => {
    if (!isNotWalletRelated) {
      ReactGA.event({
        category: 'Wallet Funding',
        action: 'User has successfully funded their wallet'
      });
    }
  }, [isNotWalletRelated]);
  return (
    <div className='payment-successful'>
      <img src={successImage || paymentSuccessIcon} alt='success' />

      <h1>{heading || 'Wallet Funding Successful'}</h1>
      <p className='mt-1'>
        {subHeading ||
          'A receipt has been sent to your email containing information about your wallet funding.'}
      </p>

      <div className='mt-6 mb-3'>
        <Link to={!isNotWalletRelated ? '/wallet' : path || '/'}>
          <SubmitButton
            text={buttonText || 'Go Home'}
            className='mt-3'
            disabled={false}
            loading={false}
            onClick={() => {
              setRequestPage(0);
              onClickButton && onClickButton();
            }}
          />
        </Link>
      </div>
    </div>
  );
};

export default PaymentSuccessfull;

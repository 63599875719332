import React, { useState } from 'react';
import tickCircle from '../../../../../../assets/image/tick-circle.png';
import SubmitButton from '../../../../../../components/submit-button';
import { WhiteCheck } from '../../../../../../constants/asset-contants';

const Confidentiality = ({ nextStep, previousStep }) => {
  const [success] = useState(false);

  const goBack = () => {
    previousStep();
  };
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  const submit = () => {
    if (isChecked) {
      nextStep();
    }
  };

  return (
    <div className='verification__body'>
      {success && (
        <div className='verification__image'>
          <img src={tickCircle} className='img-fluid' alt='' />
        </div>
      )}
      <div className={`verification__content${!success ? ' mt-3' : ''}`}>
        <div
          style={{
            textAlign: 'left'
          }}
          className='verification__header text-left'
        >
          <h1
            style={{
              textAlign: 'left',
              padding: '0',
              marginBottom: '20px'
            }}
          >
            Confidentiality Agreement
          </h1>
          <p
            style={{
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 500
            }}
          >
            Please note that Topship does not have the rights to your customers
            and your customers are your customers alone. <br /> Please check the
            "I understand" box to proceed
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          }}
          onClick={toggleCheckbox}
        >
          <div
            style={{
              width: '24px',
              height: '24px',
              borderRadius: '6px',
              backgroundColor: isChecked ? '#6B50F6' : 'transparent',
              border: '2px solid #ddd',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '8px'
            }}
          >
            {isChecked && <img width={20} src={WhiteCheck} alt='white check' />}
          </div>
          <label
            style={{ color: 'black', fontSize: '14px', marginLeft: '10px' }}
          >
            I understand
          </label>
        </div>
      </div>

      <div className='verification__action'>
        <div onClick={goBack} className='item-description__footer__action'>
          Go Back
        </div>
        <SubmitButton
          onClick={submit}
          disabled={!isChecked}
          text={success ? 'Continue' : 'Submit'}
        />
      </div>
    </div>
  );
};

export default Confidentiality;

import React from 'react';
import Wrapper from '../../components/Layouts/wrapper';
import GuidesAndResourcesComponent from '../../components/resources-component';

function GuidesAndResourcesPage() {
  return (
    <Wrapper>
      <div className='resource-page-wrap'>
        <GuidesAndResourcesComponent />
      </div>
    </Wrapper>
  );
}

export default GuidesAndResourcesPage;

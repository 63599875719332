import React from 'react';
import Validator from './validator';

const getValidationRessults = (schema, fieldValue) => {
  const validatorService = new Validator(schema);
  return validatorService.checkValidations(fieldValue || '', schema.label);
};

const checkValidations = ({ schemas, data, field, options }) => {
  const errorArray = [];
  const errors = {};

  if (options && options.dataType === 'array') {
    data.forEach(data => {
      const errors = {};

      const entries = Object.entries(schemas, data);
      entries.forEach(entry => {
        const [inputName, schema] = entry;
        const inputValue = data[inputName];
        const result = getValidationRessults(schema, inputValue);
        if (result !== true) {
          errors[inputName] = result;
        }
      });

      const success = !Object.keys(errors).length;
      errorArray.push({ errors, success });
    });
  } else if (field) {
    const schema = schemas[field];
    const inputValue = data[field];
    const result = getValidationRessults(schema, inputValue);
    if (result !== true) {
      errors[field] = result;
    }
  } else {
    const entries = Object.entries(schemas, data);
    entries.forEach(entry => {
      const [inputName, schema] = entry;
      const inputValue = data[inputName];
      const result = getValidationRessults(schema, inputValue);
      if (result !== true) {
        errors[inputName] = result;
      }
    });
  }

  const isDataArrayValidated = errorArray.filter(
    result => result.success === false
  );

  if (Object.keys(errors).length) {
    setTimeout(() => {
      const elements = document.querySelectorAll('#customError');
      for (let index = 0; index < elements.length; index += 1) {
        const element = elements[index];
        if (element.innerText && element.innerText.length) {
          const y = element.getBoundingClientRect().top + window.scrollY;
          window.scroll({
            top: y - 150,
            behavior: 'smooth'
          });
          break;
        }
      }
    }, 10);
  }

  return {
    success: !Object.keys(errors).length,
    errors,
    arrayResults: { errors: errorArray, success: !isDataArrayValidated.length }
  };
};
const ErrorMessage = ({ message }) => {
  return (
    <small id='customError' className='error'>
      {message}
    </small>
  );
};
export { checkValidations, ErrorMessage };

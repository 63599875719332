import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import {
  GrayPlusIcon,
  MinusIcon,
  PlusIcon
} from '../../constants/asset-contants';

const prohibitedLists = [
  {
    item: 'Alcoholic Beverages',
    description:
      'The import of all alcoholic beverages are strictly forbidden. Shipments containing alcoholic beverages should not be exported but held at the origin site because of penalties and fines.'
  },
  {
    item: 'Jewelry',
    description: (
      <div>
        Shipments of jewelry can be shipped as long as the value does not exceed
        US$500.00 or the local currency equivalent per package.
        <br />
        <br />
        Jewelry for which the retail price is lower than US$150.00 per item and
        which do not contain precious metal(s) and/or stone(s) are considered
        costume jewelry and can be shipped only up to this value from the origin
        country.
      </div>
    )
  },
  {
    item: 'Antiques',
    description: `Antique shipments are restricted from Europe, the Middle East and Africa and can only be shipped with an International Special Commodities 
      (ISC) contract. Antiques are defined as a work of art, piece of furniture, decorative object, or silverware that is 100 years old or older. 
      `
  },
  {
    item: 'Artworks',
    description: (
      <div>
        Artwork shipments are restricted from Europe, the Middle East and Africa
        and can only be shipped with an International Special Commodities (ISC)
        contract.
        <br />
        <br />
        Paintings or other works of art can be accepted if the artist is still
        alive, the artwork is replaceable, and an art dealer or gallery has
        appraised the artwork within the last year. If the declared value of the
        artwork is greater than $100.00 and there is a claim, the shipper must
        provide a copy of the original invoice, a bill of sale, or other proof
        certifying in writing the actual cost or replacement cost of the item.
        <br />
        <br />
        Artwork includes the following: Original engravings, prints,
        lithographs, original sculptures, statuary.
        <br />
        The term "Artwork" does not apply to: architectural, engineering,
        industrial, topographical, commercial drawings, hand-painted or
        hand-decorated manufactured articles.
      </div>
    )
  },
  {
    item: `Bank bills, notes, or currency `,
    description: (
      <div>
        It is strictly forbidden by the Eritrean authorities to send foreign
        currency (e.g. US Dollar, Euro) within packages or letters. The money
        will be seized by the authorities.
        <br /> According to the UPS terms of trade,any kind of money is
        prohibited within letters or packages.
      </div>
    )
  },
  {
    item: `Cooked meals`,
    description: (
      <div>
        Food items are restricted for importation with UPS Small Package.Only
        canned food, bottled beverages or freeze dried food can be imported as
        long as it is not temperature sensitive.
        <br />
        <br /> Small amounts of samples for testing purposes are exempted if the
        tests do not include consumption. Small amounts of food within gift
        shipments are allowed as long as they are not temperature sensitive. For
        food made from animal products please see additional information under
        section 'Commodity Specific Information'.
      </div>
    )
  },
  {
    item: 'Guns and firearms',
    description: (
      <div>
        Any form of weapons, including parts of guns, arms and ammunition are
        strictly prohibited including any form of toy gun or gun replica. If it
        is a part or piece that makes the firearm/weapon work, enhances,
        promotes or aides the performance of the firearm/weapon or if it
        operates or ejects a projectile, then it's considered a firearm & is
        prohibited.
        <br /> <br />
        Examples of prohibited guns/firearms are BB guns, crossbows, pistols,
        muzzle loaders, pellet guns, rifles, shotguns, starter pistols, Also
        included as a prohibited item are any device discharging an electrical
        charge (taser guns). Parts of guns/firearms are also prohibited. If it
        is a part or piece that makes the firearm/weapon work, or if it
        operates, discharges, or ejects a projectile, it is prohibited. If it
        enhances, promotes or aides the performance of the firearm/weapon, it is
        also prohibited.
        <br />
        <br /> Examples of prohibited parts are barrels, triggers,
        magazines/clips that hold bullets, night vision aids, and gun scopes or
        rifle scopes. No approvals will be granted using the International
        Special Commodity (ISC) exception request process.
      </div>
    )
  },

  {
    item: 'Minerals & precious stones',
    description: (
      <div>
        Precious and non-precious metals require an Import Permit which must be
        attained from the country's Customs.
      </div>
    )
  },
  {
    item: 'Personal effects',
    description: (
      <div>
        Personal Effects (for example, unaccompanied baggage) are classified as
        used items intended for the consignee's personal use. Any items intended
        for any other use, such as wholesale or retail sales, business purposes,
        or for distribution are not considered personal effects and cannot be
        shipped on this basis.
        <br />
        <br /> "Personal Effects" must be clearly stated on the invoice and
        goods description. Note: Personal effects include Items that an
        individual has owned prior to a foreign travel/trip.
      </div>
    )
  },
  { item: 'Pets' },
  { item: 'Fruits & Vegetables' },

  { item: 'Fireworks' },
  { item: 'Hazardous waste' },
  { item: 'Perishables' },
  { item: 'Radioactive materials' },
  { item: 'Illegal items, according to law enforcements' },
  { item: 'Perfumes' },
  { item: 'Gold' }
];

function ProhibitedListPrompt({
  openPrompt,
  closePrompt,
  countryName,
  countryCode
}) {
  const [expandList, setExpandList] = useState(
    prohibitedLists.map(item => ({ isExpanded: false }))
  );
  return (
    <div className={`delete-overlay ${openPrompt && 'delete-overlay--show'}`}>
      <div className='prohibited-list-container'>
        <div className='resources'>
          <div className='heading-wrap'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <h3>
                General Prohibited & Restricted Items
                {/* <img
                  style={{
                    paddingLeft: '5px'
                  }}
                  loading='lazy'
                  width='20'
                  height='14'
                  src={`https://flagcdn.com/w20/${
                    countryCode?.toLowerCase() || 'ng'
                  }.png`}
                  srcSet={`https://flagcdn.com/w40/${
                    countryCode?.toLowerCase() || 'ng'
                  }.png 2x`}
                  alt=''
                /> */}
              </h3>
            </div>

            <IconButton
              className='close-btn'
              onClick={() => closePrompt(false)}
            >
              <Close />
            </IconButton>
          </div>
          <div
            className='pt-4 pb-4'
            style={{
              backgroundColor: '#F8FBFF'
            }}
          >
            {prohibitedLists.map((list, index) => {
              return (
                <div
                  onClick={() => {
                    if (list.description) {
                      setExpandList(list => {
                        const updatedList = [...list];
                        updatedList[index] = {
                          ...updatedList[index],
                          isExpanded: !updatedList[index].isExpanded
                        };
                        return updatedList;
                      });
                    }
                  }}
                  className='list-wrap'
                >
                  <div className='title-container'>
                    <p className='mb-0'>{list.item}</p>
                    <img
                      src={
                        expandList[index].isExpanded
                          ? MinusIcon
                          : !list.description
                          ? GrayPlusIcon
                          : PlusIcon
                      }
                      alt=''
                    />
                  </div>
                  {expandList[index].isExpanded ? (
                    <div className='description'>{list.description}</div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProhibitedListPrompt;

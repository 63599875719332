import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import SubmitButton from '../../../components/submit-button';
import { useUpdatePasswordMutation } from '../../../operations/mutations';
import SuccesPage from '../../../components/Commons/success-page';
import { useShowAuthPage } from '../../../utilities/show-auth-pages';
import ContentLayout from '../../../components/Layouts/content-layout';
import Input from '../../../components/new-dashboard/custom-input';
import jwtDecode from 'jwt-decode';
import ContactSupport from '../../../components/contact-support';

const ResetPassword = () => {
  const params = useParams();
  let { token } = params;
  const [updatePassword, { loading, data }] = useUpdatePasswordMutation(token);
  const [renderPage] = useShowAuthPage();

  const getUserEmail = () => jwtDecode(token);

  const { username: userEmail } = getUserEmail();

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, 'Please enter a password that is longer than 8 characters')
      .required('Please enter your new password'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords do not match')
      .required('Confirm Password Required')
  });

  const { errors, touched, values, handleSubmit, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userEmail || '',
      password: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: async values => {
      updatePassword(values.password);
    }
  });

  if (data) {
    return (
      <SuccesPage
        heading='Password changed'
        subHeading='Your password has been successfully changed. You can now login with your new password by clicking on the link below'
        path='/login'
        buttonText='Login'
      />
    );
  }

  return renderPage(
    <>
      <ContactSupport />
      <div className='reset-password'>
        <ContentLayout title='Choose a new Password'>
          <div className='form-container'>
            <form onSubmit={handleSubmit}>
              <div className='form-item'>
                <label className='form-item-label' htmlFor='email'>
                  Email address
                </label>
                <Input
                  fullWidth
                  id='email'
                  name='email'
                  placeholder='email@domain.com'
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  disabled
                />
              </div>
              <div className='form-item'>
                <label className='form-item-label' htmlFor='password'>
                  Enter new Password
                </label>
                <Input
                  fullWidth
                  id='password'
                  name='password'
                  placeholder='Enter a password'
                  value={values.password}
                  onChange={handleChange}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
              </div>
              <div className='form-item'>
                <label className='form-item-label' htmlFor='password'>
                  Confirm new Password
                </label>
                <Input
                  fullWidth
                  id='password'
                  name='confirmPassword'
                  placeholder='Re-enter new password'
                  value={values.confirmPassword}
                  onChange={handleChange}
                  error={
                    touched.confirmPassword && Boolean(errors.confirmPassword)
                  }
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
              </div>

              <SubmitButton
                text='Update Password'
                disabled={loading}
                loading={loading}
              />
            </form>
          </div>
        </ContentLayout>
      </div>
    </>
  );
};

export default ResetPassword;

import React, { useEffect, useMemo, useState } from 'react';
import { useGetCurrentMonthShipments } from '../operations/queries';
import shipmentMetricDurations from './Commons/shipment-metric';
import { toCurrency } from '../utilities/to-currency';
import { toFixed } from '../utilities/to-fixed';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

const arrowProps = {
  style: {
    width: '16px',
    height: '16px'
  }
};

const MonthlyShipmentCount = () => {
  const [shipmentDuration, setShipmentDuration] = useState(
    shipmentMetricDurations[0].value
  );

  const [
    getCurrentMonthShipments,
    getCurrentMonthShipmentsResult
  ] = useGetCurrentMonthShipments();

  const data = useMemo(() => {
    if (!getCurrentMonthShipmentsResult?.data) {
      return {
        currentMonthShipments: 0,
        currentMonthRevenue: 0,
        percentageDifferenceOnRevenue: 0,
        percentageDifferenceOnShipments: 0
      };
    }

    return getCurrentMonthShipmentsResult?.data?.getCurrentMonthShipments;
  }, [getCurrentMonthShipmentsResult]);

  const positives = useMemo(() => {
    if (!data) {
      return { shipments: true, revenue: true };
    }

    return {
      revenue: data?.percentageDifferenceOnRevenue >= 0,
      shipments: data?.percentageDifferenceOnShipments >= 0
    };
  }, [data]);

  useEffect(() => {
    getCurrentMonthShipments(shipmentDuration);
    // eslint-disable-next-line
  }, [shipmentDuration]);

  return (
    <div className='d-flex flex-column mt-5'>
      <div className='shipment-overview__header'>
        <span className='shipment-overview__title'>Shipment Overview</span>
        <select
          value={shipmentDuration}
          defaultValue={shipmentMetricDurations[0].value}
          onChange={e => setShipmentDuration(e.target.value)}
          className='shipment-overview__input'
        >
          {shipmentMetricDurations.map(metric => (
            <option
              selected={metric.value === shipmentDuration}
              key={metric.type}
              value={metric.value}
            >
              {metric.type}
            </option>
          ))}
        </select>
      </div>

      <div className='shipment-overview'>
        <div className='shipment-overview__wrap'>
          <div className='shipment-overview__item'>
            <div className='shipment-overview__item__wrap'>
              <span className='shipment-overview__item__title'>
                Number of Shipments this month
              </span>
              <span className='shipment-overview__item__value'>
                {data?.currentMonthShipments}
              </span>
              <span className='shipment-overview__item__percentage'>
                <span
                  className={`shipment-overview__item__percentage__value ${
                    positives.shipments ? 'positive' : 'negative'
                  }`}
                >
                  {positives.shipments ? (
                    <ArrowUpward {...arrowProps} />
                  ) : (
                    <ArrowDownward {...arrowProps} />
                  )}
                  {toFixed(
                    Math.abs(data?.percentageDifferenceOnShipments || 0)
                  )}
                  %
                </span>{' '}
                vs last month
              </span>
            </div>
          </div>
          <div className='shipment-overview__divider'></div>
          <div className='shipment-overview__item'>
            <div className='shipment-overview__item__wrap'>
              <span className='shipment-overview__item__title'>
                Shipment Value for this month
              </span>
              <span className='shipment-overview__item__value'>
                {toCurrency(data?.currentMonthRevenue || 0)}
              </span>
              <span className='shipment-overview__item__percentage'>
                <span
                  className={`shipment-overview__item__percentage__value ${
                    positives.revenue ? 'positive' : 'negative'
                  }`}
                >
                  {positives.revenue ? (
                    <ArrowUpward {...arrowProps} />
                  ) : (
                    <ArrowDownward {...arrowProps} />
                  )}
                  {toFixed(Math.abs(data?.percentageDifferenceOnRevenue || 0))}%
                </span>{' '}
                vs last month
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlyShipmentCount;

// {
//   /* <ReactTooltip id='shipment-count' aria-haspopup='true' role='description'>
//         <span className='tooltip-wrapper'>
//           Ship more, pay less! Make 20 shipments or more this month to become a
//           VIB user.{' '}
//         </span>
//       </ReactTooltip> */
// }
// {
//   /* <a
//         data-tip
//         data-for='shipment-count'
//         target='_blank'
//         rel='noreferrer'
//         href='https://blog.topship.africa/topship-vib-program/'
//       >
//         <img
//           src={InviteRed}
//           alt='gift card'
//           className='gift-image animate__animated animate__infinite animate__wobble'
//         />
//       </a> */
// }

// {
//   /* <div className='wallet-balance-items w-100'>
//           <div className='wallet-balance-item'>
//             <span>
//               <CircularProgressWithLabel value={months} variant='determinate' />
//             </span>
//           </div>
//           <div className='wallet-balance-item'>
//             <h4 className='wallet-balance-item-heading'>You have made</h4>
//             <p
//               style={{
//                 fontSize:
//                   shipmentDuration === shipmentMetricDurations[0]
//                     ? '20px'
//                     : '15px'
//               }}
//               className='wallet-balance-figure w-100'
//             >
//               {getCurrentMonthShipmentsResult.loading ? (
//                 <Skeleton className='w-100' />
//               ) : (
//                 `${months || 0} shipment${months === 1 ? '' : 's'}
//                         ${shipmentDuration.timeFrame}`
//               )}
//             </p>
//           </div>
//         </div>
//         <TextField
//           variant='standard'
//           label='Duration'
//           className='monthly-shipments-input'
//           value={shipmentDuration}
//           defaultValue={shipmentMetricDurations[0]}
//           onChange={e => setShipmentDuration(e.target.value)}
//           select
//         >
//           {shipmentMetricDurations.map(metric => (
//             <MenuItem
//               selected={metric === shipmentDuration}
//               key={metric.type}
//               value={metric}
//             >
//               {metric.type}
//             </MenuItem>
//           ))}
//         </TextField> */
// }

const thirdPartyServiceEnum = {
  DHL: 'DHL',
  Overland: 'Overland',
  Cargo: 'Cargo',
  UPS: 'UPS'
};
const serviceRouteEnum = {
  export: 'Export',
  import: 'Import',
  domestic: 'Domestic'
};

const thirdPartyServices = [
  {
    shortName: thirdPartyServiceEnum.UPS,
    route: serviceRouteEnum.export,
    fullName: 'Saver - Export'
  },
  {
    shortName: thirdPartyServiceEnum.UPS,
    route: serviceRouteEnum.import,
    fullName: 'Saver - Import'
  },
  {
    shortName: thirdPartyServiceEnum.DHL,
    route: serviceRouteEnum.export,
    fullName: 'Express - Export'
  },
  {
    shortName: thirdPartyServiceEnum.DHL,
    route: serviceRouteEnum.import,
    fullName: 'Express - Import'
  },
  {
    shortName: thirdPartyServiceEnum.DHL,
    route: serviceRouteEnum.domestic,
    fullName: 'Express - Domestic'
  },
  {
    shortName: thirdPartyServiceEnum.Overland,
    route: serviceRouteEnum.domestic,
    fullName: 'Express - Plus'
  },
  {
    shortName: thirdPartyServiceEnum.Cargo,
    route: serviceRouteEnum.export,
    fullName: 'Cargo - Export'
  },
  {
    shortName: thirdPartyServiceEnum.Cargo,
    route: serviceRouteEnum.import,
    fullName: 'Cargo - Import'
  }
];

export { thirdPartyServiceEnum };
export default thirdPartyServices;

export const extractLocation = place => {
  const { address_components, formatted_address } = place;

  // country
  const country = address_components.filter(component =>
    component.types.includes('country')
  );
  const countryName = country[0].long_name;
  const countryShortName = country[0].short_name;
  // state
  const state = address_components.filter(component =>
    component.types.includes('administrative_area_level_1')
  );
  const stateName = state[0].long_name;

  // city

  const cityAdministrativeArea = address_components.filter(component =>
    component.types.includes('administrative_area_level_2')
  );
  const cityLocality = address_components.filter(component =>
    component.types.includes('locality')
  );
  const cityNeighborhood = address_components.filter(component =>
    component.types.includes('neighborhood')
  );
  const citySubLocality = address_components.filter(component =>
    component.types.includes('sublocality_level_1')
  );

  const cityName =
    (cityAdministrativeArea[0] && cityAdministrativeArea[0].long_name) ||
    (cityNeighborhood[0] && cityNeighborhood[0].long_name) ||
    (citySubLocality[0] && citySubLocality[0].long_name) ||
    (cityLocality[0] && cityLocality[0].long_name);

  // zip code

  // const zip = address_components.filter(component =>
  //   component.types.includes('postal_code')
  // );
  //   const zipCode = zip[0].long_name;

  return {
    address: formatted_address,
    countryCode: countryShortName,
    country: countryName,
    state: stateName,
    city: cityName
  };
};

export const extractCity = place => {
  const { address_components } = place;
  const country = address_components.filter(component =>
    component.types.includes('country')
  );
  const countryName = country[0].long_name;
  const state = place.address_components[place.address_components.length - 2];
  const city = place.address_components[0];
  const countryShortName = country[0].short_name;
  return {
    address: place.formatted_address,
    countryCode: countryShortName,
    country: countryName,
    state: state.long_name,
    city: parseCityName(city.long_name)
  };
};

const parseCityName = cityName => {
  if (cityName.includes('/')) {
    return cityName.split('/')[0];
  } else if (cityName.includes('-')) {
    return cityName.split('-')[0];
  } else {
    return cityName;
  }
};

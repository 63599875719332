import SeaFreightQuoteLayout from '../../../../../components/Layouts/sea-freight-layout';
import {
  DimensionsIcon,
  PalletsIcon,
  PickupAddressIcon,
  SeaFreightAddressIcon,
  SeaFreightValueIcon
} from '../../../../../constants/asset-contants';
import { seaFreightImportLinks } from '../../../../../constants/shipment-links';
import { useHistory, useLocation } from 'react-router-dom';

const SeaFreightWelcome = ({}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const currentIndex = seaFreightImportLinks.findIndex(
    link => link.link === pathname
  );
  const seaFreightOptions = [
    {
      icon: PickupAddressIcon,
      text: 'Pick-up address'
    },
    {
      icon: DimensionsIcon,
      text: 'Shipment dimensions'
    },
    {
      icon: PalletsIcon,
      text: 'Number of cartons and/or pallets '
    },
    {
      icon: SeaFreightValueIcon,
      text: 'Item Value'
    },
    {
      icon: SeaFreightAddressIcon,
      text: 'Destination address'
    }
  ];
  return (
    <SeaFreightQuoteLayout>
      <div
        style={{
          border: '1px dashed #E2E4E8',
          borderRadius: '8px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          width: '100%'
        }}
      >
        {seaFreightOptions.map(option => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px'
              }}
            >
              <img src={option.icon} alt='' />
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: 500
                }}
              >
                {option.text}
              </span>
            </div>
          );
        })}
      </div>

      <button
        className='continue-btn'
        onClick={e => {
          e.preventDefault();
          history.push(seaFreightImportLinks[currentIndex + 1].link);
        }}
      >
        Get Started
      </button>
    </SeaFreightQuoteLayout>
  );
};

export default SeaFreightWelcome;

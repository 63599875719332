import React from 'react';

const VerificationPercentage = ({ percentage }) => {
  const radius = 85;
  const circleWidth = '200';
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <div className='verification__modal__completion-percent'>
      <svg
        width={'70'}
        height={'70'}
        viewBox={`0 0 ${circleWidth} ${circleWidth}`}
      >
        <circle
          cx={circleWidth / 2}
          cy={circleWidth / 2}
          strokeWidth={'4px'}
          r={radius}
          className='progress-background'
        />
        <circle
          cx={circleWidth / 2}
          cy={circleWidth / 2}
          strokeWidth={'8px'}
          r={radius}
          className='progress-circle'
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
          }}
          transform={`rotate(-90 ${circleWidth / 2} ${circleWidth / 2})`}
        />
        <text
          x='50%'
          y='50%'
          dy='0.3em'
          textAnchor='middle'
          className='progress-text'
          style={{
            fontSize: '40px',
            color: '#E39600',
            fontWeight: 'bold'
          }}
        >
          {percentage}%
        </text>
      </svg>
    </div>
  );
};

export default VerificationPercentage;

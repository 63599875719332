const paymentMethod = [
  {
    name: 'Pay From Wallet',
    cost: 'Current Balance: NGN 300,000',
    value: 'Wallet'
  },
  {
    name: 'Pay With Paystack',
    cost: 'Pay with card, transfer or USSD via Paystack',
    value: 'Card'
  },
  {
    name: 'Pay With Monnify',
    cost: 'Pay with transfer via Monnify',
    value: 'Monnify'
  }
  // {
  //   name: 'Pay With Fincra',
  //   cost: 'Pay with card, transfer or USSD via Fincra',
  //   value: 'Fincra'
  // }
];

export default paymentMethod;

export const productTypeEnum = Object.freeze({
  shopnshipID: 'shopnshipId',
  additionalChargeID: 'additionalChargeId',
  chinaImport: 'chinaImport',
  amount: 'amount',
  multipleShipmentID: 'multipleShipmentId',
  multipleShopnshipID: 'multipleShopnshipID',
  lastMileShopnshipShipmentID: 'lastMileShopnshipShipmentID',
  shipmentID: 'shipmentId'
});

const CardComponent = ({ service, handleViewFeat, handleShipNow }) => {
  return (
    <div className='services__card-wrap'>
      <div className='img-content'>
        <img src={service.image} alt='' />
      </div>
      <div className='text-content'>
        <h4>{service.title}</h4>
        <p>{service.content}</p>
        <div className='btn-wrap'>
          <button className='view-btn' onClick={handleViewFeat}>
            View Features
          </button>
          <button className='ship-now' onClick={handleShipNow}>
            Ship Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;

export const getPickupDate = date => {
  const [datePart, timePart] = date.split(' ');

  const [day, month, year] = datePart.split('-');

  const [hours, minutes, seconds] = timePart.split(':');

  return new Date(
    `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  ).toISOString();
};

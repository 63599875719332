import React, { createRef, useEffect, useState } from 'react';
import NewShippingStepsLayout from '../../../../components/Layouts/new-shipping-steps-layout';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  useMediaQuery
} from '@mui/material';
import {
  Add,
  AddCircle,
  ChevronRight,
  RemoveCircle,
  SwapVert
} from '@mui/icons-material';
import Input from '../../../../components/new-dashboard/custom-input';
import CustomAutoComplete from '../../../../components/new-dashboard/custom-autocomplete';
import debounce from 'lodash.debounce';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router';
import {
  addressTotalState,
  saveShipmentDetailsState
} from '../../../../recoil/atoms';
import {
  useGetCountriesLazyQuery,
  useGetSavedAddresses,
  useGetStatesQuery
} from '../../../../operations/queries';
import {
  useGetCitiesMutation,
  useSaveAddressMutation,
  useUpdateAddressMutation
} from '../../../../operations/mutations';
import BusyOverlay from '../../../../components/busy-overlay';
import ChooseAddressPrompt from '../../../../components/modals/choose-address-prompt';
import SubmitButton from '../../../../components/submit-button';
import { multipleShipmentLinks } from '../../../../constants/shipment-links';
import { itemCategories } from '../../../../constants/item-categories';
import numberWithCommas from '../../../../utilities/number-with-commas';
import { checkIsCompleted } from '../../helper-functions';
import { useFormik } from 'formik';
import * as yup from 'yup';
import usePlaces from '../../../../utilities/use-places';
import { extractLocation } from '../../../../utilities/extract-location';
import { useAuthToken } from '../../../../token';
import { useFilterIntracityShipments } from '../../../../utilities/check-intracity';
import omit from 'lodash.omit';
import { defaultDetail } from '../../../../constants/book-shipment-contants';
import StickyNoteAlert from '../../../../components/Commons/sticky-note';
import AddressGuidePrompt from '../../../../components/modals/address-guide-prompt';
import AddressWithInfo from '../../../../constants/address-with-info';
import VolumetricWeight from '../../../../components/volumetric-weight-switch';
import ProhibitedListPrompt from '../../../../components/modals/prohibited-list-prompt';
import {
  AddBoxIcon,
  BoxModel,
  InfoYellowIcon
} from '../../../../constants/asset-contants';
import { segregatedCountries } from '../../../../constants/china-categories';
import {
  getItemCategoryMessage,
  itemCategoryRules
} from '../../../../constants/item-category-dictionary';

const initItem = {
  category: '',
  weight: '',
  width: '',
  height: '',
  length: '',
  quantity: '',
  value: '',
  description: '',
  isVolumetric: false
};

const initReceiver = {
  receiverDetail: {
    name: '',
    email: '',
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    state: '',
    city: '',
    countryCode: '',
    postalCode: '',
    shouldSave: false,
    codeArea: ''
  },
  items: [{ ...initItem, boxNumber: 1 }]
};

const init = [initReceiver];

const MultipleReceiverInfo = () => {
  const history = useHistory();
  const locationTypes = { state: 'state', country: 'country', city: 'city' };
  const [, , , getToken] = useAuthToken();
  const isLoggedIn = Boolean(getToken());
  const [validationSuccess, setValidationSuccess] = useState(false);
  const [locationIndex, setLocationIndex] = useState(0);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [shipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  const [prohibitedListPrompt, setProhibitedListPrompt] = useState(false);
  const [prohibitedCountry] = useState({
    name: '',
    countryCode: ''
  });
  const [saveAddress] = useSaveAddressMutation();
  const [updateAddress] = useUpdateAddressMutation();
  const [openChooseAddressPrompt, setOpenChooseAddressPrompt] = useState({
    open: false,
    index: 0
  });
  const [promptLoading, setPromptLoading] = useState(false);
  const [nextSteps, setNextSteps] = useState([]);
  const [getCountries, countries] = useGetCountriesLazyQuery(countries => {
    if (shipmentDetail?.multipleReceivers?.length) {
      if (shipmentDetail?.multipleReceivers?.length === 1) {
        const receiver = shipmentDetail?.multipleReceivers?.[0];
        if (receiver && receiver?.receiverDetail?.countryCode) {
          getStates(receiver?.receiverDetail?.countryCode);
          if (receiver?.receiverDetail?.city) {
            getCities({
              countryCode: receiver?.receiverDetail?.countryCode,
              cityName: receiver?.receiverDetail?.city
            });
          }
        }
      }

      setInitialValues(
        shipmentDetail.multipleReceivers.map((receiver, index) => {
          const isBoxShipment = receiver?.items?.some(item =>
            Boolean(item?.boxNumber)
          );

          const divideByHundred = item => ({
            ...item,
            value: item?.value / 100
          });

          if (isBoxShipment) {
            const newBoxes = [];

            receiver?.items?.forEach(item => {
              if (!newBoxes.includes(item?.boxNumber)) {
                newBoxes.push(item?.boxNumber);
              }
            });

            boxes[index] = { list: newBoxes };

            currentBoxes[index] = 1;

            setBoxes([...boxes]);

            setCurrentBoxes([...currentBoxes]);
          }

          return {
            ...receiver,
            receiverDetail: {
              ...receiver?.receiverDetail,
              shouldSave: false,
              country:
                receiver.receiverDetail?.countryCode &&
                !receiver.receiverDetail?.country
                  ? countries?.find(
                      country =>
                        country?.code?.toLowerCase() ===
                        receiver.receiverDetail?.countryCode?.toLowerCase()
                    )?.name
                  : receiver.receiverDetail?.country
            },
            items: receiver.items.map(item => {
              item = divideByHundred(item);

              // backwards compatibility
              if (!isBoxShipment && boxes[index]) {
                item.boxNumber = boxes[index].list[0];
              }

              return item;
            })
          };
        })
      );

      setAutomations(
        shipmentDetail?.multipleReceivers?.map((_, index) => ({
          index,
          isAutomated: false
        }))
      );
      setPlaces(
        shipmentDetail?.multipleReceivers?.map((receiver, index) => ({
          index,
          description: receiver?.receiverDetail?.addressLine1
        }))
      );
      setLoadings(
        shipmentDetail?.multipleReceivers?.map((_, index) => ({
          index,
          isLoaded: true
        }))
      );
      setEdits(
        shipmentDetail?.multipleReceivers?.map((_, index) => ({
          index,
          isEdit: false
        }))
      );
    }
  }, 'no-cache');
  const [getStates, getStatesResult] = useGetStatesQuery(
    undefined,
    undefined,
    data => handleLocationType(data, locationTypes.state)
  );
  const [getCities, citiesResult] = useGetCitiesMutation(data =>
    handleLocationType(data, locationTypes.city)
  );
  const [automations, setAutomations] = useState([
    { index: 0, isAutomated: false }
  ]);
  const [edits, setEdits] = useState([{ index: 0, isEdit: false }]);
  const [loadings, setLoadings] = useState([{ index: 0, isLoaded: true }]);
  const [places, setPlaces] = useState([
    {
      index: 0,
      description: ''
    }
  ]);
  const [boxes, setBoxes] = useState([{ list: [1] }]);
  const [currentBoxes, setCurrentBoxes] = useState([1]);
  const [initialValues, setInitialValues] = useState(init);
  const currentIndex = multipleShipmentLinks.findIndex(
    link => history.location.pathname === link.link
  );
  const [addressTotal] = useRecoilState(addressTotalState);

  const { getPlaces, placesService, placesResult } = usePlaces();
  const [getAddresses, getAddressesResult] = useGetSavedAddresses();

  const loading =
    citiesResult.loading ||
    getStatesResult.loading ||
    countries.loading ||
    promptLoading ||
    placesResult.loading ||
    getAddressesResult.loading;

  const { filterStates, testState } = useFilterIntracityShipments();
  const [showAddressGuide, setShowAddressGuide] = useState(false);

  const validationSchema = yup.object().shape({
    multipleReceivers: yup
      .array()
      .of(
        yup.object().shape({
          receiverDetail: yup.object().shape({
            name: yup
              .string()
              .test(
                'number of words',
                'Name should not be less than 2 words',
                value => value?.split(' ')?.length >= 2
              )
              .required('Please enter a name'),
            email: yup
              .string()
              .email('Please enter a valid email address')
              .required('please enter an email address'),
            phoneNumber: yup.string().required('Please enter a phone number'),
            addressLine1: yup
              .string()
              .max(
                40,
                'Please your address must not exceed 40 characters, use the next input.'
              )
              .test(
                'contains the + character',
                'Please enter a valid address',
                value => !value?.includes('+')
              )
              .required('Please enter an address'),
            addressLine2: yup
              .string()
              .max(
                40,
                'Please your address must not exceed 40 characters, use the next input.'
              )
              .test(
                'contains the + character',
                'Please enter a valid address',
                value => !value?.includes('+')
              )
              .nullable(),
            addressLine3: yup
              .string()
              .max(40, 'Please your address must not exceed 40 characters')
              .test(
                'contains the + character',
                'Please enter a valid address',
                value => !value?.includes('+')
              )
              .nullable(),
            country: yup.string().max(35).required('Please select a country'),
            state: yup.string().max(35).required('Please select a state'),
            city: yup.string().max(35).required('Please select a city'),
            countryCode: yup.string().required('Please select a country'),
            postalCode: yup.string().when(['countryCode'], {
              is: countryCode =>
                countryCode?.toLowerCase() !== defaultDetail.countryCode,
              then: yup.string().required('Please provide a postal code'),
              otherwise: yup.string().nullable().notRequired()
            })
          }),
          items: yup.array().of(
            yup.object().shape({
              category: yup.string().required('Please provide a Category'),
              isVolumetric: yup.bool(),
              weight: yup.number().when(['isVolumetric'], {
                is: isVolumetric => !isVolumetric,
                then: yup
                  .number()
                  .min(0.09999, 'Please enter a weight greater than zero (0)')
                  .required('Please enter the weight of your item(s)'),
                otherwise: yup.number().nullable().notRequired()
              }),
              width: yup.number().when(['isVolumetric'], {
                is: isVolumetric => isVolumetric === true,

                then: yup
                  .number()
                  .min(0.09999, 'Please enter a width greater than zero (0)')
                  .required('Please enter the width of your item(s)'),
                otherwise: yup.number().nullable().notRequired()
              }),
              height: yup.number().when(['isVolumetric'], {
                is: isVolumetric => isVolumetric === true,

                then: yup
                  .number()
                  .min(0.09999, 'Please enter a height greater than zero (0)')
                  .required('Please enter the height of your item(s)'),
                otherwise: yup.number().nullable().notRequired()
              }),
              length: yup.number().when(['isVolumetric'], {
                is: isVolumetric => isVolumetric === true,

                then: yup
                  .number()
                  .min(0.09999, 'Please enter a length greater than zero (0)')
                  .required('Please enter the length of your item(s)'),
                otherwise: yup.number().nullable().notRequired()
              }),

              quantity: yup.number().required('Please provide the quantity'),
              value: yup
                .number()
                .moreThan(0, 'Please enter a number above 1')
                .required('Please provide the value'),
              description: yup
                .string()
                .required('Please provide the item description')
            })
          )
        })
      )
      .max(5, 'Can only send to 5 receivers maximum')
  });

  const {
    values: { multipleReceivers },
    errors,
    isValid,
    validateForm,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      multipleReceivers: initialValues
    },
    validationSchema,
    onSubmit: values => {
      if (isValid) {
        setValidationSuccess(true);
      }
    }
  });

  const refs = [
    ...Array(multipleReceivers?.length)
      .fill(undefined)
      .map((_, i) => {
        return { key: i };
      })
  ];

  const generateAddressLine1Ref = index => {
    const ref = refs.find(ref => ref.key === index);
    ref.addressLine1Ref = createRef();
    return ref.addressLine1Ref;
  };

  const generateAddressLine2Ref = index => {
    const ref = refs.find(ref => ref.key === index);
    ref.addressLine2Ref = createRef();
    return ref.addressLine2Ref;
  };

  const generateAddressLine3Ref = index => {
    const ref = refs.find(ref => ref.key === index);
    ref.addressLine3Ref = createRef();
    return ref.addressLine3Ref;
  };

  const addNewReceiver = () => {
    multipleReceivers.push(initReceiver);

    const index = multipleReceivers.length - 1;

    places[index] = { index, description: '' };

    automations[index] = { index, isAutomated: false };

    nextSteps[index] = { index, open: false };

    boxes[index] = { list: [1] };

    currentBoxes[index] = boxes[index].list[0];

    setBoxes([...boxes]);

    setCurrentBoxes([...currentBoxes]);

    setPlaces([...places]);

    setAutomations([...automations]);

    setNextSteps([...nextSteps]);

    setLocationIndex(index);

    setFieldValue('multipleReceivers', [...multipleReceivers]);
  };

  const addItem = (index, boxNumber) => {
    setFieldValue(`multipleReceivers.${index}.items`, [
      ...multipleReceivers?.[index]?.items,
      { ...initItem, boxNumber }
    ]);
  };

  const deleteItem = (index, deleteIndex, boxNumber) => {
    const items = multipleReceivers[index]?.items.filter(
      item => item.boxNumber === boxNumber
    );

    if (items.length <= 1) {
      return;
    }

    setFieldValue(
      `multipleReceivers.${index}.items`,
      multipleReceivers?.[index]?.items?.filter(
        (_, itemIndex) => itemIndex !== deleteIndex
      )
    );
  };

  const setCurrentBox = (box, receiverIndex) => {
    currentBoxes[receiverIndex] = box;

    setCurrentBoxes([...currentBoxes]);
  };

  const addNewBox = receiverIndex => {
    const boxNumber = currentBoxes[receiverIndex] + 1;

    boxes[receiverIndex].list.push(boxNumber);

    currentBoxes[receiverIndex] = boxNumber;

    setBoxes([...boxes]);

    setCurrentBoxes([...currentBoxes]);

    setFieldValue(`multipleReceivers.${receiverIndex}.items`, [
      ...multipleReceivers?.[receiverIndex]?.items,
      { ...initItem, boxNumber }
    ]);
  };

  const deleteBox = (boxNumber, receiverIndex) => {
    const newBoxes = boxes?.[receiverIndex]?.list
      ?.filter(box => box !== boxNumber)
      .map(box => (box > boxNumber ? box - 1 : box));

    boxes[receiverIndex].list = newBoxes;

    currentBoxes[receiverIndex] = newBoxes[newBoxes.length - 1];

    setBoxes([...boxes]);

    setCurrentBoxes([...currentBoxes]);

    setFieldValue(
      `multipleReceivers.${receiverIndex}.items`,
      multipleReceivers?.[receiverIndex]?.items
        ?.filter(item => item.boxNumber !== boxNumber)
        .map(item => {
          if (item?.boxNumber > boxNumber) {
            item.boxNumber = item.boxNumber - 1;
          }

          return item;
        })
    );
  };

  const removeReceiver = index => {
    setNextSteps(nextSteps.filter((_, stepIndex) => stepIndex !== index));

    setBoxes(boxes.filter((_, boxIndex) => boxIndex !== index));

    setCurrentBoxes(
      currentBoxes.filter((_, currentBoxIndex) => currentBoxIndex !== index)
    );

    setFieldValue(
      'multipleReceivers',
      multipleReceivers.filter((_, receiverIndex) => receiverIndex !== index)
    );
  };

  const handleLocation = (
    locationArray = [],
    setLocationArray = () => {},
    data = []
  ) => {
    const location = locationArray.find(
      locate => locate.index === locationIndex
    );
    if (location) {
      const newlocation = { ...location, list: data };
      setLocationArray(
        locationArray.map(locate =>
          locate.index === locationIndex ? newlocation : locate
        )
      );
    } else {
      const newlocationArray = [...locationArray];
      newlocationArray.push({ index: locationIndex, list: data });
      setLocationArray(newlocationArray);
    }
  };

  const handleLocationType = (data, type) => {
    switch (type) {
      case locationTypes.state:
        handleLocation(states, setStates, data);
        break;
      case locationTypes.city:
        handleLocation(cities, setCities, data);
        break;
      default:
        break;
    }
  };

  const handleLocationSelect = place => {
    const { name, value, countryCode, index } = place;
    const choseNigeria =
      countryCode?.toLowerCase() === defaultDetail.countryCode;
    const updateReceiver = {
      ...multipleReceivers?.[index],
      receiverDetail: {
        ...multipleReceivers?.[index]?.receiverDetail,
        [name]: value,
        ...(countryCode && { countryCode }),
        ...(choseNigeria && {
          postalCode: '100001'
        })
      }
    };
    setInitialValues(
      multipleReceivers.map((receiver, receiverIndex) =>
        receiverIndex === index ? updateReceiver : receiver
      )
    );
  };

  const handleSetOpenChooseAddressPrompt = open => {
    setOpenChooseAddressPrompt(initial => ({ ...initial, open }));
  };

  const handleEditAddress = address => {
    if (testState(address)) {
      const updateReceiver = {
        ...multipleReceivers[openChooseAddressPrompt.index],
        receiverDetail: {
          ...multipleReceivers[openChooseAddressPrompt.index]?.receiverDetail,
          ...address,
          country: address.country,
          shouldSave: true
        }
      };

      const oldPlace = places.find(
        place => place.index === openChooseAddressPrompt.index
      );
      const newPlace = { ...oldPlace, description: address?.addressLine1 };

      const newEdit = {
        ...edits.find(edit => edit.index === openChooseAddressPrompt.index),
        isEdit: true
      };

      setLocationIndex(openChooseAddressPrompt.index);
      getStates(address.countryCode);
      getCities({
        countryCode: address.countryCode,
        cityName: address.city
      });
      setPlaces(places =>
        places.map(place =>
          place.index === openChooseAddressPrompt.index ? newPlace : place
        )
      );
      setEdits(edits =>
        edits.map(edit =>
          edit.index === openChooseAddressPrompt.index ? newEdit : edit
        )
      );
      setInitialValues(
        multipleReceivers.map((receiver, receiverIndex) =>
          receiverIndex === openChooseAddressPrompt.index
            ? updateReceiver
            : receiver
        )
      );
    }
  };

  const handleSelectAddress = address => {
    if (testState(address)) {
      setPromptLoading(true);
      const updateReceiver = {
        ...multipleReceivers[openChooseAddressPrompt.index],
        receiverDetail: {
          ...multipleReceivers[openChooseAddressPrompt.index]?.receiverDetail,
          ...address
        }
      };
      const choseNigeria =
        updateReceiver.receiverDetail.countryCode?.toLowerCase() ===
        defaultDetail.countryCode;

      setInitialValues(
        multipleReceivers.map((receiver, receiverIndex) =>
          receiverIndex === openChooseAddressPrompt.index
            ? updateReceiver
            : receiver
        )
      );
      setLocationIndex(openChooseAddressPrompt.index);
      getStates(address.countryCode);
      getCities({
        countryCode: address.countryCode,
        cityName: address.city
      });

      setTimeout(() => {
        const values = { multipleReceivers: [...init] };
        values.multipleReceivers[0] = {
          ...values.multipleReceivers[0],
          receiverDetail: {
            ...values.multipleReceivers[0].receiverDetail,
            ...address
          }
        };
        if (!choseNigeria && !updateReceiver.receiverDetail?.postalCode) {
          setPromptLoading(false);
          return setFieldError(
            `multipleReceivers.${openChooseAddressPrompt.index}.receiverDetail.postalCode`,
            'Please provide a postal Code'
          );
        } else {
          validateForm(values).then(errors => {
            if (
              !errors?.multipleReceivers?.[openChooseAddressPrompt.index]
                ?.receiverDetail
            ) {
              nextSteps[openChooseAddressPrompt.index] = {
                index: openChooseAddressPrompt.index,
                open: true
              };
              setNextSteps(nextSteps);
              setPromptLoading(false);
            }
          });
        }
      }, 500);
    }
  };

  const handleSelectPlace = (place, index) => {
    placesService?.getDetails(
      { placeId: place?.place_id },
      (placeInfo, placeStatus) => {
        ReactGA.event({
          category: 'Shipment Booking',
          action: `User used autocomplete and got this status in multiple receiver booking - ${placeStatus}`
        });
        const { countryCode, city, country, state, address } = extractLocation(
          placeInfo
        );
        if (testState({ state, countryCode })) {
          getStates(countryCode);
          getCities({ countryCode, cityName: city });
          const choseNigeria =
            countryCode?.toLowerCase() === defaultDetail.countryCode;
          const cleanAddress = address
            ?.split(', ')
            ?.slice(0, address?.split(', ').length - 2)
            ?.join(', ');
          const addressLine1 = cleanAddress.substring(0, 40);
          const addressLine2 = cleanAddress.substring(40, 80);
          const addressLine3 = cleanAddress.substring(80, 120);

          const newAddress = {
            ...multipleReceivers[index]?.receiverDetail,
            ...(addressLine2 && { addressLine2 }),
            ...(addressLine3 && { addressLine3 }),
            ...(choseNigeria && { postalCode: '100001' }),
            city,
            addressLine1,
            country,
            state,
            countryCode
          };
          const newValues = multipleReceivers.map((receiver, receiverIndex) =>
            receiverIndex === index
              ? { ...receiver, receiverDetail: newAddress }
              : receiver
          );
          const newAutomations = automations.map(automation =>
            automation.index === index
              ? { index, isAutomated: false }
              : automation
          );
          setInitialValues(newValues);
          setAutomations(newAutomations);
        }
      }
    );
  };

  const handleAutomation = index => () => {
    const oldAutomation = automations.find(
      automation => automation.index === index
    );
    const newAutomation = {
      ...oldAutomation,
      isAutomated: !oldAutomation.isAutomated
    };
    setAutomations(automations =>
      automations.map(automation =>
        automation.index === index ? newAutomation : automation
      )
    );
  };

  const handleChangePlace = (event, index) => {
    const {
      target: { value }
    } = event;
    const oldPlace = places.find(place => place.index === index);
    const newPlace = { ...oldPlace, description: value };
    setPlaces(places =>
      places.map(place => (place.index === index ? newPlace : place))
    );
  };

  const handleToggleStep = (index, open) => {
    const allNextSteps = [...nextSteps];
    allNextSteps[index] = { index, open };
    setNextSteps(allNextSteps);
  };

  const handleItemChange = ({ name, value }) => {
    const itemValue = name.match(new RegExp('quantity|weight|value', 'i'))
      ? value
        ? parseFloat(value)
        : 0
      : value;
    handleChange({ target: { name, value: itemValue } });
  };

  const handleAddItems = index => {
    if (testState(multipleReceivers?.[index])) {
      validateForm({ multipleReceivers }).then(errors => {
        const choseNigeria =
          multipleReceivers[index]?.receiverDetail?.countryCode !==
          defaultDetail.countryCode;
        if (
          !choseNigeria &&
          !multipleReceivers[index]?.receiverDetail.postalCode
        ) {
          setFieldError(
            `multipleReceivers.${index}.receiverDetail.postalCode`,
            'Please provide a postal Code'
          );
        } else if (!errors?.multipleReceivers?.[index]?.receiverDetail) {
          handleToggleStep(index, true);
        }
      });
    } else {
      setAutomations(automations =>
        automations.map(automate =>
          automate.index === openChooseAddressPrompt.index
            ? { ...automate, isAutomated: false }
            : automate
        )
      );
      return setFieldError(
        `multipleReceivers.${openChooseAddressPrompt.index}.receiverDetail.state`,
        'Please choose another state'
      );
    }
  };

  const handleSaveAddress = index => {
    const updatedReceiver = {
      ...multipleReceivers?.[index],
      receiverDetail: {
        ...multipleReceivers?.[index]?.receiverDetail,
        shouldSave: !multipleReceivers[index]?.receiverDetail?.shouldSave
      }
    };
    setInitialValues(
      multipleReceivers.map((receiver, receiverIndex) =>
        receiverIndex === index ? updatedReceiver : receiver
      )
    );
  };

  const handleValidationSuccess = async () => {
    const newMultipleReceivers = [];
    multipleReceivers.forEach(receiver => {
      if (receiver?.receiverDetail?.shouldSave) {
        const data = omit(receiver?.receiverDetail, [
          'id',
          'shouldSave',
          'updatedDate',
          'isCompleted'
        ]);

        if (receiver?.receiverDetail?.id) {
          updateAddress(data, receiver?.receiverDetail?.id);
        } else {
          saveAddress(data);
        }
      }

      const newItems = receiver.items.map(item => {
        let weight = item.weight;
        if (item.isVolumetric) {
          weight = Number(
            ((item.width * item.height * item.length) / 5000).toFixed(2)
          );
        }

        return omit(
          {
            ...item,
            weight,
            value: item.value * 100,
            isCompleted: true
          },
          ['itemIndex']
        );
      });

      newMultipleReceivers.push({
        ...receiver,
        receiverDetail: { ...receiver.receiverDetail, isCompleted: true },
        items: newItems
      });
    });
    setSaveShipmentDetail({
      ...shipmentDetail,
      multipleReceivers: newMultipleReceivers
    });
    return newMultipleReceivers.length;
  };

  const handleValidation = event => {
    event && event?.preventDefault();
    const nodelist = window.document.querySelectorAll(
      '.multiple-receiver-info__item'
    );
    validateForm({ multipleReceivers }).then(errors => {
      if (!Object.values(errors).length) {
        handleSubmit(event);
      } else {
        setAutomations(automations =>
          automations.map(automate => ({ ...automate, isAutomated: false }))
        );
        errors?.multipleReceivers?.forEach((receiver, receiverIndex) => {
          if (receiver) {
            if (
              !receiver?.receiverDetail &&
              receiver?.items &&
              receiver?.items?.length
            ) {
              handleToggleStep(receiverIndex, true);
            }
            const choseNigeria =
              multipleReceivers[receiverIndex]?.receiverDetail?.countryCode ===
              defaultDetail.countryCode;

            if (
              !choseNigeria &&
              !multipleReceivers[receiverIndex]?.receiverDetail.postalCode
            ) {
              setFieldError(
                `multipleReceivers.${receiverIndex}.receiverDetail.postalCode`,
                'Please provide a postal Code'
              );
            }
            if (!testState(multipleReceivers?.[receiverIndex])) {
              return setFieldError(
                `multipleReceivers.${openChooseAddressPrompt.index}.receiverDetail.state`,
                'Please choose another state'
              );
            }
            const nodeItem = nodelist[receiverIndex];
            nodeItem?.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
    });
  };

  const getCountryCode = input => {
    const term = input?.terms[input?.terms?.length - 1];
    const value = term?.value === 'USA' ? 'United states' : term.value;
    return countries.data?.getCountries
      ? countries.data?.getCountries
          .find(country => country?.name.toLowerCase() === value?.toLowerCase())
          ?.code?.toLowerCase() || defaultDetail.countryCode
      : defaultDetail.countryCode;
  };

  const isMobile = useMediaQuery('(max-width:1100px)');

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (validationSuccess) {
      handleValidationSuccess().then(arrayLength => {
        if (arrayLength === multipleReceivers.length) {
          history.push(multipleShipmentLinks[currentIndex + 1].link);
        }
      });
    }
    // eslint-disable-next-line
  }, [validationSuccess]);

  useEffect(() => {
    if (history.location.state?.reValidate) {
      const isCompleted = checkIsCompleted(currentIndex, shipmentDetail);
      if (!isCompleted) {
        handleValidation();
      }
    }
    history.replace({
      state: {}
    });
    // eslint-disable-next-line
  }, [history.location.state?.reValidate]);

  return (
    <>
      <NewShippingStepsLayout>
        <Helmet>
          <meta
            name='description'
            content='Topship Africa Shipment Request Receiver Information'
          />
          <title>Topship Africa Shipment Request Receiver Information</title>
          <link
            rel='canonical'
            href='https://topship.africa/shipment-request-receiver-information'
          />
        </Helmet>
        <BusyOverlay loading={loading} />
        <ChooseAddressPrompt
          openPrompt={openChooseAddressPrompt.open}
          setOpenPrompt={handleSetOpenChooseAddressPrompt}
          onEditAddress={handleEditAddress}
          onSelectAddress={handleSelectAddress}
        />
        <form onSubmit={handleValidation} className='multiple-receiver-info'>
          <div className='multiple-receiver-info__wrap'>
            <label className='multiple-receiver-info__label'>
              Please provide the Receivers’ information
            </label>
            <div className='multiple-receiver-info__items'>
              {multipleReceivers?.map((receiver, index) => {
                const theCountries = countries.data
                  ? countries.data?.getCountries
                  : [];

                const theStates =
                  states
                    ?.find(state => state.index === index)
                    ?.list?.filter(filterStates) || [];

                const theCities =
                  cities?.find(city => city.index === index)?.list || [];

                const isOpen = nextSteps[index]?.open;

                const isAutomated = automations.find(
                  automation => automation.index === index
                )?.isAutomated;

                const place = places?.find(place => place.index === index) || {
                  description: ''
                };

                const isEdit = edits.find(edit => edit.index === index)?.isEdit;

                const isLoaded = loadings?.find(load => load.index === index)
                  ?.isLoaded;

                const addresses =
                  isEdit ||
                  (shipmentDetail?.multipleReceivers?.[index]?.receiverDetail
                    ?.name &&
                    isLoaded)
                    ? [
                        isEdit
                          ? multipleReceivers?.[index]?.receiverDetail
                          : shipmentDetail?.multipleReceivers?.[index]
                              ?.receiverDetail
                      ]
                    : getAddressesResult.data?.getAddresses?.list || [];

                const theAddress = addresses?.find(
                  address =>
                    address.id ===
                    multipleReceivers?.[index]?.receiverDetail?.id
                );

                const receiverBoxes = boxes?.[index]?.list;

                const currentBox = currentBoxes?.[index];

                const currentBoxItems = multipleReceivers?.[index]?.items
                  ?.filter(item => item.boxNumber === currentBox)
                  .map(item => {
                    item.itemIndex = multipleReceivers?.[
                      index
                    ]?.items.findIndex(
                      value => JSON.stringify(value) === JSON.stringify(item)
                    );

                    return item;
                  });

                return (
                  <div key={index} className='multiple-receiver-info__item'>
                    <div className='multiple-receiver-info__item__wrap'>
                      <div className='item-description__main__action mb-2'>
                        {multipleReceivers.length > 1 && (
                          <Button
                            variant='text'
                            onClick={() => removeReceiver(index)}
                          >
                            <span>Remove Receiver</span>
                            <RemoveCircle />
                          </Button>
                        )}
                      </div>
                      <div className='multiple-receiver-info__item__selection'>
                        <div className='multiple-receiver-info__item__selection__wrap'>
                          <div
                            onClick={() => handleToggleStep(index, false)}
                            className={`multiple-receiver-info__item__selection__option${
                              !nextSteps[index]?.open ? ' active' : ''
                            }`}
                          >
                            <span>Receiver Details</span>
                          </div>
                          <div
                            onClick={() => handleAddItems(index)}
                            className={`multiple-receiver-info__item__selection__option${
                              nextSteps[index]?.open ? ' active' : ''
                            }`}
                          >
                            <span>Item Details</span>
                          </div>
                        </div>
                      </div>
                      {isOpen ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            // gap: '24px',
                            marginTop: '24px'
                          }}
                        >
                          <div className='new-addresses__list__item'>
                            <div className='new-addresses__list__item__wrap'>
                              <div className='new-addresses__list__item__header'>
                                <div className='new-addresses__list__item__name'>
                                  <h2>{receiver.receiverDetail.name}</h2>
                                  {receiver.receiverDetail.isDefault && (
                                    <span>Default</span>
                                  )}
                                </div>
                                <Button
                                  variant='text'
                                  className='new-addresses__list__item__action'
                                  onClick={() => handleToggleStep(index, false)}
                                  id='address-list-item-dropdown'
                                >
                                  <span className='new-addresses__list__item__divider'></span>
                                  <span className='new-addresses__list__item__divider'></span>
                                  <span className='new-addresses__list__item__divider'></span>
                                </Button>
                              </div>
                              <div className='new-addresses__list__item__body'>
                                <div className='new-addresses__list__item__meta'>
                                  <span className='new-addresses__list__item__meta__email'>
                                    {receiver.receiverDetail.email}
                                  </span>
                                  {receiver.receiverDetail.phoneNumber && (
                                    <span className='new-addresses__list__item__divider'></span>
                                  )}
                                  <span className='new-addresses__list__item__meta__phone'>
                                    {receiver.receiverDetail.phoneNumber}
                                  </span>
                                  {receiver.receiverDetail.postalCode && (
                                    <span className='new-addresses__list__item__divider'></span>
                                  )}
                                  <span className='new-addresses__list__item__meta__postal'>
                                    {receiver.receiverDetail.postalCode}
                                  </span>
                                </div>
                                <div className='new-addresses__list__item__address'>
                                  <span className='new-addresses__list__item__address__street'>
                                    {receiver.receiverDetail.addressLine1}
                                  </span>
                                  {(receiver.receiverDetail.addressLine2 ||
                                    receiver.receiverDetail.addressLine3) && (
                                    <span className='new-addresses__list__item__divider'></span>
                                  )}
                                  <span className='new-addresses__list__item__address__landmark'>
                                    {receiver.receiverDetail.addressLine2 ||
                                      receiver.receiverDetail.addressLine3}
                                  </span>
                                </div>
                                <div className='new-addresses__list__item__location'>
                                  <img
                                    loading='lazy'
                                    src={`https://flagcdn.com/w20/${receiver.receiverDetail.countryCode.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${receiver.receiverDetail.countryCode.toLowerCase()}.png 2x`}
                                    alt=''
                                  />
                                  <span>{`${receiver.receiverDetail.city.toLowerCase()}, ${
                                    receiver.receiverDetail.state
                                  }. ${receiver.receiverDetail.country}`}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'nowrap',
                              width: '100%',
                              overflowX: isMobile ? 'auto' : ''
                            }}
                          >
                            {receiverBoxes.map(box => (
                              <div
                                onClick={() => setCurrentBox(box, index)}
                                className={`item-description__box-model ${
                                  currentBox === box ? 'active' : ''
                                }`}
                                key={box}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    width: isMobile ? '200px' : 'auto'
                                  }}
                                >
                                  <div>
                                    <img
                                      style={{
                                        width: '100%',
                                        objectFit: 'contain'
                                      }}
                                      src={BoxModel}
                                      alt=''
                                    />
                                    <h3
                                      style={{
                                        fontSize: '24px',
                                        fontWeight: '700',
                                        color: '#22428F',
                                        textAlign: 'center'
                                      }}
                                      className='mt-3'
                                    >
                                      Box {box}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {receiverBoxes.length <= 3 && (
                              <div
                                style={{
                                  borderBottom: '1px solid #e2e4e8',
                                  flexGrow: '1'
                                }}
                              >
                                <div
                                  onClick={() => addNewBox(index)}
                                  className='item-description__new-box'
                                >
                                  <img src={AddBoxIcon} alt='add box' />
                                  <p>Add another box</p>
                                </div>
                              </div>
                            )}
                          </div>
                          <div>
                            {receiverBoxes.map(box => (
                              <div style={{ display: 'flex' }} key={box}>
                                <div>
                                  {currentBox === box && (
                                    <div
                                      className={
                                        currentBox === box
                                          ? 'form-transition'
                                          : ''
                                      }
                                    >
                                      <div className='item-description__mains'>
                                        {currentBoxItems.map(
                                          (item, theItemIndex) => {
                                            const itemIndex = item?.itemIndex;

                                            const itemCategoryMessage = getItemCategoryMessage(
                                              item?.category,
                                              shipmentDetail
                                            );

                                            return (
                                              <div
                                                key={itemIndex}
                                                style={{
                                                  borderTop:
                                                    theItemIndex === 0
                                                      ? ''
                                                      : '1px solid #e2e4e8',
                                                  borderTopLeftRadius:
                                                    theItemIndex === 0
                                                      ? ''
                                                      : '8px',
                                                  borderTopRightRadius:
                                                    theItemIndex === 0
                                                      ? ''
                                                      : '8px'
                                                }}
                                                className='item-description__main'
                                              >
                                                <div className='item-description__main__wrap'>
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      justifyContent:
                                                        'space-between',
                                                      alignItems: 'center'
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        color: '#545859',
                                                        fontWeight: 700
                                                      }}
                                                    >
                                                      Item {theItemIndex + 1}
                                                    </h3>
                                                    {receiverBoxes.length >
                                                      1 && (
                                                      <div
                                                        style={{
                                                          alignSelf:
                                                            'flex-end !important'
                                                        }}
                                                        className='item-description__main__action'
                                                      >
                                                        <Button
                                                          variant='text'
                                                          onClick={() =>
                                                            deleteBox(
                                                              box,
                                                              index
                                                            )
                                                          }
                                                        >
                                                          <span>
                                                            Delete Box {box}
                                                          </span>
                                                          <RemoveCircle />
                                                        </Button>
                                                      </div>
                                                    )}
                                                  </div>

                                                  <label className='item-description__label'>
                                                    Enter details about the item
                                                    you want to ship
                                                  </label>
                                                  <div
                                                    style={{
                                                      alignSelf:
                                                        'flex-end !important'
                                                    }}
                                                    className='item-description__main__action'
                                                  >
                                                    {currentBoxItems.length >
                                                      1 && (
                                                      <Button
                                                        variant='text'
                                                        onClick={() =>
                                                          deleteItem(
                                                            index,
                                                            itemIndex,
                                                            box
                                                          )
                                                        }
                                                      >
                                                        <span>Remove item</span>
                                                        <RemoveCircle />
                                                      </Button>
                                                    )}
                                                  </div>
                                                  <div className='item-description__main__grid'>
                                                    <a
                                                      href={
                                                        'https://topship.africa/prohibited-items'
                                                      }
                                                      target='_blank'
                                                      rel='noreferrer'
                                                      style={{
                                                        textDecoration: 'none',
                                                        transform: 'none'
                                                      }}
                                                      className='item-description__main__grid__helper category-helper'
                                                    >
                                                      SEE LIST OF PROHIBITED &
                                                      RESTRICTED ITEMS.
                                                    </a>
                                                    <Grid
                                                      container
                                                      columnSpacing='10px'
                                                      rowSpacing='16px'
                                                      className='item-description__main__grid__form'
                                                    >
                                                      <CustomAutoComplete
                                                        options={itemCategories}
                                                        getOptionLabel={option =>
                                                          option
                                                            ? option?.category
                                                            : ''
                                                        }
                                                        isOptionEqualToValue={(
                                                          option,
                                                          value
                                                        ) =>
                                                          option?.value ===
                                                          value?.value
                                                        }
                                                        renderOption={(
                                                          props,
                                                          option
                                                        ) => (
                                                          <Box
                                                            key={props.id}
                                                            component='li'
                                                            {...props}
                                                          >
                                                            {option?.category}
                                                          </Box>
                                                        )}
                                                        value={
                                                          itemCategories.find(
                                                            category =>
                                                              category.value ===
                                                              item.category
                                                          ) || {
                                                            category: '',
                                                            value: ''
                                                          }
                                                        }
                                                        noOptionsText={
                                                          'Item Category not found'
                                                        }
                                                        onChange={(
                                                          event,
                                                          value,
                                                          reason
                                                        ) => {
                                                          if (
                                                            reason === 'clear'
                                                          ) {
                                                            setFieldValue(
                                                              `multipleReceivers.${index}.items.${itemIndex}.category`,
                                                              ''
                                                            );
                                                          } else {
                                                            setFieldValue(
                                                              `multipleReceivers.${index}.items.${itemIndex}.category`,
                                                              value?.value
                                                            );
                                                          }
                                                        }}
                                                        textfieldProps={{
                                                          lg: 6,
                                                          md: 6,
                                                          name: `multipleReceivers.${index}.items.${itemIndex}.category`,
                                                          customLabel:
                                                            'Select Item Category',
                                                          containerClassName:
                                                            'item-description__main__input',
                                                          value: item.category,
                                                          error: Boolean(
                                                            errors
                                                              .multipleReceivers?.[
                                                              index
                                                            ]?.items?.[
                                                              itemIndex
                                                            ]?.category
                                                          ),
                                                          helperText:
                                                            errors
                                                              .multipleReceivers?.[
                                                              index
                                                            ]?.items?.[
                                                              itemIndex
                                                            ]?.category,
                                                          onChange: event => {
                                                            return handleItemChange(
                                                              event?.target
                                                            );
                                                          }
                                                        }}
                                                      />
                                                      <div className='mobile-alert'>
                                                        {itemCategoryRules[
                                                          item?.category
                                                        ] &&
                                                          itemCategoryMessage && (
                                                            <Alert
                                                              severity={'info'}
                                                              className='item-description__main__alert'
                                                              sx={{
                                                                fontSize:
                                                                  '1.5rem',
                                                                fontFamily:
                                                                  'General Sans',
                                                                marginTop:
                                                                  '10px',
                                                                backgroundColor:
                                                                  '#e6f2fe',
                                                                color:
                                                                  '#22428F',
                                                                border: 'none',
                                                                '& .MuiAlert-icon': {
                                                                  color:
                                                                    '#22428F'
                                                                }
                                                              }}
                                                            >
                                                              {
                                                                itemCategoryMessage
                                                              }
                                                            </Alert>
                                                          )}
                                                      </div>
                                                      <Input
                                                        lg={6}
                                                        md={12}
                                                        name={`multipleReceivers.${index}.items.${itemIndex}.value`}
                                                        placeholder='50,000.00'
                                                        customLabel='Item Value (₦)'
                                                        value={`${numberWithCommas(
                                                          item.value || 0
                                                        )}`}
                                                        onChange={event => {
                                                          const {
                                                            value,
                                                            name
                                                          } = event.target;
                                                          if (
                                                            !(
                                                              value &&
                                                              value.length
                                                            )
                                                          ) {
                                                            handleItemChange({
                                                              name,
                                                              value: ''
                                                            });
                                                          } else if (
                                                            isNaN(
                                                              value
                                                                .split(',')
                                                                .join('')
                                                            )
                                                          ) {
                                                            handleItemChange({
                                                              name,
                                                              value: ''
                                                            });
                                                          } else {
                                                            const itemValue = parseInt(
                                                              value
                                                                .split(',')
                                                                .join(''),
                                                              10
                                                            );
                                                            handleItemChange({
                                                              name,
                                                              value: itemValue
                                                            });
                                                          }
                                                        }}
                                                        containerClassName='item-description__main__input'
                                                        error={Boolean(
                                                          errors
                                                            .multipleReceivers?.[
                                                            index
                                                          ]?.items?.[itemIndex]
                                                            ?.value
                                                        )}
                                                        helperText={
                                                          errors
                                                            .multipleReceivers?.[
                                                            index
                                                          ]?.items?.[itemIndex]
                                                            ?.value
                                                        }
                                                      />
                                                    </Grid>
                                                    <div className='web-alert'>
                                                      {itemCategoryRules[
                                                        item?.category
                                                      ] &&
                                                        itemCategoryMessage && (
                                                          <Alert
                                                            severity={'info'}
                                                            className='item-description__main__alert'
                                                            sx={{
                                                              fontSize:
                                                                '1.5rem',
                                                              fontFamily:
                                                                'General Sans',
                                                              marginTop: '10px',
                                                              backgroundColor:
                                                                '#e6f2fe',
                                                              color: '#22428F',
                                                              border: 'none',
                                                              '& .MuiAlert-icon': {
                                                                color: '#22428F'
                                                              }
                                                            }}
                                                          >
                                                            {
                                                              itemCategoryMessage
                                                            }
                                                          </Alert>
                                                        )}
                                                    </div>
                                                    <a
                                                      href={
                                                        'https://www.docdroid.net/AX2ViKa/labelling-a-package-for-shipping-pdf'
                                                      }
                                                      target='_blank'
                                                      rel='noreferrer'
                                                      style={{
                                                        textDecoration: 'none',
                                                        transform: 'none'
                                                      }}
                                                      className='item-description__main__grid__helper'
                                                    >
                                                      SEE HOW TO LABEL YOUR
                                                      PACKAGE.
                                                    </a>
                                                    <Grid container>
                                                      <Input
                                                        fullWidth
                                                        multiline
                                                        minRows={4}
                                                        name={`multipleReceivers.${index}.items.${itemIndex}.description`}
                                                        value={item.description}
                                                        onChange={e =>
                                                          handleItemChange(
                                                            e.target
                                                          )
                                                        }
                                                        customLabel='Detailed Item Description'
                                                        containerClassName='item-description__main__input'
                                                        error={Boolean(
                                                          errors
                                                            .multipleReceivers?.[
                                                            index
                                                          ]?.items?.[itemIndex]
                                                            ?.description
                                                        )}
                                                        helperText={
                                                          errors
                                                            .multipleReceivers?.[
                                                            index
                                                          ]?.items?.[itemIndex]
                                                            ?.description
                                                        }
                                                        placeholder='A piece of text that clearly describes the item being packaged for shipping, it should leave no room for guesses so that TopShip can know how best to handle it'
                                                      />
                                                    </Grid>
                                                    <div className=''>
                                                      <VolumetricWeight
                                                        checkBoxName={`multipleReceivers.${index}.items.${itemIndex}.isVolumetric`}
                                                        isActive={
                                                          item.isVolumetric
                                                        }
                                                        handleToggleSwitch={() =>
                                                          setFieldValue(
                                                            `multipleReceivers.${index}.items.${itemIndex}.isVolumetric`,
                                                            !item.isVolumetric
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    {item.isVolumetric ? (
                                                      <div className='mt-2'>
                                                        <Grid
                                                          container
                                                          columnSpacing='10px'
                                                          rowSpacing='16px'
                                                          className='item-description__main__grid__form'
                                                        >
                                                          <Input
                                                            lg={6}
                                                            md={12}
                                                            type='number'
                                                            name={`multipleReceivers.${index}.items.${itemIndex}.quantity`}
                                                            placeholder='20'
                                                            value={
                                                              item.quantity
                                                            }
                                                            onChange={e =>
                                                              handleItemChange(
                                                                e.target
                                                              )
                                                            }
                                                            customLabel='Quantity'
                                                            error={Boolean(
                                                              errors
                                                                .multipleReceivers?.[
                                                                index
                                                              ]?.items?.[
                                                                itemIndex
                                                              ]?.quantity
                                                            )}
                                                            helperText={
                                                              errors
                                                                .multipleReceivers?.[
                                                                index
                                                              ]?.items?.[
                                                                itemIndex
                                                              ]?.quantity
                                                            }
                                                            containerClassName='item-description__main__input'
                                                            inputProps={{
                                                              min: 1
                                                            }}
                                                          />
                                                          <Input
                                                            lg={6}
                                                            md={12}
                                                            type='number'
                                                            name={`multipleReceivers.${index}.items.${itemIndex}.width`}
                                                            placeholder='20'
                                                            value={item.width}
                                                            onChange={e =>
                                                              handleItemChange(
                                                                e.target
                                                              )
                                                            }
                                                            customLabel='Width (CM)'
                                                            error={Boolean(
                                                              errors
                                                                .multipleReceivers?.[
                                                                index
                                                              ]?.items?.[
                                                                itemIndex
                                                              ]?.width
                                                            )}
                                                            helperText={
                                                              errors
                                                                .multipleReceivers?.[
                                                                index
                                                              ]?.items?.[
                                                                itemIndex
                                                              ]?.width
                                                            }
                                                            containerClassName='item-description__main__input'
                                                            inputProps={{
                                                              min: 1
                                                            }}
                                                          />
                                                        </Grid>
                                                        <Grid
                                                          container
                                                          columnSpacing='10px'
                                                          rowSpacing='16px'
                                                          className='item-description__main__grid__form'
                                                        >
                                                          <Input
                                                            lg={6}
                                                            md={12}
                                                            type='number'
                                                            name={`multipleReceivers.${index}.items.${itemIndex}.length`}
                                                            placeholder='20'
                                                            value={item.length}
                                                            onChange={e =>
                                                              handleItemChange(
                                                                e.target
                                                              )
                                                            }
                                                            customLabel='Length (CM)'
                                                            error={Boolean(
                                                              errors
                                                                .multipleReceivers?.[
                                                                index
                                                              ]?.items?.[
                                                                itemIndex
                                                              ]?.length
                                                            )}
                                                            helperText={
                                                              errors
                                                                .multipleReceivers?.[
                                                                index
                                                              ]?.items?.[
                                                                itemIndex
                                                              ]?.length
                                                            }
                                                            containerClassName='item-description__main__input mt-2'
                                                            inputProps={{
                                                              min: 1
                                                            }}
                                                          />
                                                          <Input
                                                            lg={6}
                                                            md={12}
                                                            type='number'
                                                            name={`multipleReceivers.${index}.items.${itemIndex}.height`}
                                                            customLabel='Height (CM)'
                                                            placeholder='Enter Height (CM)'
                                                            value={item.height}
                                                            onChange={e =>
                                                              handleItemChange(
                                                                e.target
                                                              )
                                                            }
                                                            error={Boolean(
                                                              errors
                                                                .multipleReceivers?.[
                                                                index
                                                              ]?.items?.[
                                                                itemIndex
                                                              ]?.height
                                                            )}
                                                            helperText={
                                                              errors
                                                                .multipleReceivers?.[
                                                                index
                                                              ]?.items?.[
                                                                itemIndex
                                                              ]?.height
                                                            }
                                                            containerClassName='item-description__main__input mt-2'
                                                            inputProps={{
                                                              min: 1,
                                                              step: 'any'
                                                            }}
                                                          />
                                                        </Grid>
                                                      </div>
                                                    ) : (
                                                      <>
                                                        <a
                                                          href={
                                                            'https://docdro.id/khtz9i8'
                                                          }
                                                          target='_blank'
                                                          rel='noreferrer'
                                                          style={{
                                                            textDecoration:
                                                              'none',
                                                            transform: 'none'
                                                          }}
                                                          className='item-description__main__grid__helper'
                                                        >
                                                          NEED HELP?
                                                        </a>
                                                        <Grid
                                                          container
                                                          columnSpacing='10px'
                                                          rowSpacing='16px'
                                                          className='item-description__main__grid__form'
                                                        >
                                                          <Input
                                                            lg={6}
                                                            md={12}
                                                            type='number'
                                                            name={`multipleReceivers.${index}.items.${itemIndex}.quantity`}
                                                            placeholder='20'
                                                            value={
                                                              item.quantity
                                                            }
                                                            onChange={e =>
                                                              handleItemChange(
                                                                e.target
                                                              )
                                                            }
                                                            customLabel='Quantity'
                                                            error={Boolean(
                                                              errors
                                                                .multipleReceivers?.[
                                                                index
                                                              ]?.items?.[
                                                                itemIndex
                                                              ]?.quantity
                                                            )}
                                                            helperText={
                                                              errors
                                                                .multipleReceivers?.[
                                                                index
                                                              ]?.items?.[
                                                                itemIndex
                                                              ]?.quantity
                                                            }
                                                            containerClassName='item-description__main__input'
                                                            inputProps={{
                                                              min: 1
                                                            }}
                                                          />
                                                          <Input
                                                            lg={6}
                                                            md={12}
                                                            type='number'
                                                            name={`multipleReceivers.${index}.items.${itemIndex}.weight`}
                                                            customLabel='Weight (KG)'
                                                            placeholder='Enter Weight'
                                                            value={item.weight}
                                                            onChange={e =>
                                                              handleItemChange(
                                                                e.target
                                                              )
                                                            }
                                                            error={Boolean(
                                                              errors
                                                                .multipleReceivers?.[
                                                                index
                                                              ]?.items?.[
                                                                itemIndex
                                                              ]?.weight
                                                            )}
                                                            helperText={
                                                              errors
                                                                .multipleReceivers?.[
                                                                index
                                                              ]?.items?.[
                                                                itemIndex
                                                              ]?.weight
                                                            }
                                                            containerClassName='item-description__main__input'
                                                            inputProps={{
                                                              min: 1,
                                                              step: 'any'
                                                            }}
                                                          />
                                                        </Grid>
                                                      </>
                                                    )}

                                                    <div className='mt-3' />

                                                    <StickyNoteAlert
                                                      isVolumetric={true}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                        <div
                                          style={{ alignSelf: 'flex-start' }}
                                          className='item-description__action'
                                        >
                                          <Button
                                            variant='text'
                                            disabled={
                                              multipleReceivers[index]?.items
                                                .length >= 5
                                            }
                                            onClick={() => addItem(index, box)}
                                          >
                                            <span>Add another item</span>
                                            <AddCircle />
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <>
                          {Boolean(addressTotal) && (
                            <>
                              <Button
                                variant='outlined'
                                className='sender-info__select mt-2'
                                onClick={() =>
                                  setOpenChooseAddressPrompt({
                                    open: true,
                                    index
                                  })
                                }
                              >
                                <span>Choose from a saved address</span>
                                <ChevronRight />
                              </Button>
                              <div className='sender-info__divider'>
                                <span></span>
                                <span>OR</span>
                                <span></span>
                              </div>
                            </>
                          )}
                          <Grid
                            className={Boolean(!addressTotal) ? 'mt-2' : ''}
                            container
                            columnSpacing='10px'
                            rowSpacing='16px'
                          >
                            <CustomAutoComplete
                              options={addresses}
                              getOptionLabel={option => option?.name}
                              freeSolo
                              isOptionEqualToValue={(option, value) =>
                                option?.id === value?.id
                              }
                              renderOption={(props, option) => (
                                <Box
                                  key={option.id}
                                  component='li'
                                  sx={{
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start !important',
                                    justifyContent: 'flex-start !important'
                                  }}
                                  {...props}
                                >
                                  <p className='d-block'>
                                    <b>{option?.name}</b>
                                  </p>
                                  <span className='d-block'>
                                    {option.addressLine1}
                                  </span>
                                </Box>
                              )}
                              value={
                                shipmentDetail?.multipleReceivers?.[index]
                                  ?.receiverDetail?.name && isLoaded
                                  ? addresses[0]
                                  : theAddress
                              }
                              noOptionsText={
                                getAddressesResult.loading
                                  ? 'Addresses loading...'
                                  : 'Saved address not found'
                              }
                              onChange={(event, value) => {
                                if (testState(value)) {
                                  if (value?.id) {
                                    const newPlaces = places.map(place =>
                                      place.index === index
                                        ? {
                                            index,
                                            description: value?.addressLine1
                                          }
                                        : place
                                    );
                                    const updatedReceiver = {
                                      ...multipleReceivers?.[index],
                                      receiverDetail: {
                                        ...multipleReceivers?.[index]
                                          ?.receiverDetail,
                                        ...value
                                      }
                                    };
                                    const newValues = multipleReceivers.map(
                                      (receiver, receiverIndex) =>
                                        receiverIndex === index
                                          ? updatedReceiver
                                          : receiver
                                    );
                                    setLocationIndex(index);
                                    getStates(value?.countryCode);
                                    getCities({
                                      countryCode: value?.countryCode,
                                      cityName: value?.city
                                    });
                                    setPlaces(newPlaces);
                                    setInitialValues(newValues);
                                  }
                                }
                              }}
                              textfieldProps={{
                                lg: 12,
                                md: 12,
                                name: 'name',
                                customLabel: 'Full Name',
                                containerClassName:
                                  'new-addresses__modal__item__input',
                                error: Boolean(
                                  errors?.multipleReceivers?.[index]
                                    ?.receiverDetail?.name
                                ),
                                helperText:
                                  errors?.multipleReceivers?.[index]
                                    ?.receiverDetail?.name,
                                value:
                                  multipleReceivers?.[index]?.receiverDetail
                                    ?.name,
                                onChange: debounce(event => {
                                  if (addressTotal) {
                                    getAddresses({
                                      take: 10,
                                      page: 1,
                                      keyword: event?.target?.value
                                    });
                                  }
                                  setFieldValue(
                                    `multipleReceivers.${index}.receiverDetail.name`,
                                    event?.target?.value
                                  );
                                  if (isLoaded) {
                                    const newLoading = {
                                      ...loadings.find(
                                        load => load.index === index
                                      ),
                                      isLoaded: false
                                    };
                                    const newEdit = {
                                      ...edits.find(
                                        edit => edit.index === index
                                      ),
                                      isEdit: false
                                    };
                                    setLoadings(loadings =>
                                      loadings.map(load =>
                                        load.index === index ? newLoading : load
                                      )
                                    );
                                    setEdits(edits =>
                                      edits.map(edit =>
                                        edit.index === index ? newEdit : edit
                                      )
                                    );
                                  }
                                }, 1000)
                              }}
                            />

                            <Input
                              error={Boolean(
                                errors?.multipleReceivers?.[index]
                                  ?.receiverDetail?.email
                              )}
                              helperText={
                                errors?.multipleReceivers?.[index]
                                  ?.receiverDetail?.email
                              }
                              value={
                                multipleReceivers[index]?.receiverDetail.email
                              }
                              onChange={handleChange}
                              customLabel='Email address'
                              name={`multipleReceivers.${index}.receiverDetail.email`}
                              id='email'
                              type='email'
                              containerClassName='new-addresses__modal__item__input'
                              placeholder='garrick@topship.com'
                              fullWidth
                              lg={6}
                              md={12}
                            />
                            <Input
                              id='phoneNumber'
                              name={`multipleReceivers.${index}.receiverDetail.phoneNumber`}
                              error={Boolean(
                                errors?.multipleReceivers?.[index]
                                  ?.receiverDetail?.phoneNumber
                              )}
                              helperText={
                                errors?.multipleReceivers?.[index]
                                  ?.receiverDetail?.phoneNumber
                              }
                              onChange={value =>
                                handleChange({
                                  target: {
                                    name: `multipleReceivers.${index}.receiverDetail.phoneNumber`,
                                    value
                                  }
                                })
                              }
                              defaultCountry={defaultDetail.countryCode}
                              value={
                                multipleReceivers[index]?.receiverDetail
                                  ?.phoneNumber
                              }
                              type='tel'
                              placeholder='+234 801 234 5678'
                              containerClassName='new-addresses__modal__item__input'
                              customLabel='Phone Number'
                              lg={6}
                              md={12}
                            />
                          </Grid>
                          <div className='mt-3 item-description__action switcher'>
                            <Button
                              variant='text'
                              onClick={handleAutomation(index)}
                            >
                              <span>
                                {isAutomated
                                  ? 'Switch to manually fill address details'
                                  : 'Switch to automatically fill address details'}
                              </span>
                              <SwapVert />
                            </Button>
                          </div>
                          <Grid
                            container
                            columnSpacing='10px'
                            rowSpacing='16px'
                          >
                            {isAutomated ? (
                              <>
                                <CustomAutoComplete
                                  options={placesResult.data}
                                  getOptionLabel={option =>
                                    option ? option?.description : ''
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option?.place_id === value?.place_id
                                  }
                                  renderOption={(props, option, data) => (
                                    <Box
                                      component='li'
                                      sx={{
                                        '& > img': { mr: 2, flexShrink: 0 }
                                      }}
                                      {...props}
                                      key={props.id}
                                    >
                                      {data && (
                                        <img
                                          loading='lazy'
                                          width='30'
                                          height='20'
                                          src={`https://flagcdn.com/w20/${getCountryCode(
                                            option
                                          )}.png`}
                                          srcSet={`https://flagcdn.com/w40/${getCountryCode(
                                            option
                                          )}.png 2x`}
                                          alt=''
                                        />
                                      )}
                                      {option?.description}
                                    </Box>
                                  )}
                                  value={place}
                                  filterOptions={option => option}
                                  noOptionsText={
                                    !place?.description
                                      ? 'Please enter your address'
                                      : placesResult.loading
                                      ? 'Addresses loading...'
                                      : 'Address not found'
                                  }
                                  onChange={(event, value, reason) => {
                                    if (reason === 'clear') {
                                      setPlaces(places =>
                                        places.map(initPlace =>
                                          initPlace.index === place.index
                                            ? { index, description: '' }
                                            : initPlace
                                        )
                                      );
                                    } else {
                                      setLocationIndex(index);
                                      handleSelectPlace(value, index);
                                    }
                                  }}
                                  textfieldProps={{
                                    lg: 12,
                                    md: 12,
                                    name: 'addressLine1',
                                    customLabel: (
                                      <AddressWithInfo
                                        onClick={() =>
                                          setShowAddressGuide(true)
                                        }
                                      />
                                    ),
                                    containerClassName:
                                      'new-addresses__modal__item__input',
                                    value: place?.description,
                                    error: Boolean(
                                      !place.description &&
                                        errors?.multipleReceivers?.[index]
                                          ?.receiverDetail?.addressLine1
                                    ),
                                    helperText:
                                      errors?.multipleReceivers?.[index]
                                        ?.receiverDetail?.addressLine1,
                                    onChange: event => {
                                      getPlaces(event.target.value);
                                      handleChangePlace(event, index);
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <Input
                                  error={Boolean(
                                    errors?.multipleReceivers?.[index]
                                      ?.receiverDetail?.addressLine1
                                  )}
                                  helperText={
                                    errors?.multipleReceivers?.[index]
                                      ?.receiverDetail?.addressLine1
                                  }
                                  value={
                                    multipleReceivers[index]?.receiverDetail
                                      ?.addressLine1
                                  }
                                  onChange={event => {
                                    const {
                                      target: { name, value }
                                    } = event;
                                    if (value.length < 41) {
                                      handleChange(event);
                                    } else {
                                      handleChange({
                                        target: {
                                          name,
                                          value: value.substring(0, 40)
                                        }
                                      });
                                      setFieldValue(
                                        `multipleReceivers.${index}.receiverDetail.addressLine2`,
                                        value.substring(
                                          40,
                                          value.length > 80 ? 80 : value.length
                                        )
                                      );
                                      if (value.length > 80) {
                                        setFieldValue(
                                          `multipleReceivers.${index}.receiverDetail.addressLine3`,
                                          value.substring(
                                            80,
                                            value.length > 120
                                              ? 120
                                              : value.length
                                          )
                                        );
                                      }
                                      refs[
                                        index
                                      ].addressLine2Ref.current?.focus();
                                    }
                                  }}
                                  customLabel={
                                    <AddressWithInfo
                                      onClick={() => setShowAddressGuide(true)}
                                    />
                                  }
                                  name={`multipleReceivers.${index}.receiverDetail.addressLine1`}
                                  id='addressLine1'
                                  type='text'
                                  containerClassName='new-addresses__modal__item__input'
                                  placeholder='1, Idumota lane, Iweka Road'
                                  fullWidth
                                  inputProps={{
                                    ref: generateAddressLine1Ref(index)
                                  }}
                                />
                                <Input
                                  error={Boolean(
                                    errors?.multipleReceivers?.[index]
                                      ?.receiverDetail?.addressLine2
                                  )}
                                  helperText={
                                    errors?.multipleReceivers?.[index]
                                      ?.receiverDetail?.addressLine2
                                  }
                                  value={
                                    multipleReceivers[index]?.receiverDetail
                                      ?.addressLine2
                                  }
                                  onChange={event => {
                                    const {
                                      target: { name, value }
                                    } = event;
                                    if (value.length < 41) {
                                      handleChange(event);
                                      if (!value) {
                                        refs[
                                          index
                                        ].addressLine1Ref.current?.focus();
                                      }
                                    } else {
                                      handleChange({
                                        target: {
                                          name,
                                          value: value.substring(0, 40)
                                        }
                                      });
                                      setFieldValue(
                                        `multipleReceivers.${index}.receiverDetail.addressLine3`,
                                        value.substring(40, value.length)
                                      );
                                      refs[
                                        index
                                      ].addressLine3Ref.current?.focus();
                                    }
                                  }}
                                  customLabel='Nearest Landmark'
                                  name={`multipleReceivers.${index}.receiverDetail.addressLine2`}
                                  id='addressLine2'
                                  type='text'
                                  containerClassName='new-addresses__modal__item__input'
                                  placeholder='Oando Petrol Station, Iweka Road'
                                  fullWidth
                                  inputProps={{
                                    ref: generateAddressLine2Ref(index)
                                  }}
                                />
                                <Input
                                  error={Boolean(
                                    errors?.multipleReceivers?.[index]
                                      ?.receiverDetail?.addressLine3
                                  )}
                                  helperText={
                                    errors?.multipleReceivers?.[index]
                                      ?.receiverDetail?.addressLine3
                                  }
                                  value={
                                    multipleReceivers[index]?.receiverDetail
                                      ?.addressLine3
                                  }
                                  onChange={event => {
                                    if (event.target.value.length < 41) {
                                      handleChange(event);
                                      if (!event.target.value) {
                                        refs[
                                          index
                                        ].addressLine2Ref.current?.focus();
                                      }
                                    } else {
                                      handleChange({
                                        target: {
                                          name: event.target.name,
                                          value: event.target.value.substring(
                                            0,
                                            40
                                          )
                                        }
                                      });
                                    }
                                  }}
                                  customLabel='Address Line 3'
                                  name={`multipleReceivers.${index}.receiverDetail.addressLine3`}
                                  id='addressLine3'
                                  type='text'
                                  containerClassName='new-addresses__modal__item__input'
                                  placeholder='Iweka Road, Agege, Lagos.'
                                  fullWidth
                                  inputProps={{
                                    ref: generateAddressLine3Ref(index)
                                  }}
                                />
                                <CustomAutoComplete
                                  options={theCountries}
                                  getOptionLabel={option => option?.name}
                                  isOptionEqualToValue={(option, value) =>
                                    option?.name === value?.name
                                  }
                                  renderOption={(props, option, country) => (
                                    <Box
                                      component='li'
                                      sx={{
                                        '& > img': { mr: 2, flexShrink: 0 }
                                      }}
                                      {...props}
                                      key={props.id}
                                    >
                                      {country && (
                                        <img
                                          loading='lazy'
                                          width='30'
                                          height='20'
                                          src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
                                          srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
                                          alt=''
                                        />
                                      )}
                                      {option.name}
                                    </Box>
                                  )}
                                  value={
                                    theCountries.find(
                                      country =>
                                        country?.name?.toLowerCase() ===
                                        multipleReceivers[
                                          index
                                        ].receiverDetail.country?.toLowerCase()
                                    ) || { name: '' }
                                  }
                                  noOptionsText={'Country not found'}
                                  onChange={(event, value, reason) => {
                                    if (reason === 'clear') {
                                      handleLocationSelect({
                                        index,
                                        name: 'country',
                                        value: '',
                                        countryCode: ''
                                      });
                                    } else {
                                      setLocationIndex(index);
                                      handleLocationSelect({
                                        index,
                                        name: 'country',
                                        value: value?.name,
                                        countryCode: value?.code
                                      });
                                      getStates(value?.code);
                                      getCities({
                                        countryCode: value?.code,
                                        cityName:
                                          multipleReceivers[index]
                                            ?.receiverDetail.city || 'a'
                                      });
                                    }
                                  }}
                                  textfieldProps={{
                                    lg: 12,
                                    md: 12,
                                    name: 'country',
                                    customLabel: 'Country',
                                    containerClassName:
                                      'new-addresses__modal__item__input',
                                    error: Boolean(
                                      errors?.multipleReceivers?.[index]
                                        ?.receiverDetail?.country
                                    ),
                                    helperText:
                                      errors?.multipleReceivers?.[index]
                                        ?.receiverDetail?.country,
                                    value:
                                      multipleReceivers[index]?.receiverDetail
                                        .country,
                                    InputProps: {
                                      startAdornment: (
                                        <InputAdornment position='start'>
                                          <img
                                            loading='lazy'
                                            width='20'
                                            height='14'
                                            src={`https://flagcdn.com/w20/${
                                              multipleReceivers[
                                                index
                                              ].receiverDetail.countryCode?.toLowerCase() ||
                                              defaultDetail.countryCode
                                            }.png`}
                                            srcSet={`https://flagcdn.com/w40/${
                                              multipleReceivers[
                                                index
                                              ].receiverDetail.countryCode?.toLowerCase() ||
                                              defaultDetail.countryCode
                                            }.png 2x`}
                                            alt=''
                                          />
                                        </InputAdornment>
                                      )
                                    }
                                  }}
                                />
                                <div
                                  className=''
                                  style={{
                                    display: 'flex',
                                    width: '100%'
                                  }}
                                >
                                  <div className='pl-3 mb-1'>
                                    {segregatedCountries.includes(
                                      multipleReceivers[index]?.receiverDetail
                                        .country
                                    ) ? (
                                      <Alert
                                        severity={'info'}
                                        sx={{
                                          fontSize: '1.5rem',
                                          fontFamily: 'General Sans',
                                          marginTop: '10px',
                                          backgroundColor: '#e6f2fe',
                                          color: '#000000',
                                          border: 'none',
                                          '& .MuiAlert-icon': {
                                            color: '#22428F'
                                          }
                                        }}
                                      >
                                        To ship to this country, you may be
                                        required to engage a local customs
                                        clearance broker. Here’s a list of
                                        companies you can contact in advance.
                                        <a
                                          href={`https://topship.africa/broker-directory?country=${multipleReceivers[index]?.receiverDetail.country}`}
                                          target='_blank'
                                          rel='noreferrer'
                                          style={{
                                            color: '#22428F',
                                            cursor: 'pointer',
                                            fontWeight: '500'
                                          }}
                                        >
                                          See Broker Directory
                                        </a>
                                      </Alert>
                                    ) : (
                                      <Alert
                                        severity={'info'}
                                        sx={{
                                          fontSize: '1.5rem',
                                          fontFamily: 'General Sans',
                                          marginTop: '10px',
                                          backgroundColor: '#e6f2fe',
                                          color: '#000000',
                                          border: 'none',
                                          '& .MuiAlert-icon': {
                                            color: '#22428F'
                                          }
                                        }}
                                      >
                                        List of prohibited items that cannot be
                                        shipped to any country.{' '}
                                        <a
                                          href={`https://topship.africa/prohibited-items`}
                                          target='_blank'
                                          rel='noreferrer'
                                          style={{
                                            color: '#22428F',
                                            cursor: 'pointer',
                                            fontWeight: '500'
                                          }}
                                        >
                                          View Prohibited Items
                                        </a>
                                      </Alert>
                                    )}
                                  </div>
                                </div>
                                <CustomAutoComplete
                                  options={theStates}
                                  getOptionLabel={option => option?.name}
                                  isOptionEqualToValue={(option, value) =>
                                    option?.name === value?.name
                                  }
                                  renderOption={(props, option) => (
                                    <Box
                                      component='li'
                                      sx={{
                                        '& > img': { mr: 2, flexShrink: 0 }
                                      }}
                                      {...props}
                                      key={props.id}
                                    >
                                      {option.name}
                                    </Box>
                                  )}
                                  value={
                                    theStates.length
                                      ? theStates?.find(
                                          state =>
                                            state.name?.toLowerCase() ===
                                            multipleReceivers[
                                              index
                                            ].receiverDetail.state?.toLowerCase()
                                        )
                                      : {
                                          name:
                                            multipleReceivers[index]
                                              .receiverDetail.state || ''
                                        }
                                  }
                                  noOptionsText={
                                    !multipleReceivers[index]?.receiverDetail
                                      .country
                                      ? 'Please Select a country'
                                      : 'State not found'
                                  }
                                  onChange={(event, value) =>
                                    handleLocationSelect({
                                      index,
                                      name: 'state',
                                      value: value?.name
                                    })
                                  }
                                  textfieldProps={{
                                    lg: 6,
                                    md: 12,
                                    name: 'state',
                                    customLabel: 'State',
                                    containerClassName:
                                      'new-addresses__modal__item__input',
                                    error: Boolean(
                                      errors?.multipleReceivers?.[index]
                                        ?.receiverDetail?.state
                                    ),
                                    helperText:
                                      errors?.multipleReceivers?.[index]
                                        ?.receiverDetail?.state,
                                    value:
                                      multipleReceivers[index]?.receiverDetail
                                        .state
                                  }}
                                />
                                <CustomAutoComplete
                                  options={theCities}
                                  getOptionLabel={option =>
                                    option?.city
                                      ?.split(' ')
                                      ?.map(
                                        word =>
                                          `${word
                                            .charAt()
                                            .toUpperCase()}${word
                                            .substring(1, word.length)
                                            ?.toLowerCase()}`
                                      )
                                      ?.join(' ')
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option?.postalCode
                                      ? option?.postalCode ===
                                          multipleReceivers[index]
                                            ?.receiverDetail.postalCode &&
                                        option?.city === value?.city
                                      : option?.city === value?.city
                                  }
                                  renderOption={(props, option) => (
                                    <Box
                                      key={`${props.id}${
                                        option?.postalCode
                                          ? `--${option?.postalCode}`
                                          : ''
                                      }`}
                                      component='li'
                                      sx={{
                                        textTransform: 'capitalize'
                                      }}
                                      {...props}
                                    >
                                      {`${option?.city?.toLowerCase()} ${
                                        option?.postalCode
                                          ? `(${option?.postalCode})`
                                          : ''
                                      }`}
                                    </Box>
                                  )}
                                  value={theCities?.find(
                                    city =>
                                      city.city?.toLowerCase() ===
                                      multipleReceivers[
                                        index
                                      ].receiverDetail.city?.toLowerCase()
                                  )}
                                  noOptionsText={
                                    !multipleReceivers[index]?.receiverDetail
                                      .country
                                      ? 'Please select a country'
                                      : citiesResult.loading
                                      ? 'Cities loading...'
                                      : 'City not found'
                                  }
                                  onChange={(event, value) =>
                                    handleLocationSelect({
                                      index,
                                      name: 'city',
                                      value: value?.city
                                    })
                                  }
                                  textfieldProps={{
                                    lg: 6,
                                    md: 12,
                                    name: 'city',
                                    customLabel: 'City',
                                    containerClassName:
                                      'new-addresses__modal__item__input',
                                    error: Boolean(
                                      errors?.multipleReceivers?.[index]
                                        ?.receiverDetail?.city
                                    ),
                                    helperText:
                                      errors?.multipleReceivers?.[index]
                                        ?.receiverDetail?.city,
                                    value:
                                      multipleReceivers[index]?.receiverDetail
                                        .city,
                                    onChange: debounce(event => {
                                      setLocationIndex(index);
                                      getCities({
                                        countryCode:
                                          multipleReceivers[index]
                                            ?.receiverDetail.countryCode,
                                        cityName: event?.target?.value
                                      });
                                    }, 500)
                                  }}
                                />
                              </>
                            )}
                            <Input
                              error={Boolean(
                                errors?.multipleReceivers?.[index]
                                  ?.receiverDetail?.postalCode
                              )}
                              helperText={
                                errors?.multipleReceivers?.[index]
                                  ?.receiverDetail?.postalCode
                              }
                              value={
                                multipleReceivers[index]?.receiverDetail
                                  .postalCode
                              }
                              onChange={handleChange}
                              customLabel='Postal Code'
                              name={`multipleReceivers.${index}.receiverDetail.postalCode`}
                              id='postalCode'
                              type='text'
                              containerClassName='new-addresses__modal__item__input'
                              placeholder='100001'
                              fullWidth
                              lg={12}
                              md={12}
                            />
                          </Grid>
                          {isLoggedIn && (
                            <FormControlLabel
                              className='sender-info__checkbox'
                              control={
                                <Checkbox
                                  checked={receiver.receiverDetail?.shouldSave}
                                  onChange={() => handleSaveAddress(index)}
                                />
                              }
                              label='Save this address'
                            />
                          )}
                          <Button
                            onClick={() => handleAddItems(index)}
                            className='multiple-receiver-info__item__action'
                          >
                            Add Items
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <Button
              disabled={multipleReceivers.length === 4}
              onClick={addNewReceiver}
              className='multiple-receiver-info__action'
            >
              <Add />
              Add new receiver
            </Button>
            <div className='mb-2'>
              <Alert
                severity={'info'}
                sx={{
                  marginTop: '10px',
                  fontSize: '1.5rem',
                  fontWeight: 'normal',
                  fontFamily: 'General Sans',
                  backgroundColor: 'rgba(239, 165, 22, 0.1)',
                  alignItems: 'flex-start',
                  color: '#0F1414',
                  border: 'none',
                  '& .MuiAlert-icon': {
                    fontSize: '2rem',
                    marginRight: '5px'
                  }
                }}
                icon={<img src={InfoYellowIcon} alt='info' />}
              >
                <b>Please be transparent.</b> Declaring a lower value than your
                item is worth could attract an additional fine from customs
                officials.
              </Alert>
            </div>
            <div className='multiple-receiver-info__footer'>
              <span
                onClick={() =>
                  history.push(multipleShipmentLinks[currentIndex]?.initialLink)
                }
                className='multiple-receiver-info__footer__action'
              >
                Go Back
              </span>
              <SubmitButton text='Continue' />
            </div>
          </div>
        </form>
      </NewShippingStepsLayout>
      <AddressGuidePrompt
        show={showAddressGuide}
        setShow={setShowAddressGuide}
      />
      <ProhibitedListPrompt
        openPrompt={prohibitedListPrompt}
        closePrompt={() => setProhibitedListPrompt(false)}
        countryName={prohibitedCountry.name}
        countryCode={prohibitedCountry.countryCode}
      />
    </>
  );
};

export default MultipleReceiverInfo;

import { Skeleton } from '@mui/material';
import React from 'react';

const PastShipmentLoader = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className='recent-shipment-item'>
          <div className='show-on-tablet-and-above'>
            <div className='recent-shipment-item-data recent-shipment-bottom-border recent-shipment-tracking-id column-above-mobile'>
              <span
                id='show-on-tablet-and-above'
                className='shipment-item-label'
              >
                <Skeleton className='w-120' />
              </span>
              <span className='recent-shipment-tracking-id'>
                <Skeleton className='w-120' />
              </span>
              <span
                id='show-only-on-mobile'
                className={`recent-shipment-item-status`}
              >
                <Skeleton className='w-120' />
              </span>
            </div>
            <div className='recent-shipment-item-data amount column-above-mobile'>
              <span className='shipment-item-label'>
                <Skeleton className='w-120' />
              </span>
              <span>
                <Skeleton className='w-120' />
              </span>
            </div>
            <div className='recent-shipment-item-data pickup column-above-mobile'>
              <span className='shipment-item-label'>
                <Skeleton className='w-120' />
              </span>
              <span className='location'>
                <Skeleton className='w-120' />
              </span>
            </div>
            <div className='recent-shipment-item-data delivery recent-shipment-bottom-border column-above-mobile'>
              <span className='shipment-item-label'>
                <Skeleton className='w-120' />
              </span>
              <span className='location'>
                <Skeleton className='w-120' />
              </span>
            </div>
            <div
              id='show-on-tablet-and-above'
              className='recent-shipment-item-data recent-shipment-bottom-border recent-shipment-tracking-id column-above-mobile'
            >
              <span className={`recent-shipment-item-status`}>
                <Skeleton className='w-120' />
              </span>
            </div>
          </div>
          <div className='recent-shipment-item-data time'>
            <span>
              <Skeleton className='w-120' />
            </span>
            <span>
              <Skeleton className='w-120' />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default PastShipmentLoader;

import { NavLink, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import ReactGA from 'react-ga';
import loginIcon from '../assets/image/menu/log-in.png';
import { useAuthToken } from '../token';
import {
  loginState,
  quoteRequestState,
  quotesAddressesState
} from '../recoil/atoms';
import Client from '../services/client';
import { useResetShipmentData } from '../utilities/reset-shipment-data';

const useSlideMenu = () => {
  const [, setQuotesAddresses] = useRecoilState(quotesAddressesState);
  const [, setRequestQuotesPage] = useRecoilState(quoteRequestState);
  const resetShipmentData = useResetShipmentData();
  const location = useLocation();
  const client = Client();
  const [, , eraseToken] = useAuthToken();
  const [, setLoggedIn] = useRecoilState(loginState);

  const logOut = () => {
    setLoggedIn(false);
    eraseToken();
    client.resetStore();
    localStorage.removeItem('lspId');
  };

  const menu = [
    <NavLink
      to='/'
      onClick={() =>
        ReactGA.event({
          category: 'Hamburger Navigation Menu',
          action: 'User pressed the "Dashboard" button'
        })
      }
    >
      <li className={location.pathname === '/' && 'active-nav'}>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g id='24 / grid / grid'>
            <path
              id='icon'
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M20 2H4C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2ZM4 20V13H11V20H4ZM13 20H20V13H13V20ZM13 11H20V4H13V11ZM11 4H4V11H11V4Z'
            />
          </g>
        </svg>
        <span>Dashboard</span>
      </li>
    </NavLink>,
    <NavLink
      to={{
        pathname: '/shipment-request',
        state: {
          requestShipment: true
        }
      }}
      onClick={() => {
        ReactGA.event({
          category: 'Hamburger Navigation Menu',
          action: 'User pressed the "Ship Now" button'
        });
        resetShipmentData();
      }}
    >
      <li className={location.pathname === '/shipment-request' && 'active-nav'}>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g id='24 / shopping / delivery'>
            <path
              id='icon'
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M16.382 7L18.2243 10.6847L21 12.5352V16H19.8293C19.4175 14.8348 18.3062 14 17 14C15.6938 14 14.5825 14.8348 14.1707 16H9.82929C9.41746 14.8348 8.30622 14 7 14C5.69378 14 4.58254 14.8348 4.17071 16H3V7H16.382ZM21 18H19.8293C19.4175 19.1652 18.3062 20 17 20C15.6938 20 14.5825 19.1652 14.1707 18H9.82929C9.41746 19.1652 8.30622 20 7 20C5.69378 20 4.58254 19.1652 4.17071 18H3C1.89543 18 1 17.1046 1 16V7C1 5.89543 1.89543 5 3 5H16.382C17.1395 5 17.832 5.428 18.1708 6.10557L19.7757 9.31526L23 11.4648V16C23 17.1046 22.1046 18 21 18ZM8 17C8 17.5523 7.55228 18 7 18C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16C7.55228 16 8 16.4477 8 17ZM18 17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17C16 16.4477 16.4477 16 17 16C17.5523 16 18 16.4477 18 17Z'
            />
          </g>
        </svg>

        <span>Ship Now</span>
      </li>
    </NavLink>,
    <NavLink
      to='/quotes'
      onClick={() => {
        ReactGA.event({
          category: 'Hamburger Navigation Menu',
          action: 'User pressed the "Request Quote" button'
        });

        setRequestQuotesPage(0);
        setQuotesAddresses({
          receiver: {},
          sender: {}
        });
      }}
    >
      <li className={location.pathname === '/quotes' && 'active-nav'}>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g id='24 / basic / list'>
            <path
              id='icon'
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M2 6C2 7.10457 2.89543 8 4 8C5.10457 8 6 7.10457 6 6C6 4.89543 5.10457 4 4 4C2.89543 4 2 4.89543 2 6ZM8 5H22V7H8V5ZM22 11H8V13H22V11ZM8 19H22V17H8V19ZM2 18C2 19.1046 2.89543 20 4 20C5.10457 20 6 19.1046 6 18C6 16.8954 5.10457 16 4 16C2.89543 16 2 16.8954 2 18ZM4 14C2.89543 14 2 13.1046 2 12C2 10.8954 2.89543 10 4 10C5.10457 10 6 10.8954 6 12C6 13.1046 5.10457 14 4 14Z'
            />
          </g>
        </svg>

        <span>Request Quote</span>
      </li>
    </NavLink>,
    <NavLink
      to='/wallet'
      onClick={() =>
        ReactGA.event({
          category: 'Hamburger Navigation Menu',
          action: 'User pressed the "Fund Wallet" button'
        })
      }
    >
      <li className={location.pathname === '/wallet' && 'active-nav'}>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M15.4506 1.40266C16.5126 1.09921 17.6196 1.7142 17.923 2.77627C17.9741 2.95495 18 3.13988 18 3.32571V4.99998H20C21.1046 4.99998 22 5.89541 22 6.99998V19C22 20.1045 21.1046 21 20 21H4C2.89543 21 2 20.1045 2 19H2.0267C2.00895 18.8924 2 18.7835 2 18.6742V6.75428C2 5.86132 2.59195 5.07655 3.45056 4.83123L15.4506 1.40266ZM10.1401 19H20V11H18V15.2457C18 16.1386 17.408 16.9234 16.5494 17.1687L10.1401 19ZM20 6.99998V8.99998H18V6.99998H20ZM4 6.75426V18.6742L16 15.2457V3.32568L4 6.75426ZM14 8.99998C14 9.55227 13.5523 9.99998 13 9.99998C12.4477 9.99998 12 9.55227 12 8.99998C12 8.4477 12.4477 7.99998 13 7.99998C13.5523 7.99998 14 8.4477 14 8.99998Z'
          />
        </svg>

        <span>Fund Wallet</span>
      </li>
    </NavLink>,
    <NavLink
      to='/transactions'
      onClick={() =>
        ReactGA.event({
          category: 'Hamburger Navigation Menu',
          action: 'User pressed the "Transactions" button'
        })
      }
    >
      <li className={location.pathname === '/transactions' && 'active-nav'}>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M2.81818 3H21.1818C22.186 3 23 3.89543 23 5V17C23 18.1046 22.186 19 21.1818 19H11V17H21V10H3V12H1V5C1 3.89543 1.81403 3 2.81818 3ZM21 5V8H3V5H21ZM1 17H5.58579L4.29289 18.2929L5.70711 19.7071L9.41421 16L5.70711 12.2929L4.29289 13.7071L5.58579 15H1V17Z'
          />
        </svg>

        <span>Transactions</span>
      </li>
    </NavLink>,
    <NavLink
      to='/addresses'
      onClick={() =>
        ReactGA.event({
          category: 'Hamburger Navigation Menu',
          action: 'User pressed the "Saved Addresses" button'
        })
      }
    >
      <li className={location.pathname === '/addresses' && 'active-nav'}>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M12 23.3276L12.6577 22.7533C18.1887 17.9237 21 13.7068 21 10C21 4.75066 16.9029 1 12 1C7.09705 1 3 4.75066 3 10C3 13.7068 5.81131 17.9237 11.3423 22.7533L12 23.3276ZM12 20.6634C7.30661 16.4335 5 12.8492 5 10C5 5.8966 8.16411 3 12 3C15.8359 3 19 5.8966 19 10C19 12.8492 16.6934 16.4335 12 20.6634ZM12 5C14.7614 5 17 7.23858 17 10C17 12.7614 14.7614 15 12 15C9.23858 15 7 12.7614 7 10C7 7.23858 9.23858 5 12 5ZM9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10Z'
          />
        </svg>

        <span>Saved Addresses</span>
      </li>
    </NavLink>,
    <NavLink
      to='/past-shipments'
      onClick={() =>
        ReactGA.event({
          category: 'Hamburger Navigation Menu',
          action: 'User pressed the "Past Shipment" button'
        })
      }
    >
      <li
        className={location.pathname.includes('past-shipments') && 'active-nav'}
      >
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M6 22.0001C7.30622 22.0001 8.41746 21.1653 8.82929 20.0001H17.5C19.9853 20.0001 22 17.9854 22 15.5001C22 13.0148 19.9853 11.0001 17.5 11.0001H6.5C5.11929 11.0001 4 9.88079 4 8.50008C4 7.11936 5.11929 6.00008 6.5 6.00008H9.58579L8.29289 7.29297L9.70711 8.70718L13.4142 5.00008L9.70711 1.29297L8.29289 2.70718L9.58579 4.00008H6.5C4.01472 4.00008 2 6.01479 2 8.50008C2 10.9854 4.01472 13.0001 6.5 13.0001H17.5C18.8807 13.0001 20 14.1194 20 15.5001C20 16.8808 18.8807 18.0001 17.5 18.0001H8.82929C8.41746 16.8349 7.30622 16.0001 6 16.0001C4.34315 16.0001 3 17.3432 3 19.0001C3 20.6569 4.34315 22.0001 6 22.0001ZM5 19.0001C5 18.4478 5.44772 18.0001 6 18.0001C6.55228 18.0001 7 18.4478 7 19.0001C7 19.5524 6.55228 20.0001 6 20.0001C5.44772 20.0001 5 19.5524 5 19.0001ZM18 2.00008C19.6569 2.00008 21 3.34322 21 5.00008C21 6.65693 19.6569 8.00008 18 8.00008C16.3431 8.00008 15 6.65693 15 5.00008C15 3.34322 16.3431 2.00008 18 2.00008ZM17 5.00008C17 4.44779 17.4477 4.00008 18 4.00008C18.5523 4.00008 19 4.44779 19 5.00008C19 5.55236 18.5523 6.00008 18 6.00008C17.4477 6.00008 17 5.55236 17 5.00008Z'
          />
        </svg>

        <span>Past Shipments</span>
      </li>
    </NavLink>,
    <NavLink
      to='/referral'
      onClick={() =>
        ReactGA.event({
          category: 'Hamburger Navigation Menu',
          action: 'User pressed the "Invite & Earn" button'
        })
      }
    >
      <li className={location.pathname.includes('referral') && 'active-nav'}>
        <svg
          width='21'
          height='21'
          viewBox='0 0 21 21'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clip-path='url(#clip0)'>
            <path d='M15.3996 8.74951C14.0043 8.7506 12.6601 9.27517 11.6329 10.2195C11.0999 9.75992 10.3432 9.66492 9.71307 9.97851C9.08299 10.2921 8.70251 10.9531 8.74778 11.6554C8.79304 12.3577 9.25521 12.9644 9.92034 13.1945C9.43125 15.5148 10.4566 17.8917 12.4801 19.1281C14.5036 20.3645 17.0864 20.1924 18.928 18.6984C20.7695 17.2045 21.4705 14.7126 20.6778 12.4777C19.8852 10.2428 17.7709 8.74937 15.3996 8.74951ZM9.44959 11.5495C9.44768 11.1485 9.67538 10.7818 10.0356 10.6056C10.3959 10.4295 10.8251 10.475 11.1404 10.7228C10.6889 11.2528 10.3403 11.8625 10.1125 12.5204C9.7128 12.364 9.44974 11.9787 9.44959 11.5495ZM15.3996 19.2495C12.6934 19.2495 10.4996 17.0557 10.4996 14.3495C10.4996 11.6433 12.6934 9.44951 15.3996 9.44951C18.1058 9.44951 20.2996 11.6433 20.2996 14.3495C20.2967 17.0545 18.1046 19.2466 15.3996 19.2495Z' />
            <path d='M18.6217 8.9276C18.7663 9.05567 18.9874 9.04235 19.1156 8.89785C20.639 7.1718 20.696 4.59878 19.2505 2.80698C17.8049 1.01518 15.278 0.526744 13.2688 1.65075C13.1577 1.71069 13.0874 1.82584 13.085 1.95208C13.0826 2.07832 13.1484 2.19609 13.2571 2.26025C13.3659 2.32441 13.5007 2.32502 13.6101 2.26185C15.3216 1.30104 17.4767 1.71578 18.7094 3.2432C19.9421 4.77062 19.8925 6.96469 18.592 8.4348C18.4646 8.57927 18.4779 8.7995 18.6217 8.9276Z' />
            <path d='M16.7988 12.775C16.7988 12.9683 16.9555 13.125 17.1488 13.125C17.3421 13.125 17.4988 12.9683 17.4988 12.775C17.462 11.7983 16.7195 10.9939 15.7488 10.8791V10.85C15.7488 10.6567 15.5921 10.5 15.3988 10.5C15.2055 10.5 15.0488 10.6567 15.0488 10.85V10.8791C14.0782 10.9939 13.3357 11.7983 13.2988 12.775C13.3357 13.7517 14.0782 14.5561 15.0488 14.671V17.1066C14.4659 17.0061 14.0301 16.5157 13.9988 15.925C13.9988 15.7317 13.8421 15.575 13.6488 15.575C13.4555 15.575 13.2988 15.7317 13.2988 15.925C13.3357 16.9017 14.0782 17.7062 15.0488 17.821V17.85C15.0488 18.0433 15.2055 18.2 15.3988 18.2C15.5921 18.2 15.7488 18.0433 15.7488 17.85V17.821C16.7378 17.7425 17.5004 16.9171 17.5004 15.925C17.5004 14.9329 16.7378 14.1075 15.7488 14.0291V11.5934C16.3317 11.6939 16.7676 12.1843 16.7988 12.775ZM13.9988 12.775C14.0301 12.1843 14.4659 11.6939 15.0488 11.5934V13.9566C14.4659 13.8561 14.0301 13.3657 13.9988 12.775ZM16.7988 15.925C16.7676 16.5157 16.3317 17.0061 15.7488 17.1066V14.7434C16.3317 14.8439 16.7676 15.3343 16.7988 15.925Z' />
            <path d='M8.22446 17.8499C8.66744 17.8502 9.10973 17.8151 9.54711 17.7449C9.67294 17.7277 9.77954 17.6436 9.82567 17.5253C9.87179 17.4069 9.8502 17.2729 9.76925 17.1751C9.6883 17.0772 9.56069 17.0309 9.43581 17.054C9.0352 17.118 8.63015 17.15 8.22446 17.1499C5.31696 17.1497 2.66974 15.4745 1.4248 12.847C0.179854 10.2195 0.559969 7.1099 2.40116 4.85966C2.52289 4.71027 2.50094 4.49058 2.35208 4.36821C2.20321 4.24584 1.98343 4.26683 1.86041 4.41516C-0.152712 6.87457 -0.568715 10.2737 0.791894 13.146C2.1525 16.0183 5.0462 17.8497 8.22446 17.8499Z' />
            <path d='M15.5261 7.80095C15.5647 7.9571 15.7047 8.06683 15.8656 8.06695C15.894 8.06686 15.9223 8.06345 15.9499 8.0568C16.1375 8.01029 16.2519 7.82055 16.2054 7.63295C15.2921 3.96941 12.0011 1.39869 8.22541 1.39945C6.44486 1.39387 4.7115 1.97166 3.29041 3.04445C3.13577 3.16043 3.10443 3.37981 3.22041 3.53445C3.33639 3.68909 3.55577 3.72043 3.71041 3.60445C6.16292 1.76608 9.48518 1.59831 12.1104 3.18025C13.8123 4.21136 15.0395 5.87147 15.5261 7.80095Z' />
            <path d='M4.6513 13.1976C4.78798 13.3343 5.00953 13.3343 5.1462 13.1976L12.1462 6.19764C12.2788 6.06031 12.2769 5.84201 12.1419 5.707C12.0069 5.572 11.7886 5.5701 11.6513 5.70274L4.6513 12.7027C4.51467 12.8394 4.51467 13.061 4.6513 13.1976Z' />
            <path d='M6.29883 9.09961C7.26533 9.09961 8.04883 8.31611 8.04883 7.34961C8.04883 6.38311 7.26533 5.59961 6.29883 5.59961C5.33233 5.59961 4.54883 6.38311 4.54883 7.34961C4.54999 8.31563 5.33281 9.09845 6.29883 9.09961ZM6.29883 6.29961C6.87873 6.29961 7.34883 6.76971 7.34883 7.34961C7.34883 7.92951 6.87873 8.39961 6.29883 8.39961C5.71893 8.39961 5.24883 7.92951 5.24883 7.34961C5.24883 6.76971 5.71893 6.29961 6.29883 6.29961Z' />
          </g>
          <defs>
            <clipPath id='clip0'>
              <rect width='21' height='21' fill='white' />
            </clipPath>
          </defs>
        </svg>

        <span>Invite & Earn</span>
      </li>
    </NavLink>,
    <NavLink
      to='/login'
      onClick={() =>
        ReactGA.event({
          category: 'Hamburger Navigation Menu',
          action: 'User pressed the "Log out" button'
        })
      }
    >
      <li className='sign-out' onClick={logOut}>
        <img src={loginIcon} alt='item-icon' />
        <span>Sign Out</span>
      </li>
    </NavLink>
  ];
  return [menu];
};

export { useSlideMenu };

import React, { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import { useRecoilState } from 'recoil';
import { shipmentPaymentState } from '../../../../../recoil/atoms';
import { Link, useLocation } from 'react-router-dom';
import blackFridayIcon from '../../../../../assets/svg/black-friday.svg';
import SubmitButton from '../../../../../components/submit-button';
import ScrollTop from '../../../../../utilities/scroll-top';
import { useUpdateShopnshipMutation } from '../../../../../operations/mutations';
import { formatUUID } from '../../../helper-functions';

const ShopNShipSuccessful = ({
  heading,
  subHeading,
  path,
  buttonText,
  successImage,
  onButtonClick
}) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [, setRequestPage] = useRecoilState(shipmentPaymentState);
  const location = useLocation();

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  const isSeaImport = location.pathname.includes(
    'sea-freight-quote-successful'
  );

  const [updateShopnship, updateShopnshipResult] = useUpdateShopnshipMutation(
    data => {
      if (data) {
        setIsCompleted(true);
      }
    }
  );

  const shopnshipID = useMemo(() => {
    const id = formatUUID(query.get('shopnshipID'));

    setIsCompleted(!id);

    return id;
  }, [query]);

  useEffect(() => {
    ReactGA.event({
      category: 'Shop & Ship',
      action: 'User has submitted item(s) for Shop n Ship'
    });
  }, []);

  useEffect(() => {
    if (shopnshipID && !isCompleted) {
      updateShopnship({
        id: shopnshipID,
        update: { status: 'PaymentPending' }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopnshipID]);

  return (
    <>
      <ScrollTop />
      <div
        className={`
      ${isSeaImport ? 'sea-payment-successful' : 'payment-successful'}
      `}
      >
        <img src={successImage || blackFridayIcon} alt='success' />

        <h1>
          {isSeaImport ? 'Booking Received' : heading || 'Order Received'}
        </h1>

        {isSeaImport ? (
          <p className='m1'>
            A price quote will be sent to your email within 24hours or sooner.{' '}
            <br /> Need a quicker response? Email{' '}
            <a
              style={{
                color: '#22428f',
                fontWeight: 600
              }}
              href='mailto:hello@topship.africa'
              target='_blank'
              rel='noreferrer'
            >
              hello@topship.africa
            </a>
          </p>
        ) : (
          <p className='mt-1'>
            {subHeading || 'A Topship agent will contact you shortly.'}
          </p>
        )}

        <div
          className='mt-6 mb-3'
          style={{
            margin: '0 auto'
          }}
        >
          <Link to={path || '/'}>
            <SubmitButton
              text={buttonText || 'Go Home'}
              className='mt-3'
              disabled={!isCompleted}
              loading={updateShopnshipResult.loading}
              onClick={() => {
                setRequestPage(0);
                onButtonClick && onButtonClick();
              }}
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default ShopNShipSuccessful;

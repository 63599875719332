export const toFixed = input => {
  let value = Number(input);

  if (!isNaN(value)) {
    return Number(value.toFixed(2));
  }

  return value;
};

export const toKoboFixed = input => {
  return Math.round(toFixed(input) * 100);
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import NewShippingStepsLayout from '../../../../../components/Layouts/new-shipping-steps-layout';
import BusyOverlay from '../../../../../components/busy-overlay';
import BookingSuccessfulPrompt from '../../../../../components/modals/booking-successful-prompt';
import { useRecoilState } from 'recoil';
import {
  multipleShipmentResultsState,
  saveShipmentDetailsState
} from '../../../../../recoil/atoms';
import { shipmentHubs } from '../../../../../constants/book-shipment-contants';
import { Button, Skeleton } from '@mui/material';
import {
  AccountBalanceWallet,
  CreditCard,
  CurrencyExchange,
  Store
} from '@mui/icons-material';
import SubmitButton from '../../../../../components/submit-button';
import { multipleShipmentLinks } from '../../../../../constants/shipment-links';
import { useHistory, Redirect } from 'react-router-dom';
import Client from '../../../../../services/client';
import {
  useGenerateTransactionReferenceQuery,
  useGetVirtualBankAccount,
  useGetWalletBalanceQuery
} from '../../../../../operations/queries';
import { useSendAlert } from '../../../../../utilities/send-alert';
import { useGetUserPayload } from '../../../../../utilities/get-payload';
import {
  usePayFromWalletMutation,
  useUpdateShipmentMutation
} from '../../../../../operations/mutations';
import { transactionTypesEnum } from '../../../../../utilities/enums/transaction-types.enum';
import { usePaystackPayment } from 'react-paystack';
import { toCurrency } from '../../../../../utilities/to-currency';
import mainPaymentMethods from '../../../../../constants/payment-method';
import NewFundWalletModal from '../../../../../components/modals/new-fund-wallet-modal';
import { useLocation } from 'react-router-dom';
import { currencies } from '../../../../../constants/currencies';
import SelectCurrencyPrompt from '../../../../../components/modals/select-currency-prompt';
import {
  DeliveryAddressComponent,
  PickupAddressComponent
} from '../../../../../components/Commons/summary-component';
// import { redirectUrlFunc } from '../../../helper-functions';
// import { productTypeEnum } from '../../../../../utilities/enums/product-type-enum';

const paymentMethods = [
  ...mainPaymentMethods,
  {
    name: 'Pay With Foreign Currency',
    cost: 'Pay with card via Stripe',
    value: 'ForeignExchange'
  }
];

const topshipHub = {
  name: 'Topship Africa',
  email: 'hello@topship.africa',
  phoneNumber: '09080777728',
  ...shipmentHubs[1].value
};

const PaymentModal = ({
  show,
  warningMessage,
  handleCancel,
  handleDelete,
  deleteText
}) => {
  return (
    <div className={`delete-overlay ${show && 'delete-overlay--show'}`}>
      <div
        className={`delete-overlay__content ${
          show && 'delete-overlay__content--show'
        }`}
      >
        <figure>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z' />
          </svg>
        </figure>
        <h2>Are you sure?</h2>
        <p style={{ fontWeight: 'bolder' }}>
          {warningMessage || 'You will not be able to recover this data'}
        </p>

        <div className='delete-overlay__buttons'>
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleDelete}>{deleteText || 'Delete'}</button>
        </div>
      </div>
    </div>
  );
};

const MultipleShipmentPayment = () => {
  const history = useHistory();
  const client = Client();
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const onCompleted = data =>
    setWalletBalance(data?.getWalletBalance?.totalBalance);
  const { loading: walletBalanceLoading, refetch } = useGetWalletBalanceQuery(
    onCompleted
  );
  const [openCurrencyPrompt, setOpenCurrencyPrompt] = useState(false);

  const [
    getVirtualBankAccount,
    getVirtualBankAccountResult
  ] = useGetVirtualBankAccount(data => {
    if (data?.generateVirtualBankAccount?.checkoutUrl) {
      window.location.replace(data?.generateVirtualBankAccount?.checkoutUrl);
    }
  });

  const location = useLocation();

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const sendAlert = useSendAlert();
  const userPayload = useGetUserPayload();
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  // const [openBankTransferModal, setOpenBankTransferModal] = useState(false);
  const [paymentID, setPaymentID] = useState('');
  const [successID, setSuccessID] = useState('');
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [expandData] = useState(true);
  const [shipmentDetail] = useRecoilState(saveShipmentDetailsState);

  const [multipleShipmentResults, setMultipleShipmentResults] = useRecoilState(
    multipleShipmentResultsState
  );

  const shipmentData = useMemo(() => {
    if (!multipleShipmentResults) {
      return null;
    }

    const result = multipleShipmentResults?.find(result =>
      result?.data?.some(resultData => resultData?.id === paymentID)
    );

    if (result?.data?.length > 1) {
      return result?.data?.find(resultData => resultData?.id === paymentID);
    }

    return result?.data[0];
  }, [multipleShipmentResults, paymentID]);

  const { multipleReceivers } = shipmentDetail;
  const [openFundWalletModal, setOpenFundWalletModal] = useState(false);
  const [
    openBookingSuccessfulPrompt,
    setOpenBookingSuccessfulPrompt
  ] = useState(false);
  const [
    generateReference,
    { loading: generateReferenceLoading, data }
  ] = useGenerateTransactionReferenceQuery();

  const [
    payFromWalletById,
    {
      loading: walletPaymentLoading,
      data: walletPaymentData,
      error: walletPaymentError
    }
  ] = usePayFromWalletMutation();

  const handlePaymentMethodSelection = (type, newPaymentID) => {
    if (!paymentID) {
      setPaymentMethod(type);
      setPaymentID(newPaymentID);

      if (type.value === paymentMethods[1].value) {
        generateReference(newPaymentID, transactionTypesEnum.cardPayment);
      }

      // if (type.value === paymentMethods[3].value) {
      //   setOpenBankTransferModal(true);
      // } else {
      setOpenPaymentModal(true);
      // }
    }
  };

  const getMethodIcon = useCallback((index = 0) => {
    switch (index) {
      // case 0: {
      //   return <AccountBalanceWallet />;
      // }

      case 1: {
        return <CreditCard />;
      }

      case 2: {
        return <Store />;
      }

      case 3: {
        return <CurrencyExchange />;
      }

      // default: {
      //   return <AccountBalance />;
      // }
      default: {
        return <AccountBalanceWallet />;
      }
    }
  }, []);

  const onSuccess = reference => {
    const shipmentCharge = shipmentData?.totalCharge;

    sendAlert('Payment successful!');

    client.resetStore();

    if (
      shipmentCharge &&
      walletBalance > 0 &&
      walletBalance - shipmentCharge > 0 &&
      paymentMethod?.value === paymentMethods[0].value
    ) {
      setWalletBalance(walletBalance - shipmentCharge);
    }

    // if (paymentMethod?.value === paymentMethods[1].value) {
    //   updateShipment(paymentID, { shipmentStatus: 'PaymentPending' });
    // }

    setMultipleShipmentResults(
      multipleShipmentResults?.map(result => {
        const dataIndex = result?.data?.findIndex(
          data => data?.id === paymentID
        );

        if (dataIndex > -1) {
          // if (
          //   paymentMethod?.value === paymentMethods[2].value ||
          //   shouldUpdateCardShipment
          // ) {
          //   data[0] = {
          //     ...data[0],
          //     isPaymentPending: true,
          //     isBankTransfer: paymentMethod?.value === paymentMethods[2].value
          //   };
          // }

          return {
            ...result,
            data: result?.data?.map((data, index) =>
              index === dataIndex ? { ...data, isPaid: true } : data
            )
          };
        }

        return result;
      })
    );

    setOpenPaymentModal(false);

    setTimeout(() => {
      setPaymentID('');
      setPaymentMethod(null);
      setLoading(false);
    }, 2000);
  };

  const finishShipment = () => {
    setOpenBookingSuccessfulPrompt(true);
    // if (
    //   multipleShipmentResults?.some(
    //     result => result?.data?.[0]?.isPaymentPending
    //   )
    // ) {
    //   window.location.href = 'mailto:tech@topship.africa';
    // }
  };

  const onClose = () => {
    setPaymentID('');
    setOpenPaymentModal(false);
    setPaymentMethod(null);
    setLoading(false);
  };

  const amount = useMemo(() => {
    if (shipmentData?.totalCharge) {
      return shipmentData?.totalCharge / 100;
    }

    return 0;
  }, [shipmentData]);

  const config = useMemo(
    () => ({
      reference: data && data.generateTransactionReference.id,
      email: userPayload.username,
      amount: amount * 100,
      publicKey: process.env.REACT_APP_PAYSTACK_PUB_KEY
    }),
    [amount, data, userPayload]
  );

  const initializePayment = usePaystackPayment(config);

  // const [updateShipment, updateShipmentResult] = useUpdateShipmentMutation();

  const handlePayment = () => {
    // if (paymentMethod.value === paymentMethods[4].value) {
    if (paymentMethod?.value === paymentMethods[3]?.value) {
      setOpenPaymentModal(false);

      setOpenCurrencyPrompt(true);
    }
    // else if (paymentMethod.value === paymentMethods[3].value) {
    //   updateShipment(paymentID, { shipmentStatus: 'PaymentPending' });
    // }
    else if (paymentMethod.value === paymentMethods[2]?.value) {
      getVirtualBankAccount({
        id: paymentID,
        redirectUrl: `${window.location.origin}/multiple-shipment-request-payment?successID=${paymentID}`
      });
    } else if (paymentMethod.value === paymentMethods[1]?.value) {
      setLoading(true);
      initializePayment(onSuccess, onClose);
    } else {
      payFromWalletById(paymentID);
    }
  };

  const disableButton = useMemo(() => {
    if (multipleShipmentResults) {
      return multipleShipmentResults?.some(
        result => result?.data?.some(resultData => !resultData?.isPaid)
        //  && !result?.data[0]?.isPaymentPending
      );
    }

    return true;
  }, [multipleShipmentResults]);

  // const didBankTransfer = useMemo(() => {
  //   if (multipleShipmentResults) {
  //     return multipleShipmentResults?.some(
  //       result => result?.data?.[0]?.isBankTransfer
  //     );
  //   }

  //   return false;
  // }, [multipleShipmentResults]);

  const removeSuccessID = () => {
    let url = new URL(window.location.href);

    url.searchParams.delete('successID');

    query.delete('successID');

    url.searchParams.delete('paymentReference');

    query.delete('paymentReference');

    window.history.replaceState(null, '', url.href.replace('?', ''));
  };

  const pageLoading =
    walletPaymentLoading ||
    generateReferenceLoading ||
    loading ||
    // updateShipmentResult.loading ||
    getVirtualBankAccountResult.loading;

  useEffect(() => {
    if (walletPaymentData && !walletPaymentError) {
      onSuccess('');
    }
    // eslint-disable-next-line
  }, [walletPaymentData]);

  useEffect(() => {
    const querySuccessID = query.get('successID');

    if (query && querySuccessID) {
      const result = multipleShipmentResults?.find(result =>
        result.data?.some(data => data?.id === querySuccessID)
      );

      let resultData = result?.data?.[0];

      if (result?.data?.length > 1) {
        resultData = result?.data?.find(data => data?.id === querySuccessID);
      }

      if (!resultData?.isPaid) {
        setSuccessID(querySuccessID);
      } else {
        removeSuccessID();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, location.search]);

  useEffect(() => {
    if (paymentMethod?.value === paymentMethods[1].value) {
      generateReference(paymentID, transactionTypesEnum.cardPayment);
    }
    // eslint-disable-next-line
  }, [paymentMethod]);

  useEffect(() => {
    refetch()?.then(res => {
      setWalletBalance(res.data?.getWalletBalance?.totalBalance);
    });
  }, [openFundWalletModal, refetch]);

  useEffect(() => {
    if (successID) {
      // updateShipment(successID, { shipmentStatus: 'PaymentPending' });

      setMultipleShipmentResults(
        multipleShipmentResults?.map(result => {
          const resultDataIndex = result?.data?.findIndex(
            data => data?.id === successID
          );

          if (resultDataIndex > -1) {
            result.data[resultDataIndex].isPaid = true;
          }

          return result;
        })
      );

      setSuccessID('');

      removeSuccessID();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successID]);

  const initialReceiverSteps = Array.from(
    { length: multipleReceivers.length },
    (_, index) => ({
      index,
      isActive: index === 0
    })
  );

  const [receiverSteps, setReceiverSteps] = useState(initialReceiverSteps);

  const currentReceiver = useMemo(() => {
    return receiverSteps.find(step => step.isActive);
  }, [receiverSteps]);

  const activeReceiverIndex = useMemo(() => {
    return receiverSteps.findIndex(step => step === currentReceiver);
  }, [currentReceiver, receiverSteps]);

  const activeReceiver = multipleReceivers[activeReceiverIndex];

  const isPaid = multipleShipmentResults[activeReceiverIndex]?.data[0]?.isPaid;

  const isPaymentPending =
    multipleShipmentResults[activeReceiverIndex]?.data[0]?.isPaymentPending;

  const budgetOutsideLagosShipment = useMemo(() => {
    if (multipleShipmentResults?.[activeReceiverIndex]?.data?.length > 1) {
      return {
        receiverDetail: topshipHub
      };
    }

    return null;
  }, [activeReceiverIndex, multipleShipmentResults]);

  return !multipleShipmentResults?.length ? (
    <Redirect to={multipleShipmentLinks[3].link} />
  ) : (
    <NewShippingStepsLayout>
      <Helmet>
        <meta name='description' content='Topship Africa Shipment Payment' />
        <title>Topship Africa Shipment Payment</title>
        <link rel='canonical' href='https://topship.africa/shipment-payment' />
      </Helmet>
      <BusyOverlay loading={pageLoading} />
      <PaymentModal
        show={openPaymentModal}
        handleCancel={() => {
          setOpenPaymentModal(false);
          setPaymentMethod(null);
          setTimeout(() => {
            setPaymentID('');
          }, 500);
        }}
        handleDelete={handlePayment}
        deleteText='Pay'
        warningMessage={`You will be paying ${toCurrency(amount)}`}
      />
      {/* <PaymentMethodPrompt
        openPrompt={openBankTransferModal}
        setOpenPrompt={setOpenBankTransferModal}
        multipleShipmentAmount={amount}
        onClosePrompt={() => {
          setOpenPaymentModal(false);
          setPaymentMethod(null);
          setTimeout(() => {
            setPaymentID('');
          }, 500);
        }}
        isMultipleShipmentBankTransfer
        resolveBankTransfer={() => {
          setOpenBankTransferModal(false);
          handlePayment();
        }}
      /> */}
      <SelectCurrencyPrompt
        openPrompt={openCurrencyPrompt}
        hideCurrencies={[currencies[0].currency]}
        setOpenPrompt={setOpenCurrencyPrompt}
        onPromptClose={() => {
          setPaymentMethod(null);
          setTimeout(() => {
            setPaymentID('');
          }, 500);
        }}
        onOpenPaymentPrompt={({ currency }) => {
          setOpenCurrencyPrompt(false);

          history.push(
            `/shipment-request-foreign-exchange-payment?multipleShipmentID=${paymentID}&currency=${currency}`
          );
        }}
      />
      <BookingSuccessfulPrompt
        openPrompt={openBookingSuccessfulPrompt}
        setOpenPrompt={setOpenBookingSuccessfulPrompt}
      />
      <NewFundWalletModal
        openModal={openFundWalletModal}
        setOpenModal={setOpenFundWalletModal}
        onModalSuccess={() => history.push(multipleShipmentLinks[4].link)}
      />
      <section className='order-summary'>
        <div className='order-summary__wrap'>
          <div className='new-wallet__balance ml-0'>
            <div className='new-wallet__balance__wrap'>
              <div className='new-wallet__balance__content'>
                <div className='new-wallet__balance__content__header'>
                  <h4>Your Balance</h4>
                </div>
                <div className='new-wallet__balance__content__value'>
                  <h2>
                    {walletBalanceLoading ? (
                      <Skeleton />
                    ) : (
                      toCurrency(walletBalance / 100)
                    )}
                  </h2>
                </div>
              </div>
              <div className='new-wallet__balance__action'>
                <button onClick={() => setOpenFundWalletModal(true)}>
                  Fund Wallet
                </button>
              </div>
            </div>
          </div>
          <div className='multiple-receiver-info__item__selection'>
            <div className='multiple-receiver-info__item__selection__wrap'>
              {multipleReceivers.map((_, index) => {
                return (
                  <div
                    onClick={() =>
                      setReceiverSteps(
                        receiverSteps.map((step, i) => ({
                          ...step,
                          isActive: i === index
                        }))
                      )
                    }
                    className={`multiple-receiver-info__item__selection__option${
                      activeReceiverIndex === index ? ' active' : ''
                    }`}
                    key={index}
                  >
                    <p
                      style={{
                        fontWeight: 500
                      }}
                      className='mb-0'
                    >
                      Receiver {index + 1}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='order-summary__shipment'>
            <div className='order-summary__shipment__wrap'>
              <div className='order-summary__shipment__addresses'>
                <div className={`order-summary__shipment__address multiple`}>
                  <PickupAddressComponent
                    shipmentDetail={shipmentDetail?.senderDetail}
                  />
                </div>
                {/* {multipleReceivers
                  .slice(0, expandData ? multipleReceivers.length : 2)
                  .map((receiver, receiverIndex) => {
                    const isPaid =
                      multipleShipmentResults[receiverIndex]?.data[0]?.isPaid;

                    const isPaymentPending =
                      multipleShipmentResults[receiverIndex]?.data[0]
                        ?.isPaymentPending;

                    return ( */}
                {budgetOutsideLagosShipment && (
                  <div className='order-summary__shipment__address deliver-to'>
                    <div className='order-summary__shipment__address__wrap'>
                      <DeliveryAddressComponent
                        receiver={budgetOutsideLagosShipment}
                      />
                      {(activeReceiverIndex !== 1 || expandData) &&
                        (multipleShipmentResults[activeReceiverIndex]?.data[1]
                          ?.isPaymentPending ? (
                          <div className='delivery-options__item__price'>
                            <span
                              style={{
                                color: '#efa516',
                                backgroundColor: '#df24031a',
                                fontSize: '15px'
                              }}
                            >
                              Payment Pending
                            </span>
                          </div>
                        ) : multipleShipmentResults[activeReceiverIndex]
                            ?.data[1]?.isPaid ? (
                          <div className='delivery-options__item__price'>
                            <span>Paid</span>
                          </div>
                        ) : (
                          <div
                            className='d-flex flex-column align-items-center'
                            style={{ gap: '1rem' }}
                          >
                            {paymentMethods.map((method, index) => (
                              <div
                                key={method.name}
                                className='order-summary__shipment__address__action'
                              >
                                <Button
                                  onClick={() =>
                                    handlePaymentMethodSelection(
                                      method,
                                      multipleShipmentResults[
                                        activeReceiverIndex
                                      ]?.data[1]?.id
                                    )
                                  }
                                  variant='text'
                                >
                                  <span>{method.name}</span>
                                  {getMethodIcon(index)}
                                </Button>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                <div className='order-summary__shipment__address deliver-to'>
                  <div className='order-summary__shipment__address__wrap'>
                    <DeliveryAddressComponent receiver={activeReceiver} />
                    {(activeReceiverIndex !== 1 || expandData) &&
                      (isPaymentPending ? (
                        <div className='delivery-options__item__price'>
                          <span
                            style={{
                              color: '#efa516',
                              backgroundColor: '#df24031a',
                              fontSize: '15px'
                            }}
                          >
                            Payment Pending
                          </span>
                        </div>
                      ) : isPaid ? (
                        <div className='delivery-options__item__price'>
                          <span>Paid</span>
                        </div>
                      ) : (
                        <div
                          className='d-flex flex-column align-items-center'
                          style={{ gap: '1rem' }}
                        >
                          {paymentMethods.map((method, index) => (
                            <div
                              key={method.name}
                              className='order-summary__shipment__address__action'
                            >
                              <Button
                                onClick={() =>
                                  handlePaymentMethodSelection(
                                    method,
                                    multipleShipmentResults[activeReceiverIndex]
                                      ?.data[0]?.id
                                  )
                                }
                                variant='text'
                              >
                                <span>{method.name}</span>
                                {getMethodIcon(index)}
                              </Button>
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {didBankTransfer && (
            <div className='payment-method__bank-transfer__notice'>
              <InfoOutlined />
              <span
                style={{ fontSize: '16px' }}
                className='payment-method__bank-transfer__notice__text'
              >
                You have to email us your receipt for confirmation
              </span>
            </div>
          )} */}
          <div className='order-summary__footer'>
            <SubmitButton
              onClick={finishShipment}
              disabled={disableButton}
              text={'Finish'}
            />
          </div>
        </div>
      </section>
    </NewShippingStepsLayout>
  );
};

export default MultipleShipmentPayment;

import { Alert, Button, Grid } from '@mui/material';
import { BoxModelHeader } from '../../../../../constants/box-model-constants';
import { useCallback, useMemo, useState } from 'react';
import { AddNewBoxComponent } from '../../../../../components/Commons/summary-component';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import Input from '../../../../../components/new-dashboard/custom-input';
import numberWithCommas from '../../../../../utilities/number-with-commas';
import { DropdownIcon } from '../../../../../constants/asset-contants';
import { ItemWeightBand } from '../../../../../constants/book-shipment-contants';
import ItemWeightBandModal from '../../../../../components/modals/item-weight-band';
import SeaFreightQuoteLayout from '../../../../../components/Layouts/sea-freight-layout';
import { useHistory } from 'react-router-dom';
import { seaFreightImportLinks } from '../../../../../constants/shipment-links';
import { saveShipmentDetailsState } from '../../../../../recoil/atoms';
import { useRecoilState } from 'recoil';
import omit from 'lodash.omit';
import { extractDimensions } from '../../../../../utilities/extract-dimensions';

const compareBands = (band, item) => {
  if (!band || !item) {
    return false;
  }

  return (
    band.width === item?.dimension?.width &&
    band.length === item?.dimension?.length &&
    band.height === item?.dimension?.height
  );
};

const initItem = {
  weight: '',
  width: '',
  height: '',
  length: '',
  value: '',
  description: '',
  boxType: ''
};

const initialItems = {
  items: [{ ...initItem, boxNumber: 1 }]
};

const validationSchema = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      weight: yup.number().when(['isVolumetric'], {
        is: isVolumetric => !isVolumetric,
        then: yup
          .number()
          .min(0.09999, 'Please enter a weight greater than zero (0)')
          .required('Please enter the weight of your item(s)'),
        otherwise: yup.number().nullable().notRequired()
      }),
      width: yup.number().when(['isVolumetric'], {
        is: isVolumetric => isVolumetric === true,

        then: yup
          .number()
          .min(0.09999, 'Please enter a width greater than zero (0)')
          .required('Please enter the width of your box'),
        otherwise: yup.number().nullable().notRequired()
      }),
      height: yup.number().when(['isVolumetric'], {
        is: isVolumetric => isVolumetric === true,

        then: yup
          .number()
          .min(0.09999, 'Please enter a height greater than zero (0)')
          .required('Please enter the height of your box'),
        otherwise: yup.number().nullable().notRequired()
      }),
      length: yup.number().when(['isVolumetric'], {
        is: isVolumetric => isVolumetric === true,

        then: yup
          .number()
          .min(0.09999, 'Please enter a length greater than zero (0)')
          .required('Please enter the length of your box'),
        otherwise: yup.number().nullable().notRequired()
      }),
      value: yup
        .number()
        .moreThan(0, 'Please enter a number above 1')
        .required('Please provide the value'),
      description: yup.string().required('Please provide the item description')
    })
  )
});

const SeaFreightItemDescription = () => {
  const history = useHistory();
  const [boxes, setBoxes] = useState([1]);
  const [currentBox, setCurrentBox] = useState(boxes[0]);
  const [openPackageDescription, setOpenPackageDescription] = useState(false);
  const [selectedItemBand, setSelectedItemBand] = useState({
    boxType: '',
    width: 0,
    height: 0,
    length: 0
  });

  const [openWeightBandDescription, setOpenWeightBandDescription] = useState(
    false
  );

  const [shipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );

  const currentIndex = useMemo(() => {
    if (!history?.location?.pathname) {
      return -1;
    }

    return seaFreightImportLinks.findIndex(
      link => history?.location?.pathname === link.link
    );
  }, [history?.location?.pathname]);

  const addItem = boxNumber => {
    setFieldValue('items', [...values.items, { ...initItem, boxNumber }]);
  };

  const initialValues = useMemo(() => {
    if (!shipmentDetail || !shipmentDetail?.items) {
      return initialItems;
    }

    const newBoxes = [];

    const result = {
      items: shipmentDetail?.items?.map(item => {
        if (!newBoxes.includes(item?.boxNumber)) {
          newBoxes.push(item?.boxNumber);
        }

        return {
          boxNumber: item?.boxNumber,
          weight: item?.weight,
          width: item?.dimension?.width,
          height: item?.dimension?.height,
          length: item?.dimension?.length,
          description: item?.description,
          manualBoxType: item?.manualBoxType,
          value: item?.value / 100,
          boxType:
            ItemWeightBand.find(band => {
              return compareBands(band, item);
            })?.boxType || ''
        };
      })
    };

    setSelectedItemBand(
      ItemWeightBand.find((band, index) => {
        return (
          compareBands(band, result.items[0]) ||
          index === ItemWeightBand.length - 1
        );
      })
    );

    setBoxes(newBoxes.sort());

    return result;
  }, [shipmentDetail]);

  const {
    values,
    errors,
    setFieldValue,
    handleChange: handleFormikChange,
    handleSubmit
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      setSaveShipmentDetail({
        ...shipmentDetail,
        items: values.items.map(item => {
          item.value = item.value * 100;

          item.isCompleted = true;

          item.dimension = {};

          item.dimension.width = Number(item.width);

          item.dimension.height = Number(item.height);

          item.dimension.length = Number(item.length);

          return omit(item, [
            'itemIndex',
            'boxType',
            'width',
            'height',
            'length'
          ]);
        })
      });

      history.push(seaFreightImportLinks[currentIndex + 1].link);
    }
  });

  const deleteItem = (index, boxNumber) => {
    const items = values.items.filter(item => item.boxNumber === boxNumber);

    if (items.length <= 1) {
      return;
    }

    setFieldValue(
      'items',
      values.items.filter((_, itemIndex) => itemIndex !== index)
    );
  };

  const showAddBoxContainer = useMemo(() => {
    return boxes?.length <= 3;
  }, [boxes]);

  const addNewBox = () => {
    if (boxes.length >= 4) {
      return;
    }

    const boxNumber = boxes[boxes.length - 1] + 1;

    boxes.push(boxNumber);

    setBoxes([...boxes]);

    setCurrentBox(boxNumber);

    setFieldValue('items', [...values.items, { ...initItem, boxNumber }]);
  };

  const deleteBox = boxNumber => {
    const newBoxes = boxes
      .filter(box => box !== boxNumber)
      .map(box => (box > boxNumber ? box - 1 : box));

    setBoxes(newBoxes);

    setCurrentBox(newBoxes[newBoxes?.length - 1]);

    setFieldValue(
      'items',
      values.items
        .filter(item => item.boxNumber !== boxNumber)
        .map(item => {
          if (item?.boxNumber > boxNumber) {
            item.boxNumber = item.boxNumber - 1;
          }

          return item;
        })
    );
  };

  const handleChange = ({ target: { name, value } }) => {
    const itemValue = name.match(new RegExp('quantity|weight', 'i'))
      ? value
        ? parseFloat(value)
        : ''
      : value;

    handleFormikChange({ target: { name, value: itemValue } });
  };

  const handleBoxTypeSelect = (index, band) => {
    setFieldValue(`items.${index}`, {
      ...values.items[index],
      boxType: band?.boxType,
      width: band?.width || '',
      height: band?.height || '',
      length: band?.length || ''
    });

    setOpenPackageDescription(false);
  };

  const isInputDisabled = useCallback(boxType => {
    if (!boxType) {
      return false;
    }

    return boxType !== 'Others';
  }, []);

  const onCloseAndProceedPrompt = val => {
    const { width, length, height } = extractDimensions(val.manualBoxType);

    values.items.forEach((item, index) => {
      const isOthers = selectedItemBand.boxType === 'Others';
      setFieldValue(`items.${index}`, {
        ...item,
        boxType: selectedItemBand.boxType,
        width: isOthers ? width : selectedItemBand.width || '',
        height: isOthers ? height : selectedItemBand.height || '',
        length: isOthers ? length : selectedItemBand.length || ''
      });
    });

    setOpenWeightBandDescription(false);
  };

  return (
    <SeaFreightQuoteLayout>
      <ItemWeightBandModal
        openPrompt={openWeightBandDescription}
        setOpenPrompt={setOpenWeightBandDescription}
        selectedItemBand={selectedItemBand}
        setSelectedItemBand={setSelectedItemBand}
        onCloseAndProceedPrompt={onCloseAndProceedPrompt}
        onClosePrompt={() => {
          setOpenWeightBandDescription(false);
        }}
      />
      <Alert
        severity={'info'}
        sx={{
          fontSize: '1.5rem',
          fontFamily: 'General Sans',
          backgroundColor: '#EFF4FF',
          color: '#0F1414',
          border: '1px solid #22428F',
          borderRadius: '8px',
          '& .MuiAlert-icon': {
            color: '#22428F'
          }
        }}
      >
        <b>Please note:</b> All items, excluding furniture, are to be placed on
        wooden pallets. For assistance,{' '}
        <a
          style={{
            color: '#22428F',
            fontWeight: 600,
            textDecoration: 'underline'
          }}
          href={'mailto:hello@topship.africa'}
          target='_blank'
        >
          contact support
        </a>
      </Alert>
      <div className='item-description mt-3'>
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap'
          }}
        >
          <div style={{ display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
            {boxes.map((box, index) => (
              <BoxModelHeader
                key={index}
                isSeaFreight
                box={box}
                currentBox={currentBox}
                setCurrentBox={setCurrentBox}
              />
            ))}
            {showAddBoxContainer && (
              <AddNewBoxComponent isSeaFreight addNewBox={addNewBox} />
            )}
          </div>
        </div>
        <div>
          {boxes?.map(box => {
            const boxItems = values?.items
              .filter(item => item?.boxNumber === box)
              .map(item => {
                item.itemIndex = values?.items.findIndex(
                  value => JSON.stringify(value) === JSON.stringify(item)
                );

                return item;
              });

            return (
              <div style={{ display: 'flex' }} key={box}>
                <div>
                  {currentBox === box && (
                    <form
                      className={currentBox === box ? 'form-transition' : ''}
                      onSubmit={handleSubmit}
                    >
                      <div className='item-description__mains'>
                        {boxItems?.map((item, index) => {
                          const itemIndex = item?.itemIndex;

                          return (
                            <div
                              key={index}
                              style={{
                                borderTop:
                                  index === 0 ? '' : '1px solid #e2e4e8',
                                borderTopLeftRadius: index === 0 ? '' : '8px',
                                borderTopRightRadius: index === 0 ? '' : '8px'
                              }}
                              className='item-description__main'
                            >
                              <div className='item-description__main__wrap'>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}
                                >
                                  {boxes.length > 1 && (
                                    <div
                                      style={{
                                        alignSelf: 'flex-end !important',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: '100%'
                                      }}
                                      className='item-description__main__action'
                                    >
                                      <Button
                                        variant='text'
                                        onClick={() => deleteBox(box)}
                                      >
                                        <span>Delete Package {box}</span>
                                        <RemoveCircle />
                                      </Button>
                                    </div>
                                  )}
                                </div>

                                <label
                                  style={{
                                    fontWeight: 600
                                  }}
                                  className='item-description__label'
                                >
                                  Dimensions
                                </label>
                                <div className='mt-2'>
                                  <label
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 500
                                    }}
                                    htmlFor=''
                                  >
                                    Which of these best describes your item
                                  </label>
                                  <div style={{ position: 'relative' }}>
                                    <div
                                      className='mb-1'
                                      onClick={() =>
                                        setOpenPackageDescription(
                                          !openPackageDescription
                                        )
                                      }
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                        border: '1px solid #E2E4E8',
                                        // height: '48px',
                                        padding: '16.5px 14px',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                        marginTop: '6px'
                                      }}
                                    >
                                      {item.boxType ? (
                                        <span
                                          style={{
                                            fontWeight: 500,
                                            fontSize: '16px'
                                          }}
                                        >
                                          {item.boxType}{' '}
                                          {item.boxType === 'Others'
                                            ? ''
                                            : 'Box'}
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            fontSize: '16px'
                                          }}
                                        >
                                          Select item band
                                        </span>
                                      )}
                                      <img src={DropdownIcon} alt='' />
                                    </div>
                                    {openPackageDescription ? (
                                      <div
                                        style={{
                                          position: 'absolute',
                                          width: '100%',
                                          top: '70px',
                                          left: 0,
                                          background: '#fff',
                                          border: '1px solid #E2E4E8',
                                          padding: '16.5px 14px',
                                          borderRadius: '8px',
                                          zIndex: 10
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '20px'
                                          }}
                                        >
                                          {ItemWeightBand.map((band, index) => {
                                            return (
                                              <div
                                                key={index}
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  gap: '5px',
                                                  fontSize: '16px',
                                                  cursor: 'pointer'
                                                }}
                                                onClick={() =>
                                                  handleBoxTypeSelect(
                                                    itemIndex,
                                                    band
                                                  )
                                                }
                                              >
                                                <b>
                                                  {band.boxType}{' '}
                                                  {band.size ? '' : 'Box'}
                                                </b>
                                                <span>
                                                  {band.size
                                                    ? band.size
                                                    : `${band.width}" x ${band.length}" x ${band.height}"`}
                                                </span>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    ) : null}
                                    <span
                                      style={{
                                        fontSize: '12px'
                                      }}
                                    >
                                      Not sure of item weight?{' '}
                                      <b
                                        style={{
                                          color: '#22428f',
                                          borderBottom: '1px solid #22428f',
                                          cursor: 'pointer'
                                        }}
                                        onClick={() =>
                                          setOpenWeightBandDescription(true)
                                        }
                                      >
                                        View Weight Bands
                                      </b>
                                    </span>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    alignSelf: 'flex-end !important'
                                  }}
                                  className='item-description__main__action'
                                >
                                  {boxItems?.length > 1 && (
                                    <Button
                                      variant='text'
                                      onClick={() => deleteItem(index, box)}
                                    >
                                      <span>Remove item</span>
                                      <RemoveCircle />
                                    </Button>
                                  )}
                                </div>

                                <div>
                                  <Grid
                                    container
                                    columnSpacing='10px'
                                    rowSpacing='16px'
                                    className='item-description__main__grid__form mt-1'
                                  >
                                    <Input
                                      lg={4}
                                      md={12}
                                      containerClassName='item-description__main__input custom-disabled-input'
                                      type='number'
                                      name={`items.${itemIndex}.width`}
                                      customLabel='Width (CM)'
                                      placeholder='100'
                                      value={item.width}
                                      onChange={e => {
                                        if (isNaN(e.target.value)) {
                                          return handleChange({
                                            target: {
                                              name: e.target.name,
                                              value: ''
                                            }
                                          });
                                        } else {
                                          handleChange(e);
                                        }
                                      }}
                                      error={Boolean(
                                        errors?.items?.[itemIndex]?.width
                                      )}
                                      helperText={
                                        errors?.items?.[itemIndex]?.width
                                      }
                                      inputProps={{
                                        min: 1,
                                        step: 'any'
                                      }}
                                      disabled={isInputDisabled(item.boxType)}
                                    />
                                    <Input
                                      lg={4}
                                      md={12}
                                      containerClassName='item-description__main__input'
                                      type='number'
                                      name={`items.${itemIndex}.length`}
                                      customLabel='Length (CM)'
                                      placeholder='100'
                                      value={item.length}
                                      onChange={e => {
                                        if (isNaN(e.target.value)) {
                                          return handleChange({
                                            target: {
                                              name: e.target.name,
                                              value: ''
                                            }
                                          });
                                        } else {
                                          handleChange(e);
                                        }
                                      }}
                                      error={Boolean(
                                        errors?.items?.[itemIndex]?.length
                                      )}
                                      helperText={
                                        errors?.items?.[itemIndex]?.length
                                      }
                                      inputProps={{
                                        min: 1,
                                        step: 'any'
                                      }}
                                      disabled={isInputDisabled(item.boxType)}
                                    />
                                    <Input
                                      lg={4}
                                      md={12}
                                      containerClassName='item-description__main__input'
                                      type='number'
                                      name={`items.${itemIndex}.height`}
                                      customLabel='Height (CM)'
                                      placeholder='100'
                                      value={item.height}
                                      onChange={e => {
                                        if (isNaN(e.target.value)) {
                                          return handleChange({
                                            target: {
                                              name: e.target.name,
                                              value: ''
                                            }
                                          });
                                        } else {
                                          handleChange(e);
                                        }
                                      }}
                                      error={Boolean(
                                        errors?.items?.[itemIndex]?.height
                                      )}
                                      helperText={
                                        errors?.items?.[itemIndex]?.height
                                      }
                                      inputProps={{
                                        min: 1,
                                        step: 'any'
                                      }}
                                      disabled={isInputDisabled(item.boxType)}
                                    />
                                  </Grid>
                                </div>
                                <div className='item-description__main__grid mt-3'>
                                  <div className='mb-2'>
                                    <label
                                      style={{
                                        fontWeight: 600
                                      }}
                                      className='item-description__label'
                                    >
                                      Item Information
                                    </label>
                                  </div>

                                  <Grid
                                    container
                                    columnSpacing='10px'
                                    rowSpacing='16px'
                                    className='item-description__main__grid__form'
                                  >
                                    <Input
                                      lg={6}
                                      md={12}
                                      type='number'
                                      name={`items.${itemIndex}.weight`}
                                      customLabel='Weight (KG)'
                                      placeholder='Enter Weight'
                                      value={item.weight}
                                      onChange={e => {
                                        if (isNaN(e.target.value)) {
                                          return handleChange({
                                            target: {
                                              name: e.target.name,
                                              value: ''
                                            }
                                          });
                                        } else {
                                          handleChange(e);
                                        }
                                      }}
                                      error={Boolean(
                                        errors?.items?.[itemIndex]?.weight
                                      )}
                                      helperText={
                                        errors?.items?.[itemIndex]?.weight
                                      }
                                      containerClassName='item-description__main__input'
                                      inputProps={{
                                        min: 1,
                                        step: 'any'
                                      }}
                                    />
                                    <Input
                                      lg={6}
                                      md={12}
                                      name={`items.${itemIndex}.value`}
                                      placeholder='50,000.00'
                                      customLabel='Item Value (NGN)'
                                      value={`${numberWithCommas(
                                        item?.value || 0
                                      )}`}
                                      onChange={event => {
                                        const { value, name } = event.target;
                                        if (!(value && value.length)) {
                                          handleChange({
                                            target: { name, value: '' }
                                          });
                                        } else if (
                                          !isNaN(value.split(',').join(''))
                                        ) {
                                          const itemValue = parseInt(
                                            value.split(',').join(''),
                                            10
                                          );
                                          handleChange({
                                            target: { name, value: itemValue }
                                          });
                                        }
                                      }}
                                      containerClassName='item-description__main__input'
                                      error={Boolean(
                                        errors?.items?.[itemIndex]?.value
                                      )}
                                      helperText={
                                        errors?.items?.[itemIndex]?.value
                                      }
                                    />
                                  </Grid>

                                  <Grid container className='mt-2'>
                                    <Input
                                      fullWidth
                                      multiline
                                      minRows={4}
                                      name={`items.${itemIndex}.description`}
                                      value={item.description}
                                      onChange={e => handleChange(e)}
                                      customLabel='Package Description'
                                      containerClassName='item-description__main__input'
                                      error={Boolean(
                                        errors?.items?.[itemIndex]?.description
                                      )}
                                      helperText={
                                        errors?.items?.[itemIndex]?.description
                                      }
                                      placeholder='A piece of text that clearly describes the item being packaged for shipping, it should leave no room for guesses so that TopShip can know how best to handle it'
                                    />
                                  </Grid>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className='item-description__action'>
                          <Button
                            variant='text'
                            disabled={boxItems?.length >= 5}
                            onClick={() => addItem(box)}
                          >
                            <span>Add another item</span>
                            <AddCircle />
                          </Button>
                        </div>
                      </div>

                      <button type='submit' className='continue-btn'>
                        Continue
                      </button>
                      <div
                        onClick={() =>
                          history.push(
                            seaFreightImportLinks[currentIndex - 1].link
                          )
                        }
                        className='go-back-btn'
                      >
                        Go Back
                      </div>
                    </form>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </SeaFreightQuoteLayout>
  );
};

export default SeaFreightItemDescription;

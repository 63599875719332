import { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'yup-phone-lite';
import debounce from 'lodash.debounce';
import { Box, Grid, InputAdornment } from '@mui/material';
import CustomAutoComplete from '../../../../../components/new-dashboard/custom-autocomplete';
import Input from '../../../../../components/new-dashboard/custom-input';
import { defaultDetail } from '../../../../../constants/book-shipment-contants';
import { useGetStatesQuery } from '../../../../../operations/queries';
import { useGetCitiesMutation } from '../../../../../operations/mutations';
import { saveShipmentDetailsState } from '../../../../../recoil/atoms';
import { useRecoilState } from 'recoil';
import { seaFreightImportLinks } from '../../../../../constants/shipment-links';
import { useHistory } from 'react-router-dom';
import SeaFreightQuoteLayout from '../../../../../components/Layouts/sea-freight-layout';
import { defaultCountry } from '../../../../../constants/countries';

const initialAddress = {
  city: '',
  state: '',
  postalCode: '',
  addressLine1: '',
  country: defaultCountry.name,
  countryCode: defaultCountry.code
};

const validationSchema = yup.object().shape({
  addressLine1: yup
    .string()
    .test(
      'contains the + character',
      'Please enter a valid address',
      value => !value?.includes('+')
    )
    .required('Please enter an address'),
  addressLine2: yup
    .string()
    .test(
      'contains the + character',
      'Please enter a valid address',
      value => !value?.includes('+')
    )
    .nullable(),
  addressLine3: yup
    .string()
    .test(
      'contains the + character',
      'Please enter a valid address',
      value => !value?.includes('+')
    )
    .nullable(),
  country: yup.string().required('Please select a country'),
  state: yup.string().required('Please select a state'),
  city: yup.string().required('Please select a city'),
  countryCode: yup.string().required(),
  postalCode: yup.string().when(['countryCode'], {
    is: countryCode => countryCode?.toLowerCase() !== defaultDetail.countryCode,
    then: yup.string().required('Please provide a postal code')
  })
});

const SeaFreightReceiverInfo = () => {
  const [getStates, states] = useGetStatesQuery();

  const [shipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );

  const history = useHistory();

  const currentIndex = useMemo(() => {
    if (!history.location.pathname) {
      return -1;
    }

    return seaFreightImportLinks.findIndex(
      link => history.location.pathname === link.link
    );
  });

  const [getCities, citiesResult] = useGetCitiesMutation();

  const initialValues = useMemo(() => {
    if (!shipmentDetail?.receiverDetail) {
      return initialAddress;
    }

    return {
      city: shipmentDetail?.receiverDetail?.city || '',
      state: shipmentDetail?.receiverDetail?.state || '',
      postalCode: shipmentDetail?.receiverDetail?.postalCode || '',
      country:
        shipmentDetail?.receiverDetail?.country || initialAddress.country,
      countryCode:
        shipmentDetail?.receiverDetail?.countryCode ||
        initialAddress.countryCode,
      addressLine1: `${shipmentDetail?.receiverDetail?.addressLine1 || ''}${
        shipmentDetail?.receiverDetail?.addressLine2 || ''
      }${shipmentDetail?.receiverDetail?.addressLine3 || ''}`
    };
  }, [shipmentDetail?.receiverDetail]);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: receiverDetail => {
      receiverDetail.isCompleted = true;

      const addressLine1 = receiverDetail.addressLine1.substring(0, 40);

      const addressLine2 = receiverDetail.addressLine1.substring(40, 80);

      const addressLine3 = receiverDetail.addressLine1.substring(80, 120);

      receiverDetail.addressLine1 = addressLine1;

      receiverDetail.addressLine2 = addressLine2 || '';

      receiverDetail.addressLine3 = addressLine3 || '';

      setSaveShipmentDetail({ ...shipmentDetail, receiverDetail });

      history.push(seaFreightImportLinks[currentIndex + 1].link);
    }
  });

  useEffect(() => {
    getStates(defaultCountry.code);

    getCities({ countryCode: defaultCountry.code, cityName: 'a' });
    // eslint-disable-next-line
  }, []);

  return (
    <SeaFreightQuoteLayout>
      <form onSubmit={handleSubmit}>
        <div>
          <div className=''>
            <div>
              <div>
                <h3 className='mb-2' style={{ fontWeight: 600 }}>
                  Destination Addresses
                </h3>
                <Grid container columnSpacing='10px' rowSpacing='16px'>
                  <Input
                    error={Boolean(errors.addressLine1)}
                    helperText={errors.addressLine1}
                    value={values.addressLine1}
                    onChange={handleChange}
                    customLabel='Address'
                    name='addressLine1'
                    id='addressLine1'
                    type='text'
                    containerClassName='new-addresses__modal__body__form__item__input'
                    placeholder='1, Idumota lane, Iweka Road'
                    fullWidth
                  />
                  <CustomAutoComplete
                    options={[defaultCountry]}
                    getOptionLabel={option => option?.name}
                    isOptionEqualToValue={(option, value) =>
                      option?.name === value?.name
                    }
                    renderOption={(props, option, country) => (
                      <Box
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={props.id}
                      >
                        {country && (
                          <img
                            loading='lazy'
                            width='30'
                            height='20'
                            src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
                            alt=''
                          />
                        )}
                        {option.name}
                      </Box>
                    )}
                    value={defaultCountry}
                    noOptionsText={'Country not found'}
                    onChange={(event, value) => {
                      getStates(value?.code);
                      getCities({
                        countryCode: value?.code,
                        cityName: 'a'
                      });
                    }}
                    textfieldProps={{
                      lg: 12,
                      md: 12,
                      name: 'country',
                      customLabel: 'Country',
                      containerClassName:
                        'new-addresses__modal__body__form__item__input',
                      error: Boolean(errors.country),
                      helperText: errors.country,
                      value: values.country,
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position='start'>
                            <img
                              loading='lazy'
                              width='20'
                              height='14'
                              src={`https://flagcdn.com/w20/${
                                values.countryCode?.toLowerCase() ||
                                defaultCountry.code
                              }.png`}
                              srcSet={`https://flagcdn.com/w40/${
                                values.countryCode?.toLowerCase() ||
                                defaultCountry.code
                              }.png 2x`}
                              alt=''
                            />
                          </InputAdornment>
                        )
                      }
                    }}
                  />
                  <CustomAutoComplete
                    options={states.data ? states.data?.getStates : []}
                    getOptionLabel={option => option?.name}
                    isOptionEqualToValue={(option, value) =>
                      option?.name === value?.name
                    }
                    renderOption={(props, option) => (
                      <Box
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={props.id}
                      >
                        {option.name}
                      </Box>
                    )}
                    value={
                      states?.data?.getStates?.length
                        ? states?.data?.getStates?.find(
                            state =>
                              state.name?.toLowerCase() ===
                              values.state?.toLowerCase()
                          )
                        : { name: '' }
                    }
                    noOptionsText={
                      !values.country
                        ? 'Please Select a country'
                        : 'State not found'
                    }
                    onChange={(event, value) =>
                      setFieldValue('state', value?.name)
                    }
                    textfieldProps={{
                      lg: 12,
                      md: 12,
                      name: 'state',
                      customLabel: 'State',
                      containerClassName:
                        'new-addresses__modal__body__form__item__input',
                      error: Boolean(errors.state),
                      helperText: errors.state,
                      value: values.state
                    }}
                  />
                  <CustomAutoComplete
                    options={citiesResult.data?.length ? citiesResult.data : []}
                    getOptionLabel={option =>
                      option?.city
                        ?.split(' ')
                        ?.map(
                          word =>
                            `${word.charAt().toUpperCase()}${word
                              .substring(1, word.length)
                              ?.toLowerCase()}`
                        )
                        ?.join(' ')
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.city === value?.city
                    }
                    renderOption={(props, option) => (
                      <Box
                        key={props.id}
                        component='li'
                        sx={{
                          textTransform: 'capitalize'
                        }}
                        {...props}
                      >
                        {`${option?.city?.toLowerCase()} ${
                          option?.postalCode ? `(${option?.postalCode})` : ''
                        }`}
                      </Box>
                    )}
                    value={citiesResult.data?.find(
                      city =>
                        city?.city?.toLowerCase() ===
                        values?.city?.toLowerCase()
                    )}
                    noOptionsText={
                      !values.country
                        ? 'Please select a country'
                        : citiesResult.loading
                        ? 'Cities loading...'
                        : 'City not found'
                    }
                    onChange={(event, value) =>
                      setFieldValue('city', value?.city)
                    }
                    textfieldProps={{
                      lg: 12,
                      md: 12,
                      name: 'city',
                      customLabel: 'City',
                      containerClassName:
                        'new-addresses__modal__body__form__item__input',
                      error: Boolean(errors.city),
                      helperText: errors.city,
                      value: values.city,
                      onChange: debounce(
                        event =>
                          getCities({
                            countryCode: values.countryCode,
                            cityName: event?.target?.value
                          }),
                        500
                      )
                    }}
                  />
                  <Input
                    error={Boolean(errors.postalCode)}
                    helperText={errors.postalCode}
                    value={values.postalCode}
                    onChange={handleChange}
                    customLabel='Postal Code'
                    name='postalCode'
                    id='postalCode'
                    type='text'
                    containerClassName='new-addresses__modal__body__form__item__input'
                    placeholder='100001'
                    fullWidth
                    lg={12}
                    md={12}
                  />
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <button className='continue-btn' type='submit'>
          Continue
        </button>
        <div
          onClick={() =>
            history.push(seaFreightImportLinks[currentIndex - 1].link)
          }
          className='go-back-btn'
        >
          Go Back
        </div>
      </form>
    </SeaFreightQuoteLayout>
  );
};

export default SeaFreightReceiverInfo;

import React, { useCallback, useEffect, useState } from 'react';
import tickCircle from '../../../../../../assets/image/tick-circle.png';
import BusyOverlay from '../../../../../../components/busy-overlay';
import SubmitButton from '../../../../../../components/submit-button';
import { Button, Grid } from '@mui/material';
import Input from '../../../../../../components/new-dashboard/custom-input';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Add, RemoveCircle } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { userState } from '../../../../../../recoil/atoms';
import { useUpdateBusinessMutation } from '../../../../../../operations/mutations';

const Directors = ({ nextStep, previousStep }) => {
  const [user, setUser] = useRecoilState(userState);
  const [success, setSuccess] = useState(false);

  const goBack = () => {
    previousStep();
  };

  const validationSchema = yup.object().shape({
    directors: yup.array().of(
      yup.object().shape({
        fullName: yup
          .string()
          .required("Please enter a director's name")
          .test(
            'number of words',
            'Name should not be less than 2 words',
            value => value?.split(' ')?.length >= 2
          )
      })
    )
  });

  const {
    values: { directors },
    errors,
    handleChange,
    handleSubmit,
    setValues
  } = useFormik({
    initialValues: {
      directors: [{ fullName: '' }]
    },
    validationSchema,
    onSubmit: values => {
      updateBusiness({
        businessId: user.businessInformation.id,
        update: { directors: values.directors }
      });
    }
  });

  const [updateBusiness, updateBusinessResult] = useUpdateBusinessMutation(
    () => {
      setUser({
        ...user,
        businessInformation: { ...user.businessInformation, directors }
      });

      setSuccess(true);
    }
  );

  const addNewDirector = () => {
    const newDirectors = [...directors, { fullName: '' }];

    setValues({ directors: newDirectors });
  };

  const removeDirector = index => {
    const newDirectors = directors.filter(
      (_, directorIndex) => directorIndex !== index
    );

    setValues({ directors: newDirectors });
  };

  const getOrdinal = useCallback((number = 1) => {
    const digit = Number(String(number).slice(-1));

    switch (digit) {
      case 1:
        return `${number}st`;

      case 2:
        return `${number}nd`;

      case 3:
        return `${number}rd`;

      default:
        return `${number}th`;
    }
  }, []);

  const loading = updateBusinessResult.loading;

  const submit = () => {
    if (success) {
      nextStep();
    } else {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (user.businessInformation.directors) {
      setValues({ directors: user.businessInformation.directors });
    }
    // eslint-disable-next-line
  }, [user.businessInformation.directors]);

  return (
    <div className='verification__body'>
      {success && (
        <div className='verification__image'>
          <img src={tickCircle} className='img-fluid' alt='' />
        </div>
      )}
      <div className={`verification__content${!success ? ' mt-3' : ''}`}>
        <div className='verification__header text-left'>
          <h1>
            {success
              ? 'Names of company directors saved!'
              : 'Enter the names of your company director(s)'}
          </h1>
        </div>
        <div className='verification__description text-left'>
          <p>
            {success
              ? 'The names of your company directors has been successfully saved.'
              : 'Please input the name(s) of your company’s directors.'}
          </p>
        </div>
      </div>
      {!success && (
        <>
          <Grid container columnSpacing='10px' rowSpacing='16px'>
            {directors.map((director, index) => (
              <div key={index} className='w-100 my-3 d-flex align-items-center'>
                <Input
                  customLabel={`${getOrdinal(index + 1)} Director's Full Name`}
                  name={`directors.${index}.fullName`}
                  error={Boolean(errors.directors?.[index]?.fullName)}
                  helperText={errors.directors?.[index]?.fullName}
                  onChange={handleChange}
                  value={director.fullName}
                  placeholder='John Doe'
                  containerClassName='new-addresses__modal__item__input text-left'
                  lg={12}
                  md={12}
                />
                {directors.length > 1 && (
                  <div className='ml-2 item-description__main__action'>
                    <Button
                      variant='text'
                      className='d-block'
                      onClick={() => removeDirector(index)}
                    >
                      <RemoveCircle />
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </Grid>
          <Button
            disabled={directors.length === 4}
            onClick={addNewDirector}
            className='multiple-receiver-info__action'
          >
            <Add />
            Add another Director's name
          </Button>
        </>
      )}
      <div className='verification__action'>
        <div onClick={goBack} className='item-description__footer__action'>
          Go Back
        </div>
        <SubmitButton onClick={submit} text={success ? 'Continue' : 'Submit'} />
      </div>
      <BusyOverlay loading={loading} />
    </div>
  );
};

export default Directors;

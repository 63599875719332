import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Grid, MenuItem } from '@mui/material';
import Input from '../../../components/new-dashboard/custom-input';
import { TopShipBlueLogo } from '../../../constants/asset-contants';
import { useRegistrationMutation } from '../../../operations/mutations';
import { useShowAuthPage } from '../../../utilities/show-auth-pages';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import {
  IntroItemsState,
  accountType as accountTypes,
  accountTypeLspUser,
  source,
  gatewayIntroItems
} from '../../../constants/authentication';
import SubmitButton from '../../../components/submit-button';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import ContactSupport from '../../../components/contact-support';
import { shipmentOptions } from '../../../constants/book-shipment-contants';
import { useRecoilValue } from 'recoil';
import { saveOrderSummaryPathState } from '../../../recoil/atoms';
import { FileUpload } from '@mui/icons-material';
import axios from 'axios';
import BusyOverlay from '../../../components/busy-overlay';
import { useSendAlert } from '../../../utilities/send-alert';
import omit from 'lodash.omit';
import { useGetBusinessInformation } from '../../../operations/queries';
import CustomSpinner from '../../../components/custom-spinner';
import extractFilename from '../../../utilities/extract-filename';
import ReCAPTCHA from 'react-google-recaptcha';

const SignUp = () => {
  const isLspParams = new URLSearchParams(window.location.search).get('isLsp');
  const location = useLocation();
  const urlParams = new URLSearchParams(location?.search);
  const businessId = urlParams.get('lsp');

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    accountType: !!isLspParams ? accountTypes[2]?.value : '',
    source: '',
    businessName: '',
    logo: ''
  };
  const params = useParams();
  const history = useHistory();
  const sendAlert = useSendAlert();
  const OrderSummaryPathState = useRecoilValue(saveOrderSummaryPathState);
  const constants = {
    shipmentDetail: 'saveShipmentDetailsState',
    shipmentOption: 'shipmentOptionSelectionState'
  };
  const [getBusinessInfo, businessInfoResult] = useGetBusinessInformation();
  const recaptcha = useRef();
  const [createUser, { loading, error, data }] = useRegistrationMutation();
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [fileLoading, setFileLoading] = useState(false);
  const [renderPage] = useShowAuthPage();

  const businessInfo = businessInfoResult?.data?.getBusinessInformationByUser;
  const businessLogo = businessInfo?.logo;
  const businessName = businessInfo?.businessName;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    if (!!businessId) {
      getBusinessInfo(businessId);
    }
    // eslint-disable-next-line
  }, [businessId]);

  const chooseAccount = !!businessId ? accountTypeLspUser : accountTypes;

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('What is your first name?'),
    lastName: yup.string().required('What is your last name?'),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('What is your email address?'),
    phoneNumber: yup.string().required('What is your phone number?'),
    password: yup
      .string()
      .min(8, 'Please enter a password that is longer than 8 characters')
      .required('Please enter your password'),
    accountType: yup.string().required('Please select your account type'),

    source: yup
      .string()
      .test(
        'check if LSP user',
        'Please select a source',
        value => value || !!businessId
      ),

    businessName: yup.string().when(['accountType'], {
      is: accountType => accountType === chooseAccount[2]?.value,
      then: yup.string().required('Please enter your Business Name')
    }),
    logo: yup.string()
  });

  const handleUploadLogo = event => {
    const file = event?.target?.files[0];
    if (file) {
      const fileType = new RegExp('png|jpeg|jpg|webp');
      if (!file.type || !file?.type.match(fileType)) {
        return sendAlert('File format not supported.', 'error');
      }
      setFileLoading(true);

      const url = `${process.env.REACT_APP_REST_API}/upload`;
      const formData = new FormData();

      formData.append('file', file);
      formData.append('fileName', file.name);
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      axios
        .post(url, formData, config)
        .then(response => {
          setFieldValue('logo', response.data.Location);
          setFileLoading(false);
        })
        .catch(err => {
          sendAlert(err?.message, 'error');
          setFileLoading(false);
        });
    }
  };

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      const recaptchaToken = recaptcha.current?.getValue();

      if (!recaptchaToken) {
        sendAlert('Please use the recaptcha', 'error');
      }

      const newValues = {
        ...values,
        recaptchaToken,
        fullName: `${values.firstName} ${values.lastName}`
      };

      if (!newValues.logo) {
        newValues.logo = `${process.env.REACT_APP_STORAGE_URL}/Topship%20Gateway.png`;
      }

      createUser({
        ...omit(newValues, ['firstName', 'lastName']),
        ...(params.topshipId ? { referrerCode: params.topshipId } : {})
      });
    }
  });

  const allIntroItems = !!isLspParams ? gatewayIntroItems : IntroItemsState;
  const [introItems, setIntroItems] = useState(allIntroItems);
  const [stopAutoPlay, setStopAutoPlay] = useState(false);
  const [counter, setCounter] = useState(0);
  const lspInfoTrue = Boolean(
    !!businessInfoResult?.data && !businessInfoResult?.loading
  );
  const lspInfoFalse = Boolean(
    !businessInfoResult?.data && !businessInfoResult?.loading
  );

  const updateCounter = (currentCounter, maxCounter) => {
    return (currentCounter + 1) % (maxCounter + 1);
  };

  useEffect(() => {
    const timer =
      !stopAutoPlay &&
      setInterval(() => {
        setIntroItems(data => {
          return data?.map((item, index) => {
            if (index === counter) {
              return { ...item, display: true };
            } else return { ...item, display: false };
          });
        });
        if (lspInfoTrue) {
          setCounter(updateCounter(counter, 1));
        } else if (!!isLspParams) {
          setCounter(updateCounter(counter, 4));
        } else {
          setCounter(updateCounter(counter, 2));
        }
      }, 2000);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [stopAutoPlay, counter]);

  const shipmentDetail = JSON.parse(
    localStorage.getItem(constants.shipmentDetail)
  )?.saveShipmentDetailsState;

  const shipmentOption = JSON.parse(
    localStorage.getItem(constants.shipmentOption)
  )?.shipmentOptionSelectionState;

  useEffect(() => {
    if (shipmentDetail) {
      const detail =
        shipmentOption?.id ===
        shipmentOptions.find(option => option.value === 'Import').id
          ? shipmentDetail?.receiverDetail
          : shipmentDetail?.senderDetail;

      setInitialValues(values => ({
        ...values,
        email: detail?.email,
        firstName: detail?.name?.split(' ')[0],
        lastName: detail?.name?.split(' ')[1],
        phoneNumber: detail?.phoneNumber
      }));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && !error) {
      if (shipmentDetail && OrderSummaryPathState?.path) {
        history.push(OrderSummaryPathState?.path);
      } else {
        if (!!businessId) {
          history.push({
            pathname: '/signup-successful',
            search: location?.search
          });
        } else {
          history.push('/signup-successful');
        }
      }
    }
    // eslint-disable-next-line
  }, [data, error, OrderSummaryPathState?.path]);

  return renderPage(
    <>
      {lspInfoFalse && <ContactSupport />}
      <Helmet>
        <meta name='description' content='Topship Africa Sign Up' />
        <title>Topship Africa Sign Up</title>
        <link rel='canonical' href='https://ship.topship.africa/signup' />
      </Helmet>
      <div className='new-signup'>
        <div className='new-signup__wrap'>
          <div className='new-signup__main'>
            <div className='new-signup__main__wrap'>
              <div className='new-signup__header'>
                <div className='new-signup__logo'>
                  {businessInfoResult?.loading ? (
                    <div>
                      <CustomSpinner
                        text=''
                        textSize='1.5rem'
                        textColor='var(--color-primary)'
                        size='4rem'
                        thickness='.6rem'
                        background='#e6e3df'
                        spinColor='var(--color-blue-dark)'
                      />
                    </div>
                  ) : lspInfoTrue ? (
                    <img
                      style={{
                        width: '170px',
                        height: '50px',
                        objectFit: 'cover'
                      }}
                      src={businessLogo}
                      alt={businessName}
                    />
                  ) : (
                    <img
                      src={TopShipBlueLogo}
                      alt='TopShip'
                      className='img-fluid'
                    />
                  )}
                </div>
                <div className='new-signup__heading'>
                  <h1>Create an account</h1>
                </div>
              </div>
              <form
                onSubmit={e => e.preventDefault()}
                className='new-signup__form'
              >
                <Grid container spacing={2} columnSpacing='10px'>
                  <Input
                    onChange={handleChange}
                    value={values.firstName}
                    name='firstName'
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    customLabel='First Name'
                    type='text'
                    placeholder='John'
                  />
                  <Input
                    onChange={handleChange}
                    value={values.lastName}
                    name='lastName'
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    customLabel='Last Name'
                    type='text'
                    placeholder='Doe'
                  />
                  <Input
                    onChange={handleChange}
                    value={values.email}
                    name='email'
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    customLabel='Email Address'
                    type='email'
                    placeholder='garrick@topship.com'
                  />
                  <Input
                    id='phoneNumber'
                    name='phoneNumber'
                    error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    onChange={value => setFieldValue('phoneNumber', value)}
                    value={values.phoneNumber}
                    variant='outlined'
                    type='tel'
                    placeholder='+234 801 234 5678'
                    customLabel='Phone Number'
                  />
                  <Input
                    fullWidth
                    id='password'
                    onChange={handleChange}
                    value={values.password}
                    name='password'
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    customLabel='Password'
                    type='password'
                    placeholder='Enter a password'
                  />
                  <Input
                    fullWidth
                    onChange={handleChange}
                    value={values.accountType}
                    name='accountType'
                    error={touched.accountType && Boolean(errors.accountType)}
                    helperText={touched.accountType && errors.accountType}
                    customLabel='Account type'
                    select
                  >
                    {chooseAccount.map((account, i) => (
                      <MenuItem value={account?.value} key={i}>
                        {account.label}
                      </MenuItem>
                    ))}
                  </Input>
                  {lspInfoFalse && (
                    <Input
                      fullWidth
                      onChange={handleChange}
                      value={values.source}
                      name='source'
                      error={touched.source && Boolean(errors.source)}
                      helperText={touched.source && errors.source}
                      customLabel='How did you hear about us?'
                      type='text'
                      select
                    >
                      {source.map((s, i) => (
                        <MenuItem value={s.value} key={i}>
                          {s.label}
                        </MenuItem>
                      ))}
                    </Input>
                  )}

                  {values.accountType === chooseAccount[2]?.value &&
                    !businessInfoResult?.data && (
                      <>
                        <Input
                          name='businessName'
                          fullWidth
                          onChange={handleChange}
                          value={values.businessName}
                          error={
                            touched.businessName && Boolean(errors.businessName)
                          }
                          helperText={
                            touched.businessName && errors.businessName
                          }
                          customLabel='Business Name'
                        />
                        <div id='input-control'>
                          <label htmlFor='evidenceOfPurchase'>
                            <span style={{ marginBottom: '10px' }}>
                              Upload Business Logo
                            </span>
                          </label>
                          <div id='upload-button'>
                            <Button
                              component='label'
                              variant='outlined'
                              startIcon={<FileUpload />}
                              sx={{
                                marginRight: '1rem',
                                '&:disabled': { opacity: '0.4' }
                              }}
                            >
                              Upload File
                              <input
                                type='file'
                                hidden
                                name='logo'
                                accept='.jpg, .jpeg, .png, .gif, .bmp, .webp'
                                onChange={handleUploadLogo}
                              />
                            </Button>
                            <span className='filename'>
                              {values.logo ? extractFilename(values.logo) : ''}
                            </span>
                          </div>
                          <small>{touched.logo && errors.logo}</small>
                        </div>
                      </>
                    )}
                </Grid>
              </form>
              <div className='new-signup__footer'>
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  style={{ marginBottom: '2em' }}
                />
                {lspInfoFalse && (
                  <div className='new-signup__acknowledgment'>
                    <p>
                      By clicking sign up, I acknowledge that I have read,
                      understand and agree to the Topship’s{' '}
                      <Link to='/terms'>Privacy Policy</Link> and{' '}
                      <Link to='/terms'>Terms of Service</Link>
                    </p>
                  </div>
                )}

                <div className='new-signup__button'>
                  <SubmitButton
                    text='Create account'
                    loading={loading}
                    type='submit'
                    onClick={() => {
                      ReactGA.event({
                        category: 'Authentication',
                        action: 'User pressed the sign up button'
                      });
                      handleSubmit();
                    }}
                  />
                </div>
                <div className='new-signup__link'>
                  <p>
                    Have an account?{' '}
                    <Link
                      to={{
                        pathname: '/login',
                        state: OrderSummaryPathState,
                        search: !!businessId && location?.search
                      }}
                    >
                      Sign in
                    </Link>{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='new-signup__intro'>
            <div className='new-signup__intro__wrap'>
              {introItems
                .filter(item => item.display === true)
                .map((item, i) => (
                  <div key={i} className='new-signup__intro__item'>
                    <div className='new-signup__intro__item__wrap'>
                      <div className='new-signup__intro__item__icon'>
                        <img src={item.icon} className='img-fluid' alt='' />
                      </div>
                      <div className='new-signup__intro__item__content'>
                        <h2>{item.header}</h2>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  </div>
                ))}
              <div className='new-signup__intro__dots'>
                {introItems
                  .filter((intro, introIndex) =>
                    lspInfoTrue ? introIndex !== 2 : intro
                  )
                  .map((_, i) => (
                    <button
                      onClick={() => {
                        setIntroItems(data =>
                          data.map((item, index) =>
                            index === i
                              ? { ...item, display: true }
                              : { ...item, display: false }
                          )
                        );
                        setStopAutoPlay(true);
                      }}
                      key={i}
                      className={`new-signup__intro__dot ${
                        _.display ? 'active' : ''
                      }`}
                    ></button>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BusyOverlay loading={fileLoading} />
    </>
  );
};

export default SignUp;

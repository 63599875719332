import React from 'react';
import { Link } from 'react-router-dom';
import DescriptionText from '../../components/Commons/description-text';
import backArrow from '../../assets/svg/back-arrow.svg';
import ScrollTop from '../../utilities/scroll-top';

const ShippingOptionsLayout = ({
  linkTo,
  onBackClick,
  children,
  description: { heading, subHeading }
}) => {
  return (
    <div className='quote-summary'>
      <ScrollTop />
      <DescriptionText heading={heading} subHeading={subHeading} />
      <Link to={linkTo}>
        <figure
          style={{
            cursor: 'pointer'
          }}
          className='mb-3'
          onClick={onBackClick}
        >
          <img src={backArrow} alt='arrow' />
        </figure>
      </Link>

      {children}
    </div>
  );
};

export default ShippingOptionsLayout;

import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { BoxModel } from './asset-contants';
import { Button, Grid, Box, Alert } from '@mui/material';
import Input from '../components/new-dashboard/custom-input';
import { itemCategories } from './item-categories';
import numberWithCommas from '../utilities/number-with-commas';
import VolumetricWeight from '../components/volumetric-weight-switch';
import StickyNoteAlert from '../components/Commons/sticky-note';
import {
  getItemCategoryMessage,
  itemCategoryRules
} from './item-category-dictionary';
import CustomAutoComplete from '../components/new-dashboard/custom-autocomplete';
import { useRecoilState } from 'recoil';
import { saveShipmentDetailsState } from '../recoil/atoms';

export const BoxModelHeader = ({
  currentBox,
  setCurrentBox,
  isMobile,
  box,
  isSeaFreight = false
}) => {
  return (
    <div
      onClick={() => setCurrentBox(box)}
      className={`item-description__box-model ${
        currentBox === box ? 'active' : ''
      }`}
      style={{
        width: isMobile ? '50%' : '30%'
      }}
    >
      <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
        <div>
          <img
            style={{ width: '100%', objectFit: 'contain' }}
            src={BoxModel}
            alt=''
          />
          <h3
            style={{
              fontSize: isSeaFreight ? '16px' : '24px',
              fontWeight: '700',
              color: '#22428F',
              textAlign: 'center'
            }}
            className='mt-3'
          >
            {isSeaFreight ? 'Package' : 'Box'} {box}
          </h3>
        </div>
      </div>
    </div>
  );
};

export const BoxModelForm = ({
  boxItems,
  deleteBox,
  deleteItem,
  handleChange,
  errors,
  setFieldValue,
  addItem,
  box,
  boxes
}) => {
  const [shipmentDetail] = useRecoilState(saveShipmentDetailsState);

  return (
    <div className='item-description__mains'>
      {boxItems?.map((item, index) => {
        const itemIndex = item?.itemIndex;

        const message = getItemCategoryMessage(item?.category, shipmentDetail);

        return (
          <div
            key={index}
            style={{
              borderTop: index === 0 ? '' : '1px solid #e2e4e8',
              borderTopLeftRadius: index === 0 ? '' : '8px',
              borderTopRightRadius: index === 0 ? '' : '8px'
            }}
            className='item-description__main'
          >
            <div className='item-description__main__wrap'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <h3
                  style={{
                    color: '#545859',
                    fontWeight: 700
                  }}
                >
                  Item {index + 1}
                </h3>
                {boxes.length > 1 && (
                  <div
                    style={{
                      alignSelf: 'flex-end !important'
                    }}
                    className='item-description__main__action'
                  >
                    <Button variant='text' onClick={() => deleteBox(box)}>
                      <span>Delete Box {box}</span>
                      <RemoveCircle />
                    </Button>
                  </div>
                )}
              </div>

              <label className='item-description__label'>
                Enter details about the item you want to ship
              </label>
              <div
                style={{
                  alignSelf: 'flex-end !important'
                }}
                className='item-description__main__action'
              >
                {boxItems?.length > 1 && (
                  <Button variant='text' onClick={() => deleteItem(index, box)}>
                    <span>Remove item</span>
                    <RemoveCircle />
                  </Button>
                )}
              </div>
              <div className='item-description__main__grid'>
                <a
                  href={'https://topship.africa/prohibited-items'}
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    textDecoration: 'none',
                    transform: 'none'
                  }}
                  className='item-description__main__grid__helper category-helper'
                >
                  SEE LIST OF PROHIBITED & RESTRICTED ITEMS.
                </a>
                <Grid
                  container
                  columnSpacing='10px'
                  rowSpacing='16px'
                  className='item-description__main__grid__form'
                >
                  <CustomAutoComplete
                    options={itemCategories}
                    getOptionLabel={option => (option ? option?.category : '')}
                    isOptionEqualToValue={(option, value) =>
                      option?.value === value?.value
                    }
                    renderOption={(props, option) => (
                      <Box key={props.id} component='li' {...props}>
                        {option?.category}
                      </Box>
                    )}
                    value={
                      itemCategories.find(
                        category => category.value === item.category
                      ) || { category: '', value: '' }
                    }
                    noOptionsText={'Item Category not found'}
                    onChange={(event, value, reason) => {
                      if (reason === 'clear') {
                        setFieldValue(`items.${itemIndex}.category`, '');
                      } else {
                        setFieldValue(
                          `items.${itemIndex}.category`,
                          value?.value
                        );
                      }
                    }}
                    textfieldProps={{
                      lg: 6,
                      md: 6,
                      name: `items.${itemIndex}.category`,
                      customLabel: 'Select Item Category',
                      containerClassName: 'item-description__main__input',
                      value: item.category,
                      error: Boolean(errors.items?.[itemIndex]?.category),
                      helperText: errors.items?.[itemIndex]?.category,
                      onChange: event => {
                        return handleChange(event);
                      }
                    }}
                  />
                  <div className='mobile-alert'>
                    {itemCategoryRules[item.category] && message && (
                      <Alert
                        severity={'info'}
                        className='item-description__main__alert'
                        sx={{
                          fontSize: '1.5rem',
                          fontFamily: 'General Sans',
                          marginTop: '10px',
                          backgroundColor: '#e6f2fe',
                          color: '#22428F',
                          border: 'none',
                          '& .MuiAlert-icon': {
                            color: '#22428F'
                          }
                        }}
                      >
                        {message}
                      </Alert>
                    )}
                  </div>
                  <Input
                    lg={6}
                    md={12}
                    name={`items.${itemIndex}.value`}
                    placeholder='50,000.00'
                    customLabel='Item Value (₦)'
                    value={`${numberWithCommas(item?.value || 0)}`}
                    onChange={event => {
                      const { value, name } = event.target;
                      if (!(value && value.length)) {
                        handleChange({
                          target: { name, value: '' }
                        });
                      } else if (!isNaN(value.split(',').join(''))) {
                        const itemValue = parseInt(
                          value.split(',').join(''),
                          10
                        );
                        handleChange({
                          target: { name, value: itemValue }
                        });
                      }
                    }}
                    containerClassName='item-description__main__input'
                    error={Boolean(errors?.items?.[itemIndex]?.value)}
                    helperText={errors?.items?.[itemIndex]?.value}
                  />
                </Grid>
                <div className='web-alert'>
                  {itemCategoryRules[item.category] && message && (
                    <Alert
                      severity={'info'}
                      className='item-description__main__alert'
                      sx={{
                        fontSize: '1.5rem',
                        fontFamily: 'General Sans',
                        marginTop: '10px',
                        backgroundColor: '#e6f2fe',
                        color: '#22428F',
                        border: 'none',
                        '& .MuiAlert-icon': {
                          color: '#22428F'
                        }
                      }}
                    >
                      {message}
                    </Alert>
                  )}
                </div>

                <a
                  href={
                    'https://www.docdroid.net/AX2ViKa/labelling-a-package-for-shipping-pdf'
                  }
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    textDecoration: 'none',
                    transform: 'none'
                  }}
                  className='item-description__main__grid__helper'
                >
                  SEE HOW TO LABEL YOUR PACKAGE.
                </a>
                <Grid container>
                  <Input
                    fullWidth
                    multiline
                    minRows={4}
                    name={`items.${itemIndex}.description`}
                    value={item.description}
                    onChange={e => handleChange(e)}
                    customLabel='Detailed Item Description'
                    containerClassName='item-description__main__input'
                    error={Boolean(errors?.items?.[itemIndex]?.description)}
                    helperText={errors?.items?.[itemIndex]?.description}
                    placeholder='A piece of text that clearly describes the item being packaged for shipping, it should leave no room for guesses so that TopShip can know how best to handle it'
                  />
                </Grid>
                <div className=''>
                  <VolumetricWeight
                    checkBoxName={`items.${itemIndex}.isVolumetric`}
                    isActive={item.isVolumetric}
                    handleToggleSwitch={() =>
                      setFieldValue(
                        `items.${itemIndex}.isVolumetric`,
                        !item.isVolumetric
                      )
                    }
                  />
                </div>
                {item.isVolumetric ? (
                  <div
                    style={{
                      marginTop: '30px'
                    }}
                  >
                    <Grid
                      container
                      columnSpacing='10px'
                      rowSpacing='16px'
                      className='item-description__main__grid__form'
                    >
                      <Input
                        lg={6}
                        md={12}
                        type='number'
                        name={`items.${itemIndex}.quantity`}
                        placeholder='20'
                        value={item.quantity}
                        onChange={e => {
                          if (isNaN(e.target.value)) {
                            return handleChange({
                              target: {
                                name: e.target.name,
                                value: ''
                              }
                            });
                          } else {
                            handleChange(e);
                          }
                        }}
                        customLabel='Quantity'
                        error={Boolean(errors?.items?.[itemIndex]?.quantity)}
                        helperText={errors?.items?.[itemIndex]?.quantity}
                        containerClassName='item-description__main__input'
                        inputProps={{
                          min: 1
                        }}
                      />
                      <Input
                        lg={6}
                        md={12}
                        containerClassName='item-description__main__input'
                        type='number'
                        name={`items.${itemIndex}.width`}
                        customLabel='Width (CM)'
                        placeholder='Enter Width (CM)'
                        value={item.width}
                        onChange={e => {
                          if (isNaN(e.target.value)) {
                            return handleChange({
                              target: {
                                name: e.target.name,
                                value: ''
                              }
                            });
                          } else {
                            handleChange(e);
                          }
                        }}
                        error={Boolean(errors?.items?.[itemIndex]?.width)}
                        helperText={errors?.items?.[itemIndex]?.width}
                        inputProps={{
                          min: 1,
                          step: 'any'
                        }}
                      />
                    </Grid>{' '}
                    <Grid
                      container
                      columnSpacing='10px'
                      rowSpacing='16px'
                      className='item-description__main__grid__form mt-1'
                    >
                      <Input
                        lg={6}
                        md={12}
                        containerClassName='item-description__main__input'
                        type='number'
                        name={`items.${itemIndex}.length`}
                        customLabel='Length (CM)'
                        placeholder='Enter Length (CM)'
                        value={item.length}
                        onChange={e => {
                          if (isNaN(e.target.value)) {
                            return handleChange({
                              target: {
                                name: e.target.name,
                                value: ''
                              }
                            });
                          } else {
                            handleChange(e);
                          }
                        }}
                        error={Boolean(errors?.items?.[itemIndex]?.length)}
                        helperText={errors?.items?.[itemIndex]?.length}
                        inputProps={{
                          min: 1,
                          step: 'any'
                        }}
                      />
                      <Input
                        lg={6}
                        md={12}
                        containerClassName='item-description__main__input'
                        type='number'
                        name={`items.${itemIndex}.height`}
                        customLabel='Height (CM)'
                        placeholder='Enter Height'
                        value={item.height}
                        onChange={e => {
                          if (isNaN(e.target.value)) {
                            return handleChange({
                              target: {
                                name: e.target.name,
                                value: ''
                              }
                            });
                          } else {
                            handleChange(e);
                          }
                        }}
                        error={Boolean(errors?.items?.[itemIndex]?.height)}
                        helperText={errors?.items?.[itemIndex]?.height}
                        inputProps={{
                          min: 1,
                          step: 'any'
                        }}
                      />
                    </Grid>
                  </div>
                ) : (
                  <>
                    <a
                      href={'https://docdro.id/khtz9i8'}
                      target='_blank'
                      rel='noreferrer'
                      style={{
                        textDecoration: 'none',
                        transform: 'none'
                      }}
                      className='item-description__main__grid__helper'
                    >
                      NEED HELP?
                    </a>

                    <Grid
                      container
                      columnSpacing='10px'
                      rowSpacing='16px'
                      className='item-description__main__grid__form'
                    >
                      <Input
                        lg={6}
                        md={12}
                        type='number'
                        name={`items.${itemIndex}.quantity`}
                        placeholder='20'
                        value={item.quantity}
                        onChange={e => {
                          if (isNaN(e.target.value)) {
                            return handleChange({
                              target: {
                                name: e.target.name,
                                value: ''
                              }
                            });
                          } else {
                            handleChange(e);
                          }
                        }}
                        customLabel='Quantity'
                        error={Boolean(errors?.items?.[itemIndex]?.quantity)}
                        helperText={errors?.items?.[itemIndex]?.quantity}
                        containerClassName='item-description__main__input'
                        inputProps={{
                          min: 1
                        }}
                      />
                      <Input
                        lg={6}
                        md={12}
                        type='number'
                        name={`items.${itemIndex}.weight`}
                        customLabel='Weight (KG)'
                        placeholder='Enter Weight'
                        value={item.weight}
                        onChange={e => {
                          if (isNaN(e.target.value)) {
                            return handleChange({
                              target: {
                                name: e.target.name,
                                value: ''
                              }
                            });
                          } else {
                            handleChange(e);
                          }
                        }}
                        error={Boolean(errors?.items?.[itemIndex]?.weight)}
                        helperText={errors?.items?.[itemIndex]?.weight}
                        containerClassName='item-description__main__input'
                        inputProps={{
                          min: 1,
                          step: 'any'
                        }}
                      />
                    </Grid>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
      <div className='item-description__action'>
        <Button
          variant='text'
          disabled={boxItems?.length >= 5}
          onClick={() => addItem(box)}
        >
          <span>Add another item</span>
          <AddCircle />
        </Button>
      </div>
      <div className='' />

      <StickyNoteAlert isVolumetric={true} />
    </div>
  );
};

import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { shipmentTypeState, warningState } from '../../../recoil/atoms';
import blueEllipse from '../../../assets/svg/blue-ellipse.svg';
import greenBox from '../../../assets/svg/green-box.svg';
import getPlan from '../../../utilities/getPlans';
import { toCurrency } from '../../../utilities/to-currency';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import ShipmentStatus from '../../../components/Commons/shipment-status';
import { useCancelShipmentMutation } from '../../../operations/mutations';
import { useGetShipmentsQuery } from '../../../operations/queries';
import DeleteOverlay from '../../../components/delete-overlay';
import Moment from 'react-moment';
import { useSendAlert } from '../../../utilities/send-alert';
import NewTrackingModal from '../../../components/modals/new-tracking-modal';
import {
  itemCategories,
  itemCategoriesChinaImport
} from '../../../constants/item-categories';

const PastShipmentDetailsModal = ({
  openModal,
  setOpenModal,
  shipmentItem,
  setShipmentItem,
  currentPage,
  isChinaImport,
  isShopnship = false
}) => {
  const [shipmentType] = useRecoilState(shipmentTypeState);
  const getShipments = useGetShipmentsQuery();
  const sendAlert = useSendAlert();
  const [, setShow] = useRecoilState(warningState);
  const [openTrackingModal, setOpenTrackingModal] = useState(false);

  const [cancelShipment] = useCancelShipmentMutation(data => {
    if (data?.cancelShipment?.id) {
      if (shipmentItem?.shipmentStatus === 'Draft') {
        sendAlert('Shipment cancelled!');
      } else {
        sendAlert('Shipment cancellation requested!');
      }
      getShipments(currentPage, 10).then(shipments => {
        const newShipmentItem = shipments?.getShipments?.list?.find(
          s => s?.id === data?.cancelShipment?.id
        );
        setShipmentItem(newShipmentItem);
      });
    }
  });

  return (
    <>
      <div
        className={`past-shipment-detail-modal ${
          openModal ? 'show-modal' : ''
        }`}
      >
        <div className='past-shipment-detail-modal-container'>
          <div className='past-shipment-modal-content'>
            <div className='past-shipment-detail-modal-header'>
              <h2>Shipment Information</h2>
              <div className='close-button'>
                <IconButton
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  <Close id='close-button' />
                </IconButton>
              </div>
            </div>

            <div className='past-shipment-detail-delivery-item'>
              <div className='delivery-status-item'>
                <div className='delivery-status-item-content'>
                  <div className='content-item'>
                    <span className='content-label'>Est. Delivery Date</span>
                    <span className='content-value'>
                      <Moment format='DD/MM/YYYY '>
                        {new Date(shipmentItem?.estimatedDeliveryDate)}
                      </Moment>
                    </span>
                  </div>
                  <div className='content-item'>
                    <ShipmentStatus shipment={shipmentItem} />
                  </div>
                </div>
                <div className='delivery-status-item-content'>
                  <div className='content-item'>
                    <span className='content-label'>Shipment Method</span>
                    <span className='content-value'>
                      {isShopnship
                        ? shipmentItem?.pricingTier
                        : getPlan('shipping', shipmentItem?.pricingTier)?.name}
                    </span>
                  </div>
                  <div className='content-item'>
                    <span className='content-label'>Amount Paid</span>
                    <span className='content-value'>
                      {toCurrency(shipmentItem?.totalCharge / 100)}
                    </span>
                  </div>
                </div>
                {shipmentItem?.budgetDeliveryCharge ? (
                  <div className='delivery-status-item-content'>
                    <div className='content-item'>
                      <span className='content-label'>
                        Budget Delivery Method
                      </span>
                      <span className='content-value'>
                        {shipmentItem?.budgetDeliveryCharge
                          ? getPlan('shipping', shipmentItem?.pricingTier)
                              ?.budgetOptionWithExpress
                          : getPlan('shipping', shipmentItem?.pricingTier)
                              ?.budgetOptionFree}
                      </span>
                    </div>
                    <div className='content-item'>
                      <span className='content-label'>Amount Paid</span>
                      <span className='content-value'>
                        {toCurrency(shipmentItem?.budgetDeliveryCharge / 100)}
                      </span>
                    </div>
                  </div>
                ) : null}
                <div className='delivery-status-item-content'>
                  <div className='content-item'>
                    <span className='content-label'>Insurance Cover</span>
                    <span className='content-value'>
                      {getPlan('insurance', shipmentItem?.insuranceType)?.name}
                    </span>
                  </div>
                  <div className='content-item'>
                    <span className='content-label'>Amount Paid</span>
                    <span className='content-value'>
                      {toCurrency(shipmentItem?.insuranceCharge / 100)}
                    </span>
                  </div>
                </div>
                {isShopnship && (
                  <>
                    <div className='delivery-status-item-content'>
                      <div className='content-item'>
                        <span className='content-label'>Initial Charge</span>
                        <span className='content-value'>
                          {toCurrency(shipmentItem?.initialCharge / 100)}
                        </span>
                      </div>
                    </div>
                    <div className='delivery-status-item-content'>
                      <div className='content-item'>
                        <span className='content-label'>Additional Charge</span>
                        <span className='content-value'>
                          {toCurrency(shipmentItem?.finalCharge / 100)}
                        </span>
                      </div>
                    </div>
                    <div className='delivery-status-item-content'>
                      <div
                        style={{
                          width: '100%'
                        }}
                        className='content-item'
                      >
                        <span className='content-label'>Item Information</span>
                        <table>
                          <thead>
                            <tr>
                              <td
                                style={{
                                  textAlign: 'center',
                                  padding: '5px 0',
                                  fontSize: '1.2rem'
                                }}
                                className='title'
                              >
                                Item Weight
                              </td>
                              {isChinaImport && (
                                <td
                                  style={{
                                    textAlign: 'center',
                                    padding: '5px 0',
                                    fontSize: '1.2rem'
                                  }}
                                  className='title'
                                >
                                  Category
                                </td>
                              )}
                              {!isChinaImport && (
                                <td
                                  style={{
                                    textAlign: 'center',
                                    padding: '5px 0',
                                    fontSize: '1.2rem'
                                  }}
                                  className='title'
                                >
                                  Proof of Id
                                </td>
                              )}
                              {!isChinaImport && (
                                <td
                                  style={{
                                    textAlign: 'center',
                                    padding: '5px 0 ',
                                    fontSize: '1.2rem'
                                  }}
                                  className='title'
                                >
                                  Evidence of purchase
                                </td>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {shipmentItem?.items?.map(item => {
                              const currentItemCategory = isChinaImport
                                ? itemCategoriesChinaImport
                                : itemCategories;

                              return (
                                <tr>
                                  <td
                                    className='description item-table'
                                    style={{
                                      textAlign: 'center',
                                      padding: '5px'
                                    }}
                                  >
                                    {item.estimatedWeight}KG
                                  </td>
                                  {isChinaImport && (
                                    <td
                                      className='description item-table'
                                      style={{
                                        textAlign: 'center',
                                        padding: '5px'
                                      }}
                                    >
                                      {currentItemCategory?.find(
                                        category =>
                                          category?.value === item?.category ||
                                          category?.category === item?.category
                                      )?.category || item?.category?.category}
                                    </td>
                                  )}
                                  {!isChinaImport && (
                                    <td
                                      className='center'
                                      style={{
                                        padding: '10px 5px',
                                        textAlign: 'center'
                                      }}
                                    >
                                      <a
                                        href={
                                          !item.proofOfId
                                            ? shipmentItem.proofOfId
                                            : item.proofOfId
                                        }
                                      >
                                        <button className='document-button'>
                                          View Document
                                        </button>
                                      </a>
                                    </td>
                                  )}
                                  {!isChinaImport && (
                                    <td
                                      className='center'
                                      style={{
                                        padding: '10px 5px',
                                        textAlign: 'center'
                                      }}
                                    >
                                      <a
                                        href={
                                          !item.evidenceOfPurchase
                                            ? shipmentItem.evidenceOfPurchase
                                            : item.evidenceOfPurchase
                                        }
                                      >
                                        <button className='document-button'>
                                          View Document
                                        </button>
                                      </a>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='past-shipment-detail-delivery-item'>
              <span className='past-shipment-location-label'>
                <img src={blueEllipse} alt='ellipse' />{' '}
                {shipmentType.name && shipmentType.name.includes('Drop')
                  ? 'Drop Off'
                  : 'Pick up from'}
              </span>

              <div className='past-shipment-address past-shipment-node-origin'>
                <div className='request__form__node__address'>
                  <h4>{shipmentItem?.senderDetail.name}</h4>
                  <p className='past-shipment-detail-address'>
                    {shipmentItem?.senderDetail.addressLine1}
                  </p>
                  <p>{shipmentItem?.senderDetail.email}</p>
                  <p>{shipmentItem?.senderDetail.phoneNumber}</p>
                </div>
              </div>

              <span className='past-shipment-location-label'>
                <img src={greenBox} alt='ellipse' /> Deliver To
              </span>

              <div className='past-shipment-address'>
                <div className='request__form__node__address'>
                  <h4>{shipmentItem?.receiverDetail.name}</h4>
                  <p className='past-shipment-detail-address'>
                    {shipmentItem?.receiverDetail.addressLine1}
                  </p>
                  <p>{shipmentItem?.receiverDetail.email}</p>
                  <p>{shipmentItem?.receiverDetail.phoneNumber}</p>
                </div>
              </div>
            </div>
            {!isShopnship && (
              <div className='button-group'>
                <button
                  className='track-shipment'
                  onClick={() => {
                    setOpenTrackingModal(true);
                  }}
                >
                  Track shipment
                </button>
                {!['CancellationPending', 'Cancelled'].includes(
                  shipmentItem?.shipmentStatus
                ) && (
                  <button
                    className='cancel-shipment'
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Cancel shipment
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteOverlay
        warningMessage={'Are you sure you want to cancel this shipment?'}
        deleteText={'Cancel shipment'}
        onDelete={() => cancelShipment(shipmentItem.id)}
      />

      {!isShopnship && (
        <NewTrackingModal
          trackingId={shipmentItem?.trackingId}
          openModal={openTrackingModal}
          setOpenModal={setOpenTrackingModal}
        />
      )}
    </>
  );
};

export default PastShipmentDetailsModal;

import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthToken } from '../token';
import { verifyToken } from '../authorization';

export const useShowAuthPage = () => {
  const [authToken] = useAuthToken();
  const user = verifyToken(authToken);

  const renderPage = page => {
    if (user.success) {
      return <Redirect to='/' />;
    }
    return page;
  };
  return [renderPage];
};

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/svg/blue-logo.svg';
import { useSlideMenu } from '../../constants/slide-menu';
import { useGetUserPayload } from '../../utilities/get-payload';

const Header = () => {
  const [show, setShow] = useState(false);
  const [slideMenu] = useSlideMenu();
  const history = useHistory();
  const userPayload = useGetUserPayload();

  return (
    <div className='header-container'>
      <nav className='header'>
        <figure onClick={() => history.push('/')} style={{ cursor: 'pointer' }}>
          <img src={logo} alt='logo' />
        </figure>
        {userPayload.fullName && (
          <div onClick={() => setShow(!show)}>
            <div
              className='header__slide-menu'
              onClick={() => {
                if (show) {
                  setShow(false);
                }
              }}
            >
              <input id='slide-menu' type='checkbox' checked={show} />
              <label htmlFor='slide-menu3' onClick={() => setShow(!show)}>
                <div className='header__slide-menu__content'>
                  <ul className='header__slide-menu__list'>
                    <h2>Menu</h2>
                    {slideMenu.map(Menu => (
                      <div
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        {Menu}
                      </div>
                    ))}
                  </ul>
                </div>
              </label>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Header;

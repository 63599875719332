import React from 'react';
import { useRecoilState } from 'recoil';
import { shipmentRequestState, warningState } from '../recoil/atoms';

const DeleteOverlay = ({ warningMessage, onDelete, onCancel, deleteText }) => {
  const [show, setShow] = useRecoilState(warningState);
  const [, setRequestPage] = useRecoilState(shipmentRequestState);

  const handleDelete = () => {
    setShow(false);
    onDelete && onDelete();
  };

  const handleCancel = () => {
    setShow(false);
    setRequestPage(0);
    onCancel && onCancel();
  };

  return (
    <div className={`delete-overlay ${show && 'delete-overlay--show'}`}>
      <div
        className={`delete-overlay__content ${
          show && 'delete-overlay__content--show'
        }`}
      >
        <figure>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z' />
          </svg>
        </figure>
        <h2>Are you sure?</h2>
        <p>{warningMessage || 'You will not be able to recover this data'}</p>

        <div className='delete-overlay__buttons'>
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleDelete}>{deleteText || 'Delete'}</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteOverlay;

import React, { useState } from 'react';
import { IconButton, Radio } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ItemWeightBand } from '../../constants/book-shipment-contants';
import Input from '../new-dashboard/custom-input';
import { useFormik } from 'formik';
import * as yup from 'yup';

const ItemWeightBandModal = ({
  openPrompt,
  setOpenPrompt,
  onCloseAndProceedPrompt,
  onClosePrompt,
  selectedItemBand,
  setSelectedItemBand
}) => {
  const validationSchema = yup.object().shape({
    boxType: yup.string().required('Please select box type'),
    manualBoxType: yup.string().when('boxType', {
      is: 'Others',
      then: yup.string().required('Please include weight band')
    })
  });

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      boxType: 'Others',
      manualBoxType: ''
    },
    validationSchema,
    onSubmit: values => {
      onCloseAndProceedPrompt(values);
    }
  });
  const handleSelectBand = (index, band) => {
    setFieldValue('boxType', band.boxType);
    setSelectedItemBand({
      boxType: band.boxType,
      width: band.width,
      height: band.height,
      length: band.length
    });
  };
  return (
    <>
      <div
        className={`in-app__prompt fade isItemBand ${openPrompt ? ' show' : ''}
      `}
      >
        <form onSubmit={handleSubmit}>
          <div className='in-app__prompt__wrap isItemBand'>
            <div className='in-app__prompt__header mt-2'>
              <h2>Item Weight Bands Description</h2>
              <IconButton
                onClick={() => {
                  setOpenPrompt(false);
                }}
              >
                <p>
                  <Close id='close-button' />
                </p>
              </IconButton>
            </div>
            <div
              className={`in-app__prompt__body
              `}
              style={{
                borderTop: '1px solid #e2e4e8'
              }}
            >
              <p
                style={{
                  fontSize: '15px',
                  lineHeight: '26px'
                }}
              >
                Use this guide to determine your package’s dimensions and
                weight. If none apply, please enter manually.
              </p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  gap: '10px',
                  marginTop: '20px'
                }}
              >
                {ItemWeightBand.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => handleSelectBand(index, item)}
                      className={`custom-box ${
                        item.boxType === selectedItemBand.boxType
                          ? ' active'
                          : ''
                      }`}
                    >
                      <Radio
                        checked={item.boxType === selectedItemBand.boxType}
                        sx={{ transform: 'scale(1.5)' }}
                      />
                      {item.boxImg && (
                        <img
                          style={{
                            width: '120px',
                            objectFit: 'contain'
                          }}
                          src={item.boxImg}
                          alt='box'
                        />
                      )}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: '5px',
                          fontSize: '14px',
                          paddingBottom: '10px'
                        }}
                      >
                        <b>{item.boxType}</b>

                        <span>
                          {item.size
                            ? item.size
                            : `${item.width}" x ${item.length}" x ${item.height}" CM`}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {selectedItemBand.boxType === 'Others' ? (
            <div className='mt-4 px-4'>
              <p
                style={{
                  fontSize: '15px',
                  lineHeight: '26px'
                }}
              >
                You clicked <b>‘others’</b> so kindly describe your weight band
                to your best knowledge below:
              </p>
              <Input
                error={Boolean(errors.manualBoxType)}
                helperText={errors.manualBoxType}
                value={values.manualBoxType}
                onChange={handleChange}
                customLabel='Describe weight band (CM)'
                name='manualBoxType'
                id='manualBoxType'
                type='text'
                containerClassName='new-addresses__modal__item__input mt-3'
                placeholder='18 X 18 X 54 CM'
                fullWidth
                lg={12}
                md={12}
              />
            </div>
          ) : null}
          <div className='in-app__prompt__footer mb-1 mt-1'>
            <div className='in-app__prompt__footer__wrap'>
              <div className='in-app__prompt__footer__actions isSaver'>
                <button onClick={onClosePrompt} className='select-option'>
                  Cancel
                </button>
                <button type='submit' className=''>
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div
        className={`in-app__prompt__backdrop fade${openPrompt ? ' show' : ''}`}
      ></div>
    </>
  );
};

export default ItemWeightBandModal;

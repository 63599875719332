import React from 'react';

export const resourcesData = [
  {
    url: 'https://topship.africa/shipping-checklist',
    label: 'Smart Shipping Checklist'
  },
  {
    url: 'https://topship.africa/prohibited-items',
    label: 'Prohibited Items'
  },
  {
    url: 'https://topship.africa/non-served-countries',
    label: 'Non-served countries and territories'
  },
  {
    url: 'https://topship.africa/shipping-documentation',
    label: 'Shipping Documentation'
  }
];

function GuidesAndResourcesComponent() {
  return (
    <div>
      <div className='resources'>
        <div className='resources__wrapper'>
          <p>Access resources concerning shipping various packages.</p>
          <div className='resources__content'>
            <div className='all-resources-div'>
              {resourcesData.map(resource => (
                <div className='resource-card' key={resource.label}>
                  <p>{resource.label}</p>
                  <a
                    className='view'
                    href={resource.url}
                    target='_blank'
                    rel='noreferrer'
                  >
                    View
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuidesAndResourcesComponent;

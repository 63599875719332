import React, { useCallback, useMemo } from 'react';
import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { verificationStepsState } from '../../../../../recoil/atoms';

const VerificationModal = ({ openModal, setOpenModal }) => {
  const [steps, setSteps] = useRecoilState(verificationStepsState);
  const activeStep = useMemo(() => {
    if (Array.isArray(steps)) {
      return steps.find(step => step.isActive);
    }
    return null;
  }, [steps]);

  const activeIndex = useMemo(() => {
    if (activeStep && Array.isArray(steps)) {
      return steps.findIndex(step => step === activeStep);
    }
    return -1;
  }, [activeStep, steps]);

  const nextStep = useCallback(
    (isCompleted = undefined) => {
      if (activeIndex === 5) {
        setOpenModal(false);
      } else {
        setSteps(prevSteps =>
          prevSteps.map((step, index) => ({
            ...step,
            isActive: index === activeIndex + 1
          }))
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [steps, openModal, activeIndex, setOpenModal]
  );

  const previousStep = useCallback(() => {
    if (activeIndex === 0) {
      return setOpenModal(false);
    } else {
      setSteps(
        steps?.map((step, index) => {
          return index === activeIndex - 1
            ? { ...step, isActive: true }
            : { ...step, isActive: false };
        })
      );
    }
  }, [steps, setSteps, setOpenModal, activeIndex]);

  const ActiveComponent = useMemo(() => {
    return () =>
      activeStep?.component({
        nextStep,
        previousStep,
        activeStep
      });
  }, [activeStep, nextStep, previousStep]);

  return (
    <>
      <div className={`verification__modal fade${openModal ? ' show' : ''}`}>
        <div className='verification__modal__wrap'>
          <div className='verification__modal__header'>
            <Button variant='text' className='verification__modal__action'>
              <Close onClick={() => setOpenModal(false)} />
            </Button>
            <h4 className='verification__modal__heading'>
              Complete Verification
            </h4>
            <div className='verification__modal__steps'>
              {Array.isArray(steps) &&
                steps.map((step, index) => (
                  <div
                    key={index}
                    className={`verification__modal__step${
                      step.isActive || index <= activeIndex ? ' complete' : ''
                    }`}
                    onClick={() =>
                      setSteps(
                        steps.map((step, stepIndex) =>
                          stepIndex === index
                            ? { ...step, isActive: true }
                            : { ...step, isActive: false }
                        )
                      )
                    }
                  ></div>
                ))}
            </div>
          </div>
          <ActiveComponent />
        </div>
      </div>
      <div
        className={`new-tracking__modal__backdrop fade${
          openModal ? ' show' : ''
        }`}
      ></div>
    </>
  );
};

export default VerificationModal;

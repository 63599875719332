import {
  Globe,
  DoorToDoor,
  Support,
  ProfitIcon,
  MoreRoutesIcon,
  ChargesIcon,
  BrandExperienceIcon,
  MuchMoreIcon
} from './asset-contants';

export const IntroItemsState = [
  {
    icon: Globe,
    header: 'Send items to any location worldwide',
    content: `Ship to anyone anywhere in the world with full control of
              your price and delivery times. No matter how far or how
              large, we’ve got your shipment covered.`,
    display: true
  },
  {
    icon: DoorToDoor,
    header: 'Receive items from anyone, delivered to your doorstep',
    content: `Receive items from your family abroad or shop from your
              favourite international stores like Zara and Amazon using
              our Use Shop & Ship feature. We pick up your items and
              bring them to your doorstep.`,
    display: false
  },
  {
    icon: Support,
    header: 'Customer support you can rely on',
    content: `Our customer support representatives are equipped to provide
              you with answers and real-time updates on your deliveries.
              We are available everyday from 9 a.m to 6 p.m. WAT. Reach us
              at hello@topship.africa or call +234-908-077-7728.`,
    display: false
  }
];

export const gatewayIntroItems = [
  {
    icon: ProfitIcon,
    header: 'Increase Profit',
    content: `Set your prices and earn money on every shipment. Get discounted rates on your frequently used routes.`,
    display: true
  },
  {
    icon: MoreRoutesIcon,
    header: 'Access more routes',
    content: `Tap into our network of vetted routes and trusted courier partners to export and import to more cities worldwide! `,
    display: false
  },
  {
    icon: ChargesIcon,
    header: 'No Unexpected charges',
    content: `Gateway proactively alerts you to weight discrepancies before your package leaves the country, allowing you to control your expenses fully.`,
    display: false
  },
  {
    icon: BrandExperienceIcon,
    header: 'Strengthen Your Brand & Customer Experience',
    content: `Generate instant waybills and shipping quotes customised with your business name and logo.`,
    display: false
  },
  {
    icon: MuchMoreIcon,
    header: '.. & so much more!',
    content: `Create your free account and start shipping to unlock more benefits!`,
    display: false
  }
];

export const accountType = [
  { value: 'Personal', label: 'Personal' },
  { value: 'Business', label: 'Business' },
  { value: 'LogisticsBusiness', label: 'Logistics Company' }
];
export const accountTypeLspUser = [
  { value: 'Personal', label: 'Personal' },
  { value: 'Business', label: 'Business' }
];

export const source = [
  { value: 'Event', label: 'Event' },
  {
    value: 'Referred by a friend/family',
    label: 'Referred by a friend/family'
  },
  { value: 'Email', label: 'Email' },
  { value: 'Radio', label: 'Radio' },
  { value: 'Billboard', label: 'Billboard' },
  { value: 'Webinar', label: 'Webinar' },
  { value: 'Online Ad', label: 'Online Ad' },
  { value: 'YouTube', label: 'YouTube' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Twitter', label: 'Twitter' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'ChowDeck', label: 'ChowDeck' },
  { value: 'Arise TV', label: 'Arise TV' },
  { value: 'Business Day', label: 'Business Day' },
  { value: 'Channels TV', label: 'Channels TV' },
  { value: 'TechCabal', label: 'TechCabal' },
  { value: 'The Guardian', label: 'The Guardian' },
  { value: 'This Day News', label: 'This Day News' },
  { value: 'Punch Newspaper', label: 'Punch Newspaper' },
  { value: 'Reddit', label: 'Reddit' },
  { value: 'Quora', label: 'Quora' },
  { value: 'Google search', label: 'Google search' },
  { value: 'Other', label: 'Other' }
];

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import {
  chinaImportState,
  multiplePhoneNumbersState,
  multipleSaveShipmentLoadingState,
  multipleShipmentCounponListState,
  multipleShipmentRatesState,
  multipleShipmentResultsState,
  saveOrderSummaryPathState,
  saveShipmentDetailsState,
  saveShopNShipRateState,
  selectedInsurancePlan,
  selectedRateState,
  shipmentDestinationSelectionState,
  shipmentHubSelectionState,
  shipmentMethodSelectionState,
  shipmentOptionSelectionState,
  shipmentRequestState,
  shopNShipDetailsState,
  shopNShipResultState,
  shopnshipSelectedCountryState,
  shopnshipSelectedProductState
} from '../recoil/atoms';

export default function useClearLocalStorage() {
  const resetShipmentDetail = useResetRecoilState(saveShipmentDetailsState);
  const resetShopnshipDetail = useResetRecoilState(shopNShipDetailsState);
  const resetShopnshipResult = useResetRecoilState(shopNShipResultState);
  const resetRequestPage = useResetRecoilState(shipmentRequestState);
  const resetSelectedOption = useResetRecoilState(shipmentOptionSelectionState);
  const resetSelectedMethod = useResetRecoilState(shipmentMethodSelectionState);
  const resetSelectedHub = useResetRecoilState(shipmentHubSelectionState);
  const resetChinaImport = useResetRecoilState(chinaImportState);
  const resetInsurancePlan = useResetRecoilState(selectedInsurancePlan);
  const resetSelectedRate = useResetRecoilState(selectedRateState);
  const resetSelectedProduct = useResetRecoilState(
    shopnshipSelectedProductState
  );
  const resetSelectedCountry = useResetRecoilState(
    shopnshipSelectedCountryState
  );
  const resetShopnshipRate = useResetRecoilState(saveShopNShipRateState);
  const resetMultipleShipmentLoading = useResetRecoilState(
    multipleSaveShipmentLoadingState
  );
  const resetMultipleShimpentRates = useResetRecoilState(
    multipleShipmentRatesState
  );
  const resetMultipleShimpentResults = useResetRecoilState(
    multipleShipmentResultsState
  );
  const resetOrderSummaryPathState = useResetRecoilState(
    saveOrderSummaryPathState
  );
  const resetMultiplePhoneNumbersState = useResetRecoilState(
    multiplePhoneNumbersState
  );
  const resetSelectedDestinationType = useResetRecoilState(
    shipmentDestinationSelectionState
  );

  const resetCouponListState = useResetRecoilState(
    multipleShipmentCounponListState
  );

  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const searchParamsId = searchParams.get('lsp');

  return extra => {
    resetChinaImport();
    resetShipmentDetail();
    resetRequestPage();
    resetSelectedOption();
    resetSelectedMethod();
    resetSelectedDestinationType();
    resetSelectedHub();
    resetInsurancePlan();
    resetSelectedRate();
    resetOrderSummaryPathState();
    resetMultipleShimpentRates();
    resetMultipleShimpentResults();
    resetSelectedProduct();
    resetSelectedCountry();
    resetMultiplePhoneNumbersState();
    resetShopnshipDetail();
    resetMultipleShipmentLoading();
    resetShopnshipRate();
    resetCouponListState();
    resetShopnshipResult();
    localStorage.clear();
    localStorage.setItem('lspId', searchParamsId);

    extra && extra();
  };
}

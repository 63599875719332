import React, { useState } from 'react';
import Wrapper from '../../../components/Layouts/wrapper';
import NewTransactions from '../Transactions/new-transactions.list';
import { useGetWalletBalanceQuery } from '../../../operations/queries';
import { toCurrency } from '../../../utilities/to-currency';
import NewFundWalletModal from '../../../components/modals/new-fund-wallet-modal';
import { Skeleton } from '@mui/material';
const NewWallet = () => {
  const { data, loading } = useGetWalletBalanceQuery();
  const [openModal, setOpenModal] = useState(false);
  return (
    <Wrapper>
      <NewFundWalletModal openModal={openModal} setOpenModal={setOpenModal} />
      <div className='new-wallet'>
        <div className='new-wallet__wrap'>
          <div className='new-wallet__balance'>
            <div className='new-wallet__balance__wrap'>
              <div className='new-wallet__balance__content'>
                <div className='new-wallet__balance__content__header'>
                  <h4>Your Balance</h4>
                </div>
                <div className='new-wallet__balance__content__value'>
                  <h2>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      toCurrency(
                        data ? data.getWalletBalance.totalBalance / 100 : 0
                      )
                    )}
                  </h2>
                </div>
              </div>
              <div className='new-wallet__balance__action'>
                <button onClick={() => setOpenModal(true)}>Fund Wallet</button>
              </div>
            </div>
          </div>
          <div className='new-wallet__transactions'>
            <NewTransactions />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default NewWallet;

import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';

const ServicesFeaturesModal = ({
  openPrompt,
  setOpenPrompt,
  isExport = false,
  contentData,
  featureTitle,
  exportTabs,
  currentTab,
  setCurrentTab
}) => {
  return (
    <>
      <div className={`services__prompt fade${openPrompt ? ' show' : ''}`}>
        <div className='services__prompt__wrap'>
          <div className='heading-group'>
            <h4>{featureTitle}</h4>
            <div className='book-shipment-modal-close-button'>
              <IconButton
                onClick={() => {
                  setOpenPrompt(false);
                }}
              >
                <p>
                  <Close id='close-button' />
                </p>
              </IconButton>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '30px 0'
            }}
          >
            <img src={contentData.image} alt='' />
          </div>
          <div>
            {isExport ? (
              <div className='nav-wrap'>
                {exportTabs.map(tab => {
                  return (
                    <div
                      className={`pill-item ${
                        currentTab === tab ? 'isActive' : ''
                      }`}
                      onClick={() => setCurrentTab(tab)}
                    >
                      {tab}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>

          <div className='service-wrap'>
            <ul className='service-list'>
              {contentData.contents.map((feat, index) => {
                return (
                  <li key={index}>
                    <img src={feat.icon} alt='check' />

                    <p className='mb-0'>{feat.label}</p>
                  </li>
                );
              })}
            </ul>
            {contentData.info && (
              <div
                className='mt-3'
                style={{
                  borderTop: '1px solid #e2e4e8',
                  display: 'flex',
                  alignItems: 'flex-start',
                  paddingTop: '20px'
                }}
              >
                <img
                  style={{
                    paddingTop: '2px'
                  }}
                  src={contentData.info.icon}
                  alt=''
                />
                <p
                  className=''
                  style={{
                    color: '#0f1414',
                    fontSize: '14px',
                    lineHeight: '26px',
                    fontWeight: 500,
                    paddingLeft: '10px'
                  }}
                >
                  {contentData.info.label}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`services__prompt__backdrop fade${
          openPrompt ? ' show' : ''
        }`}
      ></div>
    </>
  );
};

export default ServicesFeaturesModal;

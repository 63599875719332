import React, { useMemo, useState } from 'react';
import Wrapper from '../../../components/Layouts/wrapper';
import { Helmet } from 'react-helmet';
import {
  BudgetImportService,
  ChinaImportService,
  ExportsByAirBudget,
  ExportsByAirExpress,
  ExportsByAirSaver,
  ExportsBySeaUKService,
  ExportsBySeaUSAService,
  ImportsBySeaService,
  OurServicesLists,
  shopNShipService
} from '../../../constants/our-services';
import CardComponent from './card-component';
import ServicesFeaturesModal from '../../../components/modals/services-feature-modal';
import { useHistory, Link } from 'react-router-dom';
import { ContactForm } from './contact-sales';
import { BackSales } from '../../../constants/asset-contants';
import BookShipmentModal from '../../../components/modals/book-shipment-modal';

const OurServices = () => {
  const [currentTab, setCurrentTab] = useState('import');
  const [openShopOnlineFeat, setOpenShopOnlineFeat] = useState(false);
  const [openImportsBySeaFeat, setOpenImportsBySeaFeat] = useState(false);
  const [openExportsBySeaFeat, setOpenExportsBySeaFeat] = useState(false);
  const [openExportsByAirFeat, setOpenExportsByAirFeat] = useState(false);
  const [openChinaImportsFeat, setOpenChinaImportsFeat] = useState(false);
  const [openBudgetImportsFeat, setOpenBudgetImportsFeat] = useState(false);
  const [currentExportTab, setCurrentExportTab] = useState(
    'Export Via Sea to UK🇬🇧'
  );
  const [currentExportByAirTab, setCurrentExportByAirTab] = useState('Budget');
  const [viewContactSales, setViewContactSales] = useState(false);
  const history = useHistory();
  const [openBookShipmentModal, setOpenBookShipmentModal] = useState(false);

  const handleImportViewFeat = index => {
    switch (index) {
      case 0:
        return () => setOpenImportsBySeaFeat(true);
      case 1:
        return () => setOpenBudgetImportsFeat(true);
      case 2:
        return () => setOpenChinaImportsFeat(true);
      default:
        return () => {};
    }
  };

  const handleExportViewFeat = index => {
    switch (index) {
      case 0:
        return () => setOpenExportsBySeaFeat(true);
      case 1:
        return () => setOpenExportsByAirFeat(true);
      default:
        return () => {};
    }
  };

  const exportBySeaData = useMemo(() => {
    if (currentExportTab.includes('UK')) {
      return ExportsBySeaUKService;
    }

    return ExportsBySeaUSAService;
  }, [currentExportTab]);

  const exportByAirData = useMemo(() => {
    if (currentExportByAirTab === 'Budget') {
      return ExportsByAirBudget;
    }

    if (currentExportByAirTab === 'Express') {
      return ExportsByAirExpress;
    }

    return ExportsByAirSaver;
  }, [currentExportByAirTab]);

  return (
    <>
      <BookShipmentModal
        openModal={openBookShipmentModal}
        setOpenModal={setOpenBookShipmentModal}
      />

      <ServicesFeaturesModal
        openPrompt={openShopOnlineFeat}
        contentData={shopNShipService}
        featureTitle={'Shop and Ship Features'}
        setOpenPrompt={setOpenShopOnlineFeat}
      />

      <ServicesFeaturesModal
        openPrompt={openChinaImportsFeat}
        contentData={ChinaImportService}
        featureTitle={'China Imports Features'}
        setOpenPrompt={setOpenChinaImportsFeat}
      />
      <ServicesFeaturesModal
        openPrompt={openImportsBySeaFeat}
        contentData={ImportsBySeaService}
        featureTitle={'Imports Via Sea Features'}
        setOpenPrompt={setOpenImportsBySeaFeat}
      />
      <ServicesFeaturesModal
        openPrompt={openBudgetImportsFeat}
        contentData={BudgetImportService}
        featureTitle={'Budget Imports Features'}
        setOpenPrompt={setOpenBudgetImportsFeat}
      />
      <ServicesFeaturesModal
        openPrompt={openExportsBySeaFeat}
        contentData={exportBySeaData}
        featureTitle={'Export Via Sea Features'}
        setOpenPrompt={setOpenExportsBySeaFeat}
        isExport
        exportTabs={['Export Via Sea to UK🇬🇧', 'Export Via Sea to USA🇺🇸']}
        currentTab={currentExportTab}
        setCurrentTab={setCurrentExportTab}
      />
      <ServicesFeaturesModal
        openPrompt={openExportsByAirFeat}
        contentData={exportByAirData}
        featureTitle={'Export Via Air Features'}
        setOpenPrompt={setOpenExportsByAirFeat}
        isExport
        exportTabs={['Budget', 'Express', 'Saver']}
        currentTab={currentExportByAirTab}
        setCurrentTab={setCurrentExportByAirTab}
      />
      <Wrapper>
        <Helmet>
          <meta name='description' content='Topship Africa Services' />
          <title>Topship Africa Services</title>
          <link rel='canonical' href='https://topship.africa/services' />
        </Helmet>
        <div className='services'>
          <div className='services__wrap'>
            {viewContactSales ? (
              <>
                <div
                  onClick={() => setViewContactSales(false)}
                  style={{
                    display: 'flex',
                    fontSize: '16px',
                    alignItems: 'center',
                    gap: '6px',
                    cursor: 'pointer'
                  }}
                >
                  <img src={BackSales} alt='back' />
                  <p
                    style={{
                      color: '#22428f',
                      fontSize: '14px',
                      fontWeight: 500
                    }}
                    className='mb-0'
                  >
                    Back
                  </p>
                </div>
                <ContactForm />
              </>
            ) : (
              <div className='new-referral__content'>
                <div className='new-referral__content__groups'>
                  <div className='new-referral__content__group'>
                    <div className='new-referral__content__header'>
                      <h2>Our Services</h2>
                    </div>
                    <div className='services__heading-group'>
                      <div className='new-referral__content__text'>
                        <span>
                          Learn how each service works and why we’re the trusted
                          choice for businesses and individuals.
                        </span>
                      </div>
                      <button
                        className='contact-sales'
                        onClick={() => setViewContactSales(true)}
                      >
                        Contact Sales
                      </button>
                    </div>

                    <div className='nav-wrap'>
                      <div
                        className={`pill-item ${
                          currentTab === 'import' ? 'isActive' : ''
                        }`}
                        onClick={() => setCurrentTab('import')}
                      >
                        Import
                      </div>
                      <div
                        className={`pill-item ${
                          currentTab === 'export' ? 'isActive' : ''
                        }`}
                        onClick={() => setCurrentTab('export')}
                      >
                        Export
                      </div>
                      <div
                        className={`pill-item ${
                          currentTab === 'shop-online' ? 'isActive' : ''
                        }`}
                        onClick={() => setCurrentTab('shop-online')}
                      >
                        Shop Online
                      </div>
                    </div>
                  </div>

                  <div>
                    {currentTab === 'import' && (
                      <div className='card-group'>
                        {OurServicesLists.import.map((service, index) => {
                          return (
                            <CardComponent
                              key={index}
                              service={service}
                              handleViewFeat={handleImportViewFeat(index)}
                              handleShipNow={() => {
                                if (index === 0) {
                                  return history.push('/sea-freight-welcome');
                                }

                                setOpenBookShipmentModal(true);
                              }}
                            />
                          );
                        })}
                      </div>
                    )}
                    {currentTab === 'export' && (
                      <div className='card-group'>
                        {OurServicesLists.export.map((service, index) => {
                          return (
                            <CardComponent
                              key={index}
                              service={service}
                              handleViewFeat={handleExportViewFeat(index)}
                              handleShipNow={() =>
                                setOpenBookShipmentModal(true)
                              }
                            />
                          );
                        })}
                      </div>
                    )}
                    {currentTab === 'shop-online' && (
                      <div className='card-group'>
                        {OurServicesLists.shopOnline.map((service, index) => {
                          return (
                            <CardComponent
                              key={index}
                              service={service}
                              handleViewFeat={() => setOpenShopOnlineFeat(true)}
                              handleShipNow={() =>
                                history.push('/shop-and-ship-welcome')
                              }
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default OurServices;

import { Close, EditLocationAlt } from '@mui/icons-material';
import {
  Button,
  IconButton,
  InputAdornment,
  Radio,
  Skeleton
} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useGetSavedAddresses } from '../../operations/queries';
import BusyOverlay from '../busy-overlay';
import EmptyList from '../empty-list';
import emptyAddressIcon from '../../assets/svg/address-empty.svg';
import { useRecoilState } from 'recoil';
import { addressTotalState } from '../../recoil/atoms';
import { singleShipmentLinks } from '../../constants/shipment-links';
import Input from '../new-dashboard/custom-input';
const ChooseAddressPrompt = ({
  openPrompt,
  setOpenPrompt,
  onEditAddress,
  onSelectAddress,
  sender,
  receiver
}) => {
  const observer = useRef();
  const history = useHistory();
  const { pathname } = useLocation();
  const take = 10;
  const [activeID, setActiveID] = useState('');
  const [search, setSearch] = useState('');
  const [resetSearch, setResetSearch] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [addressTotal, setAddressTotal] = useRecoilState(addressTotalState);
  const handleAddress = type => {
    setOpenPrompt(false);
    const list = search ? searchResults : addresses;
    const address = list.find(address => address.id === activeID);
    delete address?.isDefault;
    delete address?.createdDate;
    delete address?.updatedDate;
    delete address?.addressType;
    delete address?.user;
    delete address?.__typename;
    setActiveID('');
    if (type === 'select') {
      onSelectAddress && onSelectAddress(address);
    } else if (type === 'edit') {
      onEditAddress && onEditAddress(address);
    } else return;
  };
  const [getAddresses, getAddressesResult] = useGetSavedAddresses(
    undefined,
    data => onCompleted(data)
  );

  const total = search
    ? getAddressesResult.data?.getAddresses?.totalCount
    : addressTotal;
  const hasMore = page * take < total;
  const results = search ? searchResults : addresses;

  const onCompleted = data => {
    if (search) {
      if (!resetSearch) {
        setSearchResults(addresses => [
          ...addresses,
          ...data?.getAddresses?.list
        ]);
      } else {
        setSearchResults(data?.getAddresses?.list);
      }
    } else {
      setAddresses(addresses => [...addresses, ...data?.getAddresses?.list]);
      setAddressTotal(data?.getAddresses?.totalCount);
    }
  };

  const onClosePrompt = () => {
    setOpenPrompt(false);
    setActiveID('');
  };

  const searchAddress = () => {
    setPage(1);
    setResetSearch(true);
    getAddresses({
      take,
      page: 1,
      keyword: search
    });
  };

  const lastItem = useCallback(
    node => {
      if (getAddressesResult.loading) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setResetSearch(false);
          setPage(page => page + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [getAddressesResult.loading, hasMore]
  );

  useEffect(() => {
    getAddresses({
      take,
      page,
      keyword: search
    });
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (pathname === singleShipmentLinks[0].link && sender) {
      setActiveID(sender);
    } else if (pathname === singleShipmentLinks[1].link && receiver) {
      setActiveID(receiver);
    }
    // eslint-disable-next-line
  }, [pathname, sender, receiver]);

  return (
    <>
      <div
        className={`sender-info__choose-address fade${
          openPrompt ? ' show' : ''
        }`}
      >
        <BusyOverlay loading={getAddressesResult.loading} />
        <div className='sender-info__choose-address__wrap'>
          <div className='sender-info__choose-address__header'>
            <h2>Choose Address</h2>
            <IconButton onClick={onClosePrompt}>
              <Close />
            </IconButton>
          </div>
          <div className='sender-info__choose-address__body'>
            <div className='sender-info__choose-address__body__wrap'>
              <div className='sender-info__choose-address__search'>
                <Input
                  name='search'
                  type='text'
                  variant='standard'
                  placeholder='Garrick, Yaba'
                  value={search}
                  onChange={e => {
                    if (!e.target.value) {
                      setSearchResults([]);
                      setResetSearch(true);
                    }
                    setSearch(e.target.value);
                  }}
                  fullWidth
                  onKeyDown={event => {
                    if (event.keyCode === 13) {
                      searchAddress();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <div className='order-summary__shipment__address__action'>
                          <Button onClick={searchAddress} variant='text'>
                            <span>Search</span>
                          </Button>
                        </div>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              {getAddressesResult.data?.getAddresses?.totalCount ||
              getAddressesResult.loading ? (
                <>
                  <div className='sender-info__choose-address__list'>
                    {results.length
                      ? results
                          .sort((x, y) =>
                            x?.isDefault === y?.isDefault
                              ? 0
                              : x?.isDefault
                              ? -1
                              : 1
                          )
                          .map((address, addressIndex) => (
                            <div
                              onClick={() => setActiveID(address.id)}
                              ref={
                                results.length === addressIndex + 1
                                  ? lastItem
                                  : null
                              }
                              key={address.id}
                              className={`sender-info__choose-address__address${
                                activeID === address.id ? ' active' : ''
                              }
                            ${address?.isDefault ? 'default' : ''}
                            `}
                            >
                              <div className='sender-info__choose-address__address__wrap'>
                                <div className='sender-info__choose-address__address__content'>
                                  <div className='sender-info__choose-address__address__name'>
                                    <h3>{address.name}</h3>
                                    {address.isDefault && <span>Default</span>}
                                  </div>
                                  <div className='sender-info__choose-address__address__address'>
                                    <span>{`${address.addressLine1}, ${address.state}`}</span>
                                  </div>
                                </div>
                                <div className='sender-info__choose-address__address__radio'>
                                  <Radio
                                    value={address.id}
                                    onChange={e => setActiveID(e.target.value)}
                                    checked={activeID === address.id}
                                    sx={{ transform: 'scale(2)' }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))
                      : getAddressesResult.loading
                      ? [...Array(14).keys()].map(key => (
                          <div
                            key={key}
                            className={`sender-info__choose-address__address`}
                          >
                            <div className='sender-info__choose-address__address__wrap'>
                              <div className='sender-info__choose-address__address__content w-100'>
                                <div className='sender-info__choose-address__address__name w-100'>
                                  <Skeleton className='w-100' />
                                </div>
                                <div className='sender-info__choose-address__address__address w-100'>
                                  <Skeleton className='w-100' />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : addresses
                          .sort((x, y) =>
                            x?.isDefault === y?.isDefault
                              ? 0
                              : x?.isDefault
                              ? -1
                              : 1
                          )
                          .map((address, addressIndex) => (
                            <div
                              onClick={() => setActiveID(address.id)}
                              ref={
                                results.length === addressIndex + 1
                                  ? lastItem
                                  : null
                              }
                              key={address.id}
                              className={`sender-info__choose-address__address${
                                activeID === address.id ? ' active' : ''
                              }
                        ${address?.isDefault ? 'default' : ''}
                        `}
                            >
                              <div className='sender-info__choose-address__address__wrap'>
                                <div className='sender-info__choose-address__address__content'>
                                  <div className='sender-info__choose-address__address__name'>
                                    <h3>{address.name}</h3>
                                    {address.isDefault && <span>Default</span>}
                                  </div>
                                  <div className='sender-info__choose-address__address__address'>
                                    <span>{`${address.addressLine1}, ${address.state}`}</span>
                                  </div>
                                </div>
                                <div className='sender-info__choose-address__address__radio'>
                                  <Radio
                                    value={address.id}
                                    onChange={e => setActiveID(e.target.value)}
                                    checked={activeID === address.id}
                                    sx={{ transform: 'scale(2)' }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                  </div>
                  {results.length >= total && (
                    <p className='sender-info__choose-address__body__text'>
                      All items have been fetched
                    </p>
                  )}
                </>
              ) : !getAddressesResult.loading ? (
                <EmptyList
                  emptyIcon={emptyAddressIcon}
                  buttonText={'Create a new address'}
                  heading={
                    search
                      ? 'You do not have any address with this keyword'
                      : 'You do not have any saved addresses'
                  }
                  onButtonClick={() => history.push('/addresses')}
                />
              ) : null}
            </div>
          </div>
          <div className='sender-info__choose-address__footer'>
            <div className='sender-info__choose-address__footer'>
              <div className='sender-info__choose-address__footer__wrap'>
                <div className='sender-info__choose-address__footer__actions'>
                  <Button
                    onClick={() => handleAddress('edit')}
                    disabled={!activeID}
                    variant='text'
                  >
                    <span>Edit</span>
                    <EditLocationAlt />
                  </Button>
                  <Button
                    disabled={!activeID}
                    onClick={() => handleAddress('select')}
                    variant='text'
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`sender-info__choose-address__backdrop fade${
          openPrompt ? ' show' : ''
        }`}
      ></div>
    </>
  );
};

export default ChooseAddressPrompt;

const vibStatusOptions = [
  {
    name: 'Tier A',
    value: 'TierA'
  },
  {
    name: 'Tier B',
    value: 'TierB'
  },
  {
    name: 'Tier C',
    value: 'TierC'
  },
  {
    name: 'Not a VIB',
    value: 'None'
  }
];

export default vibStatusOptions;

import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  shipmentDestinationTypes,
  shipmentHubs,
  shipmentMethods,
  shipmentOptions
} from '../constants/book-shipment-contants';
import {
  multipleShipmentLinks,
  singleShipmentLinks
} from '../constants/shipment-links';
import {
  saveShipmentDetailsState,
  shipmentDestinationSelectionState,
  shipmentRequestState,
  warningState
} from '../recoil/atoms';
import { useAuthToken } from '../token';
import BookShipmentContentItem from './Commons/book-shipment-content-item';

const BookShipmentDestination = ({
  scroll,
  firstHeight,
  secondHeight,
  thirdHeight
}) => {
  const isMiniMobile = window.innerWidth <= 425;
  const [, , , getToken] = useAuthToken();
  const isLoggedIn = Boolean(getToken());
  const history = useHistory();
  const [selectedDestinationType, setSelectedDestinationType] = useRecoilState(
    shipmentDestinationSelectionState
  );
  const [shipmentDetail, setShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  const selectedHub = shipmentHubs.find(
    hub => shipmentDetail?.hubSlug === hub.slug
  );
  const selectedOption = shipmentOptions.find(
    option => shipmentDetail.shipmentRoute === option.value
  );
  const selectedMethod = shipmentMethods.find(
    method => shipmentDetail?.itemCollectionMode === method.value
  );
  const [, setRequestPage] = useRecoilState(shipmentRequestState);
  const [, setShow] = useRecoilState(warningState);

  const handleshipmentDestinationTypeselection = selectedDestinationType => () => {
    setSelectedDestinationType(selectedDestinationType);
    handleNextButtonClick(selectedDestinationType).then(() => {
      if (!selectedOption) {
        return scroll && scroll(0);
      }
      if (!selectedMethod) {
        const scrollValue = isMiniMobile ? 330 : 550;
        return scroll && scroll(scrollValue);
      }
      if (selectedMethod?.value === 'DropOff' && !selectedHub) {
        const scrollValue = (isMiniMobile ? 333 : 650) * 2;
        return scroll && scroll(scrollValue);
      }
      if (selectedDestinationType.value === shipmentDestinationTypes[0].value) {
        if (selectedHub?.slug && selectedOption?.id !== 2 && isLoggedIn) {
          history.push(singleShipmentLinks[1].link);
        } else {
          history.push(singleShipmentLinks[0].link);
        }
      }
      if (selectedDestinationType.value === shipmentDestinationTypes[1].value) {
        if (selectedHub?.slug) {
          history.push(multipleShipmentLinks[1].link);
        } else {
          history.push(multipleShipmentLinks[0].link);
        }
      }
    });
  };

  const handleNextButtonClick = async selectedDestinationType => {
    if (selectedOption && selectedMethod) {
      setShipmentDetail({
        ...shipmentDetail,
        destinationType: selectedDestinationType.value
      });
    }
  };

  const handleBackButtonClick = () => {
    if (selectedMethod?.dropoff) {
      const total = firstHeight + secondHeight + 70 * 2;
      const scrollValue = isMiniMobile ? total : 650 * 2;
      scroll && scroll(scrollValue);
      setRequestPage(requestPage => requestPage - 1);
    } else {
      const total = firstHeight - 70 / 2;
      const scrollValue = isMiniMobile ? total : 650;
      scroll && scroll(scrollValue);
      setRequestPage(requestPage => requestPage - 2);
    }
  };

  return (
    <div className='book-shipment-modal-contents'>
      <div className='book-shipment-modal-header'>
        <h3>Shipment Destination</h3>
        <div className='book-shipment-modal-close-button'>
          <IconButton
            onClick={() => {
              setShow(true);
            }}
          >
            <p>
              <Close id='close-button' />
            </p>
          </IconButton>
        </div>
      </div>
      <div className='book-shipment-modal-content'>
        <p className='book-shipment-content-title'>
          What would you like to do?
        </p>
        {shipmentDestinationTypes
          .filter(option =>
            selectedOption?.id === 2 ? option.id !== 2 : option
          )
          .map((option, index) => (
            <BookShipmentContentItem
              key={index}
              onClick={handleshipmentDestinationTypeselection(option)}
              selected={selectedDestinationType.title === option.title}
              option={option}
            />
          ))}
      </div>

      <div className='book-shipment-modal-footer shipment-option-footer'>
        <div
          className='book-shipment-modal-button book-shipment-modal-cancel-button'
          onClick={handleBackButtonClick}
        >
          <span>Back</span>
        </div>
      </div>
    </div>
  );
};

export default BookShipmentDestination;

import React from 'react';

const VolumetricWeight = ({ isActive, handleToggleSwitch, checkBoxName }) => {
  return (
    <div className='volumetric-weight'>
      <div className='switch-container'>
        <p>Switch to Volumetric weight</p>
        <div className={`switch ${isActive ? 'active' : ''}`}>
          <input
            type='checkbox'
            className='input-switch'
            name={checkBoxName}
            checked={isActive}
            onChange={handleToggleSwitch}
          />
          <span className='circle'></span>
        </div>
      </div>
    </div>
  );
};

export default VolumetricWeight;

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  chinaImportState,
  filteredShopNShipLinksState,
  saveShopNShipRateState,
  selectedRateState,
  shopNShipDetailsState,
  shopnshipSelectedCountryState,
  shopnshipSelectedProductState
} from '../../../../../recoil/atoms';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Grid, Skeleton } from '@mui/material';
import NewShopNShipStepsLayout from '../../../../../components/Layouts/shop-n-ship-steps-layout';
import SubmitButton from '../../../../../components/submit-button';
import AddressGuidePrompt from '../../../../../components/modals/address-guide-prompt';
import { useGetShopNShipRateQuery } from '../../../../../operations/queries';

import BusyOverlay from '../../../../../components/busy-overlay';
import emptyStateIcon from '../../../../../assets/svg/empty.svg';
import { DeliveryOptions2 } from '../../../../../components/shipping-options';
import { GetShopnshipHubAddress } from '../../../../../constants/shopnship-hub-address';
import Input from '../../../../../components/new-dashboard/custom-input';
import {
  getCountryTags,
  shippingFromOptions,
  isStandard
} from '../../../../../constants/shopnship-constants';
import { PackageNameCustomLabelMessage } from '../../../../../constants/shopnship-custom-label-message';

const countryTags = getCountryTags();

const expressLabel = 'TOPSHIP AFRICA/LOS 80205';

const packageRegex = (shippingFrom = '') => {
  if (!shippingFrom) {
    return null;
  }

  const option = shippingFromOptions.find(
    option => option.value === shippingFrom
  );

  if (!option || !option.origin) {
    return null;
  }

  return new RegExp(option.origin, 'g');
};

const expressValidation = yup.string().required('Package Name is required');

const ShopnshipShippingInformation = () => {
  const [shopNshipDetails, setSaveShopNshipDetails] = useRecoilState(
    shopNShipDetailsState
  );

  const history = useHistory();

  const [isChinaImport] = useRecoilState(chinaImportState);

  const [showAddressGuide, setShowAddressGuide] = useState(false);

  const [selectedRate, setSelectedRate] = useRecoilState(selectedRateState);

  const [selectedCountry] = useRecoilState(shopnshipSelectedCountryState);

  const [selectedProduct] = useRecoilState(shopnshipSelectedProductState);

  const handleRateSelection = rate => {
    setSelectedRate(rate);

    setFieldValue('pricingTier', rate?.pricingTier);

    if (rate?.pricingTier === 'Express') {
      setFieldValue('packageName', expressLabel);
    } else {
      setFieldValue('packageName', '');
    }
  };

  const [getShopNshipRates, { loading }] = useGetShopNShipRateQuery({
    onCompleted: data => {
      if (data?.getShopnshipRates?.length === 1) {
        handleRateSelection(data?.getShopnshipRates?.[0]);
      }
    }
  });

  const [filteredLinks] = useRecoilState(filteredShopNShipLinksState);

  const isExpressOption = useMemo(() => {
    if (!selectedRate) {
      return false;
    }

    return selectedRate?.pricingTier === 'Express';
  }, [selectedRate]);

  const tagName = useMemo(() => {
    if (isStandard(selectedCountry?.value)) {
      return selectedCountry?.origin;
    }

    return countryTags[shopNshipDetails?.shippingFrom?.split(' ')[0]] || '';
  }, [selectedCountry, shopNshipDetails?.shippingFrom]);

  const regex = useMemo(() => {
    if (!shopNshipDetails?.shippingFrom) {
      return null;
    }

    return packageRegex(shopNshipDetails?.shippingFrom);
  }, [shopNshipDetails?.shippingFrom]);

  const standardValidation = useMemo(() => {
    if (!regex) {
      return yup.string().required('Package Name is required');
    }

    return yup
      .string()
      .required('Package Name is required')
      .matches(regex, {
        message: `Please include "- ${
          countryTags[shopNshipDetails?.shippingFrom?.split(' ')[0]]
        }" `
      });
  }, [regex, shopNshipDetails]);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      packageName: isExpressOption ? expressValidation : standardValidation,
      pricingTier: yup.string().required('Please select an option')
    });
  }, [isExpressOption, standardValidation]);

  const currentIndex = useMemo(() => {
    if (!history.location.pathname) {
      return -1;
    }

    return filteredLinks.findIndex(
      link => link.link === history.location.pathname
    );
  }, [history.location.pathname]);

  const [shopNShipRates] = useRecoilState(saveShopNShipRateState);

  const {
    values,
    touched,
    setFieldValue,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      pricingTier: '',
      packageName: ''
    },

    onSubmit: values => {
      setSaveShopNshipDetails({ ...shopNshipDetails, ...values });

      history.push(filteredLinks[currentIndex + 1].link);
    }
  });

  useEffect(() => {
    if (shopNshipDetails?.pricingTier) {
      setFieldValue('pricingTier', shopNshipDetails.pricingTier);

      setSelectedRate({ pricingTier: shopNshipDetails.pricingTier });
    }

    if (shopNshipDetails?.packageName) {
      setFieldValue('packageName', shopNshipDetails.packageName);
    }

    // eslint-disable-next-line
  }, [selectedCountry?.value, shopNshipDetails]);

  useEffect(() => {
    if (selectedProduct?.label) {
      getShopNshipRates({
        shippingFrom: selectedCountry?.value,
        items: [
          {
            category: selectedProduct?.label?.includes('Not an electric')
              ? 'Others'
              : 'Gadgets',
            weight: 1
          }
        ]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct?.label, selectedCountry?.value]);

  return (
    <>
      <NewShopNShipStepsLayout mobileTitle='Package Information'>
        <div className='new-shop-n-ship__modal__body'>
          <div className='new-shop-n-ship__modal__body__wrap'>
            <div className='new-shop-n-ship__modal__body__content'>
              <form
                onSubmit={handleSubmit}
                className='delivery-options new-shop-n-ship__modal__body__content__welcome-guide__shipping-guide'
              >
                <div className='mt-4'>
                  <DeliveryOptions2
                    loading={loading}
                    shopNShipRates={shopNShipRates}
                    selectedRate={selectedRate}
                    handleRateSelection={handleRateSelection}
                    lightColors={[]}
                    emptyStateIcon={emptyStateIcon}
                    selectedCountry={selectedCountry}
                    isPerKg={true}
                  />
                </div>
                <Grid container rowSpacing='16px' columnSpacing='16px'>
                  <Input
                    name='packageName'
                    value={values.packageName}
                    error={touched.packageName && Boolean(errors.packageName)}
                    helperText={touched.packageName && errors.packageName}
                    customLabelMessage={
                      <PackageNameCustomLabelMessage
                        tagName={tagName}
                        isExpress={isExpressOption}
                      />
                    }
                    onChange={handleChange}
                    customLabel='Name on package'
                    type='text'
                    placeholder={
                      isChinaImport
                        ? '邓涛topship - Jane Doe'
                        : isExpressOption
                        ? expressLabel
                        : `e.g: Garrick Braide - ${
                            countryTags[
                              isStandard(selectedCountry?.value)
                                ? selectedCountry?.value
                                : shopNshipDetails?.shippingFrom?.split(' ')[0]
                            ] || ''
                          }`
                    }
                    containerClassName='new-shop-n-ship__modal__body__form__item__input'
                    fullWidth
                    disabled={isExpressOption}
                  />
                </Grid>

                <div>
                  <div className=''>
                    <label>Delivery address</label>
                    {values.packageName &&
                    values.packageName.includes(
                      isExpressOption ? expressLabel : tagName
                    ) ? (
                      <div>
                        <div className='address-info-wrap mb-2'>
                          {selectedCountry?.value && (
                            <>
                              <div className='address-info'>
                                <strong>
                                  {isStandard(selectedCountry.value)
                                    ? selectedCountry.value
                                    : selectedCountry?.label}{' '}
                                  Hub:{' '}
                                </strong>
                                {selectedCountry?.address ||
                                  GetShopnshipHubAddress(
                                    selectedCountry.value,
                                    selectedRate.pricingTier
                                  )}
                              </div>
                            </>
                          )}
                        </div>
                        <small>
                          Please input the above address as your shipping
                          address, your items get shipped there.
                        </small>
                      </div>
                    ) : (
                      // [...Array(1).keys()].map(key => (
                      <div className={`delivery-options__item`}>
                        <div className='delivery-options__item__wrap discount'>
                          <div className='delivery-options__item__content w-100'>
                            <div className='delivery-options__item__name w-100'>
                              <Skeleton className='w-100' />
                            </div>
                            <div className='delivery-options__item__address w-100'>
                              <Skeleton className='w-100' />
                            </div>
                          </div>
                        </div>
                      </div>
                      // ))
                    )}
                  </div>
                </div>

                <div className='delivery-options__footer'>
                  <div
                    onClick={() =>
                      !shopNshipDetails?.id &&
                      history.push(filteredLinks[currentIndex - 1].link)
                    }
                    className='delivery-options__footer__action'
                  >
                    Go Back
                  </div>
                  <SubmitButton text='Continue' />
                </div>
              </form>
            </div>
          </div>
        </div>
        <BusyOverlay loading={loading} speed='1.7s' />
      </NewShopNShipStepsLayout>
      <AddressGuidePrompt
        show={showAddressGuide}
        setShow={setShowAddressGuide}
      />
    </>
  );
};

export default ShopnshipShippingInformation;

import { useRecoilState } from 'recoil';

import {
  shipmentRequestState,
  shipmentTypeState,
  saveShipmentDetailsState,
  selectedRateState,
  selectedInsurancePlan,
  saveShipmentRateState,
  shipmentPaymentState,
  selectedPaymentMethod,
  feedbackOverlayState,
  blackFridayState,
  selectedBlackFridayState
} from '../recoil/atoms';

export const useResetShipmentData = () => {
  const [, setIsBlackFriday] = useRecoilState(blackFridayState);
  const [, setBlackFridayOption] = useRecoilState(selectedBlackFridayState);
  const [, setRequestPage] = useRecoilState(shipmentRequestState);
  const [, setShipmentType] = useRecoilState(shipmentTypeState);
  const [, setSaveShipmentDetail] = useRecoilState(saveShipmentDetailsState);
  const [, setShipmentRates] = useRecoilState(saveShipmentRateState);
  const [, setSelectedRate] = useRecoilState(selectedRateState);
  const [, setSelectedInsurance] = useRecoilState(selectedInsurancePlan);
  const [, setPaymentMethod] = useRecoilState(selectedPaymentMethod);
  const [, setPaymentPage] = useRecoilState(shipmentPaymentState);
  const [, setShowFeedback] = useRecoilState(feedbackOverlayState);

  const resetData = () => {
    setRequestPage(0);
    setIsBlackFriday(false);
    setBlackFridayOption({});
    setSaveShipmentDetail({
      itemCollectionMode: '',
      senderDetail: {},
      receiverDetail: {},
      items: [
        {
          category: '',
          weight: '',
          quantity: '',
          value: '',
          description: ''
        }
      ],
      pricingTier: '',
      insuranceType: '',
      insuranceCharge: 0,
      pickupCharge: 0,
      shipmentCharge: 0
    });
    setShipmentType({});
    setShowFeedback(false);
    setShipmentRates([]);
    setSelectedRate({});
    setSelectedInsurance({});
    setPaymentPage(0);
    setPaymentMethod('');
  };

  return resetData;
};

import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Link, useLocation } from 'react-router-dom';
import SubmitButton from '../../../components/submit-button';
import { useShowAuthPage } from '../../../utilities/show-auth-pages';
import Input from '../../../components/new-dashboard/custom-input';
import { useForgotPasswordMutation } from '../../../operations/mutations';
import ContentLayout from '../../../components/Layouts/content-layout';
import VerifyMail from './verify-mail';
import ContactSupport from '../../../components/contact-support';

const ForgotPassword = () => {
  const [renderPage] = useShowAuthPage();
  const [resetPassword, { data, loading }] = useForgotPasswordMutation();
  const location = useLocation();
  const urlParams = new URLSearchParams(location?.search);
  const businessId = urlParams.get('lsp');
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .email('Please enter a valid email address')
      .required('What is your email address?')
  });

  const { errors, touched, values, handleSubmit, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: ''
    },
    validationSchema,
    onSubmit: async values => {
      const forgotPasswordData = {
        email: values.email.trim()
      };
      resetPassword(forgotPasswordData);
    }
  });

  if (data) {
    return <VerifyMail />;
  }

  return renderPage(
    <>
      {!businessId && <ContactSupport />}

      <ContentLayout title='Forgot your Password?'>
        <div className='form-container'>
          <form onSubmit={handleSubmit}>
            <div className='form-item'>
              <label className='form-item-label' htmlFor='email'>
                Email address
              </label>
              <Input
                fullWidth
                id='email'
                name='email'
                placeholder='email@domain.com'
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </div>
            <SubmitButton
              text='Reset Password'
              disabled={loading}
              loading={loading}
            />
          </form>
          <p className='remember-password'>
            Remember your password? &nbsp;
            <span>
              <Link className='link-to' to='/login'>
                Sign in
              </Link>
            </span>
          </p>
        </div>
      </ContentLayout>
    </>
  );
};

export default ForgotPassword;

import React from 'react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  DiscussLine,
  InstagramLine,
  PhoneLine,
  WhatsappLine,
  NextArrow
} from '../../constants/asset-contants';

const GetHelpModal = ({ openModal, handleClose }) => {
  const workingTimeline = `We'll respond during working hours`;
  const getHelpPlatforms = [
    {
      label: 'Chat',
      icon: DiscussLine,
      url: `https://engage.myserviceagent.net/widget?website_token=fEf3snSSq1vDbTJT5mdBgRA4&locale=en`
    },
    {
      label: 'Phone',
      icon: PhoneLine,
      url: '+2342013302594'
    },
    {
      label: 'Whatsapp',
      icon: WhatsappLine,
      url: '+2349080777728'
    },
    {
      label: 'Instagram',
      icon: InstagramLine,
      url: 'https://www.instagram.com/topshipafrica/'
    }
  ];

  if (!openModal) {
    return null;
  }

  return (
    <>
      <div
        className={`past-shipment-detail-modal ${
          openModal ? 'show-modal' : ''
        }`}
      >
        <div className='past-shipment-detail-modal-container'>
          <div className='get-help-modal-content'>
            <div className='get-help-modal-header'>
              <div className='close-button'>
                <IconButton onClick={handleClose}>
                  <Close id='close-button' />
                </IconButton>
              </div>
              <div>
                <h2>Get Help</h2>
                <p>Got a problem with your shipment ? Let’s help fix it</p>
              </div>
            </div>

            <div className='get-help-content-wrap'>
              {getHelpPlatforms.map(platform => {
                return (
                  <a
                    href={`${
                      platform.label === 'Phone'
                        ? `tel:${platform.url}`
                        : platform.label === 'Whatsapp'
                        ? `https://wa.me/${platform.url}`
                        : platform.url
                    }`}
                    target='_blank'
                    rel='noreferrer'
                    className='get-help-content-item mb-3'
                  >
                    <div
                      style={{
                        display: 'flex'
                      }}
                    >
                      <img
                        style={{
                          paddingRight: '15px'
                        }}
                        src={platform.icon}
                        alt='live chat'
                      />
                      <div>
                        <p
                          style={{
                            fontWeight: 700,
                            color: '#0f1414'
                          }}
                          className='content-label'
                        >
                          {platform.label}
                        </p>
                        <p className='content-value'>{workingTimeline}</p>
                      </div>
                    </div>
                    <img
                      style={{
                        width: '22px'
                      }}
                      src={NextArrow}
                      alt='live chat'
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetHelpModal;

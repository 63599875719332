import React from 'react';
import SubmitButton from './submit-button';
import { WarningAlert } from '../constants/asset-contants';
import { toCurrency } from '../utilities/to-currency';

const PendingShopnship = ({
  shopnship = {
    trackingId: '',
    finalCharge: 0,
    actualWeight: 0,
    estimatedWeight: 0
  },
  onPay = () => {}
}) => {
  return (
    <div className='pending-shopnship'>
      <div className='pending-shopnship__wrap'>
        <div className='pending-shopnship__content'>
          <div className='pending-shopnship__icon'>
            <img src={WarningAlert} alt='Warning' />
          </div>
          <div className='pending-shopnship__text'>
            <span className='pending-shopnship__header'>
              Payment Due! Additional charge on your booking -{' '}
              {shopnship?.trackingId}
            </span>
            <span className='pending-shopnship__description'>
              Your package weighs {shopnship?.actualWeight || 0}kg and booking
              was made for {shopnship?.estimatedWeight || 0}kg. There is a
              pending balance of{' '}
              <b>{toCurrency(shopnship?.finalCharge / 100 || 0)}</b> to be made.
            </span>
          </div>
        </div>
        <div className='pending-shopnship__action'>
          <SubmitButton
            className={'w-100'}
            text='Pay'
            onClick={() => {
              onPay?.();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PendingShopnship;

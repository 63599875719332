import React, { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import Helmet from 'react-helmet';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { singleShipmentLinks } from '../../../../constants/shipment-links';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { AddCircle } from '@mui/icons-material';
import { Button } from '@mui/material';
import {
  disableShippingLinkState,
  saveShipmentDetailsState
} from '../../../../recoil/atoms';
import { itemCategories } from '../../../../constants/item-categories';
import SubmitButton from '../../../../components/submit-button';
import NewShippingStepsLayout from '../../../../components/Layouts/new-shipping-steps-layout';
import { useFormik } from 'formik';
import { checkIsCompleted } from '../../helper-functions';
import * as yup from 'yup';
import { itemCategoryRules } from '../../../../constants/item-category-dictionary';
import {
  BoxModelForm,
  BoxModelHeader
} from '../../../../constants/box-model-constants';
import omit from 'lodash.omit';
import { AddNewBoxComponent } from '../../../../components/Commons/summary-component';

const initItem = {
  category: '',
  weight: '',
  width: '',
  height: '',
  length: '',
  quantity: '',
  value: '',
  description: '',
  isVolumetric: false
};

const initialValues = {
  items: [{ ...initItem, boxNumber: 1 }]
};

const ItemDescription = () => {
  const history = useHistory();

  const [shipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );

  const currentIndex = singleShipmentLinks.findIndex(
    link => history.location.pathname === link.link
  );

  const [boxes, setBoxes] = useState([1]);

  const [currentBox, setCurrentBox] = useState(boxes[0]);

  const [, setDisableShippingLink] = useRecoilState(disableShippingLinkState);

  const validationSchema = yup.object().shape({
    items: yup.array().of(
      yup.object().shape({
        category: yup.string().required('Please provide a Category'),
        isVolumetric: yup.bool(),
        weight: yup.number().when(['isVolumetric'], {
          is: isVolumetric => !isVolumetric,
          then: yup
            .number()
            .min(0.09999, 'Please enter a weight greater than zero (0)')
            .required('Please enter the weight of your item(s)'),
          otherwise: yup.number().nullable().notRequired()
        }),
        width: yup.number().when(['isVolumetric'], {
          is: isVolumetric => isVolumetric === true,

          then: yup
            .number()
            .min(0.09999, 'Please enter a width greater than zero (0)')
            .required('Please enter the width of your box'),
          otherwise: yup.number().nullable().notRequired()
        }),
        height: yup.number().when(['isVolumetric'], {
          is: isVolumetric => isVolumetric === true,

          then: yup
            .number()
            .min(0.09999, 'Please enter a height greater than zero (0)')
            .required('Please enter the height of your box'),
          otherwise: yup.number().nullable().notRequired()
        }),
        length: yup.number().when(['isVolumetric'], {
          is: isVolumetric => isVolumetric === true,

          then: yup
            .number()
            .min(0.09999, 'Please enter a length greater than zero (0)')
            .required('Please enter the length of your box'),
          otherwise: yup.number().nullable().notRequired()
        }),
        quantity: yup.number().required('Please provide the quantity'),
        value: yup
          .number()
          .moreThan(0, 'Please enter a number above 1')
          .required('Please provide the value'),
        description: yup
          .string()
          .required('Please provide the item description')
      })
    )
  });

  const {
    values,
    errors,
    validateForm,
    setFieldValue,
    handleChange: handleFormikChange,
    handleSubmit
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      if (
        values.items.some(
          (item, index) =>
            (shipmentDetail?.items || [])[index]?.weight !== item?.weight
        ) &&
        shipmentDetail?.pricingTier
      ) {
        setDisableShippingLink(true);
      }

      setSaveShipmentDetail({
        ...shipmentDetail,
        items: values.items.map(item => {
          let weight = item.weight;

          if (item.isVolumetric) {
            weight = Number(
              ((item.width * item.height * item.length) / 5000).toFixed(2)
            );
          }
          return omit(
            {
              ...item,
              weight,
              value: item.value * 100,
              isCompleted: true
            },
            ['itemIndex']
          );
        })
      });

      history.push(singleShipmentLinks[currentIndex + 1].link);
    }
  });

  const handleChange = ({ target: { name, value } }) => {
    const itemValue = name.match(new RegExp('quantity|weight', 'i'))
      ? value
        ? parseFloat(value)
        : ''
      : value;

    handleFormikChange({ target: { name, value: itemValue } });
  };
  const isMobile = useMediaQuery('(max-width:1100px)');

  const showAddBoxContainer = useMemo(() => {
    return isMobile && boxes ? boxes?.length <= 1 : boxes?.length <= 3;
  }, [isMobile, boxes]);

  const addNewBox = () => {
    if (boxes.length >= 4) {
      return;
    }

    const boxNumber = boxes[boxes.length - 1] + 1;

    boxes.push(boxNumber);

    setBoxes([...boxes]);

    setCurrentBox(boxNumber);

    setFieldValue('items', [...values.items, { ...initItem, boxNumber }]);
  };

  const deleteBox = boxNumber => {
    const newBoxes = boxes
      .filter(box => box !== boxNumber)
      .map(box => (box > boxNumber ? box - 1 : box));

    setBoxes(newBoxes);

    setCurrentBox(newBoxes[newBoxes?.length - 1]);

    setFieldValue(
      'items',
      values.items
        .filter(item => item.boxNumber !== boxNumber)
        .map(item => {
          if (item?.boxNumber > boxNumber) {
            item.boxNumber = item.boxNumber - 1;
          }

          return item;
        })
    );
  };

  const addItem = boxNumber => {
    setFieldValue('items', [...values.items, { ...initItem, boxNumber }]);
  };

  const deleteItem = (index, boxNumber) => {
    const items = values.items.filter(item => item.boxNumber === boxNumber);

    if (items.length <= 1) {
      return;
    }

    setFieldValue(
      'items',
      values.items.filter((_, itemIndex) => itemIndex !== index)
    );
  };

  const currentBoxItems = useMemo(() => {
    if (!currentBox || !values.items) {
      return [];
    }

    return values.items
      .filter(item => item.boxNumber === currentBox)
      .map(item => {
        item.itemIndex = values.items.findIndex(
          value => JSON.stringify(value) === JSON.stringify(item)
        );

        return item;
      });
  }, [currentBox, values.items]);

  const disabled = useMemo(() => {
    return values.items.some(
      item =>
        itemCategoryRules[item.category] &&
        !itemCategoryRules[item.category]?.canShip
    );
  }, [values.items]);

  const handleValidations = async values => {
    const errors = await validateForm(values);
    return !Boolean(errors);
  };

  useEffect(() => {
    ReactGA.event({
      category: 'Shipment',
      action: 'User completes receivers information'
    });

    if (shipmentDetail?.items?.length) {
      const isBoxShipment = shipmentDetail?.items?.some(item =>
        Boolean(item?.boxNumber)
      );

      const divideByHundred = item => ({ ...item, value: item?.value / 100 });

      if (isBoxShipment) {
        const newBoxes = [];

        shipmentDetail?.items?.forEach(item => {
          if (!newBoxes.includes(item?.boxNumber)) {
            newBoxes.push(item?.boxNumber);
          }
        });

        setBoxes(newBoxes.sort());

        setFieldValue(
          'items',
          shipmentDetail?.items?.map(item => divideByHundred(item))
        );
      } else {
        // backwards compatibility
        setFieldValue(
          'items',
          shipmentDetail?.items?.map(item => {
            item = divideByHundred(item);

            item.boxNumber = boxes[0];

            return item;
          })
        );
      }
    }

    // eslint-disable-next-line
  }, []);

  const filteredBoxesForIndex2 = useMemo(() => {
    return boxes.filter(box => box <= 2);
  }, [boxes]);

  const filteredBoxesForIndex4 = useMemo(() => {
    return boxes.filter(box => box > 2 && box < 5);
  }, [boxes]);

  useEffect(() => {
    if (history.location.state?.reValidate) {
      const isCompleted = checkIsCompleted(currentIndex, shipmentDetail);

      if (!isCompleted) {
        handleValidations(values);
      }
    }

    history.replace({
      state: {}
    });
    // eslint-disable-next-line
  }, [history.location.state?.reValidate]);

  return (
    <NewShippingStepsLayout>
      <Helmet>
        <meta
          name='description'
          content='Topship Africa Shipment Request Item Description'
        />
        <title>Topship Africa Shipment Request Item Description</title>
        <link
          rel='canonical'
          href='https://topship.africa/shipment-request-item-description'
        />
      </Helmet>
      {isMobile ? (
        <form onSubmit={handleSubmit} className='item-description'>
          {/* first 2 boxes */}
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap'
            }}
          >
            <div style={{ display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
              {filteredBoxesForIndex2.map(box => (
                <BoxModelHeader
                  isMobile={isMobile}
                  setCurrentBox={setCurrentBox}
                  currentBox={currentBox}
                  box={box}
                />
              ))}

              {showAddBoxContainer && (
                <AddNewBoxComponent addNewBox={addNewBox} />

                // <div
                //   style={{
                //     borderBottom: '1px solid #e2e4e8',
                //     flexGrow: '1'
                //   }}
                // >
                //   <div
                //     onClick={addNewBox}
                //     className='item-description__new-box'
                //     style={{
                //       width: '80%'
                //     }}
                //   >
                //     <img src={AddBoxIcon} alt='add box' />
                //     <p>Add another box</p>
                //   </div>
                // </div>
              )}
            </div>
          </div>
          <div>
            {filteredBoxesForIndex2
              .filter(box => currentBox === box)
              .map(box => {
                const boxItems = values.items
                  .filter(item => item.boxNumber === box)
                  .map(item => {
                    item.itemIndex = values.items.findIndex(
                      value => JSON.stringify(value) === JSON.stringify(item)
                    );

                    return item;
                  });

                return (
                  <BoxModelForm
                    boxes={boxes}
                    box={box}
                    boxItems={boxItems}
                    deleteBox={deleteBox}
                    deleteItem={deleteItem}
                    handleChange={handleChange}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    addItem={addItem}
                  />
                );
              })}
          </div>

          {/* next section */}
          {boxes.length >= 3 && (
            <div className='mt-5'>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      width: '100%'
                    }}
                  >
                    {filteredBoxesForIndex4.map(box => {
                      return (
                        <BoxModelHeader
                          isMobile={isMobile}
                          setCurrentBox={setCurrentBox}
                          currentBox={currentBox}
                          box={box}
                        />
                      );
                    })}

                    {boxes.length === 3 && (
                      <AddNewBoxComponent addNewBox={addNewBox} />
                    )}
                  </div>
                </div>

                {filteredBoxesForIndex4
                  .filter(box => {
                    return currentBox === box;
                  })
                  .map(box => {
                    const boxItems = values.items
                      .filter(item => item.boxNumber === box)
                      .map(item => {
                        item.itemIndex = values.items.findIndex(
                          value =>
                            JSON.stringify(value) === JSON.stringify(item)
                        );

                        return item;
                      });

                    return (
                      <BoxModelForm
                        box={box}
                        boxes={boxes}
                        boxItems={boxItems}
                        deleteBox={deleteBox}
                        deleteItem={deleteItem}
                        handleChange={handleChange}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        addItem={addItem}
                      />
                    );
                  })}
              </div>
            </div>
          )}
          {boxes.length === 2 && (
            <AddNewBoxComponent
              style={{
                margin: '40px auto 0'
              }}
              wrapStyle={{
                borderBottom: 'none'
              }}
              addNewBox={addNewBox}
            />
          )}
          <div
            style={{
              paddingBottom: '30px'
            }}
            className='item-description__footer mt-2'
          >
            <div
              onClick={() =>
                history.push(singleShipmentLinks[currentIndex]?.initialLink)
              }
              className='item-description__footer__action'
            >
              Go Back
            </div>
            <SubmitButton text='Continue' disabled={disabled} />
          </div>
        </form>
      ) : (
        <div className='item-description'>
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap'
            }}
          >
            <div style={{ display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
              {boxes.map(box => (
                <BoxModelHeader
                  box={box}
                  currentBox={currentBox}
                  isMobile={isMobile}
                  setCurrentBox={setCurrentBox}
                />
              ))}
              {showAddBoxContainer && (
                <AddNewBoxComponent addNewBox={addNewBox} />
              )}
            </div>
          </div>
          <div>
            {boxes?.map(box => (
              <div style={{ display: 'flex' }} key={box}>
                <div>
                  {currentBox === box && (
                    <form
                      className={currentBox === box ? 'form-transition' : ''}
                      onSubmit={handleSubmit}
                    >
                      <BoxModelForm
                        addItem={addItem}
                        box={box}
                        boxes={boxes}
                        boxItems={currentBoxItems}
                        deleteBox={deleteBox}
                        deleteItem={deleteItem}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                      {isMobile && boxes.length >= 2 && (
                        <div className='item-description__action mb-3'>
                          <Button
                            variant='text'
                            disabled={boxes.length >= 4}
                            onClick={addNewBox}
                          >
                            <span>Add another boGGx</span>
                            <AddCircle />
                          </Button>
                        </div>
                      )}
                      <div className='item-description__footer mt-3'>
                        <div
                          onClick={() =>
                            history.push(
                              singleShipmentLinks[currentIndex].initialLink
                            )
                          }
                          className='item-description__footer__action'
                        >
                          Go Back
                        </div>
                        <SubmitButton text='Continue' disabled={disabled} />
                      </div>
                    </form>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </NewShippingStepsLayout>
  );
};

export default ItemDescription;

import React, { useState } from 'react';
import { Button } from '@mui/material';
import image from '../../../../../../assets/image/verification.png';
import tickCircle from '../../../../../../assets/image/tick-circle.png';
import SubmitButton from '../../../../../../components/submit-button';
import OtpInput from '@igloo_cloud/mui-otp-input';
import {
  useEmailVerificationMutation,
  useResendOTPMutation
} from '../../../../../../operations/mutations';
import { userState } from '../../../../../../recoil/atoms';
import { useRecoilState } from 'recoil';
import BusyOverlay from '../../../../../../components/busy-overlay';

const Verification = ({
  nextStep,
  previousStep,
  activeStep = { isCompleted: false }
}) => {
  const [otp, setOtp] = useState('');
  const [user, setUser] = useRecoilState(userState);
  const [success, setSuccess] = useState(activeStep.isCompleted);

  const goBack = () => {
    previousStep();
  };

  const [resendOTP, resendOTPResult] = useResendOTPMutation();

  const id = user?.id;

  const [verifyEmail, verifyEmailResult] = useEmailVerificationMutation(() => {
    setUser({ ...user, isEmailVerified: true });
    setSuccess(true);
  });

  const handleVerification = () => {
    if (!success && otp.length === 4) {
      verifyEmail({ userId: id, passCode: otp });
    } else {
      nextStep();
    }
  };

  const loading = resendOTPResult.loading || verifyEmailResult.loading;

  return (
    <div className='verification__body'>
      <div className='verification__image'>
        <img src={success ? tickCircle : image} className='img-fluid' alt='' />
      </div>
      <div className='verification__content'>
        <div className='verification__header'>
          <h1>
            {success
              ? 'Email/Phone number verified'
              : 'Verify your email or phone number'}
          </h1>
        </div>
        <div className='verification__description'>
          <p>
            {success
              ? 'Your email is verified, click to continue below.'
              : 'We sent a verification code to your mail and SMS, Enter the code below.'}
          </p>
        </div>
      </div>
      {!success && (
        <div className='verification__form'>
          <OtpInput
            value={otp.split('')}
            className='verification__form__input'
            onChange={value => setOtp(value.join(''))}
          />
        </div>
      )}
      <div className='verification__action'>
        <div onClick={goBack} className='item-description__footer__action'>
          Go Back
        </div>
        <SubmitButton
          onClick={handleVerification}
          loading={verifyEmailResult.loading}
          disabled={!success && otp.length !== 4}
          text={success ? 'Continue' : 'Verify email'}
        />
      </div>
      {!success && (
        <div className='verification__footer'>
          <p>
            <span>Didn’t receive the email? </span>
            <Button variant='text' onClick={() => resendOTP(id)}>
              Resend Code.
            </Button>
          </p>
        </div>
      )}
      <BusyOverlay loading={loading} />
    </div>
  );
};

export default Verification;

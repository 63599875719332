import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { shipmentHubs } from '../constants/book-shipment-contants';
import { useGetUserLazyQuery } from '../operations/queries';
import {
  saveShipmentDetailsState,
  shipmentHubSelectionState,
  shipmentRequestState,
  warningState
} from '../recoil/atoms';
import BusyOverlay from './busy-overlay';
import BookShipmentContentItem from './Commons/book-shipment-content-item';
import { useAuthToken } from '../token';

const BookShipmentHubs = ({ scroll, firstHeight, secondHeight }) => {
  const modal = useRef();
  const isMiniMobile = window.innerWidth <= 425;
  const [, , , getToken] = useAuthToken();
  const isLoggedIn = Boolean(getToken());
  const [selectedHub, setSelectedHub] = useRecoilState(
    shipmentHubSelectionState
  );
  const [getUser, getUserResult] = useGetUserLazyQuery(undefined, 'sender');
  const [, setRequestPage] = useRecoilState(shipmentRequestState);
  const [shipmentDetail, setSaveShipmentDetail] = useRecoilState(
    saveShipmentDetailsState
  );
  const [, setShow] = useRecoilState(warningState);

  const handleShipmentHubSelection = option => () => {
    setSelectedHub(option);
    handleNextButtonClick(option);
  };

  const handleNextButtonClick = selectedHub => {
    setSaveShipmentDetail({
      ...shipmentDetail,
      senderDetail: {
        ...shipmentDetail.senderDetail,
        ...selectedHub.value,
        city: selectedHub.value.city,
        isCompleted: true
      },
      hubSlug: selectedHub.slug
    });
    if (isLoggedIn) {
      getUser();
    } else {
      setRequestPage(requestPage => requestPage + 1);
      const scrollValue = (isMiniMobile ? 333 : 650) * 3;
      scroll && scroll(scrollValue);
    }
  };

  const handleBackButtonClick = () => {
    setRequestPage(requestPage => requestPage - 1);
    setSelectedHub({});
    const scrollValue = isMiniMobile ? firstHeight - 70 / 2 : 550;
    scroll && scroll(scrollValue);
  };

  useEffect(() => {
    if (getUserResult.data) {
      setRequestPage(requestPage => requestPage + 1);
      const total =
        modal.current?.clientHeight + firstHeight + secondHeight + 70 * 3;
      const scrollValue = isMiniMobile ? total : 650 * 3;
      scroll && scroll(scrollValue);
    }
    // eslint-disable-next-line
  }, [getUserResult.data]);

  return (
    <div ref={modal} className='book-shipment-modal-contents'>
      <BusyOverlay loading={getUserResult.loading} />
      <div className='book-shipment-modal-header'>
        <h3>Shipment Method</h3>
        <div className='book-shipment-modal-close-button'>
          <IconButton
            onClick={() => {
              setShow(true);
            }}
          >
            <p>
              <Close id='close-button' />
            </p>
          </IconButton>
        </div>
      </div>
      <div className='book-shipment-modal-content'>
        <p className='book-shipment-content-title'>
          Select your preferred drop-off center
        </p>

        {shipmentHubs.map((option, index) => (
          <BookShipmentContentItem
            key={index}
            onClick={handleShipmentHubSelection(option)}
            selected={selectedHub.slug === option.slug}
            option={option}
          />
        ))}
      </div>
      <div className='book-shipment-modal-footer'>
        <div
          className='book-shipment-modal-button book-shipment-modal-cancel-button'
          onClick={handleBackButtonClick}
        >
          <span>Back</span>
        </div>
      </div>
    </div>
  );
};

export default BookShipmentHubs;

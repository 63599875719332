import React from 'react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const InAppSaverPrompt = ({ openPrompt, setOpenPrompt, selectNewService }) => {
  const onClosePrompt = () => {
    setOpenPrompt(false);
  };

  return (
    <>
      <div
        className={`in-app__prompt fade isSaver ${openPrompt ? ' show' : ''}
      `}
      >
        <div className='in-app__prompt__wrap isSaver'>
          <div className='in-app__prompt__header mt-2'>
            <h2
              style={{
                color: '#f05d3d'
              }}
            >
              Important Information
            </h2>
            <IconButton
              onClick={() => {
                setOpenPrompt(false);
              }}
            >
              <p>
                <Close id='close-button' />
              </p>
            </IconButton>
          </div>
          <div
            className={`in-app__prompt__body
              `}
          >
            <p
              style={{
                fontSize: '15px',
                lineHeight: '26px'
              }}
            >
              This service is experiencing significant processing delays. This
              means your package(s) may not be delivered on time. For urgent
              deliveries, kindly select a different service. Please confirm you
              understand this before booking.
            </p>
          </div>
        </div>

        <div className='in-app__prompt__footer mb-2 mt-1'>
          <div className='in-app__prompt__footer__wrap'>
            <div className='in-app__prompt__footer__actions isSaver'>
              <button onClick={selectNewService} className='select-option'>
                Select another service
              </button>
              <button onClick={onClosePrompt} className='accept'>
                I understand
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`in-app__prompt__backdrop fade${openPrompt ? ' show' : ''}`}
      ></div>
    </>
  );
};

export default InAppSaverPrompt;

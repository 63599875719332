import React from 'react';
import { InfoDarkIcon } from './asset-contants';

const AddressWithInfo = ({ onClick, isReceiver }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <p className='mb-0'>{isReceiver ? "Receiver's" : ''}Address</p>
      <img
        title='Click to see address guide'
        onClick={onClick}
        style={{
          width: '20px',
          paddingLeft: '6px',
          cursor: 'pointer'
        }}
        src={InfoDarkIcon}
        alt=''
      />
    </div>
  );
};

export default AddressWithInfo;

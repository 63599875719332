import React, { useEffect, useState } from 'react';
import { Button, IconButton, Skeleton } from '@mui/material';
import Wrapper from '../../../components/Layouts/wrapper';
import {
  useDeleteAddressMutation,
  useUpdateAddressMutation
} from '../../../operations/mutations';
import { warningState } from '../../../recoil/atoms';
import { useRecoilState } from 'recoil';
import { useGetSavedAddresses } from '../../../operations/queries';
import Pagination from '../../../components/Commons/pagination';
import usePagination from '../../../utilities/usePagination';
import CustomSpinner from '../../../components/custom-spinner';
import DeleteOverlay from '../../../components/delete-overlay';
import BusyOverlay from '../../../components/busy-overlay';
import EmptyList from '../../../components/empty-list';
import emptyAddressIcon from '../../../assets/svg/address-empty.svg';
import { CheckBox, Delete, Edit } from '@mui/icons-material';
import NewAddressesModal from '../../../components/modals/new-addresses-modal';

const NewAddresses = () => {
  const take = 10;
  const [deleteAddress, deleteAddressResult] = useDeleteAddressMutation();
  const [, setShow] = useRecoilState(warningState);
  const [deleteId, setDeleteId] = useState(null);
  const [defaultId, setDefaultId] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [address, setAddress] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [getAddressesList, { error, loading, data }] = useGetSavedAddresses();
  const {
    start,
    end,
    totalCount,
    disableNext,
    disablePrevious,
    currentPage,
    onPreviousPage,
    onNextPage
  } = usePagination({
    take,
    currentPage: data?.getAddresses?.currentPage,
    totalCount: data?.getAddresses?.totalCount
  });
  const [updateAddress, updateAddressResult] = useUpdateAddressMutation(() =>
    setDefaultId(null)
  );
  const handleDelete = id => {
    setShow(true);
    setActiveId(null);
    setDeleteId(id);
  };
  const handleDropdown = id => {
    setActiveId(id);
  };
  const handleSetAsDefault = (id, unset, mydata) => {
    setShow(true);
    setActiveId(null);
    setDefaultId({ id, unset });
  };
  const handleCancel = () => {
    setTimeout(() => {
      setActiveId(null);
      setDefaultId(null);
    }, 1000);
  };

  const exitModal = () => {
    setIsDirty(false);
    setOpenModal(false);
  };

  const onSetAsDefault = id => {
    resetDefault();
    updateAddress({ isDefault: true }, id);
    delete address?.createdDate;
    delete address?.updatedDate;
    delete address?.addressType;
    delete address?.user;
    delete address?.id;
    delete address?.__typename;
  };

  const resetDefault = async () => {
    const addressArr = data?.getAddresses?.list.filter(add => add.isDefault);
    for (const address of addressArr) {
      updateAddress({ isDefault: false }, address.id);
    }
  };

  const handleEditAddress = id => {
    const address = data?.getAddresses?.list?.find(add => add.id === id);
    setAddress({ ...address, id });
    setOpenModal(true);
  };

  useEffect(() => {
    getAddressesList({
      take,
      page: currentPage
    });
    // eslint-disable-next-line
  }, [data, currentPage]);
  const paginationSettings = {
    start,
    end,
    take,
    disableNext,
    disablePrevious,
    totalCount,
    loading,
    onNextPage,
    onPreviousPage
  };

  window.document.addEventListener('click', event => {
    if (event.target.id !== 'address-list-item-dropdown') {
      setActiveId(null);
    }
  });
  return (
    <Wrapper>
      <NewAddressesModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        address={address}
        setAddress={setAddress}
        setIsDirty={setIsDirty}
      />
      <div className='new-addresses'>
        <div className='new-addresses__wrap'>
          <div className='new-addresses__header'>
            {loading ? (
              <CustomSpinner
                text='Getting your addresses...'
                textSize='1.2rem'
                textColor='var(--color-primary)'
                size='2rem'
                thickness='.3rem'
                background='#e6e3df'
                spinColor='var(--color-blue-dark)'
              />
            ) : (
              <h2>
                {data?.getAddresses?.list?.length
                  ? `${data?.getAddresses?.totalCount} Saved addresses`
                  : null}
              </h2>
            )}
            <button onClick={() => setOpenModal(true)}>
              Add a new address
            </button>
          </div>
          {data && !error && !data.getAddresses.list.length ? (
            <EmptyList
              emptyIcon={emptyAddressIcon}
              heading='You don’t have any saved addresses yet'
              subHeading='Request a new shipment, save an address and your addresses would appear here.'
            />
          ) : (
            <div className='new-addresses__list'>
              <div className='new-addresses__list__wrap'>
                {loading
                  ? [...Array(take).keys()].map(key => (
                      <div key={key} className='new-addresses__list__item'>
                        <div className='new-addresses__list__item__wrap'>
                          <div className='new-addresses__list__item__header'>
                            <div className='new-addresses__list__item__name w-75'>
                              <Skeleton className='w-100' />
                            </div>
                            <Button
                              variant='text'
                              className='new-addresses__list__item__action'
                            >
                              <span className='new-addresses__list__item__divider'></span>
                              <span className='new-addresses__list__item__divider'></span>
                              <span className='new-addresses__list__item__divider'></span>
                            </Button>
                          </div>
                          <div className='new-addresses__list__item__body'>
                            <div className='new-addresses__list__item__meta'>
                              <span className='w-100 new-addresses__list__item__meta__email'>
                                <Skeleton className='w-100' />
                              </span>

                              <span className='new-addresses__list__item__divider'></span>
                              <span className='w-100 new-addresses__list__item__meta__phone'>
                                <Skeleton className='w-100' />
                              </span>

                              <span className='new-addresses__list__item__divider'></span>
                              <span className='w-100 new-addresses__list__item__meta__postal'>
                                <Skeleton className='w-100' />
                              </span>
                            </div>
                            <div className='new-addresses__list__item__address'>
                              <span className='w-100 new-addresses__list__item__address__street'>
                                <Skeleton className='w-100' />
                              </span>
                              <span className='new-addresses__list__item__divider'></span>

                              <span className='w-100 new-addresses__list__item__address__landmark'>
                                <Skeleton className='w-100' />
                              </span>
                            </div>
                            <div className='new-addresses__list__item__location w-75'>
                              <Skeleton className='w-100' />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : data &&
                    !error &&
                    data?.getAddresses?.list
                      .sort((x, y) =>
                        x.isDefault === y.isDefault ? 0 : x.isDefault ? -1 : 1
                      )
                      .map(address => (
                        <div
                          key={address.id}
                          className='new-addresses__list__item'
                        >
                          <div className='new-addresses__list__item__wrap'>
                            <div
                              id='address-list-item-dropdown'
                              className={`new-addresses__list__item__dropdown${
                                address.id === activeId ? ' active' : ''
                              }`}
                            >
                              <div className='new-addresses__list__item__dropdown__wrap'>
                                <Button
                                  onClick={() => handleEditAddress(address.id)}
                                  variant='text'
                                  className='new-addresses__list__item__dropdown__action'
                                >
                                  <IconButton>
                                    <Edit />
                                  </IconButton>
                                  <span>Edit</span>
                                </Button>

                                <Button
                                  onClick={() =>
                                    handleSetAsDefault(
                                      address.id,
                                      address.isDefault,
                                      address
                                    )
                                  }
                                  variant='text'
                                  className='new-addresses__list__item__dropdown__action'
                                >
                                  <IconButton>
                                    <CheckBox />
                                  </IconButton>
                                  <span>{`${
                                    address.isDefault ? 'Uns' : 'S'
                                  }et as Default`}</span>
                                </Button>

                                <Button
                                  variant='text'
                                  className='new-addresses__list__item__dropdown__action'
                                  onClick={() => handleDelete(address.id)}
                                >
                                  <IconButton>
                                    <Delete />
                                  </IconButton>
                                  <span>Delete</span>
                                </Button>
                              </div>
                            </div>
                            <div className='new-addresses__list__item__header'>
                              <div className='new-addresses__list__item__name'>
                                <h2>{address.name}</h2>
                                {address.isDefault && <span>Default</span>}
                              </div>
                              <Button
                                variant='text'
                                className='new-addresses__list__item__action'
                                onClick={() => handleDropdown(address.id)}
                                id='address-list-item-dropdown'
                              >
                                <span className='new-addresses__list__item__divider'></span>
                                <span className='new-addresses__list__item__divider'></span>
                                <span className='new-addresses__list__item__divider'></span>
                              </Button>
                            </div>
                            <div className='new-addresses__list__item__body'>
                              <div className='new-addresses__list__item__meta'>
                                <span className='new-addresses__list__item__meta__email'>
                                  {address.email}
                                </span>
                                {address.phoneNumber && (
                                  <span className='new-addresses__list__item__divider'></span>
                                )}
                                <span className='new-addresses__list__item__meta__phone'>
                                  {address.phoneNumber}
                                </span>
                                {address.postalCode && (
                                  <span className='new-addresses__list__item__divider'></span>
                                )}
                                <span className='new-addresses__list__item__meta__postal'>
                                  {address.postalCode}
                                </span>
                              </div>
                              <div className='new-addresses__list__item__address'>
                                <span className='new-addresses__list__item__address__street'>
                                  {address.addressLine1}
                                </span>
                                {(address.addressLine2 ||
                                  address.addressLine3) && (
                                  <span className='new-addresses__list__item__divider'></span>
                                )}
                                <span className='new-addresses__list__item__address__landmark'>
                                  {address.addressLine2 || address.addressLine3}
                                </span>
                              </div>
                              <div className='new-addresses__list__item__location'>
                                <img
                                  loading='lazy'
                                  src={`https://flagcdn.com/w20/${address.countryCode.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${address.countryCode.toLowerCase()}.png 2x`}
                                  alt=''
                                />
                                <span>{`${address.city.toLowerCase()}, ${
                                  address.state
                                }. ${address.country}`}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
              </div>
            </div>
          )}
          <Pagination {...paginationSettings} />
        </div>
      </div>
      <DeleteOverlay
        warningMessage={
          defaultId
            ? defaultId?.unset
              ? 'By doing this, you unset this address as default'
              : 'You can only set one address as default.'
            : isDirty
            ? 'You have unsaved changes'
            : undefined
        }
        onDelete={() =>
          defaultId
            ? onSetAsDefault(defaultId?.id)
            : isDirty
            ? exitModal()
            : deleteAddress(deleteId)
        }
        deleteText={
          defaultId
            ? defaultId?.unset
              ? 'Unset as Default'
              : 'Set as Default'
            : isDirty
            ? 'Go Back'
            : undefined
        }
        onCancel={handleCancel}
      />
      <BusyOverlay
        loading={
          defaultId ? updateAddressResult.loading : deleteAddressResult.loading
        }
        text={
          defaultId
            ? defaultId?.unset
              ? 'Unsetting as default...'
              : 'Setting as default...'
            : 'deleting...'
        }
        speed='1.7s'
      />
    </Wrapper>
  );
};

export default NewAddresses;

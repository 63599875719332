import { useState } from 'react';

export default function usePagination({
  currentPage = 1,
  take,
  totalCount,
  totalPages
}) {
  const _totalPages = totalPages || Math.ceil(totalCount / take);
  const [_currentPage, setCurrentPage] = useState(currentPage);
  const [start, setStart] = useState(
    currentPage ? (currentPage - 1) * take + 1 : 0
  );
  const [end, setEnd] = useState(currentPage ? currentPage * take : take);
  const disablePrevious = (currentPage - 1) * take <= 0;
  const disableNext = currentPage * take >= totalCount;
  const onNextPage = () => {
    setCurrentPage(p => {
      const newCurrent = p + 1;
      setStart((newCurrent - 1) * take + 1);
      setEnd(newCurrent * take);
      return newCurrent;
    });
  };
  const onPreviousPage = () => {
    setCurrentPage(p => {
      const newCurrent = p - 1;
      setStart((newCurrent - 1) * take + 1);
      setEnd(newCurrent * take);
      return newCurrent;
    });
  };
  return Object.freeze({
    totalCount: totalCount ? totalCount : take,
    totalPages: _totalPages || 1,
    currentPage: _currentPage,
    start,
    end,
    disablePrevious,
    disableNext,
    setCurrentPage,
    onNextPage,
    onPreviousPage,
    setStart,
    setEnd
  });
}

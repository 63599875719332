import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useAuthToken } from '../token';
import { currentLoginState } from '../recoil/selectors';
import { verifyToken } from '../authorization';
import { useGetWalletQuery } from '../operations/queries';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const [authToken, setToken] = useAuthToken();
  const isLoggedIn = useRecoilValue(currentLoginState);
  const isValidToken = verifyToken(authToken);
  const isNotExpiredToken =
    isValidToken && new Date(isValidToken.exp * 1000) > Date.now();

  useGetWalletQuery();

  return (
    <Route
      {...rest}
      render={props => {
        if (
          isLoggedIn ||
          (authToken && isValidToken.success) ||
          isNotExpiredToken
        ) {
          setToken('unauthorizedSession', '/');
          return <Component {...props} />;
        } else {
          const path = history.location.pathname;
          setToken('unauthorizedSession', path);
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
                search: !!history.location.search && history.location.search
              }}
            />
          );
        }
      }}
    />
  );
};

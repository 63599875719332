import React from 'react';
import SubmitButton from '../components/submit-button';

const EmptyList = ({
  heading,
  subHeading,
  style,
  buttonText,
  onButtonClick,
  emptyIcon
}) => {
  return (
    <div className='empty-list' style={style}>
      <img src={emptyIcon} alt='no data' />

      <div className='intro-text mb-5'>
        {heading && <h1 className='intro-text__heading'>{heading}</h1>}
        <p className='intro-text__sub-heading '>{subHeading}</p>
      </div>

      {buttonText && <SubmitButton onClick={onButtonClick} text={buttonText} />}
    </div>
  );
};

export default EmptyList;

import React, { useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { shipmentMethods } from '../../constants/book-shipment-contants';
import { saveShipmentDetailsState } from '../../recoil/atoms';
import useClearLocalStorage from '../../utilities/clear-localstorage';
import BookShipmentDestination from '../book-shipment-destination';
import BookShipmentHubs from '../book-shipment-hubs';
import BookImportOptions from '../book-shipment-imports';
import BookShipmentMethods from '../book-shipment-methods';
import BookShipmentOptions from '../book-shipment-options';
import DeleteOverlay from '../delete-overlay';

const BookShipmentModal = ({
  openModal,
  setOpenModal,
  setOpenPrompt,
  onCustomNext
}) => {
  const clearStorage = useClearLocalStorage();
  const isMiniMobile = window.innerWidth <= 600;
  const firstHeight = 615;
  const secondHeight = 417;
  const thirdHeight = 583;
  const shipmentDetail = useRecoilValue(saveShipmentDetailsState);
  const selectedMethod = shipmentMethods.find(
    method => method.value === shipmentDetail?.itemCollectionMode
  );
  const modalContainer = useRef();

  const scroll = value => {
    modalContainer.current?.scroll({
      behavior: 'smooth',
      left: isMiniMobile ? 0 : value,
      top: isMiniMobile ? value : 0
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    clearStorage();
    if (window.location.pathname === '/viewquote') {
      window.location.reload();
    }
  };

  const shipmentPagesState = useMemo(
    () => [
      <BookShipmentOptions
        key={1}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setOpenPrompt={setOpenPrompt}
        scroll={scroll}
      />,
      <BookShipmentMethods
        key={2}
        openModal={openModal}
        setOpenModal={setOpenModal}
        onCustomNext={onCustomNext}
        scroll={scroll}
        firstHeight={firstHeight}
      />,
      <BookShipmentHubs
        key={3}
        scroll={scroll}
        firstHeight={firstHeight}
        secondHeight={secondHeight}
      />,

      <BookShipmentDestination
        key={4}
        openModal={openModal}
        setOpenModal={setOpenModal}
        scroll={scroll}
        firstHeight={firstHeight}
        secondHeight={secondHeight}
        thirdHeight={thirdHeight}
      />,
      <BookImportOptions
        key={5}
        openModal={openModal}
        setOpenModal={setOpenModal}
        onCustomNext={onCustomNext}
        scroll={scroll}
        firstHeight={firstHeight}
        secondHeight={secondHeight}
        // thirdHeight={thirdHeight}
      />
    ],
    // eslint-disable-next-line
    []
  );
  const shipmentPages = useMemo(
    () =>
      shipmentPagesState.filter(element => {
        if (shipmentDetail?.shipmentRoute === 'Import') {
          return [1, 5].includes(Number(element.key));
        }
        if (
          shipmentDetail?.itemCollectionMode &&
          selectedMethod?.value === 'PickUp'
        ) {
          return Number(element.key) !== 3;
        }
        return true;
      }),
    // eslint-disable-next-line
    [
      shipmentDetail?.itemCollectionMode,
      shipmentDetail?.shipmentRoute,
      selectedMethod?.value
    ]
  );

  return (
    <div
      className={`book-shipment-modal ${
        openModal ? 'show-modal' : 'close-modal'
      } `}
      style={{
        transition: 'all 0.3s ease'
      }}
    >
      <DeleteOverlay
        warningMessage={'All entries will be lost'}
        deleteText={'Close'}
        onDelete={handleCloseModal}
      />
      <div
        ref={modalContainer}
        className='book-shipment-modal-content-container'
      >
        {shipmentPages}
      </div>
    </div>
  );
};

export default BookShipmentModal;

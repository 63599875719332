import React from 'react';
import { IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const Pagination = ({
  loading,
  start,
  end,
  totalCount,
  take,
  disableNext,
  disablePrevious,
  onPreviousPage,
  onNextPage
}) => {
  return (
    <div className='pagination'>
      <div className='pagination__wrap'>
        <div className='pagination__content'>
          <span>{`Showing ${!loading ? start : 1} - ${
            end >= totalCount ? totalCount : end
          } of ${totalCount || take} results`}</span>
        </div>
        <div className='pagination__actions'>
          <IconButton
            id='pagination-action'
            className='pagination__action'
            disabled={disablePrevious}
            onClick={event => {
              window.scroll(0, 0);
              onPreviousPage(event);
            }}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            id='pagination-action'
            className='pagination__action'
            disabled={disableNext}
            onClick={event => {
              window.scroll(0, 0);
              onNextPage(event);
            }}
          >
            <ChevronRight />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default Pagination;

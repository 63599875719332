import React, { useState } from 'react';
import tickCircle from '../../../../../../assets/image/tick-circle.png';
import SubmitButton from '../../../../../../components/submit-button';
import BusyOverlay from '../../../../../../components/busy-overlay';
import FileUpload from '../../../../../../components/file-upload';
import axios from 'axios';
import { useSendAlert } from '../../../../../../utilities/send-alert';
import { useUpdateBusinessMutation } from '../../../../../../operations/mutations';
import { useRecoilState } from 'recoil';
import { userState } from '../../../../../../recoil/atoms';
import { useFormik } from 'formik';

const Certification = ({ nextStep, previousStep }) => {
  const sendAlert = useSendAlert();
  const [user, setUser] = useRecoilState(userState);
  const [success, setSuccess] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      compliance: user.businessInformation.compliance
    },
    enableReinitialize: true,
    onSubmit: values => {
      updateBusiness({
        businessId: user.businessInformation.id,
        update: values
      });
    }
  });

  const [updateBusiness, updateBusinessResult] = useUpdateBusinessMutation(
    () => {
      setUser({
        ...user,
        businessInformation: {
          ...user.businessInformation,
          compliance: values.compliance
        }
      });
      setSuccess(true);
    }
  );

  const goBack = () => {
    previousStep();
  };

  const handleUploadFile = (file, setIsUploaded) => {
    if (file && !values.compliance) {
      setFileLoading(true);

      const url = `${process.env.REACT_APP_REST_API}/upload`;
      const formData = new FormData();

      formData.append('file', file);
      formData.append('fileName', file.name);
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      axios
        .post(url, formData, config)
        .then(response => {
          setFieldValue('compliance', response.data.Location);
          setFileLoading(false);
          setIsUploaded(true);
        })
        .catch(err => {
          sendAlert(err?.message, 'error');
          setFileLoading(false);
        });
    }
  };

  const loading = fileLoading || updateBusinessResult.loading;

  return (
    <div className='verification__body'>
      {success && (
        <div className='verification__image'>
          <img src={tickCircle} className='img-fluid' alt='' />
        </div>
      )}
      <div className={`verification__content${!success ? ' mt-3' : ''}`}>
        <div className='verification__header'>
          <h1>
            {success
              ? 'CAC certificate Uploaded'
              : 'Upload your company’s CAC certificate'}
          </h1>
        </div>
        <div className='verification__description'>
          <p>
            {success
              ? 'Your CAC certificate has been successfully uploaded.'
              : 'Please ensure it matches the business name on your Topship for LSP account.'}
          </p>
        </div>
      </div>
      {!success && (
        <FileUpload
          onUploadFile={handleUploadFile}
          uploadedFile={values.compliance}
          onCancel={() => setFieldValue('compliance', '')}
        />
      )}
      {errors.compliance && (
        <p class='MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained'>
          {errors.compliance}
        </p>
      )}
      <div className='verification__action'>
        <div onClick={goBack} className='item-description__footer__action'>
          Go Back
        </div>
        <SubmitButton
          disabled={!success && !values.compliance}
          onClick={success ? nextStep : handleSubmit}
          text={success ? 'Continue' : 'Upload'}
        />
      </div>
      <BusyOverlay loading={loading} />
    </div>
  );
};

export default Certification;

import { Edit, RemoveCircle } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { toCurrency } from '../utilities/to-currency';

const OrderSummaryBox = ({
  box,
  shipmentDetail,
  itemCategories,
  deleteItem,
  pushRoute,
  boxItems
}) => {
  return (
    <div key={box} className='order-summary__box'>
      <h3
        style={{
          fontSize: '22px',
          fontWeight: '700'
        }}
        className='mt-2 mb-2'
      >
        Box {box}
      </h3>
      <div className='order-summary__item'>
        {boxItems?.map((item, index) => (
          <div key={index}>
            <h3
              style={{
                fontSize: '18px',
                fontWeight: '700',
                color: '#545859',
                padding: '16px'
              }}
            >
              Item {index + 1}
            </h3>
            <div className='m-4 order-summary__item__wrap order-summary__item'>
              <div className='order-summary__item__actions'>
                {boxItems.length > 1 && (
                  <Button
                    variant='text'
                    onClick={() => deleteItem(box, item?.itemIndex)}
                  >
                    <span>Remove item</span>
                    <RemoveCircle />
                  </Button>
                )}
                <Button onClick={() => pushRoute(2)} variant='text'>
                  <span>Edit</span>
                  <Edit />
                </Button>
              </div>
              <div className='order-summary__item__rows'>
                <div className='order-summary__item__row'>
                  <div className='order-summary__item__row__wrap'>
                    <div className='order-summary__item__row__content'>
                      <span className='order-summary__item__row__content__label'>
                        ITEM CATEGORY
                      </span>
                      <span className='order-summary__item__row__content__value'>
                        {itemCategories.find(
                          category =>
                            category.value === item?.category ||
                            category.category === item?.category
                        )?.category || '-'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='order-summary__item__row'>
                  <div className='order-summary__item__row__wrap'>
                    <div className='order-summary__item__row__content'>
                      <span className='order-summary__item__row__content__label'>
                        ITEM DESCRIPTION
                      </span>
                      <span className='order-summary__item__row__content__value'>
                        {item?.description || '-'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='order-summary__item__row'>
                  <div className='order-summary__item__row__wrap'>
                    <div
                      style={{ width: '50%' }}
                      className='order-summary__item__row__content'
                    >
                      <span className='order-summary__item__row__content__label'>
                        QUANTITY
                      </span>
                      <span className='order-summary__item__row__content__value'>
                        {item?.quantity || '-'}
                      </span>
                    </div>
                    <div className='order-summary__item__row__content'>
                      <span className='order-summary__item__row__content__label'>
                        ITEM VALUE
                      </span>
                      <span className='order-summary__item__row__content__value'>
                        {toCurrency(item?.value / 100 || 0)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderSummaryBox;

import React from 'react';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { VibIconActive } from '../../../constants/asset-contants';
import Input from '../../../components/new-dashboard/custom-input';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useEffect } from 'react';
import ChangePasswordPrompt from '../../../components/modals/change-password-prompt';
import { useRecoilState } from 'recoil';
import { userState, userUpdateLoadingState } from '../../../recoil/atoms';
import Wrapper from '../../../components/Layouts/wrapper';
import {
  useGetCitiesMutation,
  useUpdateBusinessMutation,
  useUpdateUserMutation
} from '../../../operations/mutations';
import debounce from 'lodash.debounce';
import BusyOverlay from '../../../components/busy-overlay';
import { useSendAlert } from '../../../utilities/send-alert';
import axios from 'axios';
import * as yup from 'yup';
import {
  useGetStatesQuery,
  useGetUserLazyQuery
} from '../../../operations/queries';
import {
  accountType,
  accountTypeLspUser
} from '../../../constants/authentication';
import getPlan from '../../../utilities/getPlans';
import { FileUpload } from '@mui/icons-material';
import extractFilename from '../../../utilities/extract-filename';
import CustomAutoComplete from '../../../components/new-dashboard/custom-autocomplete';

const Profile = () => {
  const sendAlert = useSendAlert();
  const [user, setLspInfo] = useRecoilState(userState);
  const [openChangePasswordPrompt, setOpenChangePasswordPrompt] = useState(
    false
  );
  const businessId = localStorage.getItem('lspId');

  const [topshipId, setTopshipId] = useState('');
  const updateUserMutation = useUpdateUserMutation();
  const [updateLoading] = useRecoilState(userUpdateLoadingState);
  const [fileLoading, setFileLoading] = useState(false);
  const [getUser] = useGetUserLazyQuery(undefined, undefined, true);
  const defaultUserValues = {
    email: '',
    phoneNumber: '',
    accountType: 'Business',
    displayPictureUrl: ''
  };
  const defaultBusinessValues = {
    businessName: '',
    logo: '',
    address: '',
    city: '',
    state: ''
  };

  const [initialProfileValues] = useState(defaultUserValues);
  const [initialBusinessValues] = useState(defaultBusinessValues);
  const [getStates, states] = useGetStatesQuery();
  const [getCities, citiesResult] = useGetCitiesMutation();

  const profileForm = useFormik({
    initialValues: initialProfileValues,
    enableReinitialize: true,
    onSubmit: async values => {
      const result = await updateUserMutation(
        {
          accountType: values.accountType,
          phoneNumber: values.phoneNumber,
          displayPictureUrl: values.displayPictureUrl
        },

        topshipId
      );
      if (!!result) {
        getUser();
      }
    }
  });

  const validationSchema = yup.object().shape({
    businessName: yup.string().required('Business name is required')
  });

  const businessForm = useFormik({
    validationSchema,
    initialValues: initialBusinessValues,
    enableReinitialize: true,
    onSubmit: async values => {
      const businessInfo = {
        businessName: values.businessName || '',
        logo:
          values.logo ||
          `${process.env.REACT_APP_STORAGE_URL}/Topship%20Gateway.png`,
        location: {
          address: values.address,
          state: values.state,
          city: values.city
        }
      };

      updateBusiness({
        businessId: user?.businessInformation?.id || '',
        update: businessInfo
      });
    }
  });
  const handleLocationSelect = place => {
    const { name, value } = place;
    businessForm.handleChange({ target: { name, value } });
  };
  const [
    updateBusiness,
    { loading: businessLoading }
  ] = useUpdateBusinessMutation(() => {
    sendAlert('Business info updated!', 'success');
    const businessInfo = {
      businessName: initialBusinessValues.businessName || '',
      logo:
        initialBusinessValues.logo ||
        `${process.env.REACT_APP_STORAGE_URL}/Topship%20Gateway.png`,
      location: {
        address: initialBusinessValues.address,
        state: initialBusinessValues.state,
        city: initialBusinessValues.city
      }
    };
    setLspInfo({
      ...user,
      businessInformation: {
        ...user.businessInformation,
        businessInfo
      }
    });

    getUser();
  });
  const handleDisplayPicture = (event, field) => {
    const file = event?.target?.files[0];

    if (file) {
      const fileType = new RegExp('png|jpeg|jpg|webp|pdf|doc|docx');
      if (!file.type || !file?.type.match(fileType)) {
        return sendAlert('File format not supported.', 'error');
      }
      setFileLoading(true);

      const url = `${process.env.REACT_APP_REST_API}/upload`;
      const formData = new FormData();

      formData.append('file', file);
      formData.append('fileName', file.name);
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      axios
        .post(url, formData, config)
        .then(response => {
          field(event.target.name, response.data.Location);
          setFileLoading(false);
        })
        .catch(err => {
          sendAlert(err?.message, 'error');
          setFileLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!!user) {
      setTopshipId(user?.topshipId);
      profileForm.setFieldValue('accountType', user?.accountType);
      profileForm.setFieldValue('phoneNumber', user?.phoneNumber);
      profileForm.setFieldValue('email', user?.email);
      profileForm.setFieldValue('displayPictureUrl', user?.displayPictureUrl);
      businessForm.setFieldValue(
        'businessName',
        user?.businessInformation?.businessName
      );
      businessForm.setFieldValue('logo', user?.businessInformation?.logo);
      businessForm.setFieldValue(
        'address',
        user?.businessInformation?.location?.address
      );
      businessForm.setFieldValue(
        'state',
        user?.businessInformation?.location?.state
      );
      businessForm.setFieldValue(
        'city',
        user?.businessInformation?.location?.city
      );
      getStates('NG');
      getCities({
        countryCode: 'NG',
        cityName: user?.businessInformation?.location
          ? user?.businessInformation?.location?.city
          : 'a'
      });
    }
    // eslint-disable-next-line
  }, [user]);

  const setLoader = updateLoading || fileLoading || businessLoading;
  return (
    <Wrapper>
      <BusyOverlay loading={setLoader} />

      <ChangePasswordPrompt
        openPrompt={openChangePasswordPrompt}
        setOpenPrompt={setOpenChangePasswordPrompt}
      />

      <div className='profile'>
        <div className='profile'>
          <div className='profile__wrap'>
            <div className='profile__header'>
              <h2
                className='mb-3'
                style={{
                  fontWeight: '600'
                }}
              >
                Personal Information
              </h2>
              <div className='profile__header__wrap'>
                <div className='profile__avatar'>
                  <div className='profile__avatar__wrap'>
                    <Button component='label' variant='text'>
                      <input
                        hidden
                        type='file'
                        name={`displayPictureUrl`}
                        onChange={e =>
                          handleDisplayPicture(e, profileForm.setFieldValue)
                        }
                      />
                      <Avatar
                        sx={{
                          width: '108px',
                          height: '108px',
                          fontSize: '5rem'
                        }}
                        src={profileForm?.values?.displayPictureUrl}
                        alt={user?.fullName}
                        className='user-avatar'
                      >
                        {!user.displayPictureUrl &&
                          user?.fullName?.split(' ')[0].charAt().toUpperCase()}
                      </Avatar>
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)'
                        }}
                      >
                        <CameraAltIcon />
                      </div>
                    </Button>

                    <h3>{user?.fullName}</h3>
                  </div>
                </div>
                {user?.accountType === accountType[1]?.value &&
                businessId === 'null' ? (
                  <div className='profile__vib-status'>
                    <div className='profile__vib-status__wrap'>
                      <div className='profile__vib-status__icon'>
                        <img src={VibIconActive} className='img-fluid' alt='' />
                      </div>
                      <span className='profile__vib-status__label'>
                        VIB Status
                      </span>
                      <span className='profile__vib-status__value'>
                        {getPlan('vibStatus', user.vibStatus)?.name} member
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className='profile__body'>
              <div className='profile__body'>
                <div className='profile__body__wrap'>
                  <div className='profile__inputs'>
                    <div className='profile__input'>
                      <label className='profile__input__label'>
                        Email Address
                      </label>
                      <Input
                        variant='outlined'
                        customLabel=''
                        name='email'
                        value={profileForm?.values?.email}
                        onChange={profileForm.handleChange}
                        helperText={
                          profileForm.touched.email && profileForm.errors.email
                        }
                        error={Boolean(
                          profileForm.touched.email && profileForm.errors.email
                        )}
                        disabled
                        className='profile__input__textfield'
                      />
                    </div>
                    <div className='profile__input'>
                      <label className='profile__input__label'>
                        Phone Number
                      </label>
                      <Input
                        variant='outlined'
                        customLabel=''
                        name='phoneNumber'
                        value={profileForm.values.phoneNumber}
                        onChange={value =>
                          profileForm.setFieldValue('phoneNumber', value)
                        }
                        helperText={
                          profileForm.touched.phoneNumber &&
                          profileForm.errors.phoneNumber
                        }
                        error={Boolean(
                          profileForm.touched.phoneNumber &&
                            profileForm.errors.phoneNumber
                        )}
                        className='profile__input__textfield'
                      />
                    </div>

                    {!user.accountType
                      .toLocaleLowerCase()
                      .includes('logistic') && (
                      <div className='profile__input'>
                        <label className='profile__input__label account-label'>
                          Account Type
                        </label>
                        <FormControl sx={{ width: '100%', marginLeft: '0' }}>
                          <RadioGroup
                            row
                            sx={{ gap: '32px' }}
                            defaultValue={profileForm.values.accountType}
                            name='accountType'
                          >
                            {accountTypeLspUser.map((account, i) => (
                              <FormControlLabel
                                className='profile__input__control-label'
                                value={account?.value}
                                key={i}
                                control={
                                  <Radio
                                    sx={{ transform: 'scale(2)' }}
                                    checked={
                                      profileForm.values.accountType ===
                                      account.value
                                    }
                                    onChange={event =>
                                      profileForm?.setFieldValue(
                                        'accountType',
                                        event.target.value
                                      )
                                    }
                                    className='sender-info__choose-address__address__radio'
                                  />
                                }
                                label={account.label}
                              >
                                {account.label}
                              </FormControlLabel>
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}
                    <div className='profile__input'>
                      <label className='profile__input__label'>Password</label>
                      <div className='w-100'>
                        <Button
                          size={'large'}
                          onClick={() => setOpenChangePasswordPrompt(true)}
                          className='profile__input__button-light'
                        >
                          Change password
                        </Button>
                      </div>
                    </div>
                    <div className='profile__input '>
                      <div className='w-100 mb-2'>
                        <Button
                          size={'large'}
                          onClick={profileForm.handleSubmit}
                          className='profile__input__button-blue'
                        >
                          Update
                        </Button>
                      </div>
                    </div>

                    {user?.accountType === accountType[2]?.value ? (
                      <div>
                        <h2
                          className='mb-4'
                          style={{
                            fontWeight: '600'
                          }}
                        >
                          Business Information
                        </h2>
                        <div className='profile__input mb-4'>
                          <label className='profile__input__label'>
                            Business Address
                          </label>
                          <Input
                            variant='outlined'
                            customLabel=''
                            name='address'
                            value={businessForm.values.address}
                            onChange={businessForm.handleChange}
                            helperText={
                              businessForm.touched.address &&
                              businessForm.errors.address
                            }
                            error={Boolean(
                              businessForm.touched.address &&
                                businessForm.errors.address
                            )}
                            placeholder='Enter business address'
                            className='profile__input__textfield'
                          />
                        </div>
                        <div className='profile__input mb-4'>
                          <label className='profile__input__label'>
                            State of Operation
                          </label>
                          <CustomAutoComplete
                            options={states.data ? states.data?.getStates : []}
                            getOptionLabel={option => option?.name}
                            isOptionEqualToValue={(option, value) =>
                              option?.name === value?.name
                            }
                            renderOption={(props, option) => (
                              <Box
                                component='li'
                                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                {...props}
                                key={props.id}
                              >
                                {option.name}
                              </Box>
                            )}
                            value={
                              states?.data?.getStates?.length
                                ? states?.data?.getStates?.find(
                                    state =>
                                      state.name?.toLowerCase() ===
                                      businessForm.values.state?.toLowerCase()
                                  )
                                : { name: '' }
                            }
                            onChange={(event, value) =>
                              handleLocationSelect({
                                name: 'state',
                                value: value?.name
                              })
                            }
                            textfieldProps={{
                              lg: 12,
                              md: 12,
                              name: 'state',
                              customLabel: '',
                              placeholder: 'Enter State of operation',
                              containerClassName: 'state',

                              error: Boolean(businessForm.errors.state),
                              helperText: businessForm.errors.state,
                              value: businessForm.values.state
                            }}
                          />
                        </div>
                        <div className='profile__input mb-4'>
                          <label className='profile__input__label'>
                            City of Operation
                          </label>
                          <CustomAutoComplete
                            options={
                              citiesResult.data.length ? citiesResult.data : []
                            }
                            getOptionLabel={option =>
                              option?.city
                                ?.split(' ')
                                ?.map(
                                  word =>
                                    `${word
                                      .charAt()
                                      .toUpperCase()}${word
                                      .substring(1, word.length)
                                      ?.toLowerCase()}`
                                )
                                ?.join(' ')
                            }
                            isOptionEqualToValue={(option, value) =>
                              option?.city === value?.city
                            }
                            renderOption={(props, option) => (
                              <Box
                                key={props.id}
                                component='li'
                                sx={{
                                  textTransform: 'capitalize'
                                }}
                                {...props}
                              >
                                {`${option?.city?.toLowerCase()}${
                                  option?.postalCode
                                    ? ` (${option?.postalCode})`
                                    : ''
                                }`}
                              </Box>
                            )}
                            value={citiesResult.data?.find(
                              city =>
                                city.city?.toLowerCase() ===
                                businessForm.values.city?.toLowerCase()
                            )}
                            noOptionsText={
                              citiesResult.loading
                                ? 'Cities loading...'
                                : 'City not found'
                            }
                            onChange={(event, value, reason) => {
                              if (!!businessForm.values.state) {
                                if (reason === 'clear') {
                                  handleLocationSelect({
                                    name: 'city',
                                    value: ''
                                  });
                                } else {
                                  handleLocationSelect({
                                    name: 'city',
                                    value: value?.city?.toLowerCase()
                                  });
                                }
                              }
                            }}
                            textfieldProps={{
                              lg: 12,
                              md: 12,
                              name: 'city',
                              customLabel: '',
                              placeholder: 'Enter City of operation',
                              containerClassName: 'profile__input__textfield',
                              error: Boolean(businessForm.errors.city),
                              helperText: businessForm.errors.city,
                              value: businessForm.values.city?.toLowerCase(),
                              onChange:
                                !!businessForm.values.state &&
                                debounce(event => {
                                  getCities({
                                    countryCode: 'NG',
                                    cityName: event?.target?.value || 'a'
                                  });
                                }, 500)
                            }}
                          />
                        </div>

                        <div className='profile__input mb-4'>
                          <label className='profile__input__label'>
                            Business Name
                          </label>
                          <Input
                            variant='outlined'
                            customLabel=''
                            name='businessName'
                            value={businessForm.values.businessName}
                            onChange={businessForm.handleChange}
                            helperText={
                              businessForm.touched.businessName &&
                              businessForm.errors.businessName
                            }
                            error={Boolean(
                              businessForm.touched.businessName &&
                                businessForm.errors.businessName
                            )}
                            placeholder='Enter Business Name'
                            className='profile__input__textfield'
                          />
                        </div>
                        <div className='profile__input mb-4'>
                          <label
                            htmlFor='evidenceOfPurchase'
                            className='profile__input__label'
                          >
                            Business Logo
                          </label>

                          <div id='upload-button' className='w-100'>
                            <Button
                              component='label'
                              variant='outlined'
                              startIcon={<FileUpload />}
                              sx={{
                                marginRight: '1rem',
                                '&:disabled': { opacity: '0.4' }
                              }}
                            >
                              Upload File
                              <input
                                type='file'
                                hidden
                                name='logo'
                                accept='.jpg, .jpeg, .png, .gif, .bmp, .webp'
                                onChange={e =>
                                  handleDisplayPicture(
                                    e,
                                    businessForm.setFieldValue
                                  )
                                }
                              />
                            </Button>
                            <span className='filename'>
                              {businessForm.values.logo
                                ? extractFilename(businessForm.values.logo)
                                : ''}
                            </span>
                          </div>
                        </div>
                        <div className='profile__input '>
                          <div className='w-100 mb-4'>
                            <Button
                              size={'large'}
                              onClick={businessForm.handleSubmit}
                              className='profile__input__button-blue'
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Profile;

import Input from '../new-dashboard/custom-input';
import { Button, Grid } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import BusyOverlay from '../busy-overlay';
import { useChangePasswordMutation } from '../../operations/mutations';

const ChangePasswordPrompt = ({ openPrompt, setOpenPrompt }) => {
  const onClosePrompt = () => {
    setOpenPrompt(false);
  };

  const [changePassword, changePasswordResult] = useChangePasswordMutation(
    onClosePrompt
  );

  const validationSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .min(8, 'Please enter a password that is longer than 8 characters')
      .required('Please enter your old password'),
    newPassword: yup
      .string()
      .min(8, 'Please enter a password that is longer than 8 characters')
      .required('Please enter your new password')
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: ''
    },
    validationSchema,
    onSubmit: values => {
      changePassword({ resetPasswordInput: values });
    }
  });

  return (
    <>
      <BusyOverlay loading={changePasswordResult.loading} />
      <div
        style={{ width: '450px' }}
        className={`sender-info__choose-address fade${
          openPrompt ? ' show' : ''
        }`}
      >
        <div className='sender-info__choose-address__wrap'>
          <div className='sender-info__choose-address__header'>
            <h2>Change Password</h2>
          </div>
          <div className='sender-info__choose-address__body'>
            <div className='sender-info__choose-address__body__wrap'>
              <Grid container spacing={'16px'}>
                <Input
                  fullWidth
                  id='password'
                  type='password'
                  name='oldPassword'
                  onChange={handleChange}
                  value={values.oldPassword}
                  customLabel={'Old Password'}
                  helperText={touched.oldPassword && errors.oldPassword}
                  error={Boolean(touched.oldPassword && errors.oldPassword)}
                />
                <Input
                  fullWidth
                  id='password'
                  type='password'
                  name='newPassword'
                  onChange={handleChange}
                  value={values.newPassword}
                  customLabel={'New Password'}
                  helperText={touched.newPassword && errors.newPassword}
                  error={Boolean(touched.newPassword && errors.newPassword)}
                />
              </Grid>
            </div>
          </div>
          <div className='sender-info__choose-address__footer'>
            <div className='sender-info__choose-address__footer'>
              <div className='sender-info__choose-address__footer__wrap'>
                <div className='sender-info__choose-address__footer__actions'>
                  <Button onClick={onClosePrompt} variant='text'>
                    <span>Cancel</span>
                  </Button>
                  <Button onClick={handleSubmit} variant='text'>
                    Change Password
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`sender-info__choose-address__backdrop fade${
          openPrompt ? ' show' : ''
        }`}
      ></div>
    </>
  );
};

export default ChangePasswordPrompt;
